import * as React from 'react';
import { Button, Col, Row, Tag } from 'antd5';
import { WorkspaceItem } from '@octostar/platform-types';
import { SaveOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { TabNode } from 'flexlayout-react';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import Editor from '@monaco-editor/react';

import { t } from '@superset-ui/core';
import { useTabNode } from 'src/octostar/hooks/useTabNode';
import { safeParseJSON } from 'src/octostar/lib/Formatters';
import { ItemTags } from '../tags/ItemTags';

export const FileMetadata = ({
  entity,
  disableEdit,
  node,
}: {
  entity: WorkspaceItem;
  disableEdit?: boolean;
  node?: TabNode;
}) => {
  const [showFileMetadata, setShowFileMetadata] = React.useState(false);
  const [fileData, setFileData] = React.useState<any>(
    entity.os_item_content || {},
  );
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const { height } = useTabNode({ node });
  const defaultHeight =
    height === 0
      ? 200
      : height > 800
      ? height / 3
      : height > 500
      ? height / 2
      : height;

  React.useEffect(() => {
    if (entity.os_item_content) {
      setFileData(entity.os_item_content);
    }
  }, [entity.os_item_content]);

  React.useEffect(() => {
    setDisabled(_.isEqual(fileData, entity.os_item_content));
  }, [entity.os_item_content, fileData]);

  const handleChange = React.useCallback(
    (value: string) => {
      const json = safeParseJSON(value);
      if (json !== null && typeof json === 'object') {
        setFileData((curr: any) => (_.isEqual(fileData, json) ? curr : json));
      }
    },
    [fileData],
  );

  const debouncedChange = _.debounce(handleChange, 1000);

  const onSave = React.useCallback(
    (entity: WorkspaceItem) => {
      const save = async (): Promise<void> => {
        if (entity) {
          setLoadingSave(true);
          return DesktopAPI.save({
            ...entity,
            os_item_content: fileData,
          })
            .then(() => {
              DesktopAPI.showToast({
                message: t('Saved %s', entity.os_item_name || ''),
                level: 'success',
              });
            })
            .finally(() => {
              setLoadingSave(false);
              setDisabled(true);
            });
        }
        return Promise.resolve();
      };
      save();
    },
    [fileData],
  );

  return (
    <div key="json-object-viewer" className="record-editor">
      <div className="record-editor-header">
        <span className="table-title">
          <span className="record-label">{`${entity?.entity_label}`}</span>
          <Tag color="default" className="table-subtitle">
            <Row justify="space-between" gutter={6}>
              <Col>File Metadata</Col>
            </Row>
          </Tag>
          <ItemTags item={entity} />
        </span>
        <div className="record-editor-header-buttons">
          {entity.os_item_content && (
            <>
              {!disableEdit && showFileMetadata && (
                <Button
                  type="primary"
                  onClick={() => onSave(entity)}
                  loading={loadingSave}
                  disabled={disabled}
                >
                  <SaveOutlined />
                </Button>
              )}
              <Button
                type="default"
                onClick={() => setShowFileMetadata(!showFileMetadata)}
              >
                {showFileMetadata ? 'Hide' : 'Show'}
              </Button>
            </>
          )}
        </div>
      </div>
      <div>
        {entity.os_item_content && showFileMetadata && (
          <Editor
            language="json"
            value={JSON.stringify(fileData, null, 2)}
            onChange={debouncedChange}
            height={defaultHeight}
            saveViewState
          />
        )}
      </div>
    </div>
  );
};
