import * as React from 'react';
import { bigNumberFormat } from 'src/octostar/lib/Formatters';
import { openDatasetViewer } from 'src/octostar/lib/workspaceOperations';
import { EventEmitterType } from '@octostar/platform-types';
import { Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ConceptCounts, ConceptsInheritance, TreeConcept } from './types';
import ConfiguredIcon from '../ConfiguredIcon';
import './conceptSelector.css';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

export const formatConceptLabel = (
  concept: string,
  counts?: number,
  ee?: EventEmitterType,
  disabled?: boolean,
  isLeaf?: boolean,
): React.ReactNode => (
  <div
    className="concept-selector-tree-label-container"
    onDoubleClick={ee && openDatasetViewer({ concept, ee })}
  >
    <>
      <span
        className={
          isLeaf && disabled
            ? 'concept-selector-tree-label-disabled'
            : 'concept-selector-tree-label'
        }
      >
        {concept}
      </span>
      {counts === -1 ? (
        <Spin indicator={antIcon} />
      ) : (
        typeof counts === 'number' && <Tag>{`${bigNumberFormat(counts)}`}</Tag>
      )}
    </>
  </div>
);

export const toTreeData = (
  concepts: ConceptsInheritance,
  conceptCounts: ConceptCounts | undefined,
  toExclude?: string[],
  filterValue?: string,
  ee?: EventEmitterType,
): { treeData: TreeConcept[]; expandedKeys: string[] } => {
  const expandedKeys: string[] = [];
  const conceptsToExclude: string[] = toExclude || [
    'os_business_workspace_record',
  ];

  const matchesFilter = (concept: string) =>
    filterValue
      ? concept.toLowerCase().includes(filterValue.toLowerCase())
      : true;

  const arrange = (base: string, path: string): TreeConcept | null => {
    const value = `${path}:${base}`;
    const title = formatConceptLabel(base, conceptCounts?.[base], ee);

    const children = concepts
      .filter(([base_concept]) => base_concept === base)
      .map(([, derived]) => arrange(derived, `${value}:${derived}`))
      .filter(node => node !== null) as TreeConcept[];

    const isFilteredNode = matchesFilter(base) || children.length > 0;

    if (isFilteredNode) {
      if (children.length > 0) {
        expandedKeys.push(value);
      }

      return {
        title,
        key: value,
        value: base,
        icon: <ConfiguredIcon hint={base} />,
        children,
      };
    }

    return null;
  };

  const filteredConcepts = concepts.filter(
    ([base, derived]) => matchesFilter(base) || matchesFilter(derived),
  );

  const branches: string[] = filteredConcepts
    .filter(([base]) => !base.startsWith('os_'))
    .map(([, derived]) => derived)
    .filter((v, i, a) => a.indexOf(v) === i);

  const roots: string[] = filteredConcepts
    .map(([base, derived]) =>
      base === 'os_business_workspace_record' ? derived : base,
    )
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter(
      concept =>
        !branches.includes(concept) && !conceptsToExclude.includes(concept),
    );

  return {
    treeData: roots
      .map(concept => arrange(concept, ''))
      .filter(node => node !== null) as TreeConcept[],

    expandedKeys,
  };
};
