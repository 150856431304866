import React from 'react';
import Button from 'src/components/Button';
import Icons from 'src/components/Icons';
import { css, t } from '@superset-ui/core';
import { isSavedSearch } from './utils';
import SavedSearchModal from './SaveEntityModal';
import { WorkspaceEntityContext } from '../WorkspaceItemContext';

const saveButtonStyles = css`
  margin-right: 0px;
`;

type SaveButtonProps = {
  saveDisabled?: boolean;
};

export const SaveButton = (props: SaveButtonProps) => {
  const { item } = React.useContext(WorkspaceEntityContext);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (item?.entity_id) {
      setLoading(false);
    }
  }, [item?.entity_id]);

  const onHide = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const renderModal = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const isSavedSearchEntity = isSavedSearch(item);

  return (
    <>
      <Button
        buttonStyle="link"
        onClick={renderModal}
        loading={loading}
        disabled={props.saveDisabled || loading}
        data-test="query-save-button"
        title={isSavedSearchEntity ? t('Save changes') : t('Save a new search')}
      >
        <Icons.SaveOutlined iconSize="l" css={saveButtonStyles} />
        {isSavedSearchEntity ? t('Save') : t('Save as')}
      </Button>
      {showModal && !!item && <SavedSearchModal item={item} onHide={onHide} />}
    </>
  );
};
