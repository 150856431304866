import * as React from 'react';
import { EventEmitterType } from '@octostar/platform-types';
import ee from '../interface';

type EventeEmitterContextType = {
  ee: EventEmitterType;
  context: string;
};
export const EventEmitterContext =
  React.createContext<EventeEmitterContextType>({
    ee,
    context: '',
  });

/**
 * Is the message still relevant? Which 'parent provider'? EventEmitterContextProvider is used nowhere atm.
 * TODO: connect to the parent provider for events travelling across this boundary
 * @param param0
 * @returns
 */
export const EventEmitterContextProvider = ({
  context,
  children,
}: {
  context: string;
  children: any;
}) => {
  const state = React.useRef({ ee, context });
  return (
    <EventEmitterContext.Provider value={state.current}>
      {children}
    </EventEmitterContext.Provider>
  );
};
