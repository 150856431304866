import LRU from 'lru-cache';
import {
  Entity,
  QueryExecutor,
  SendQueryOptions,
} from '@octostar/platform-types';

const MAX_BATCH_SIZE = 500;

type PreflightPromise<T> = {
  promises: { [key: string]: Promise<T> };
  promise: Promise<{ [key: string]: T }>;
  fetch: () => any;
  includeEntity: (entity: Entity) => void;
  timeout: NodeJS.Timeout;
};
class Deferred {
  promise;

  reject: (reason?: any) => any = () => undefined;

  resolve: (value?: unknown) => any = () => undefined;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
export type Row = any;
export type ToKey<T> = (t: T) => string;
export type ToEntity<T> = (t: T) => Entity;
export type RowTransform<O> = (row: Row) => O;
export type QueryResultsPreprocessor = (
  rows: Row[],
  input: Entity[],
) => Promise<Row[]>;
export type KeysToQuery<Input> = (
  batchKey: string,
  keys: string[],
  input: Input,
  entities: Entity[],
) => Promise<string | string[]>;
export type LocalStorage<Input, Output> = {
  get: (input: Input) => Promise<Output | undefined>;
  set: (input: Input, value: Output) => Promise<void>;
};
export type CachingQueryExecutorArgs<Input, Output> = {
  cache: LRU<string, any>;
  queryExecutor: QueryExecutor;
  localStorage?: LocalStorage<Input, Output>;

  /**
   * Required
   * Get the sql query for the given set of keys.
   */
  toSql: KeysToQuery<Input>;
  /**
   * Optional.
   * Gets the key for the input data
   * default uses the entity_id field.
   */
  key?: ToKey<Input>;
  /**
   * Required
   * Get the entity query for the input data
   */
  getEntity: ToEntity<Input>;
  /**
   * Optional.
   * Gets the key by which the data is batched
   * default uses the entity_type field.
   */
  queryBatchKey?: ToKey<Input>;
  /**
   * Optional.
   * Gets the key for the row of data before transform; default
   * uses the value of the entity_id field.
   */
  rowToKey?: ToKey<Output>;
  /**
   * Whether the query output is grouped by key default = false.
   * If asArray = true getArray must be use to get the results
   */
  asArray?: boolean;
  /*
   * Optional.
   * Transforms the row of query result data into the output type.
   * By default the result is not transformed.
   */
  transform?: RowTransform<Output>;

  /**
   * Optional.
   * Validates the query results data immediately after query execution,
   * returning the validated rows, or raising an exception if not valid
   * ; default is not to validate the results.
   */
  preprocess?: QueryResultsPreprocessor;

  /**
   * Optional.
   * Called immediately when a new value added to the cache.
   */
  afterCacheSet?: (key: string, value: any) => void;

  /**
   * Optional.
   * Debounce timeout in milliseconds; default = 100
   */
  debounceTimeout?: number;
  /**
   * Optional.
   * Make the query cancellable or low priorty
   */
  sendQueryOptions?: SendQueryOptions;
  /**
   * Optional.
   * assign a default value if no result.
   */
  getDefaultValue?: (input: Input) => Output | undefined;

  /**
   * Optional.
   * Maximum batch size before sending query.
   * Defaults to {@link MAX_BATCH_SIZE}
   */
  maxBatchSize?: number;
};
export const EntityToKey = (entity: any) => entity.entity_id;
export class CachinQueryExecutor<Input, Output> {
  private cache: LRU<string, any>;

  private queryExecutor: QueryExecutor;

  private localStorage?: LocalStorage<Input, Output>;

  private toSql: KeysToQuery<Input>;

  private asArray = false;

  private union: string[] = [];

  private key: ToKey<Input> = EntityToKey;

  private getEntity: ToEntity<Input>;

  private queryBatchKey: ToKey<Input> = (i: any) => i.entity_type;

  private rowToKey: ToKey<Row> = EntityToKey;

  private transform: RowTransform<Output> = (row: Row) => row;

  private preprocessor: QueryResultsPreprocessor = async (rows: Row[]) => rows;

  private afterCacheSet: (key: string, value: Output) => void = () => undefined;

  private debounceTimeout = 100;

  private maxBatchSize = MAX_BATCH_SIZE;

  private sendQueryOptions: SendQueryOptions | undefined = undefined;

  private getDefaultValue = (input: Input) => undefined as Output;

  private preflightPromises: {
    [key: string]: PreflightPromise<Output>;
  } = {};

  constructor(args: CachingQueryExecutorArgs<Input, Output>) {
    this.cache = args.cache;
    this.queryExecutor = args.queryExecutor;
    this.toSql = args.toSql;
    this.preprocessor = args.preprocess || this.preprocessor;
    Object.assign(this, args);
  }

  public async get(input: Input): Promise<Output> {
    if (this.asArray) {
      return Promise.reject(
        new Error(
          'options included {asArray: true} so you must use getArray(...)',
        ),
      );
    }
    return this.myGet(input);
  }

  /**
   * Gets the output result as an array
   * @param input
   * @returns
   */
  public async getArray(input: Input): Promise<Output[]> {
    if (!this.asArray) {
      throw new Error('options did not include {asArray: true}');
    }
    const a = (await this.myGet(input)) as Promise<Output[]>;
    return a || [];
  }

  public set(input: Input): void {
    const key = this.key(input);
    const batchKey = this.queryBatchKey(input);
    const cacheKey = `${batchKey}|${key}`;
    this.cache.set(cacheKey, input);
  }

  public clear(input: Input): void {
    const key = this.key(input);
    const batchKey = this.queryBatchKey(input);
    this.cache.delete(key);
    this.cache.delete(`${batchKey}|${key}`);
  }

  private async myGet(input: Input): Promise<Output> {
    const key = this.key(input);
    const batchKey = this.queryBatchKey(input);
    if (!key || !batchKey) {
      return Promise.reject(
        new Error(`Invalid cache input: ${JSON.stringify(input)}`),
      );
    }
    const cacheKey = `${batchKey}|${key}`;
    // console.log('Try get: cacheKey:', cacheKey);
    let result = this.cache.get(cacheKey) as Output;
    if (result === undefined) {
      if (this.localStorage) {
        const stored = await this.localStorage.get(input);
        if (stored) {
          this.cache.set(cacheKey, stored);
          return stored;
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      result = await this._getPromise(input);
      // console.log('cache setting: ', cacheKey, result);
      if (result === undefined) {
        result = this.getDefaultValue?.(input);
      }
      if (result) {
        await this.localStorage?.set(input, result);
      }
      if (result !== undefined) {
        this.cache.set(cacheKey, result);
        setTimeout(() => this.afterCacheSet(cacheKey, result), 0);
      }
    }
    return result;
  }

  private _createPreflightPromise = (input: Input) => {
    const promises = {};
    const deferred = new Deferred();
    const batchKey = this.queryBatchKey(input);
    const entities: Entity[] = [];
    const promise: Promise<{ [batchKey: string]: Output | Output[] }> =
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        await deferred.promise;
        const query = async () => {
          const keys = Object.keys(promises);
          if (keys.length < this.maxBatchSize) {
            // start a new batch now.
            delete this.preflightPromises[batchKey];
          }
          return this.toSql(batchKey, keys, input, entities);
        };
        let data = await this.queryExecutor.sendQuery(
          query,
          this.sendQueryOptions,
        );
        data = await this.preprocessor(data, entities);

        const map: { [key: string]: Output | Output[] } = {};
        (data || []).forEach((row: any) => {
          const key = this.rowToKey(row);
          const o = this.transform(row);
          map[key] = this.asArray ? map[key] || [] : o;
          if (this.asArray) {
            if (Array.isArray(o)) {
              (o as Output[]).forEach(x => (map[key] as Output[]).push(x));
            } else {
              (map[key] as Output[]).push(o);
            }
          }
        });
        resolve(map);
      });
    const fetch = () => {
      if (Object.keys(promises).length === this.maxBatchSize) {
        // start a new batch now.
        delete this.preflightPromises[batchKey];
      }
      deferred.resolve();
    };

    return {
      promise,
      promises,
      fetch,
      includeEntity: (entity: Entity) => {
        // push the entity into the batch if it is not already there.
        if (
          !entities.find(
            x =>
              x.entity_id === entity.entity_id &&
              x.entity_type === entity.entity_type,
          )
        ) {
          entities.push(entity);
        }
      },
      timeout: setTimeout(() => {
        throw new Error('this never happens');
      }, 0),
    };
  };

  // eslint-disable-next-line no-underscore-dangle
  private _getPromise(input: Input) {
    const batchKey = this.queryBatchKey(input);
    const key = this.key(input);
    const preflight = (this.preflightPromises[batchKey] =
      // eslint-disable-next-line no-underscore-dangle
      this.preflightPromises[batchKey] || this._createPreflightPromise(input));
    preflight.includeEntity(this.getEntity(input));
    const promise = (preflight.promises[key] =
      preflight.promises[key] ||
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          const result = await preflight.promise;
          resolve(result[key]);
        } catch (e) {
          reject(e);
        }
      }));
    clearTimeout(preflight.timeout);
    const batchSize = Object.entries(preflight.promises).length;
    if (batchSize >= this.maxBatchSize) {
      console.log(`🐽 Sending large batch! size: ${batchSize}`);
      preflight.fetch();
    } else {
      preflight.timeout = setTimeout(preflight.fetch, this.debounceTimeout);
    }
    return promise as Promise<Output>;
  }
}
