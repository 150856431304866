import * as localforage from 'localforage';
import getBootstrapData from 'src/utils/getBootstrapData';
import ee, { clientCore } from '../interface';

let userkey = 'no user';
let ontology: string;

const qualify = (key: string) => `23|${userkey}|${ontology}|${key}`;
const getChangeNotificationKey = (key: string) =>
  clientCore(`preferenceChanged/${key}`);

const getOntologyKey = () => `23|${userkey}|os_preferred_ontology`;
export const bootstrapPreferences = async () => {
  const { user } = getBootstrapData();
  userkey = user?.username || 'no user';
  ee.on(clientCore('ontologyChanged'), newOntology => {
    ontology = newOntology;
    if (!ontology) {
      localforage.removeItem(getOntologyKey());
    } else {
      localforage.setItem(getOntologyKey(), newOntology);
    }
  });
};

export const clear = () => localforage.clear();

/**
 * Returns presently selected ontology as stored in the browser
 */
export const getSelectedOntology = async (): Promise<string | undefined> => {
  if (!ontology) {
    const onto = await localforage.getItem(getOntologyKey());
    if (onto) {
      ontology = `${onto}`;
    }
  }
  return ontology;
};

export const getPreference = async <T>(
  key: string,
  fallback: T,
): Promise<T> => {
  const fqkey = qualify(key);
  const pref = await localforage.getItem(fqkey);
  if (pref !== undefined && pref !== null) {
    return pref as T;
  }

  return fallback;
};

export const onPreferenceChanged = <T>(
  key: string,
  listener: (preference: T) => void,
) => {
  const fqkey = getChangeNotificationKey(key);
  ee.on(fqkey, listener);
  return () => {
    ee.off(fqkey, listener);
  };
};
const privateSetPreference = async (key: string, preference: any) => {
  const current = await getPreference(key, undefined);
  if (current === preference) {
    return;
  }
  if (current && preference) {
    const updated = JSON.stringify(preference);
    if (JSON.stringify(current) === updated) {
      return;
    }
  }
  const notify = (value: any) => {
    const notifcationKey = getChangeNotificationKey(key);
    setTimeout(() => {
      ee.emit(notifcationKey, value);
    }, 0);
  };

  const fqkey = qualify(key);
  if (preference === undefined || preference === null) {
    await localforage.removeItem(fqkey);
    notify(undefined);
    return;
  }
  const copy = JSON.parse(JSON.stringify(preference));
  try {
    await localforage.setItem(fqkey, copy);
    notify(copy);
  } catch (err) {
    console.log(err);
  }
};

export const clearPreferences = async (keyPrefix: string): Promise<void> => {
  if (!keyPrefix) {
    clear();
    return;
  }
  const finalPrefix = qualify(keyPrefix);
  const qualifyLength = qualify('').length;

  const keys = await localforage.keys();
  keys.forEach(key => {
    if (key.startsWith(finalPrefix)) {
      privateSetPreference(key.substring(qualifyLength), undefined);
    }
  });
};

export const setPreference = async <T>(
  key: string,
  preference: T | ((currentValue: T | undefined) => T | undefined),
) => {
  if (typeof preference === 'function') {
    return getPreference(key, undefined)
      .then(preference as any)
      .then(value => privateSetPreference(key, value));
  }
  return privateSetPreference(key, preference);
};