import { QueryObjectFilterClause } from '@superset-ui/core';

export interface Property {
  concept: string;
  property_name: string;
  property_type: string;
}

export interface Inheritance {
  base_concept: string;
  derived_concept: string;
}

export type Tag = {
  tag_name: string;
  tag_value: string;
};

export interface Concept {
  concept_name: string;
  parents: string[];
  properties: Property[] | undefined;
  allProperties: Property[] | undefined;
  relationships: Relationship[];
  columns: string[];
  archetype: Concept;
  tags: Tag[];
  labelKeys: string[];
  allLabelKeys: string[];
}

export type ConceptMap = Map<string, Concept>;

export type Entity = {
  entity_id: string;
  entity_type: string;
  entity_label: string;
};
export type ConnectedEntity = Entity & {
  concept_name: string;
};

export interface Relationship {
  key: string;
  concept: string;
  target_concept: string;
  relationship_name: string;
  inverse_name: string;
  source_properties: string;
  target_properties: string;
  mapping_name: string;
  additional_properties: string;
  mapping_query: string;
  tables: string;
  mapping_json: string;
  is_inverse: number;
  is_mtm: number;
  transitivity: number;
  datasource_id: string;
  sort_order: number;
  description?: string;
}

export enum SortOrder {
  PREFERRED = 21,
  OTHER = 10,
  HIDE = -1,
}

export interface WorkspaceRecordIdentifier {
  entity_type: string;
  os_entity_uid: string;
  os_workspace: string;
}
export type OsWorkspaceEntity = Entity & WorkspaceRecordIdentifier;

export type ConceptWithParents = Concept & { parents: string[] };

export interface WorkspaceItemConfig {
  entity_id: string;
  entity_type: string;
  workspace_id: string;
  last_updated: number;
}
export type ConceptFilter = {
  concept: string;
  filters: QueryObjectFilterClause[];
};
export type WorkspaceItemModel = {
  // Ephemeral Ui state in lieu of using complex redux logic
  // It is not saved in persistence, can be mutated by components
  __uiState?: {
    rename?: boolean;
    query?: ConceptFilter;
    getEntitiesCount?: () => Promise<number>;
    getEntities?: (limit?: number) => Promise<Entity[]>;
    showCountOnlyWhenClosed?: boolean;
    initialState?: any;
    highlight?: boolean;
    draft?: boolean;
  };
  os_item_name: string;
  os_item_type: string;
};

export type WorkspaceItemBase = WorkspaceItemModel & {
  os_entity_uid: string;
  os_workspace: string;
};
export type OsAuditFields = {
  os_created_by?: string;
  os_created_at?: string;
  os_deleted_by?: string;
  os_deleted_at?: string;
  os_hidden_by?: string;
  os_hidden_at?: string;
  os_last_updated_by?: string;
  os_last_updated_at?: string;
};

export type CommentWorkspaceItem = WorkspaceItemBase &
  OsAuditFields &
  Entity & {
    contents: string;
    slug?: string;
    os_parent_uid?: string;
  };

export type WorkspaceRecord = Entity &
  WorkspaceItemBase &
  OsAuditFields & {
    os_icon?: string;
  };

export interface Colorful {
  color?: string;
}
export interface TagAttributes extends Colorful {
  group?: string;
  order?: number;
  os_workspace: string;
  os_item_name: string;
}

export type OsTag = WorkspaceRecord & TagAttributes;

export type WorkspaceItem<T = any> = WorkspaceRecord & {
  os_item_content?: T;
  os_item_content_type?: string;
  os_parent_folder?: string;
  os_has_attachment?: boolean;
};

export type WorkspaceRelationship = WorkspaceItemBase &
  OsAuditFields & {
    os_entity_uid_from: string;
    os_entity_uid_to: string;
    os_entity_type_from: string;
    os_entity_type_to: string;
    os_relationship_name: string;
  };
export type DraftWorkspaceItem = WorkspaceItem & {
  os_draft_item: true;
};
export type WorkspaceItems = WorkspaceItem[];

export interface RecordProvider {
  getCount: () => Promise<number>;
  getRecord: (i: number) => Promise<Entity>;
  onDelete?: (entity: Entity) => Promise<void>;
  getMessage(): Promise<string | undefined>;
}

export type NamedEntityGroup = {
  entity_label: string;
  query: ConceptFilter;
  count: number;
  type: 'group';
};

export type FocusListItem =
  | {
      entity: Entity;
      count: number;
      tabName?: string;
      type?: 'single';
    }
  | NamedEntityGroup;

export type ReasonedBoolean =
  | { value: true }
  | { value: false; reason: string };
