import { parse } from 'yaml';
import nunjucks from 'nunjucks';
import SqlString from 'sqlstring';
import { uniq } from 'lodash';
import { Entity, Relationship, WorkspaceItem } from '@octostar/platform-types';
import { DESKTOP_MESSAGE_TYPES } from '../api/messagesTypes';
import { apiCall } from '../interface';

type RelationshipQueryUncompiled = {
  counts?: string;
  entities?: string;
};
type RelationshipQueriesUncompiled = {
  [key: string]: RelationshipQueryUncompiled;
};
type RelationshipQuery = {
  counts?: nunjucks.Template;
  entities?: nunjucks.Template;
};
type RelationshipQueries = { [key: string]: RelationshipQuery };
const CACHE_TTL = 1000 * 60; // 1 minute
type CachedValueType = {
  expires: number;
  sig: string;
  queries: RelationshipQueries;
};
const cachedValue: CachedValueType = {
  expires: 0,
  sig: '',
  queries: {},
};
const RELATIONSHIP_QUERIES_FILENAME = 'relationship_queries.yaml';
const getRelationshipQueriesYaml = async (): Promise<RelationshipQueries> => {
  if (cachedValue.expires > Date.now()) {
    return cachedValue.queries;
  }
  try {
    const items: WorkspaceItem[] = await apiCall(
      DESKTOP_MESSAGE_TYPES.getWorkspaceItems,
      [RELATIONSHIP_QUERIES_FILENAME],
    );
    if (items.length === 0) {
      return {};
    }
    const sig = items
      .map(x => `${x.os_entity_uid}|${x.os_last_updated_at}`)
      .join('|');
    if (sig === cachedValue.sig) {
      return cachedValue.queries;
    }

    const attachments: string[] = await Promise.all(
      items.map(x => apiCall<string>(DESKTOP_MESSAGE_TYPES.getAttachment, [x])),
    );

    const parsed: RelationshipQueriesUncompiled[] = attachments
      .map(x => {
        try {
          return parse(x);
        } catch (e) {
          console.log(e);
          return undefined;
        }
      })
      .filter(x => x !== undefined);
    // reduced the unparsed quereis to a single object, parsed
    const queries: RelationshipQueries = {};
    parsed.forEach(x => {
      Object.entries(x).forEach(([key, value]) => {
        try {
          queries[key] = {
            counts: value.counts ? nunjucks.compile(value.counts) : undefined,
            entities: value.entities
              ? nunjucks.compile(value.entities)
              : undefined,
          };
        } catch (e) {
          console.log(
            `problem compiling relationship ${key} query from file ${RELATIONSHIP_QUERIES_FILENAME}`,
            e,
          );
        }
      });
    });
    cachedValue.sig = sig;
    cachedValue.queries = queries;
    return cachedValue.queries;
  } catch (e) {
    console.log(`problem fetching/parsing ${RELATIONSHIP_QUERIES_FILENAME}`, e);
    return {};
  } finally {
    cachedValue.expires = Date.now() + CACHE_TTL;
  }
};

export const toLocalDataRelationshipCountSql = async (
  batchKey: string,
  keys: string[],
  input: { entity: Entity; relationship: Relationship },
) => {
  const entityIds = uniq(
    keys.map(x => SqlString.escape(x.split('|').shift())),
  ).join(', ');

  return `SELECT os_entity_type_from, os_entity_uid_from, os_relationship_name, os_entity_uid_to 
    FROM timbr.os_workspace_relationship
    WHERE os_entity_uid_from in (${entityIds})
    OR os_entity_uid_to in (${entityIds})
    `;
};

export const toRelationshipCountSql = async (
  input: { entity: Entity; relationship: Relationship },
  entities: Entity[],
) => {
  const { relationship: rel } = input;
  const escaped_entity_ids = entities
    .map(x => SqlString.escape(x.entity_id))
    .join(', ');

  const configured = await getRelationshipQueriesYaml();
  const configuredQuery = configured[rel.relationship_name]?.counts;
  let labels = '';
  if (configuredQuery) {
    labels = entities.map(x => SqlString.escape(x.entity_label)).join(', ');
  }

  const q1 = configuredQuery
    ? configuredQuery.render({ ids: escaped_entity_ids, labels })
    : `
      SELECT /*+ dtimbr_join_type(inner) */ "${rel.inverse_name}[${rel.concept}].entity_id" AS entity_id, count(1) AS cardinality from dtimbr."${rel.target_concept}"
      WHERE entity_id IS NOT NULL
      AND "${rel.inverse_name}[${rel.concept}].entity_id" in (${escaped_entity_ids})
      GROUP BY "${rel.inverse_name}[${rel.concept}].entity_id"
      `;
  // const q2 = getLocalCountsQuery(rel, entity_ids);
  return `SELECT /*+ dtimbr_join_type(inner) */ entity_id, ${SqlString.escape(
    rel.relationship_name,
  )} as relationship_name, sum(cardinality) as cardinality FROM (
  ${q1}
  ) GROUP BY entity_id, relationship_name\n`;
};

export const toRelationshipSql = async (
  rel: Relationship,
  entities: Entity[],
): Promise<string> => {
  const configured = await getRelationshipQueriesYaml();
  const configuredQuery = configured[rel.relationship_name]?.entities;
  let labels = '';
  if (configuredQuery) {
    labels = entities.map(x => SqlString.escape(x.entity_label)).join(', ');
  }
  const escaped_entity_ids = entities.map(x => SqlString.escape(x.entity_id));

  return configuredQuery
    ? configuredQuery.render({ ids: escaped_entity_ids, labels })
    : `
    SELECT /*+ dtimbr_join_type(inner) */ t1.entity_id as mapping_entity_id, 
    "${rel.relationship_name}[${rel.target_concept}].entity_id" as entity_id, 
    '${rel.target_concept}' as entity_type,
    null as entity_label
    FROM dtimbr."${rel.concept}" as t1 
    WHERE t1.entity_id in (${escaped_entity_ids})
    AND "${rel.relationship_name}[${rel.target_concept}].entity_id" IS NOT NULL`;
};

export const preprocess = async (rows: any[], entities: Entity[]) => {
  /*
   * if there are any rows, check the first row to see if `entity_id` is among the entities.
   * if not, then entity_id is actually the `entity_label` and we need to
   * find and replace with the actual entity_id for each row, keeping in mind
   * that the same entity_label could be used for multiple entities, in which
   * case we need to duplicate the row for each entity.
   */
  if (
    rows.length === 0 ||
    entities.find(
      (x: any) =>
        x.entity_id === (rows[0].mapping_entity_id || rows[0].entity_id),
    )
  ) {
    return rows;
  }
  const labelToEntityId: { [label: string]: string[] } = {};
  entities.forEach(x => {
    labelToEntityId[x.entity_label] = labelToEntityId[x.entity_label] || [];
    labelToEntityId[x.entity_label].push(x.entity_id);
  });
  const newRows: any[] = [];
  rows.forEach(row => {
    const key = row.mapping_entity_id ? 'mapping_entity_id' : 'entity_id';
    const entity_ids = labelToEntityId[row[key]];
    if (entity_ids) {
      entity_ids.forEach(entity_id => {
        const newRow = { ...row };
        newRow[key] = entity_id;
        newRows.push(newRow);
      });
    } else {
      newRows.push(row);
    }
  });
  return newRows;
};
