import { Relationship, SortOrder } from '@octostar/platform-types';

export const relationshipCompare = (r1: Relationship, r2: Relationship) => {
  const is1Inverse = r1?.is_inverse ? 1 : 0;
  const s1 = is1Inverse ? r1.inverse_name : r1.relationship_name;
  const is2Inverse = r2?.is_inverse ? 1 : 0;
  const s2 = is2Inverse ? r2.inverse_name : r2.relationship_name;
  return s1 === s2 ? is1Inverse - is2Inverse : s1.localeCompare(s2);
};

export const safeParseSortOrder = (
  value: string,
  defaultValue = SortOrder.OTHER,
) => {
  try {
    if (typeof value !== 'string' || value === '' || value === null) {
      return defaultValue;
    }
    const parsed = parseInt(value, 10);
    return Number.isNaN(parsed) ? defaultValue : parsed;
  } catch (e) {
    return defaultValue;
  }
};

export const sortRelationships = (relationships: Relationship[]) =>
  relationships
    .filter(r => r.sort_order !== SortOrder.HIDE)
    .sort(
      (r1, r2) => r2.sort_order - r1.sort_order || relationshipCompare(r1, r2),
    );
