import * as React from 'react';
import { Spin } from 'antd5';
import { CommentWorkspaceItem, Entity } from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { WorkspaceContext } from 'src/octostar/components/WorkspaceContext';
import { CommentItem } from 'src/octostar/components/Entity/Comments/types';
import {
  addComment,
  entityToComment,
  getCommentsForEntity,
} from 'src/octostar/components/Entity/Comments/CommentsIO';

import { CommentEditor } from './CommentEditor';
import { ThreadedComment } from './ThreadedComment';

export const EntityComments: React.FC<{
  entity: Entity;
}> = ({ entity }) => {
  const [comments, setComments] = React.useState<CommentItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editorContent, setEditorContent] = React.useState<string>('');
  const { os_workspace } = React.useContext(WorkspaceContext);
  const [sending, setSending] = React.useState(false);
  const isMounted = React.useRef(true);

  const refresh = React.useCallback(
    async (force: boolean) => {
      try {
        setLoading(true);
        const comments = await getCommentsForEntity(entity, force);
        if (isMounted.current) {
          setComments(comments);
        }
      } catch (e) {
        console.warn('Error getting comments', e);
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    },
    [entity],
  );

  React.useEffect(() => {
    const promise = OntologyAPI.subscribe(entity, () => {
      if (isMounted.current) {
        refresh(false);
      }
    }).then(unsubscribe => {
      if (!isMounted.current) {
        unsubscribe();
      }
      return unsubscribe;
    });
    return () => {
      isMounted.current = false;
      promise.then(unsubscribe => unsubscribe());
    };
  }, [entity, refresh]);

  const appendComment = React.useCallback(
    (text: string, parent_id?: string) =>
      addComment(entity, os_workspace!, text, parent_id).then(comment => {
        if (isMounted.current) {
          setComments(x => [
            ...x,
            entityToComment(entity, comment as CommentWorkspaceItem),
          ]);
        }
      }),

    [entity, os_workspace],
  );

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditorContent(e.target.value);
  };

  if (loading && !comments.length) {
    return (
      <div className="comments-spinner-wrapper">
        <Spin size="large" />
      </div>
    );
  }

  const topLevelComments = comments
    .filter(comment => comment.parent_comment === null)
    .sort((a, b) => a.created_at - b.created_at);

  return (
    <div className="comments-threaded-comments">
      <h4>
        Conversations about <code>{entity.entity_label}</code> (
        {entity.entity_type})
      </h4>
      {topLevelComments.map(tlComment => (
        <ThreadedComment
          key={tlComment.comment_id}
          comment={tlComment}
          allComments={comments}
          parent_id={tlComment.comment_id}
          appendComment={appendComment}
          refresh={refresh}
        />
      ))}
      <div className="comments-editor-wrapper">
        <CommentEditor
          onChange={handleTextChange}
          loading={sending}
          onSubmit={() => {
            setSending(true);
            appendComment(editorContent).finally(() => {
              if (isMounted.current) {
                setSending(false);
                setEditorContent('');
              }
            });
          }}
          value={editorContent}
        />
      </div>
    </div>
  );
};
