import { ApiResult, EventEmitterType } from '@octostar/platform-types';

/**
 * Creates a proxy object that will emit events on the given event emitter
 * when any of the methods are called.
 *
 * @param iface The interface to create a proxy for
 * @param prefix The prefix to use for the emitted events
 * @param ee The event emitter to emit events on
 */
export const createProxy = <T extends object>(
  iface: new () => T,
  prefix: string,
  ee: EventEmitterType,
): T => {
  const obj: T = new (iface as any)();
  const proxy = {
    get(target: any, prop: PropertyKey, receiver: any): any {
      if (typeof target[prop] === 'function') {
        if (target[prop].constructor.name === 'AsyncFunction') {
          return async function (...args: any[]) {
            return new Promise((resolve, reject) => {
              const topic = `${prefix}${prop.toString()}`;
              const r = Math.random();
              const replyTopic = `${topic}-${r}-reply`;
              const handler = (result: ApiResult<any>) => {
                if (result.status === 'success') {
                  resolve(result.data);
                } else {
                  reject(result.data);
                }
              };
              ee.once(replyTopic, handler);

              try {
                ee.emit.apply(ee, [topic, replyTopic, args]);
              } catch (e) {
                console.log(`Error emitting ${topic} with args ${args}`, e);
                reject(new Error(`Error emitting ${topic} with args ${args}`));
              }
            });
          };
        }
        return function () {
          throw new Error(
            `Method ${prop.toString()}() not implemented because only async methods are supported`,
          );
        };
      }
      return Reflect.get(target, prop, receiver);
    },
  };
  return new Proxy(obj, proxy);
};

export function getStaticMethods<T extends object>(
  classInterface: new () => T,
) {
  return Object.getOwnPropertyNames(classInterface).filter(
    prop => typeof classInterface[prop] === 'function',
  );
}

/**
 * Register an object as the server for a given interface
 * @param delegate Object to register
 * @param iface Interface to register
 * @param prefix Topic prefix for the interface
 * @param ee Event emitter to use for communication
 */
export const registerServer = <T extends object>(
  delegate: T,
  iface: new () => T,
  prefix: string,
  ee: EventEmitterType,
): void => {
  const unsubs: (() => void)[] = [];
  function registerEmitHandler(classDelegate: any, property: string) {
    // check if the property is a function and exists in the object
    if (property === 'constructor' || property.startsWith('_')) return;
    const fn = classDelegate[property];
    if (typeof fn === 'function') {
      const cb = (replyTo: string, ...args: any) =>
        fn
          .apply(classDelegate, ...(args || []))
          .then((data: any) => ee.emit(replyTo, { status: 'success', data }))
          .catch((data: any) => ee.emit(replyTo, { status: 'error', data }));

      const topic = `${prefix}${property}`;
      ee.on(topic, cb);
      unsubs.push(() => ee.off(topic, cb));
    }
  }

  // iterate over all the static methods of the interface
  getStaticMethods(iface).forEach(property =>
    registerEmitHandler(iface, property),
  );

  // iterate over all the object methods of the delegate
  Object.getOwnPropertyNames(iface.prototype).forEach(property =>
    registerEmitHandler(delegate, property),
  );
  const unsubTopic = `${prefix}registerInterface${iface.name}`;
  ee.emit(unsubTopic);
  const unsubscribe = () => unsubs.forEach(fn => fn());
  ee.once(unsubTopic, unsubscribe);
  console.log(`registered EventEmitter server for ${iface.name} on ${prefix}`);
};
