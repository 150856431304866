import React from 'react';
import { Spin } from 'antd5';
import { WorkspaceItem } from '@octostar/platform-types';
import './ContentContainer.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useAttachment } from '../hooks/useAttachment';

const extensionToLanguageMap: { [key: string]: string } = {
  js: 'javascript',
  jsx: 'jsx',
  ts: 'typescript',
  tsx: 'tsx',
  txt: 'text',
  java: 'java',
  py: 'python',
  cs: 'csharp',
  php: 'php',
  rb: 'ruby',
  cpp: 'cpp',
  c: 'c',
  go: 'go',
  r: 'r',
  swift: 'swift',
  kotlin: 'kotlin',
  scala: 'scala',
  m: 'objectivec',
  mm: 'objectivec',
  lua: 'lua',
  rs: 'rust',
  hs: 'haskell',
  elm: 'elm',
  erl: 'erlang',
  pl: 'perl',
  perl: 'perl',
  bash: 'bash',
  sh: 'bash',
  html: 'html',
  css: 'css',
  scss: 'scss',
  less: 'less',
  json: 'json',
  xml: 'xml',
  yml: 'yaml',
  sql: 'sql',
  md: 'markdown',
  diff: 'diff',
  dart: 'dart',
  coffee: 'coffeescript',
  clj: 'clojure',
  asm: 'assembly',
  ada: 'ada',
  applescript: 'applescript',
  awk: 'awk',
  dockerfile: 'dockerfile',
  'f#': 'fsharp',
  gcode: 'gcode',
  gdscript: 'gdscript',
  graphql: 'graphql',
  hbs: 'handlebars',
  ini: 'ini',
  julia: 'julia',
  latex: 'latex',
  lisp: 'lisp',
  makefile: 'makefile',
  markdown: 'markdown',
  nginx: 'nginx',
  objectivec: 'objectivec',
  ocaml: 'ocaml',
  pascal: 'pascal',
  prolog: 'prolog',
  pug: 'pug',
  puppet: 'puppet',
  rust: 'rust',
  sas: 'sas',
  stylus: 'stylus',
  twig: 'twig',
  typescript: 'typescript',
  vhdl: 'vhdl',
  vim: 'vim',
  wasm: 'wasm',
  wast: 'wast',
  yaml: 'yaml',
  zsh: 'zsh',
};

export const CodeViewer = ({
  item,
  fileType,
  height,
}: {
  height: number;
  item: WorkspaceItem;
  fileType: string;
}) => {
  const [code, setCode] = React.useState('<i>Loading...</i>');
  const { loading, error, text } = useAttachment(item, 'text');

  React.useEffect(() => {
    if (text) {
      setCode(text);
    }
  }, [text]);

  if (loading) {
    return (
      <div className="content-container">
        <Spin />
      </div>
    );
  }
  if (error) {
    return <div className="content-container">{error}</div>;
  }
  return (
    <div className="content-container" style={{ maxHeight: height }}>
      <SyntaxHighlighter
        wrapLines
        language={extensionToLanguageMap[fileType] || fileType}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );
};
export default CodeViewer;

export const isTextOrCodeFile = (s: string, item: WorkspaceItem) =>
  extensionToLanguageMap[s] !== undefined ||
  item.os_item_content_type?.startsWith('text/');
