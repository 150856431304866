import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Entity, Relationship } from '@octostar/platform-types';
import APIClient from 'src/octostar/lib/APIClient';
import { getConceptSelectAllFields } from 'src/octostar/lib/query';
import { EntityWithWatchIntents, Watcher, WatchIntent } from './types';
import { parseToWatcher } from './utils';

export const WATCH_INTENT_RELNAME_TOWARDS_OS_WORKSPACE_ITEM = 'has_watcher';

export const WATCH_INTENT_ENTITY_NAME = 'os_watch_intent';

export const WATCH_INTENT_PAUSED = 'STOPPED';

export const getIntentsFromEntity = async (
  entity: Entity,
): Promise<WatchIntent[]> => {
  const eRels = await OntologyAPI.getRelationshipsForEntity(entity);
  const rel = eRels.find(
    rel =>
      rel.relationship_name === WATCH_INTENT_RELNAME_TOWARDS_OS_WORKSPACE_ITEM,
  ) as Relationship;
  if (!rel) {
    throw new Error(
      `No watcher intent relationship '${WATCH_INTENT_RELNAME_TOWARDS_OS_WORKSPACE_ITEM}' found for entity: ${entity.entity_type}`,
    );
  }
  const itemsP = await OntologyAPI.getConnectedEntities<WatchIntent>(
    entity,
    rel,
    true,
  ).then(e => e.map(wi => OntologyAPI.getEntity(wi, true)));

  return (await Promise.all(itemsP)) as WatchIntent[];
};

export const getWatchIntentsForEntities = async (
  entities: Entity[],
): Promise<EntityWithWatchIntents[]> => {
  if (!entities.length) {
    return [];
  }
  const res = await Promise.all(
    entities.map(async es => {
      const intents = await getIntentsFromEntity(es);
      const resolvedIntents = await Promise.all(intents);
      return { entity: es, intents: resolvedIntents };
    }),
  );
  return res;
};

export const addWatchIntent = async (entity: Entity, watcher: Watcher) => {
  await DesktopAPI.addWatchIntent(entity, watcher);
};

export const startWatchIntent = (intent: WatchIntent) =>
  DesktopAPI.save({
    ...intent,
    description: null,
  } as any);

export const pauseWatchIntent = (intent: WatchIntent) =>
  DesktopAPI.save({
    ...intent,
    description: WATCH_INTENT_PAUSED,
  } as any);

export const removeWatchIntent = (intent: WatchIntent) =>
  DesktopAPI.removeWatchIntent(intent.os_workspace, intent.entity_id);

export const getWatchers = async (): Promise<Watcher[]> => {
  try {
    // VSF00: Shouldn't this be ontology dependent?
    // SS: it is ontology dependent, but the ontology is a property of the flask user session. So it's applied in the backend.
    const api_client = new APIClient();
    const res = await api_client.fetch('/api/octostar/watcher/', {
      method: 'POST',
      body: JSON.stringify({
        os_workspace_ids: [],
      }),
    });
    const data = await res.json();
    return data.message.map(parseToWatcher);
  } catch (error) {
    if (error instanceof TypeError) {
      // eslint-disable-next-line no-console
      console.error('Cannot parse the response to JSON:', error);
    } else {
      // eslint-disable-next-line no-console
      console.error('Cannot fetch watchers:', error);
    }
    return [];
  }
};

// VSFQuery: Shouldn't this also filter on entity_id or entity_label if present? Do we only support watchers by entity_type for now?
// Watcher = watcher functions. This function returns all w.functions that are compatible (semantically bound) with the entity type.
export const getWatchersForEntity = async (
  watchers: Watcher[],
  entity: Entity,
): Promise<Watcher[]> =>
  watchers.filter(async watcher => {
    if (watcher.semantically_bound.includes(entity.entity_type)) {
      return true;
    }
    const ancestors = await OntologyAPI.getConceptByName(
      entity.entity_type,
    ).then(x => x?.parents);
    if (!ancestors) {
      return false;
    }
    return ancestors.some(ancestor =>
      watcher.semantically_bound.includes(ancestor),
    );
  });

export const getIntents = async (): Promise<WatchIntent[]> => {
  const fields = await getConceptSelectAllFields(WATCH_INTENT_ENTITY_NAME);
  const intents = await OntologyAPI.sendQueryT<WatchIntent>(
    `SELECT ${fields} FROM timbr.${WATCH_INTENT_ENTITY_NAME}`,
  ).then(res => {
    if (res.length > 0) {
      return res;
    }
    return [];
  });
  return intents;
};
