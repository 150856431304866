export const examples: { title: string; chart: string; icon?: string }[] = [
  {
    title: 'Sequence Diagram',
    chart: `sequenceDiagram
    Alice->>John: Hello John, how are you?
    John-->>Alice: Great!
    Alice-)John: See you later!`,
  },
  {
    title: 'Flowchart',
    chart: `flowchart TD
    A[Start] --> B{Is it?}
    B -- Yes --> C[OK]
    C --> D[Rethink]
    D --> B
    B -- No ----> E[End]`,
  },
  {
    title: 'Flowchart with icons',
    chart: `flowchart TD
  B["fab:fa-twitter for peace"]
  B-->C[fa:fa-ban forbidden]
  B-->D(fa:fa-spinner)
  B-->E(A fa:fa-camera-retro perhaps?)`,
  },
  {
    title: 'Another Flowchart',
    chart: `flowchart LR
  A[Hard edge] -->|Link text| B(Round edge)
  B --> C{Decision}
  C -->|One| D[Result one]
  C -->|Two| E[Result two]`,
  },
  {
    title: 'Class Diagram',
    chart: `classDiagram
      note "From Duck till Zebra"
      Animal <|-- Duck
      note for Duck "can fly\ncan swim\ncan dive\ncan help in debugging"
      Animal <|-- Fish
      Animal <|-- Zebra
      Animal : +int age
      Animal : +String gender
      Animal: +isMammal()
      Animal: +mate()
      class Duck{
          +String beakColor
          +swim()
          +quack()
      }
      class Fish{
          -int sizeInFeet
          -canEat()
      }
      class Zebra{
          +bool is_wild
          +run()
      }`,
  },
  {
    title: 'State Diagram',
    chart: `stateDiagram-v2
  State1: The state with a note
  note right of State1
      Important information! You can write
      notes.
  end note
  State1 --> State2
  note left of State2 : This is the note to the left.`,
  },
  {
    title: `Entity Relationship Diagram`,
    chart: `erDiagram
        CUSTOMER ||--o{ ORDER : places
        ORDER ||--|{ LINE-ITEM : contains
        CUSTOMER }|..|{ DELIVERY-ADDRESS : uses`,
  },
];
