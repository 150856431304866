import * as React from 'react';
import _, { isEqual } from 'lodash';
import { Button, Col, Modal, Row, Steps, Table, Tag, Tooltip } from 'antd5';
import { Entity, WorkspaceItem } from '@octostar/platform-types';
import Loading from 'src/components/Loading';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { t } from '@superset-ui/core';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import SearchXperience from 'src/octostar/components/search/SearchXperience';
import ConfiguredIcon from 'src/octostar/components/ConfiguredIcon';
import WorkspacePicker from 'src/octostar/components/Workspace/WorkspacePicker';
import { WatcherMenu } from './WatcherMenu';
import { Watcher } from './types';
import { addWatchIntent, getWatchers } from './WatchersIO';
import { useWatchersContext } from './WatchersContext';
import { getEntityFromIntent } from './utils';
import './WatchersModal.css';

interface WatchersModalEntitiesProps {
  entities: Entity[];
  setEntities: (entities: Entity[]) => void;
  defaultConcepts?: string[];
}

const WatchersModalEntities = ({
  entities,
  setEntities,
  defaultConcepts,
}: WatchersModalEntitiesProps) => {
  const [openSearchXperience, setOpenSearchXperience] =
    React.useState<boolean>(false);

  const onCloseSearchXperience = (selectedEntities: Entity[]) => {
    if (selectedEntities?.length) {
      const entitiesToAdd = [...entities];
      entitiesToAdd.push(
        ...selectedEntities.map((entity: Entity) => ({
          key: entity?.entity_id,
          entity_label: entity?.entity_label,
          entity_id: entity?.entity_id,
          entity_type: entity?.entity_type,
        })),
      );
      Promise.all(
        entitiesToAdd.map(entity => OntologyAPI.getEntity(entity)),
      ).then(entities => {
        setEntities(_.uniq(entities));
      });
    }
    setOpenSearchXperience(false);
  };

  const showModal = React.useCallback(() => {
    setOpenSearchXperience(true);
  }, []);

  const handleDeleteEntity = (key: string) => {
    setEntities(entities.filter(item => item.entity_id !== key));
  };

  const columns = [
    {
      title: t('Entity label'),
      dataIndex: 'entity_label',
      key: 'entity_label',
    },
    {
      title: t('Entity type'),
      dataIndex: 'entity_type',
      key: 'entity_type',
      render: (conceptName: string) => (
        <span>
          <Tag>
            <Row justify="space-between" gutter={6}>
              <Col>
                <ConfiguredIcon hint={conceptName} />
              </Col>
              <Col>{`${conceptName}`}</Col>
            </Row>
          </Tag>
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_: any, entity: Entity) =>
        entities.length >= 1 ? (
          <Tooltip title={t('Remove Record')}>
            <Button
              type="text"
              onClick={() => handleDeleteEntity(entity.entity_id)}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        ) : null,
    },
  ];
  return (
    <div key={crypto.randomUUID()}>
      <>
        <Button type="default" onClick={showModal} style={{ marginBottom: 16 }}>
          {t('Select existing entity')}
        </Button>
        <SearchXperience
          onClose={onCloseSearchXperience}
          open={openSearchXperience}
          defaultConcept={defaultConcepts}
          disableConceptSelector
        />
      </>
      {entities.length > 0 && (
        <div className="relationship-modal-content">
          <Table columns={columns} dataSource={entities} />
          <Button
            type="default"
            style={{ width: 'fit-content' }}
            onClick={() => setEntities([])}
          >
            {t('Clear Records')}
          </Button>
        </div>
      )}
    </div>
  );
};

type WorkspaceOption = {
  value: string;
  label?: string;
};

interface WatchersModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultEntity?: Entity;
  defaultWatcher?: Watcher;
  label?: string;
  refreshIntents?: () => void;
}

export const WatchersModal = ({
  open,
  defaultEntity,
  setOpen,
  defaultWatcher,
  label,
  refreshIntents,
}: WatchersModalProps) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [watchers, setWatchers] = React.useState<Watcher[]>([]);
  const [workspace, setWorkspace] = React.useState<
    WorkspaceOption | undefined
  >();
  const [selectedWatcher, setSelectedWatcher] = React.useState<
    Watcher | undefined
  >(defaultWatcher);
  const [current, setCurrent] = React.useState(0);
  const { intents, refresh } = useWatchersContext();
  const mounted = React.useRef(0);

  React.useEffect(() => {
    const { current } = mounted;
    if (defaultEntity && current === mounted.current) {
      const newValue = [defaultEntity];
      setEntities(curr => (isEqual(curr, newValue) ? curr : newValue));
    }
  }, [defaultEntity]);

  const fetchWatchers = React.useCallback(
    async (entity?: Entity) => {
      try {
        const { current } = mounted;
        const watchersData = await getWatchers();
        if (entity) {
          let watchersForEntity = watchersData.filter(w =>
            w.semantically_bound.includes(entity.entity_type),
          );
          if (defaultEntity) {
            const intentsEntity = intents.filter(intent => {
              const entity = getEntityFromIntent(intent);
              if (!entity) {
                return false;
              }
              if (entity.entity_id === defaultEntity.entity_id) {
                return true;
              }
              return false;
            });
            watchersForEntity = watchersForEntity.filter(
              watcher =>
                !intentsEntity
                  .map(intent => intent.watcher_name)
                  .includes(watcher.watcher_name),
            );
          }
          if (current === mounted.current) {
            setWatchers(watchersForEntity);
          }

          return;
        }
        if (current === mounted.current) {
          setWatchers(watchersData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching watchers', error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [defaultEntity, intents],
  );

  React.useEffect(() => {
    const { current } = mounted;
    if (current !== mounted.current) {
      return;
    }
    if (defaultWatcher) {
      setSelectedWatcher(defaultWatcher);
      setCurrent(1);
    } else {
      setLoading(true);
      fetchWatchers(defaultEntity);
    }
  }, [defaultEntity, defaultWatcher, fetchWatchers]);

  React.useEffect(() => {
    const { current } = mounted;
    if (current !== mounted.current || !workspace?.value || workspace?.label) {
      return;
    }
    DesktopAPI.listAllWorkspaces().then(async (workspaces: WorkspaceItem[]) => {
      workspaces.forEach(ws => {
        if (ws.entity_id === workspace?.value && current === mounted.current) {
          setWorkspace({ label: ws.entity_label, value: ws.entity_id });
        }
      });
    });
  }, [workspace]);

  React.useEffect(
    () => () => {
      // prevent updating state of unmounted component
      mounted.current += 1;
    },
    [],
  );

  const handleAddWatcher = () => {
    if (!selectedWatcher || !entities?.length) {
      return;
    }
    setConfirmLoading(true);

    const promises = entities.map((entity: Entity) => {
      const entityWithWorkspace = {
        ...entity,
        os_workspace: workspace?.value,
      };
      return addWatchIntent(entityWithWorkspace, selectedWatcher);
    });
    DesktopAPI.withProgressBar(
      Promise.all(promises)
        .then(() => {
          refresh();
          refreshIntents?.();
          setConfirmLoading(false);
          setOpen(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('Error adding watcher', e);
          DesktopAPI.showToast({
            message: 'Error adding watcher',
            level: 'error',
            description: 'See console for more details.',
          });
          setConfirmLoading(false);
          setOpen(false);
        }),
      { job_type: 'Create watcher', label: selectedWatcher.watcher_name },
    );
  };

  const handleCancel = React.useCallback(() => {
    setEntities([]);
    setOpen(false);
  }, [setOpen]);

  const handelSelectWatcher = React.useCallback(
    (watcher: Watcher) => {
      setSelectedWatcher(watcher);
      setCurrent(1);
    },
    [setSelectedWatcher],
  );

  const nextStep = React.useCallback(() => {
    setCurrent(current + 1);
  }, [current]);

  const prevStep = React.useCallback(() => {
    setCurrent(current - 1);
  }, [current]);

  const items = [
    {
      title: 'Watcher',
      key: 0,
    },
    {
      key: 1,
      title: 'Entities',
    },
    {
      key: 2,
      title: 'Workspace',
    },
  ];

  return (
    <Modal
      title={label || 'Create a new Watcher'}
      open={open}
      className="watchers-modal"
      onCancel={handleCancel}
      footer={[
        current > 0 && (
          <Button
            key="previous"
            type="default"
            onClick={() => prevStep()}
            disabled={current === 1 && selectedWatcher !== undefined}
          >
            Previous
          </Button>
        ),
        current === 0 && (
          <Button key="cancel" type="default" onClick={handleCancel}>
            Cancel
          </Button>
        ),
        current < items.length - 1 && (
          <Button key="next" type="primary" onClick={() => nextStep()}>
            Next
          </Button>
        ),
        current === items.length - 1 && (
          <Button
            key="create"
            type="primary"
            loading={confirmLoading}
            disabled={!selectedWatcher || !entities?.length || !workspace}
            onClick={handleAddWatcher}
          >
            Create
          </Button>
        ),
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <div key={crypto.randomUUID()}>
          <Steps
            current={current}
            items={items}
            className="watchers-modal-steps"
          />
          <div className="watchers-modal-content">
            {current === 0 && (
              <WatcherMenu
                watchers={selectedWatcher ? [selectedWatcher] : watchers}
                select={handelSelectWatcher}
                showSearch={selectedWatcher === undefined}
                showModal={false}
              />
            )}
            {current === 1 && (
              <WatchersModalEntities
                entities={entities}
                setEntities={setEntities}
                defaultConcepts={selectedWatcher?.semantically_bound}
              />
            )}
            {current === 2 && (
              <div className="watchers-modal-workspace">
                {workspace?.value ? (
                  <>
                    <div>Selected Workspace</div>
                    <span className="watchers-workspace-label">
                      {workspace.label} &nbsp;
                      <Button
                        type="default"
                        onClick={() => setWorkspace(undefined)}
                        icon={<CloseOutlined />}
                      />
                    </span>
                  </>
                ) : (
                  <>
                    <div>Select a Workspace</div>
                    <WorkspacePicker
                      onChange={(value: string) => setWorkspace({ value })}
                      openWorkspacesOnly
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};
