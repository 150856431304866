import React from 'react';
import { isEqual } from 'lodash';
// @ts-ignore
import { WorkspaceItem } from '@octostar/platform-types';
import Loading from 'src/components/Loading';
import ee, { clientCore } from 'src/octostar/interface';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks';
import { TabNode } from 'flexlayout-react';
import ReactMarkdown from 'react-markdown';
import mime from 'mime';
import { Spin } from 'antd5';
import { BUILTIN_APPS } from './layout/builtins';
import { useTabNode } from '../hooks/useTabNode';
import { useAttachment } from '../hooks/useAttachment';
import { MARKDOWN_EXTENSIONS } from '../lib/workspaceOperations';
import useHandleErrorEffect from '../hooks/useHandleErrorEffect';
import './ContentContainer.css';

export const MarkdownViewer = ({
  workspaceItem,
  inputText,
  node,
}: {
  workspaceItem?: WorkspaceItem;
  inputText?: string;
  node?: TabNode;
}) => {
  const [markdown, setMarkdown] = React.useState('Loading...');
  const { loading, text, error, item, setItem, state } = useAttachment(
    workspaceItem,
    'text',
    '',
  );
  const { height } = useTabNode({ node });

  useHandleErrorEffect(
    workspaceItem,
    state,
    loading,
    error,
    BUILTIN_APPS.markdownViewer,
  );

  React.useEffect(() => {
    if (typeof inputText === 'string') {
      setMarkdown(inputText);
      return;
    }
    setMarkdown(text || '');
  }, [inputText, text]);

  React.useEffect(() => {
    if (workspaceItem) {
      setItem(curr => (isEqual(curr, workspaceItem) ? curr : workspaceItem));
    }
  }, [setItem, workspaceItem]);

  if (loading) {
    <div className="content-container">
      <Spin />
    </div>;
  }
  if (error) {
    <div className="content-container">{error}</div>;
  }
  const extension =
    mime.getExtension(item?.os_item_content_type || '') || 'dunno';
  if (extension === 'markdown') {
    return (
      <div className="content-container" style={{ minHeight: height - 40 }}>
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </div>
    );
  }
  // TODO: this breaks for the edge case where the text contains "```"
  const prefix = `\`\`\`${item?.os_item_content_type || ''}\n`;
  const suffix = '\n```';
  const wrapped = `${prefix}${markdown}${suffix}`;
  return (
    <div className="content-container" style={{ minHeight: height - 40 }}>
      <ReactMarkdown>{wrapped}</ReactMarkdown>
    </div>
  );
};

export const MarkdownViewerLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return <MarkdownViewer workspaceItem={workspaceItem} node={node} />;
};

export const registerMarkdownViewer = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.markdownViewer.os_entity_uid,
    factory: (node: TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <MarkdownViewerLoader item={item} node={node} />;
    },
  });
};

export const isMarkdownFile = (s: string) =>
  MARKDOWN_EXTENSIONS.includes(s.toLowerCase());
