import * as React from 'react';
import _ from 'lodash';
import { t } from '@superset-ui/core';
import {
  Entity,
  OsTag,
  TagWithRelationship,
  WorkspaceItem,
  WorkspaceRelationship,
} from '@octostar/platform-types';
import { PlusOutlined, SyncOutlined, TagOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd5';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { TAGGED_RELATIONSHIP, apiCall } from 'src/octostar/interface';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import { newWorkspaceItem } from 'src/octostar/lib/handy';
import { OctostarTag } from './OctostarTag';
import './ItemTags.css';

type MenuItem = Required<MenuProps>['items'][number];
const MOCK_TAG_NAME = 'MOCK_TAG_PENDING_CHANGES';
function mockWorkspaceRelationship(
  entity: Entity | undefined,
  tag: OsTag,
): WorkspaceRelationship {
  const wi = newWorkspaceItem({
    os_item_name: MOCK_TAG_NAME,
    os_item_type: 'os_workspace_relationship',
    os_workspace: tag.os_workspace,
  });
  return {
    ...wi,
    os_entity_uid_from: entity?.entity_id || 'x',
    os_entity_type_from: entity?.entity_type || 'x',
    os_entity_uid_to: tag.entity_id,
    os_entity_type_to: 'os_tag',
    os_relationship_name: TAGGED_RELATIONSHIP,
  };
}
function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
  } as MenuItem;
}

const addNewTag = (entity: Entity) => async () => {
  apiCall(
    BUILTINS_MESSAGE_TYPES.showCreateTagDialog,
    (entity as WorkspaceItem).os_workspace,
    entity,
  );
};

const items = (
  tags: TagWithRelationship[],
  availableTags: OsTag[],
  entity?: Entity,
  onTagAdded?: (tag: OsTag) => void,
): MenuItem[] => {
  const tagsGroups = availableTags?.reduce((groups, tag) => {
    const groupName = tag.group;
    if (groupName) {
      groups[groupName] = groups[groupName] || [];
      groups[groupName].push(tag);
    }
    return groups;
  }, {});
  const groupItems = Object.entries(tagsGroups).map(([groupName, tags]) =>
    getItem(
      groupName,
      groupName,
      undefined,
      tags.map(tag =>
        getItem(
          <OctostarTag
            entity={entity as Entity}
            tag={tag}
            contextMenu
            onTagAdded={onTagAdded}
          />,
          `${entity?.entity_id}-${tag.entity_id}`,
        ),
      ),
    ),
  );

  return !entity
    ? []
    : [
        ...[
          getItem(
            'Create Tag',
            'new-tag',
            <PlusOutlined />,
            undefined,
            undefined,
            addNewTag(entity),
          ),
        ],
        ...availableTags
          .filter(
            x =>
              !tags.find(t => t.os_tag.entity_id === x.entity_id) && !x.group,
          )
          .map(tag =>
            getItem(
              <OctostarTag
                entity={entity}
                tag={tag}
                contextMenu
                onTagAdded={onTagAdded}
              />,
              `${entity.entity_id}-${tag.entity_id}`,
            ),
          ),
        ...groupItems,
      ];
};

export const ItemTags = ({
  item,
  label,
  updateTabCounter,
}: {
  item: Entity;
  label?: string;
  updateTabCounter?: (badgeCounter?: number | undefined) => void;
}) => {
  const [entityTags, setEntityTags] = React.useState<TagWithRelationship[]>([]);
  const [availableTags, setAvailableTags] = React.useState<
    OsTag[] | undefined
  >();
  const [entity, setEntity] = React.useState<Entity>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const ref = React.useRef(1);
  React.useEffect(
    () => () => {
      ref.current += 1;
    },
    [],
  );
  const onTagAdded = React.useCallback(
    (tag: OsTag) => {
      const twr: TagWithRelationship = {
        os_tag: tag,
        os_workspace_relationship: mockWorkspaceRelationship(entity, tag),
      };
      setEntityTags(curr => [...curr, twr]);
      setAvailableTags(curr =>
        curr?.filter(t => t.entity_id !== tag.entity_id),
      );
      updateTabCounter?.(entityTags.length + 1);
    },
    [entity, entityTags.length, updateTabCounter],
  );
  const onTagRemoved = React.useCallback(
    (tag: OsTag) => {
      setEntityTags(curr =>
        curr.filter(t => t.os_tag.entity_id !== tag.entity_id),
      );
      updateTabCounter?.(entityTags.length - 1);
    },
    [entityTags.length, updateTabCounter],
  );

  React.useEffect(() => {
    setEntity((item as WorkspaceItem)?.os_item_content?.entity || item);
  }, [item]);

  React.useEffect(() => {
    // only turn on spinner if entity changes.
    setLoading(true);
    setAvailableTags(undefined);
    setEntityTags([]);
  }, [entity?.entity_id]);

  const loadTags = React.useCallback(async () => {
    try {
      if (!entity) {
        return;
      }
      const { current } = ref;
      const entityTags = await DesktopAPI.getTags(entity);
      if (current === ref.current) {
        setEntityTags(_.uniq(entityTags));
        updateTabCounter?.(entityTags.length);
      }
    } finally {
      setLoading(false);
    }
  }, [entity, updateTabCounter]);

  const loadAvailableTags = React.useCallback(async () => {
    if (!entity) return;
    const { current } = ref;
    const availableTags = await DesktopAPI.getAvailableTags(entity);
    if (current === ref.current) {
      setAvailableTags(_.uniq(availableTags.flat()));
    }
  }, [entity]);

  React.useEffect(() => {
    if (!entity) return;
    loadTags();
  }, [entity, loadTags]);

  return (
    <div className="item-tags-container">
      {entity &&
        entityTags.length > 0 &&
        entityTags
          .sort(
            (a, b) =>
              a.os_tag.entity_label
                .toLocaleLowerCase()
                .localeCompare(b.os_tag.entity_label.toLocaleLowerCase()) ||
              a.os_tag.entity_id.localeCompare(b.os_tag.entity_id),
          )
          .map(tag => (
            <OctostarTag
              key={tag.os_workspace_relationship.os_entity_uid}
              entity={entity}
              os_workspace={tag.os_workspace_relationship.os_workspace}
              tag={tag.os_tag}
              onTagAdded={onTagAdded}
              onTagRemoved={onTagRemoved}
              readonly={
                tag.os_workspace_relationship.os_item_name === MOCK_TAG_NAME
              }
              tagged
            />
          ))}
      <Dropdown
        menu={{
          items: items(entityTags, availableTags || [], entity, onTagAdded),
        }}
      >
        <Button
          className="item-tags-dropdown-buttons"
          onMouseOver={() => {
            if (!availableTags) {
              loadAvailableTags();
            }
          }}
        >
          {!label && <TagOutlined />}
          {loading ? <SyncOutlined spin /> : <PlusOutlined />}
          {label && t(label)}
        </Button>
      </Dropdown>
    </div>
  );
};
