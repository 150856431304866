import { Button, Card, Form, Select, Space } from 'antd5';
import React from 'react';
import OntologyAPI from '../lib/OntologyAPI';
import Loading from './Loading';
import ee, { clientCore } from '../interface';
import { getSelectedOntology } from '../api/preferences';
import { registerServer } from '../api/event-driven';
import { ONTOLOGY_PREFIX } from '../api/messagesTypes';

type OntologyContextType = {
  ontologyAPI: OntologyAPI | undefined;
  setOntology: (ontology: string) => void;
  ontologies: string[];
  ontology: string | undefined;
};
const OntologyContext = React.createContext<OntologyContextType>({
  ontologyAPI: undefined,
  ontology: undefined,
  setOntology: (ontology: string) => undefined,
  ontologies: [],
});
type OntologyContextProviderProps = {
  children: any;
};
type OntologySelectionProps = {
  ontologies: string[];
  setSelectedOntology: (ontology: string) => void;
};
export const OntologySelection = (props: OntologySelectionProps) => {
  const { ontologies, setSelectedOntology } = props;
  const [ontology, setOntology] = React.useState<string>();
  const HandleSetOntology = (ontologyProp: string) => {
    const o = ontologyProp || '';
    setSelectedOntology(o);
    // VSF00: Wise to extract out event emitter from here?
    ee.emit(clientCore('ontologyChanged'), o);
  };
  const onOntologyChanged = React.useCallback(() => {
    HandleSetOntology(ontology);
  }, [ontology, setSelectedOntology]);

  React.useEffect(() => {
    if (ontologies.length === 1) HandleSetOntology(ontologies[0]);
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Card title="Select Ontology" style={{ width: 336 }}>
        <Form style={{ padding: '24px' }}>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Form.Item>
              <Select
                showSearch
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={setOntology}
                options={ontologies.map(x => ({ value: x, label: x }))}
              />
            </Form.Item>
            <Form.Item>
              <Button
                disabled={!ontology}
                type="primary"
                onClick={onOntologyChanged}
              >
                Continue
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Card>
    </div>
  );
};

export const OntologyContextProvider = (
  props: OntologyContextProviderProps,
) => {
  const [availableOntologies, setAvailableOntologies] = React.useState<
    string[]
  >([]);
  const [selectedOntology, setSelectedOntology] = React.useState<
    string | undefined
  >();
  const [ontologyAPI, setOntologyAPI] = React.useState<
    OntologyAPI | undefined
  >();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  const changeOntology = React.useCallback((newOntology?: string) => {
    const o = newOntology || '';

    console.log(`changing ontology to ${newOntology}`);
    setSelectedOntology(o);

    if (newOntology) {
      const apiInstance = new OntologyAPI(ee, newOntology);
      setOntologyAPI(apiInstance);
      registerServer(apiInstance, OntologyAPI, ONTOLOGY_PREFIX, ee);
    }
  }, []);

  React.useEffect(() => {
    if (!selectedOntology) {
      setErrorMessage(undefined);
      // VSF00: Fetch or init from redux?
      getSelectedOntology().then(changeOntology);
      OntologyAPI.getAvailableOntologies().then(ontologies => {
        if (ontologies.length) {
          setAvailableOntologies(ontologies);
        } else {
          setErrorMessage(
            'No ontologies found. Please check with the administrator.',
          );
        }
      });
    }
  }, [selectedOntology, changeOntology, setErrorMessage]);

  // This would also auto-select when user decides to 'Change ontology'/'Reset Desktop'. Might not the behaviour we're looking for.
  // TODO: Introduce or delete:
  // React.useEffect(() => {
  //   if (
  //     !selectedOntology &&
  //     !isEmpty(availableOntologies) &&
  //     availableOntologies.length === 1
  //   ) {
  //     setSelectedOntology(availableOntologies[0]);
  //   }
  // }, [availableOntologies, selectedOntology]);

  React.useEffect(() => {
    const topic = clientCore('ontologyChanged');
    ee.on(topic, changeOntology);
    return () => ee.off(topic, changeOntology);
  }, [changeOntology]);

  if (errorMessage) {
    return <p>{errorMessage}</p>;
  }

  if (!availableOntologies.length) {
    return (
      <Loading
        title="Loading Ontologies"
        detail="Fetching ontology data from the server"
      />
    );
  }

  if (!selectedOntology) {
    return (
      <div className="applayout">
        <OntologySelection
          ontologies={availableOntologies}
          setSelectedOntology={setSelectedOntology}
        />
      </div>
    );
  }

  return (
    <OntologyContext.Provider
      value={{
        ontologyAPI,
        ontologies: availableOntologies,
        setOntology: setSelectedOntology,
        ontology: selectedOntology,
      }}
    >
      {props.children}
    </OntologyContext.Provider>
  );
};

export default OntologyContext;
