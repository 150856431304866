import * as React from 'react';

import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  MenuProps,
  Typography,
  Button,
} from 'antd5';
import { examples } from './examples';
import { Icon } from '../layout/Icon';
import '../Menu/index.css';
import '../layout/AppLayout.css';
import { Mermaid } from './Mermaid';

const { Content, Sider } = Layout;
const { Title } = Typography;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
const items = examples.map(({ title, icon }, i) =>
  getItem(title, i, <Icon icon={icon || 'fa-chart-bar'} />),
);
export const ExamplePicker = ({
  onChange,
}: {
  onChange: (chart: string) => void;
}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [example, setExample] = React.useState(examples[0]);

  const onExampleSelected = React.useCallback(() => {
    onChange(example.chart);
  }, [example, onChange]);
  return (
    <Layout className="custom-antlayout">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        className="sider"
        theme="light"
        collapsedWidth={48}
      >
        <Title level={4}>Choose Example</Title>
        <Menu
          defaultSelectedKeys={['0']}
          mode="inline"
          items={items}
          onSelect={({ key }) => setExample(examples[key])}
        />
      </Sider>
      <Layout>
        <Content>
          <Button type="primary" onClick={onExampleSelected}>
            Create Diagram
          </Button>
          <Mermaid chart={example.chart} height={400} width={400} />
        </Content>
      </Layout>
    </Layout>
  );
};
