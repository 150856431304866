import LRU from 'lru-cache';
import {
  Entity,
  Relationship,
  Ontology,
  RelationshipCountResult,
  WorkspaceItem,
} from '@octostar/platform-types';
import { CachinQueryExecutor } from './CachingQueryExecutor';
import {
  preprocess,
  toLocalDataRelationshipCountSql,
  toRelationshipCountSql,
} from './RelationshipQueries';

export const CACHE_TTL = 1000 * 60 * 60 * 24; // 24 hours

const getCacheKey = ({
  entity,
  relationship,
}: {
  entity: Entity;
  relationship: Relationship;
}) => `${entity.entity_id}|${relationship.relationship_name}`;

type MyRelationshipCountResult = RelationshipCountResult & {
  entity_os_last_updated: number;
  cached: number;
};

export class RelationshipCountCache {
  private ontology: Ontology;

  private cache: LRU<string, number>;

  executor: CachinQueryExecutor<
    { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
    MyRelationshipCountResult
  >;

  quickExecutor: CachinQueryExecutor<
    { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
    MyRelationshipCountResult
  >;

  constructor(ontology: Ontology) {
    this.ontology = ontology;
    this.cache = new LRU({ max: 100000, ttl: CACHE_TTL });
    this.executor = this.createRelationshipCountCache();
    this.quickExecutor = this.createLocalRelationshipCountCache();
  }

  clear = async ({
    entity,
    relationship,
  }: {
    entity: Entity;
    relationship: Relationship;
  }) => {
    this.executor.clear({ entity, relationship });
    this.quickExecutor.clear({ entity, relationship });
  };

  getRelationshipCount = async (
    entity: Entity,
    rel: Relationship,
    forceRefresh?: boolean,
  ): Promise<RelationshipCountResult> => {
    const quick: MyRelationshipCountResult = await this.quickExecutor.get({
      entity,
      relationship: rel,
    });
    if (!quick.expired || !forceRefresh) {
      return quick;
    }

    try {
      const relCountResp = await this.executor.get({
        entity,
        relationship: rel,
        forceRefresh,
      });
      const { count, entity_os_last_updated, cached } = relCountResp;
      let { expired } = relCountResp;

      if (!expired && entity_os_last_updated) {
        const current = await this.ontology.getEntity(entity, false, true);
        const currentLastUpdated = (current as WorkspaceItem)
          ?.os_last_updated_at;
        if (
          currentLastUpdated &&
          new Date(currentLastUpdated).getTime() > entity_os_last_updated
        ) {
          expired = true;
        }
      }
      if (!expired && !entity_os_last_updated) {
        expired = cached + CACHE_TTL < Date.now();
      }
      return { count, expired };
    } catch (e) {
      console.log(
        `problem getting big data relationships for ${rel.relationship_name}`,
        e,
      );
      const { count } = quick;
      return { count };
    }
  };

  private createRelationshipCountCache(): CachinQueryExecutor<
    { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
    MyRelationshipCountResult
  > {
    return new CachinQueryExecutor<
      { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
      MyRelationshipCountResult
    >({
      cache: this.cache,
      sendQueryOptions: { lowPriority: false },

      queryExecutor: this.ontology,
      maxBatchSize: 500,
      getDefaultValue: () => ({
        count: 0,
        entity_os_last_updated: 0,
        cached: new Date().getTime(),
      }),
      toSql: (
        batchkey: string,
        keys: string[],
        input: { entity: Entity; relationship: Relationship },
        entities: Entity[],
      ) => toRelationshipCountSql(input, entities),
      preprocess,
      getEntity: ({ entity }) => entity,
      transform: (row: any) => {
        const result: MyRelationshipCountResult = {
          count: Number(row.cardinality),
          expired: false,
          entity_os_last_updated: 0,
          cached: new Date().getTime(),
        };
        result.count = row.cardinality ? Number(row.cardinality) : 0;
        return result;
      },
      key: getCacheKey,
      rowToKey: (row: any) => `${row.entity_id}|${row.relationship_name}`,
      queryBatchKey: ({ relationship }) =>
        `${relationship.concept}|${relationship.target_concept}|${relationship.relationship_name}`,
    });
  }

  private createLocalRelationshipCountCache(): CachinQueryExecutor<
    { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
    MyRelationshipCountResult
  > {
    return new CachinQueryExecutor<
      { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
      MyRelationshipCountResult
    >({
      cache: this.cache,
      getEntity: ({ entity }) => entity,
      queryExecutor: this.ontology,
      maxBatchSize: 500,
      getDefaultValue: () => ({
        count: 0,
        expired: true,
        entity_os_last_updated: 0,
        cached: 0,
      }),
      toSql: toLocalDataRelationshipCountSql,
      preprocess: async (
        rows: {
          os_entity_type_from: string;
          os_entity_uid_from: string;
          os_relationship_name: string;
          os_entity_uid_to: string;
        }[],
      ) => {
        const counts: { [key: string]: number } = {};
        const inverse: { [name: string]: string } = {};
        await Promise.all(
          rows.map(async row => {
            let key = `${row.os_entity_uid_from}|${row.os_relationship_name}`;
            counts[key] = counts[key] || 0;
            counts[key] += 1;
            let inverseName = inverse[row.os_relationship_name];
            if (!inverseName) {
              const concept = await this.ontology.getConceptByName(
                row.os_entity_type_from,
              );
              if (concept) {
                const rel = concept.relationships.find(
                  r => r.relationship_name === row.os_relationship_name,
                );
                if (rel) {
                  inverseName = rel.inverse_name;
                  inverse[row.os_relationship_name] = inverseName;
                }
              }
            }
            if (inverseName) {
              key = `${row.os_entity_uid_to}|${inverseName}`;
              counts[key] = counts[key] || 0;
              counts[key] += 1;
            }
          }),
        );
        return Object.entries(counts).map(([key, cardinality]) => ({
          key,
          cardinality,
        }));
      },
      transform: (row: any) => {
        const result: MyRelationshipCountResult = {
          count: Number(row.cardinality),
          expired: true, // always expired for local counts
          entity_os_last_updated: 0,
          cached: 0,
        };
        result.count = row.cardinality ? Number(row.cardinality) : 0;
        return result;
      },
      key: getCacheKey,
      rowToKey: (row: any) => row.key,
      queryBatchKey: () => 'x',
    });
  }
}
