import * as React from 'react';
import { Tooltip } from 'antd5';
import { Entity } from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';
import './EntityLabelTooltip.css';

type Props = {
  entity: {
    entity_type: string;
    entity_id?: string;
    entity_label?: string;
  };
  placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
};
const DEFAULT_LABEL = '... ';
export const EntityLabelTooltip: React.FC<Props> = props => {
  const [title, setTitle] = React.useState<string | undefined>(
    props.entity.entity_label || DEFAULT_LABEL,
  );
  React.useEffect(() => {
    setTitle(props.entity.entity_label || DEFAULT_LABEL);
  }, [props.entity.entity_label]);
  const onOpenChange = React.useCallback(
    (open: boolean) => {
      if (open) {
        if (props.entity.entity_id && title === DEFAULT_LABEL) {
          OntologyAPI.getEntity({
            entity_label: '',
            entity_id: '',
            ...props.entity,
          }).then((entity: Entity) => {
            setTitle(entity?.entity_label);
          });
        } else if (title === DEFAULT_LABEL && !props.entity.entity_id) {
          setTitle(undefined);
        }
      }
    },
    [props.entity, title],
  );
  return (
    <Tooltip
      overlayClassName="entity-label-tooltip"
      title={title}
      placement={props.placement}
      onOpenChange={onOpenChange}
    >
      {props.children}
    </Tooltip>
  );
};
