export const memoize =
  (fn: any, cache: any) =>
  (...args: any[]) => {
    const n = args[0];
    if (n in cache) {
      //  console.log('Fetching from cache', n)
      return cache[n];
    }
    // console.log('Calculating result', n)
    const result = fn(n);
    // eslint-disable-next-line no-param-reassign
    cache[n] = result;
    return result;
  };

export const compose =
  (...fns: any[]) =>
  (x: any) =>
    fns.reduceRight((y, f) => f(y), x);
