import * as React from 'react';
import { isEmpty } from 'lodash';
import { Inheritance } from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';

export type NodeItem = {
  name: string;
  children: Record<string, NodeItem>;
};

function createTree(concepts: Inheritance[]): Record<string, NodeItem> {
  const tree: Record<string, NodeItem> = {};
  concepts.forEach(({ base_concept, derived_concept }) => {
    if (!tree[base_concept]) {
      tree[base_concept] = {
        name: base_concept,
        children: {},
      };
    }
    if (!tree[derived_concept]) {
      tree[derived_concept] = {
        name: derived_concept,
        children: {},
      };
    }

    tree[base_concept].children[derived_concept] = tree[derived_concept];
  });

  return tree;
}

function addChildConcept(
  base: string,
  concept: NodeItem,
  accumulator: Record<string, [string, string]>,
  unique: Set<string>,
) {
  accumulator[`${base}-${concept.name}`] = [base, concept.name]; // Is a JSON, to prune out duplication from timbr
  unique.add(concept.name);
  if (!isEmpty(concept.children)) {
    Object.values(concept.children).forEach(child => {
      addChildConcept(concept.name, child, accumulator, unique);
    });
  }
}

export async function getSubTreeConcepts(baseConcept: string) {
  const inheritance = await OntologyAPI.getSysInheritance();
  const tree = createTree(inheritance);
  const baseNode = tree[baseConcept];
  const concepts: Record<string, [string, string]> = {};
  const conceptsSet = new Set<string>();

  if (!baseNode) return { concepts: [], conceptsSet, conceptTree: baseNode };

  conceptsSet.add(baseNode.name);
  Object.values(baseNode.children).forEach(child =>
    addChildConcept(baseNode.name, child, concepts, conceptsSet),
  );

  return {
    concepts: Object.values(concepts),
    conceptsSet,
    conceptTree: baseNode,
  };
}

export const useConceptSubTree = (baseConcept?: string) => {
  const [subTreeConcepts, setSubTreeConcepts] = React.useState<
    [string, string][]
  >([]);
  const [subTreeConceptSet, setSubTreeConceptSet] = React.useState(
    new Set<string>(),
  );
  const [conceptTree, setConceptTree] = React.useState<NodeItem>({
    name: baseConcept || '',
    children: {},
  });

  React.useEffect(() => {
    async function fetchSubTreeConcepts(concept: string) {
      try {
        const {
          concepts: subTreeConcepts,
          conceptsSet,
          conceptTree,
        } = await getSubTreeConcepts(concept);
        setSubTreeConcepts(subTreeConcepts);
        setSubTreeConceptSet(conceptsSet);
        setConceptTree(conceptTree);
      } catch (e) {
        console.error(e);
      }
    }

    if (baseConcept) {
      fetchSubTreeConcepts(baseConcept);
    }
  }, [baseConcept]);

  return { subTreeConcepts, subTreeConceptSet, conceptTree };
};
