import * as React from 'react';
import { Entity } from '@octostar/platform-types';
import { Button, Spin } from 'antd5';
import { BorderTabHead } from 'src/octostar/components/flexlayout/wrappers/BorderTabHead';
import { LoadingOutlined } from '@ant-design/icons';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { isEqual } from 'lodash';
import { WatchersModal } from './WatchersModal';
import { WatchIntent, Watcher } from './types';
import { getIntentsFromEntity, getWatchersForEntity } from './WatchersIO';
import { WatchersTable } from './WatchersTable';
import { useRightSidebarControllerContext } from '../../flexlayout/wrappers/WrapperRightSidebarContext';
import { EmptyBorder } from '../../flexlayout/wrappers/EmptyBorder';
import { useWatchersContext } from './WatchersContext';
import './IntentsForEntities.css';

const IntentsForEntity: React.FC<{ watchers: Watcher[]; entity: Entity }> = ({
  watchers,
  entity,
}) => {
  const [intents, setIntents] = React.useState<WatchIntent[]>([]);
  const [entityWatchers, setEntityWatchers] = React.useState<Watcher[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const { refresh } = useWatchersContext();
  const mounted = React.useRef(1);

  React.useEffect(
    () => () => {
      mounted.current += 1;
    },
    [],
  );

  React.useEffect(() => {
    const { current } = mounted;
    getWatchersForEntity(watchers, entity).then(watchers => {
      if (current !== mounted.current) {
        return;
      }
      setEntityWatchers(curr => (isEqual(curr, watchers) ? curr : watchers));
    });
  }, [entity, watchers]);

  React.useEffect(() => {
    setLoading(true);
    const { current } = mounted;
    getIntentsFromEntity(entity)
      .then(intents => {
        if (current !== mounted.current) {
          return;
        }

        setIntents(curr => (isEqual(curr, intents) ? curr : intents));
      })
      .finally(() => setLoading(false));
  }, [entity, refresh]);

  const refreshIntents = async () => {
    const { current } = mounted;
    setLoading(true);
    refresh();
    getIntentsFromEntity(entity).then(intents => {
      if (current !== mounted.current) {
        return;
      }

      setIntents(intents);
      setLoading(false);
    });
  };

  if (!entityWatchers || entityWatchers?.length === 0) {
    return (
      <EmptyBorder description="No watchers found for the selected entities" />
    );
  }

  return (
    <div>
      <div className="watcher-header">
        <span className="watcher-label">{entity.entity_label}</span>
        <Button
          type="default"
          className="watcher-header-button"
          onClick={() => setOpen(true)}
        >
          Create
        </Button>
        {open && (
          <WatchersModal
            open={open}
            defaultEntity={entity}
            key={entity.entity_id}
            setOpen={setOpen}
            refreshIntents={refreshIntents}
          />
        )}
      </div>
      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
      ) : (
        <div className="intents-container">
          <ErrorBoundary>
            <WatchersTable
              intents={intents}
              refresh={refreshIntents}
              tiny
              rightSidebar
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export const WatchersAndIntentsForEntities = () => {
  const { entities, detailsTabsContentMap, setTabContent, setTabCounter } =
    useRightSidebarControllerContext();
  const { watchers } = useWatchersContext();

  React.useEffect(() => {
    const updateWatchersTabCounter = async () => {
      const totalWatchersCount = await Promise.all(
        entities.map(x => getIntentsFromEntity(x)),
      ).then(results =>
        results.reduce((total, curr) => total + curr.length, 0),
      );
      if (!detailsTabsContentMap.has('Watchers')) {
        const content = <WatchersAndIntentsForEntities />;
        setTabContent('Watchers', content, totalWatchersCount);
      } else if (
        detailsTabsContentMap.get('Watchers')?.badgeCounter !==
        totalWatchersCount
      ) {
        setTabCounter('Watchers', totalWatchersCount);
      }
    };
    updateWatchersTabCounter();
  }, [detailsTabsContentMap, entities, setTabContent, setTabCounter]);

  return (
    <div title="Watchers">
      <BorderTabHead
        title="Watchers"
        tabName="Watchers"
        showCloseBorderButton
        items={[]}
      />
      {entities.length === 0 ? (
        <EmptyBorder description="Select any nodes to see all their watchers" />
      ) : (
        entities.map(e => (
          <div key={e.entity_id} className="watcher-container">
            <IntentsForEntity entity={e} watchers={watchers} />
          </div>
        ))
      )}
    </div>
  );
};
