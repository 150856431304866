import * as React from 'react';
import { Form, Select, Tree } from 'antd5';
import {
  CaretDownOutlined,
  CloseCircleOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { SizeType } from 'antd5/es/config-provider/SizeContext';
import { t } from '@superset-ui/core';
import _ from 'lodash';
import {
  ConceptCounts,
  ConceptSelectorKeyValue,
  ConceptsInheritance,
  TreeConcept,
} from './types';
import {
  findValueInTreeData,
  filterAcceptedConcepts,
  updateTreeDataAsync,
  isChildKey,
  findValueByKeyInTreeData,
} from './util';
import { toTreeData } from './ConceptSelectorUtils';
import './conceptSelector.css';

export interface ConceptSelectorProps {
  concepts: ConceptsInheritance;
  conceptCounts?: ConceptCounts;
  conceptsToExclude?: string[];
  accepts?: (concept: string) => Promise<boolean>;
  onChange?: ((selected: string[]) => void) | undefined;
  onSearch?: ((selected: string[]) => void) | undefined;
  size?: SizeType;
  defaultValue?: string[] | undefined;
  label?: React.ReactNode | false;
  icon?: React.ReactNode;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  strictCheckMode?: boolean;
}

const ConceptSelectorTree = (props: ConceptSelectorProps) => {
  const {
    concepts,
    conceptCounts,
    conceptsToExclude,
    accepts,
    onChange,
    onSearch,
    size,
    defaultValue,
    multiple = true,
    disabled = false,
    placeholder = t('All or Selected Concepts'),
    label,
    strictCheckMode = false,
  } = props;
  const finalLabel =
    label === false
      ? false // No label
      : { label: label || (multiple ? t('Concepts') : t('Concept')) };
  const [filterValue, setFilterValue] = React.useState<string>('');
  const [treeData, setTreeData] = React.useState<TreeConcept[]>(
    toTreeData(concepts, conceptCounts, conceptsToExclude, filterValue)
      .treeData,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = React.useState<
    ConceptSelectorKeyValue[]
  >([]);
  const [checkedKeys, setCheckedKeys] = React.useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);
  const [initialTreeData, setInitialTreeData] = React.useState<TreeConcept[]>(
    [],
  );
  const isFirstRender = React.useRef<boolean>(true);
  const elRef = React.useRef<HTMLDivElement>(null);

  const filteredConcepts = React.useMemo(
    () =>
      concepts.filter(
        ([base, derived]) =>
          base.toLowerCase().includes(filterValue.toLowerCase()) ||
          derived.toLowerCase().includes(filterValue.toLowerCase()),
      ),
    [concepts, filterValue],
  );

  React.useEffect(() => {
    const { treeData } = toTreeData(concepts, conceptCounts, conceptsToExclude);
    setInitialTreeData(curr => (_.isEqual(curr, treeData) ? curr : treeData));
  }, [conceptCounts, concepts, conceptsToExclude]);

  React.useEffect(() => {
    if (
      isFirstRender.current &&
      defaultValue?.length &&
      initialTreeData?.length
    ) {
      const newSelectedKeys: ConceptSelectorKeyValue[] = defaultValue.map(
        value => {
          const selectedConcept: ConceptSelectorKeyValue = {
            key: '',
            value,
          };
          selectedConcept.key = findValueInTreeData(initialTreeData, value)
            ?.key as string;
          return selectedConcept;
        },
      );
      setSelectedKeys(curr =>
        _.isEqual(curr, newSelectedKeys) ? curr : newSelectedKeys,
      );
      const newCheckedKeys = newSelectedKeys.map(key => key.key);
      setCheckedKeys(curr =>
        _.isEqual(curr, newCheckedKeys) ? curr : newCheckedKeys,
      );
      isFirstRender.current = false;
    }
  }, [defaultValue, initialTreeData]);

  React.useEffect(() => {
    setSelectedKeys(curr => {
      const newSelectedKeys: ConceptSelectorKeyValue[] = curr.map(
        (selectedKey: ConceptSelectorKeyValue) => ({
          key: findValueInTreeData(treeData, selectedKey.value)?.key as string,
          value: selectedKey.value,
        }),
      );
      return _.isEqual(curr, newSelectedKeys) ? curr : newSelectedKeys;
    });
  }, [filterValue, treeData]);

  React.useEffect(() => {
    if (filterValue === '') {
      if (accepts) {
        updateTreeDataAsync(initialTreeData, conceptCounts, accepts).then(
          updatedTreeData => {
            if (!_.isEqual(initialTreeData, updatedTreeData)) {
              setTreeData(curr =>
                _.isEqual(curr, updatedTreeData) ? curr : updatedTreeData,
              );
            }
          },
        );
        setExpandedKeys(curr => (_.isEqual(curr, []) ? curr : []));
        return;
      }
      setTreeData(curr =>
        _.isEqual(curr, initialTreeData) ? curr : initialTreeData,
      );
      setExpandedKeys(curr => (_.isEqual(curr, []) ? curr : []));
      return;
    }

    const { treeData, expandedKeys } = toTreeData(
      filteredConcepts,
      conceptCounts,
      conceptsToExclude,
      filterValue,
    );
    setExpandedKeys(curr =>
      _.isEqual(curr, expandedKeys) ? curr : expandedKeys,
    );

    if (accepts) {
      updateTreeDataAsync(treeData, conceptCounts, accepts).then(
        updatedTreeData => {
          if (!_.isEqual(treeData, updatedTreeData)) {
            setTreeData(updatedTreeData);
          }
        },
      );
    } else {
      setTreeData(curr => (_.isEqual(curr, treeData) ? curr : treeData));
    }
  }, [
    initialTreeData,
    filteredConcepts,
    filterValue,
    conceptCounts,
    conceptsToExclude,
    accepts,
  ]);

  React.useEffect(() => {
    if (!accepts || !treeData?.length) return;
    const filterConcepts = async () => {
      const filteredConcepts = await filterAcceptedConcepts(treeData, accepts);
      setTreeData(curr =>
        filteredConcepts?.length && !_.isEqual(curr, filteredConcepts)
          ? filteredConcepts
          : curr,
      );
    };
    filterConcepts();
    // WARNING WARNING WARNING Circular dependency on treeData, setTreeData
  }, [accepts, treeData]);

  const onTreeChange = React.useCallback(
    (selected: string[], info: { node: TreeConcept }) => {
      const isEmptySelection: boolean = selected.length === 0;
      const isMultipleSelection: boolean = multiple;
      const selectedConcept: ConceptSelectorKeyValue = {
        key: info?.node?.key as string,
        value: info?.node?.value as string,
      };

      const resetOnEmptySelection = (): void => {
        setSelectedKeys((current: ConceptSelectorKeyValue[]) =>
          current.length === 0 ? current : [],
        );
        setCheckedKeys((current: string[]) =>
          current.length === 0 ? current : [],
        );
        onChange?.([]);
        onSearch?.([]);
        setFilterValue((current: string) => (current === '' ? current : ''));
        setExpandedKeys([]);
      };

      const updateMultipleSelection = (): void => {
        if (!selectedConcept?.value) {
          const filteredKeys = selectedKeys.filter(selectedConcept =>
            selected.includes(selectedConcept?.value),
          );
          setSelectedKeys((current: ConceptSelectorKeyValue[]) =>
            _.isEqual(current, filteredKeys) ? current : filteredKeys,
          );
          setCheckedKeys((current: string[]) =>
            _.isEqual(
              current,
              filteredKeys.map(key => key.key),
            )
              ? current
              : filteredKeys.map(key => key.key),
          );
          onChange?.([..._.uniq(filteredKeys.map(key => key.value))]);
          onSearch?.([..._.uniq(filteredKeys.map(key => key.key))]);
        }
      };

      setFilterValue((current: string) => (current === '' ? current : ''));
      setOpen(false);

      if (isEmptySelection) {
        resetOnEmptySelection();
        return;
      }

      if (isMultipleSelection) {
        updateMultipleSelection();
      }
    },
    [selectedKeys, multiple, onChange, onSearch],
  );

  const onTreeSelect = React.useCallback(
    (selected: string[], info: { node: TreeConcept }) => {
      if (multiple) {
        return;
      }
      const isEmptySelection: boolean = selected.length === 0;
      const selectedConcept: ConceptSelectorKeyValue = {
        key: info?.node?.key as string,
        value: info?.node?.value as string,
      };

      const updateSingleSelection = (): void => {
        if (
          selectedConcept.key &&
          !_.isEqual(selectedKeys, [selectedConcept])
        ) {
          setSelectedKeys((current: ConceptSelectorKeyValue[]) =>
            _.isEqual(current, [selectedConcept]) ? current : [selectedConcept],
          );
          onSearch?.([selectedConcept.value]);
        }
      };

      setFilterValue((current: string) => (current === '' ? current : ''));
      setOpen(false);

      if (isEmptySelection) {
        return;
      }
      updateSingleSelection();
    },
    [selectedKeys, multiple, onSearch],
  );

  const onTreeCheck = (
    checked: string[] | { checked: string[]; halfChecked: string[] },
  ) => {
    const newCheckedKeys = Array.isArray(checked) ? checked : checked.checked;
    newCheckedKeys.forEach(key => {
      if (!checkedKeys.includes(key)) {
        newCheckedKeys.push(key);
      }
    });

    setCheckedKeys(newCheckedKeys);

    const areAllChildrenChecked = (parentKey: string, allKeys: string[]) => {
      const keyValue = findValueByKeyInTreeData(initialTreeData, parentKey);
      if (!keyValue) {
        return false;
      }
      const parentNode = findValueInTreeData(initialTreeData, keyValue);
      if (
        !parentNode ||
        !parentNode.children ||
        parentNode.children.length <= 1
      ) {
        return false;
      }
      const childrenKeys = parentNode.children.map(child => child.key);
      return (
        childrenKeys.length > 0 &&
        childrenKeys.every(childKey => allKeys.includes(childKey as string))
      );
    };

    // Filter out children if their parent is already selected
    const filteredCheckedKeys = newCheckedKeys.filter(
      key =>
        !newCheckedKeys.some(
          parentKey =>
            parentKey !== key &&
            isChildKey(key, parentKey) &&
            areAllChildrenChecked(parentKey, newCheckedKeys),
        ),
    );

    const updatedSelectedKeys = filteredCheckedKeys
      .map(key => {
        const value = findValueByKeyInTreeData(treeData, key);
        return { key, value };
      })
      .filter(item => item.value);
    setSelectedKeys(updatedSelectedKeys as ConceptSelectorKeyValue[]);
    const checkedValues = updatedSelectedKeys.map(item => item.value);
    onChange?.([..._.uniq(checkedValues as string[])]);
    onSearch?.([..._.uniq(newCheckedKeys as string[])]);
    setFilterValue('');

    setOpen(false);
  };

  const clearSelection = React.useCallback(() => {
    setSelectedKeys([]);
    setCheckedKeys([]);
    setExpandedKeys([]);
  }, []);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && e.currentTarget.value !== '') {
        const selectedValue = e.currentTarget.value;
        if (!selectedKeys.map(key => key.value).includes(selectedValue)) {
          const selectedTreeConcept: TreeConcept | undefined =
            findValueInTreeData(treeData, selectedValue);
          if (!selectedTreeConcept?.key || selectedTreeConcept?.disabled) {
            return;
          }
          const selectedConcept: ConceptSelectorKeyValue = {
            key: selectedTreeConcept?.key as string,
            value: selectedTreeConcept?.value as string,
          };
          if (multiple) {
            const newSelectedConcepts: ConceptSelectorKeyValue[] = _.uniq([
              ...selectedKeys.map(x => {
                if (!x?.key) {
                  const matchingKey = checkedKeys.find(k =>
                    k.endsWith(`:${x.value}`),
                  );
                  return {
                    key: matchingKey || '',
                    value: x.value,
                  };
                }
                return x;
              }),
              selectedConcept,
            ]);

            const newCheckedKeys: string[] = newSelectedConcepts
              .map(x => x.key)
              .filter(x => x !== '');

            // Filter out children if their parent is already selected
            const filteredConcepts = newSelectedConcepts.filter(
              concept =>
                !newSelectedConcepts.some(
                  parentConcept =>
                    concept?.key &&
                    parentConcept.key !== concept.key &&
                    isChildKey(concept.key, parentConcept.key),
                ),
            );
            setSelectedKeys(curr =>
              _.isEqual(curr, filteredConcepts) ? curr : filteredConcepts,
            );
            setCheckedKeys(curr =>
              _.isEqual(curr, newCheckedKeys) ? curr : newCheckedKeys,
            );
            onChange?.(filteredConcepts.map(key => key.value));
            onSearch?.(filteredConcepts.map(key => key.value));
          } else {
            setSelectedKeys(curr =>
              _.isEqual(curr, [selectedConcept]) ? curr : [selectedConcept],
            );
            onChange?.([selectedConcept.value]);
            onSearch?.([selectedConcept.value]);
          }
          setOpen(false);
          setFilterValue('');
          e.currentTarget.blur();
        }
      }
    },
    [selectedKeys, treeData, multiple, onChange, onSearch, checkedKeys],
  );

  const handleSearch = React.useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  return (
    <Form.Item {...finalLabel} className="concept-selector-form-input">
      <div ref={elRef}>
        <Select
          showSearch
          mode={multiple ? 'multiple' : undefined}
          disabled={disabled}
          size={size}
          popupMatchSelectWidth={Math.min(
            (elRef.current as any)?.offsetWidth * 2 || 350,
            350,
          )}
          dropdownRender={() => (
            <Tree.DirectoryTree
              treeData={treeData}
              className={
                multiple
                  ? 'concept-selector-tree-directory-multiple'
                  : 'concept-selector-tree-directory'
              }
              expandedKeys={expandedKeys}
              onExpand={keys => setExpandedKeys(keys as string[])}
              multiple={multiple}
              {...((multiple
                ? {
                    checkable: true,
                    checkedKeys,
                    onCheck: onTreeCheck,
                    checkStrictly: filterValue !== '' || strictCheckMode,
                  }
                : {
                    onSelect: onTreeSelect,
                    selectedKeys: _.uniq(selectedKeys.map(key => key.key)),
                    selectable: true,
                  }) as any)}
              switcherIcon={<CaretDownOutlined className="custom-icon" />}
              height={300}
              icon={<MailOutlined />}
            />
          )}
          allowClear={multiple}
          value={_.uniq(selectedKeys.map(key => key.value))}
          onChange={onTreeChange}
          onDropdownVisibleChange={() => setOpen(!open)}
          onInputKeyDown={handleKeyDown}
          onClick={e => e.currentTarget.focus()}
          open={open}
          onSearch={handleSearch}
          placeholder={
            disabled && defaultValue?.length ? defaultValue[0] : placeholder
          }
          suffixIcon={
            multiple ? undefined : (
              <>
                {selectedKeys?.length > 0 && (
                  <CloseCircleOutlined onClick={clearSelection} />
                )}
              </>
            )
          }
        />
      </div>
    </Form.Item>
  );
};

export const ConceptSelector = (props: ConceptSelectorProps) => {
  const { concepts, ...other } = props;
  const sanitizedConcepts = React.useMemo(
    () => concepts.filter((val: any) => typeof val !== 'string' && val.length),
    [concepts],
  );

  return <ConceptSelectorTree concepts={sanitizedConcepts} {...other} />;
};

export default ConceptSelector;
