import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { encode } from 'blurhash';

import { getAttachment } from 'src/octostar/hooks/useAttachment';
import {
  WorkspaceItem,
  CustomTemplate,
  SavedTemplate,
  TemplateLayout,
} from '@octostar/platform-types';

export function toValidClassName(input: string, spaces?: boolean): string {
  // Remove invalid characters and ensure no spaces
  let name = input.replace(/[^a-zA-Z0-9_ ]/g, '');
  if (!spaces) {
    name = name.replace(/\s/g, '');
  }

  // Remove leading numbers
  name = name.replace(/^[0-9]+/, '');

  // Capitalize the first letter to make it PascalCase (common for class names)
  name = name.charAt(0).toUpperCase() + name.slice(1);

  // If after processing, the name doesn't start with a letter, return an empty string
  if (!/^[A-Z]/.test(name)) {
    return '';
  }

  return name;
}

export function toValidVariableName(input: string): string {
  const s = toValidClassName(input);
  if (s.length === 0) return s;
  return s.charAt(0).toLowerCase() + s.slice(1);
}

export async function getTemplatesForConcepts(
  concepts: string[],
  layout?: TemplateLayout,
): Promise<CustomTemplate[]> {
  return DesktopAPI.getTemplates(layout).then(a =>
    a.filter(x => (x.concepts || []).some(c => concepts.includes(c))),
  );
}
export const getTemplateForItem = async (
  item: WorkspaceItem,
  defaultTemplate: string,
): Promise<SavedTemplate> => {
  if (item.os_item_content_type !== 'os_template') {
    throw new Error('Item is not a template');
  }
  const template = await getAttachment(item, 'text', defaultTemplate);
  const t: SavedTemplate = {
    ...item.os_item_content,
    template: template.text,
    os_entity_uid: item.os_entity_uid,
    os_workspace: item.os_workspace,
  };
  if (!t.name) {
    t.name = item.os_item_name;
  }
  return t;
};

export const toBlurhash = async (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        const img = new Image();
        img.src = event.target.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, img.width, img.height);
            const blurhash = encode(
              imageData.data,
              img.width,
              img.height,
              4,
              4,
            );
            resolve(blurhash);
          } else {
            reject(new Error('Failed to get canvas context'));
          }
        };
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
