import { RefObject } from 'react';

function onKeyPress(
  keyName: string,
  elementRef: RefObject<HTMLElement>,
  onKeyPressed: () => void,
): () => void {
  function handleKeyDown(event: KeyboardEvent) {
    if (
      document.visibilityState === 'visible' &&
      elementRef.current &&
      elementRef.current.contains(document.activeElement)
    ) {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.key.toLowerCase() === keyName.toLowerCase()
      ) {
        event.preventDefault();
        onKeyPressed();
      }
    }
  }

  window.addEventListener('keydown', handleKeyDown);

  // Return the unsubscribe function
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}

export default onKeyPress;
