/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import LRU from 'lru-cache';
import SqlString from 'sqlstring';
import { v5 as uuidv5 } from 'uuid';
import _ from 'lodash';
import {
  ApiErrorResult,
  EConnection,
  ApiSuccessResult,
  Concept,
  ConceptWithParents,
  ConnectedEntity,
  Entity,
  EntityEdge,
  Inheritance,
  Ontology,
  Property,
  QueryResponse,
  Relationship,
  SendQueryOptions,
  ShowOntology,
  Callback,
  Unsubscribe,
  EventEmitterType,
  RelationshipCountResult,
  WorkspaceRelationship,
  OntologyTags,
  SortOrder,
  WorkspaceItem,
} from '@octostar/platform-types';
import { compose, memoize } from './functional';
import { CancellableQueryClient } from './CancellableQueryClient';
import { BACKEND_URL } from '../Constants';
import { CachinQueryExecutor } from './CachingQueryExecutor';
import { DeferredPromise } from './handy';
import {
  TEMPORARY_ITEM_TYPE,
  VIRTUAL_ITEM_TYPE,
  getNotificationKey,
} from '../interface';
import { RelationshipCountCache } from './RelationshipCountCache';
import { RelationshipCache } from './RelationshipCache';
import { dbconsole } from './dbconsole';
import { tryToFixLabel } from './labelFixer';
import { relationshipCompare, safeParseSortOrder } from './relationshipLib';
import { RelationshipCountResultWithExpires, ShadowGraph } from './Shadow';
import { notifyEntityUpdated } from './notifyEntityUpdated';
import { getConceptSelectAllFields } from './query';

const CACHE_TTL = 1000 * 60 * 30; // 30 minutes
const quickbatch: SendQueryOptions = Object.freeze({
  batchTimeout: 100,
  lowPriority: true,
});

const lrus: { [ontology: string]: LRU<string, any> } = {};

type RelationshipQueryResult = {
  entity_id_from: string;
  rel_name: string;
  entity_id_to: string;
};

const NO_DATA_ENTITY: Entity = {
  entity_id: 'ONTOLOGY_API_NO_DATA_ENTITY',
  entity_type: 'ONTOLOGY_API_INTERNAL_USE_ONLY',
  entity_label: '😭 you should not be seeing this',
};

export default class OntologyAPI implements Ontology {
  private ontology_name: string;

  private cache: LRU<string, any>;

  private queryClient: CancellableQueryClient;

  private entitiesCache: CachinQueryExecutor<Entity, Entity>;

  private relationshipCountCache: RelationshipCountCache;

  private relationshipsCache: RelationshipCache;

  private ee: EventEmitterType | undefined;

  private loaded = false;

  private shadowGraph: ShadowGraph = new ShadowGraph();

  constructor(ee?: EventEmitterType, ontology?: string) {
    this.ee = ee;
    this.queryClient = new CancellableQueryClient(BACKEND_URL);
    this.ontology_name = ontology || 'no_ontology';
    this.cache = lrus[this.ontology_name] =
      lrus[this.ontology_name] ||
      new LRU<string, any>({ max: 100000, ttl: CACHE_TTL });
    if (this.ontology_name !== 'no_ontology') {
      this.entitiesCache = this.createEntitiesCache();
      this.relationshipCountCache = new RelationshipCountCache(this);
      this.relationshipsCache = new RelationshipCache(
        this,
        this.relationshipCountCache,
      );
      setTimeout(this.getConcepts.bind(this), 0);
    }
  }

  public async getOntologyName() {
    return this.ontology_name;
  }

  private check_ontology() {
    if (this.ontology_name === 'no_ontology') {
      throw new Error('No ontology selected');
    }
  }

  public async sendQuery(
    query: string,
    options?: SendQueryOptions,
  ): Promise<QueryResponse> {
    this.check_ontology();
    return this.queryClient.sendQuery(query, this.ontology_name, {
      ...quickbatch,
      ...options,
    });
  }

  public async sendQueryT<T>(
    query: string,
    options?: SendQueryOptions,
    force_refresh = false,
  ): Promise<T[]> {
    this.check_ontology();
    const result = await this.queryClient.executeQuery<T>(
      query,
      this.ontology_name,
      {
        ...quickbatch,
        ...options,
      },
      force_refresh,
    );
    if (result.status === 'success') {
      return (result as ApiSuccessResult<T[]>).data;
    }
    return Promise.reject((result as ApiErrorResult).data);
  }

  public async cancelQueries(context: string) {
    this.queryClient.reset(context);
  }

  private createEntitiesCache = () =>
    new CachinQueryExecutor<Entity, Entity>({
      cache: this.cache,
      queryExecutor: this,
      getEntity: entity => entity,
      toSql: async (entity_type, entity_ids) => {
        const entityIds = entity_ids.map(x => SqlString.escape(x)).join(', ');
        const fields = this.loaded
          ? await getConceptSelectAllFields(entity_type)
          : '*';
        return `SELECT ${fields} FROM timbr.${entity_type} as t1
 WHERE t1.entity_id in (${entityIds})`.trim();
      },
      preprocess: async data => this.validateEntityResult(data),
      afterCacheSet: (key: string, entity: Entity) => {
        notifyEntityUpdated(entity);
      },
      getDefaultValue: () => ({ ...NO_DATA_ENTITY }),
    });

  private static ontologies: string[];

  static async fetchOntologies(): Promise<string[]> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return fetch(`${BACKEND_URL}/api/octostar/timbr/get_ontologies`, {
      method: 'GET',
      headers,
    })
      .then(r => r.json())
      .then((o: any) => {
        if (o.status === 'success') {
          return o.data;
        }
        console.log('ontologies not fetched', o);
        throw new Error(`fetch ontology status was ${o.status}`);
      });
  }

  static async getAvailableOntologies(): Promise<string[]> {
    if (OntologyAPI.ontologies === undefined) {
      try {
        OntologyAPI.ontologies = await OntologyAPI.fetchOntologies();
      } catch (e) {
        console.log('fetching ontologies failed', e);
        alert('No ontologies loaded. The application cannot run');
        return ['none'];
      }
    }
    return OntologyAPI.ontologies;
  }

  async getAvailableOntologies(): Promise<string[]> {
    return OntologyAPI.getAvailableOntologies();
  }

  sysInheritancePromise: Promise<Inheritance[]> | undefined;

  async getSysInheritance(): Promise<Inheritance[]> {
    if (!this.sysInheritancePromise) {
      this.sysInheritancePromise = this.sendQuery(
        'SELECT * FROM timbr.SYS_INHERITANCE',
      );
    }
    return this.sysInheritancePromise;
  }

  deferredConceptsPromise: DeferredPromise<Map<string, Concept>> | undefined;

  async getConcepts(): Promise<Map<string, Concept>> {
    if (this.deferredConceptsPromise) {
      return this.deferredConceptsPromise.promise;
    }
    this.deferredConceptsPromise = new DeferredPromise<Map<string, Concept>>();
    console.log("🗺️ Querying for 'getConcepts'");
    try {
      const conceptsMap: Map<string, Concept> = new Map();
      // Parallel queries
      const [
        respInheritance,
        respProperties,
        relationshipsResp,
        showOntology,
        ontologyTags,
      ] = await Promise.all([
        await this.getSysInheritance(),
        (await this.sendQuery(
          'SELECT * FROM timbr.SYS_CONCEPT_PROPERTIES',
        )) as Property[],
        (
          await this.sendQueryT<Relationship>(
            `SELECT * FROM timbr.SYS_CONCEPT_RELATIONSHIPS`,
          )
        ).map(r => ({
          ...r,
          key: `${r.datasource_id}|${r.concept}|${r.target_concept}|${r.relationship_name}`,
        })),
        await this.sendQueryT<ShowOntology>('SHOW ONTOLOGY'),
        await this.sendQueryT<OntologyTags>('SHOW TAGS'),
      ]);

      // Tree building
      const inheritanceMap = new Map<string, Inheritance>();
      let head: string | null = null;

      const memoCache = {};
      const showOntologyMap = showOntology.reduce((acc, row) => {
        acc[row.concept] = row;
        return acc;
      }, {});

      const getParentsOf = (concept_name: string): string[] => {
        function _getParentsOf(_concept: string) {
          const parents = respInheritance.filter(
            (row: Inheritance) => row.derived_concept === _concept,
          );
          const extendedParents = [...parents];
          for (const p of parents) {
            extendedParents.push(..._getParentsOf(p.base_concept));
          }
          return extendedParents;
        }

        function _adaptResult(input: any) {
          return input.map((r: any) => r.base_concept);
        }

        return memoize(
          compose(_adaptResult, _getParentsOf),
          memoCache,
        )(concept_name);
      };

      for (const edge of respInheritance) {
        conceptsMap.set(
          edge.derived_concept as string,
          {
            concept_name: edge.derived_concept,
            parents: getParentsOf(edge.derived_concept).filter(
              (s, i, a) => a.indexOf(s) === i,
            ),
            properties: [],
            allProperties: [],
            relationships: [],
            columns: [],
            archetype: {} as Concept,
            tags: [],
            labelKeys: [],
            allLabelKeys: [],
          } as ConceptWithParents,
        );
        conceptsMap.set(
          edge.base_concept as string,
          {
            concept_name: edge.base_concept,
            parents: getParentsOf(edge.base_concept).filter(
              (s, i, a) => a.indexOf(s) === i,
            ),
            properties: [],
            allProperties: [],
            relationships: [],
            columns: [],
            archetype: {} as Concept,
            tags: [],
            labelKeys: [],
            allLabelKeys: [],
          } as ConceptWithParents,
        );
      }

      for (const concept of conceptsMap.keys()) {
        const parents = inheritanceMap.get(concept);
        if (!parents) {
          if (parents == null) {
            head = concept;
          } else {
            throw new Error(
              `Invalid tree, multiple heads: ${JSON.stringify([
                head,
                concept,
              ])}`,
            );
          }
        }
      }
      if (!head) {
        throw new Error('Invalid tree, no head');
      }

      // Fill in the concept properties
      for (const row of respProperties) {
        const c: string = row.concept;
        let existingConcept: Concept = {} as Concept;
        if (!conceptsMap.has(c)) {
          console.log('discovered new concept', c);
          const blank = {} as Concept;
          conceptsMap.set(c, blank);
          existingConcept = blank;
        }
        existingConcept = conceptsMap.get(c) as Concept;
        if (!existingConcept.properties) {
          existingConcept.properties = [];
          conceptsMap.set(c, existingConcept);
        }
        conceptsMap.get(c)?.properties?.push({
          property_name: row.property_name,
          property_type: row.property_type,
          concept: row.concept,
        });
      }
      // Fill in the concept relationships
      try {
        for (const rel of relationshipsResp) {
          const concept = conceptsMap.get(rel.concept) as Concept;
          concept.relationships =
            (conceptsMap.get(rel.concept)?.relationships as Relationship[]) ||
            [];
          const sortOrder = ontologyTags
            .filter(
              tag =>
                tag.tag_name === 'show_priority' &&
                tag.target_type === 'relationship',
            )
            ?.find(tag => tag.target_name === rel.relationship_name)?.tag_value;
          rel.sort_order = sortOrder
            ? safeParseSortOrder(sortOrder)
            : SortOrder.OTHER;
          conceptsMap.get(rel.concept)?.relationships.push(rel);
        }
        // Inheriting relationships
        for (const conceptName of conceptsMap.keys()) {
          const concept = conceptsMap.get(conceptName) as Concept;
          const parents = concept.parents || [];
          concept.relationships = concept.relationships || [];
          for (const p of parents) {
            const parentRelationships = conceptsMap.get(p)?.relationships || [];
            concept.relationships = _.uniqBy(
              [...concept.relationships, ...parentRelationships].sort(
                relationshipCompare,
              ),
              'relationship_name',
            );
          }
          conceptsMap.set(conceptName, concept);
        }
      } catch (e) {
        console.log('error retrieving relationships', e);
      }
      // calculate columns;
      for (const conceptName of conceptsMap.keys()) {
        const concept = conceptsMap.get(conceptName) as Concept;
        const allConceptColumns = ['entity_id', 'entity_type', 'entity_label'];
        const thisColumns = (concept.properties || []).map(
          x => x.property_name,
        );
        const parentColumns = (concept.parents || []).map(parent =>
          ((conceptsMap.get(parent) || {}).properties || []).map(
            x => x.property_name,
          ),
        );
        const columns: string[] = [
          ...allConceptColumns,
          ...thisColumns,
          ...Array.prototype.concat.apply([], parentColumns),
        ];
        columns
          .filter((s, i, a) => a.indexOf(s) === i)
          .sort()
          .forEach(column => concept.columns.push(column));
      }
      for (const conceptName of conceptsMap.keys()) {
        const concept = conceptsMap.get(conceptName) as Concept;
        // eslint-disable-next-line no-await-in-loop
        concept.archetype = await this.getConceptArchetype(
          concept,
          conceptsMap,
        );
      }

      // Add tags to concepts
      const tags: { concept: string; tag_name: string; tag_value: string }[] =
        ontologyTags
          .filter(tag => tag.target_type === 'concept')
          .map(tag => ({
            concept: tag.target_name,
            tag_name: tag.tag_name,
            tag_value: tag.tag_value,
          }));
      for (let i = 0; i < tags.length; i += 1) {
        const tag = tags[i];
        const concept = conceptsMap.get(tag.concept);
        if (concept) {
          concept.tags.push(tag);
        }
      }

      // calculate all properties
      for (const conceptName of conceptsMap.keys()) {
        const concept = conceptsMap.get(conceptName) as Concept;
        const allConceptProperties = [
          {
            property_name: 'entity_id',
            property_type: 'string',
            concept: 'os_thing',
          },
          {
            property_name: 'entity_type',
            property_type: 'string',
            concept: 'os_thing',
          },
        ];
        const conceptProperties = (concept.properties || []).map(x => x);
        const inheritedProperties = (concept.parents || []).map(parent =>
          ((conceptsMap.get(parent) || {}).properties || []).map(x => x),
        );
        const allProperties: Property[] = [
          ...allConceptProperties,
          ...conceptProperties,
          ...Array.prototype.concat.apply([], inheritedProperties),
        ];
        allProperties
          .filter((s, i, a) => a.indexOf(s) === i)
          .sort()
          .forEach(property => {
            if (concept.allProperties !== undefined) {
              concept.allProperties.push(property);
            }
          });
      }
      // assign label tags
      for (const conceptName of conceptsMap.keys()) {
        const concept = conceptsMap.get(conceptName) as Concept;
        concept.labelKeys =
          showOntologyMap[conceptName]?.label_keys?.split(/,\s*/) || [];
      }
      for (const conceptName of conceptsMap.keys()) {
        const concept = conceptsMap.get(conceptName) as Concept;
        for (let i = 0; i < concept.parents.length; i += 1) {
          concept.labelKeys = concept.labelKeys.concat(
            conceptsMap.get(concept.parents[i])?.labelKeys || [],
          );
        }
        concept.labelKeys = concept.labelKeys.filter(
          (s, i, a) => a.indexOf(s) === i && s !== '',
        );
        if (concept.labelKeys.length === 0) concept.labelKeys.push('');
      }

      console.log('Calculated concepts map', conceptsMap);
      this.loaded = true;
      this.deferredConceptsPromise.resolve(conceptsMap);
    } catch (e) {
      this.deferredConceptsPromise.reject(e);
    }
    return this.deferredConceptsPromise.promise;
  }

  async getConceptArchetype(
    concept: Concept,
    concepts: Map<string, Concept>,
    seen: string[] = [],
  ): Promise<Concept> {
    seen.push(concept.concept_name);
    const validParents = concept.parents.filter(
      c =>
        c !== 'thing' &&
        c !== concept.concept_name &&
        !c.startsWith('os_') &&
        !c.endsWith('_thing') &&
        !seen.includes(c),
    );
    for (const c of validParents) {
      const parent = concepts.get(c);
      if (!parent) {
        throw new Error(
          `Concept ${c} not found while looking for archetype of ${concept.concept_name}`,
        );
      }
      return this.getConceptArchetype(parent, concepts, seen);
    }
    return concept;
  }

  async validateEntityResult(resp: Entity[]) {
    if (resp?.length === 0) {
      return resp;
    }
    if (!resp?.[0]?.entity_id) {
      console.log('Invalid entity result: ', resp);
      throw new Error(`Entity not found: ${resp?.[0]?.entity_id}`);
    }
    for (let i = 0; i < resp.length; i += 1) {
      if (_.isEmpty(resp[i].entity_label)) {
        // eslint-disable-next-line no-await-in-loop, no-param-reassign
        resp[i] = await tryToFixLabel(this, resp[i]);
      }
    }
    return resp;
  }

  async getConceptByName(conceptName: string): Promise<Concept | undefined> {
    if (!conceptName) {
      return undefined;
    }
    const concepts = await this.getConcepts();
    if (!concepts.has(conceptName)) {
      if ([TEMPORARY_ITEM_TYPE, VIRTUAL_ITEM_TYPE].includes(conceptName)) {
        return undefined;
      }
      throw new Error(`Concept ${conceptName} not found`);
    }
    return concepts.get(conceptName);
  }

  private async assignConceptName(entity: Entity): Promise<ConnectedEntity> {
    if (!entity) {
      throw new Error('entity missing');
    }
    if (
      (entity as ConnectedEntity).concept_name !== undefined ||
      entity.entity_type === TEMPORARY_ITEM_TYPE
    ) {
      return entity as ConnectedEntity;
    }
    const concept = (await this.getConceptByName(
      entity.entity_type,
    )) as Concept;
    const connected = entity as ConnectedEntity;
    if (concept) {
      connected.concept_name = concept.concept_name;
    }
    return connected;
  }

  private fetchEntityPromises: { [key: string]: Promise<Entity> } = {};

  async updateCache(
    entities: (Entity & WorkspaceItem)[],
  ): Promise<(Entity & WorkspaceItem)[]> {
    // this.entitiesCache
    entities.forEach(entity => {
      this.entitiesCache.set(entity);
    });

    return entities;
  }

  async getEntity<T extends Entity>(
    e: Entity,
    refresh?: boolean,
    skipSideEffects?: boolean,
  ): Promise<T> {
    e.entity_id = e.entity_id || (e as WorkspaceItem).os_entity_uid;

    if (!e?.entity_id || !e.entity_type) {
      return e as T;
    }
    const fetching = this.fetchEntityPromises[e.entity_id];
    if (fetching) {
      return fetching as Promise<T>;
    }
    if (refresh) {
      this.entitiesCache.clear(e);
      const rels = await this.getRelationshipsForEntity(e);
      rels.forEach(async relationship => {
        this.relationshipsCache.clear({ entity: e, relationship });
        await this.relationshipCountCache.clear({ entity: e, relationship });
      });
    }

    const promise = this.entitiesCache.get(e).then(async entity => {
      if (
        !entity ||
        (entity.entity_id === NO_DATA_ENTITY.entity_id && !refresh)
      ) {
        dbconsole.error(`entity not fetched ${e.entity_type}:${e.entity_id}`);
        return tryToFixLabel<T>(this, e as T);
      }
      if (
        (e as any).os_last_updated_at &&
        !refresh &&
        entity &&
        (entity as any).os_last_updated_at < (e as any).os_last_updated_at
      ) {
        await this.getEntity(e, true, skipSideEffects);
      }
      if (entity.entity_id === NO_DATA_ENTITY.entity_id) {
        return tryToFixLabel<T>(this, e as T);
      }
      return tryToFixLabel<T>(this, entity as T);
    });
    if (refresh) {
      this.fetchEntityPromises[e.entity_id] = promise;
    }
    try {
      const entity = await promise;
      return entity;
    } finally {
      if (refresh) {
        delete this.fetchEntityPromises[e.entity_id];
      }
    }
  }

  async subscribe<T>(
    entity: Entity,
    callback: Callback<T>,
  ): Promise<Unsubscribe> {
    const { ee } = this;
    if (!ee) {
      console.log('subscribe called without event emitter in OntologyAPI');
      return async () => undefined;
    }
    const key = getNotificationKey(entity);
    const fn = (e: T) => {
      if (!_.isEqual(e, (fn as any).e)) {
        (fn as any).e = e;
        callback(e);
      }
    };
    ee.on(key, fn);
    this.getEntity(entity, false, true).then(e => fn(e as T));
    return async () => ee.off(key, fn);
  }

  async getRelationshipCount(
    entity: Entity,
    rel: Relationship | string,
    forceRefresh?: boolean,
  ): Promise<RelationshipCountResult> {
    const relationship =
      typeof rel === 'string'
        ? (await this.getRelationshipsForEntity(entity)).find(
            relationship => relationship.relationship_name === rel,
          )
        : rel;
    if (!relationship) {
      console.warn('relationship not found', rel);
      return { count: 0 };
    }

    if (!forceRefresh) {
      const quick: RelationshipCountResultWithExpires | undefined =
        await this.shadowGraph.getReationshipCount(
          entity,
          relationship.relationship_name,
        );
      if (quick) {
        const result = {
          ...quick,
          expired: quick.expired || quick.expires < CACHE_TTL + Date.now(),
        };
        return result;
      }
    }

    const result = await this.relationshipCountCache.getRelationshipCount(
      entity,
      relationship,
      forceRefresh,
    );
    if (!result.expired) {
      this.shadowGraph.setRelationshipCount(
        entity,
        relationship.relationship_name,
        { ...result, expires: CACHE_TTL + Date.now() },
      );
    }
    return result;
  }

  async getConceptForEntity(entity: Entity): Promise<Concept | undefined> {
    const connected = await this.assignConceptName(entity);
    return this.getConceptByName(connected.concept_name);
  }

  async getRelationshipsForEntity(entity: Entity): Promise<Relationship[]> {
    const concept = await this.getConceptForEntity(entity);
    return concept?.relationships || [];
  }

  async getWorkspaceRelationshipRecords(
    entity: Entity,
    rel: string | Relationship,
  ): Promise<WorkspaceRelationship[]> {
    const relationship = await this.asRelationship(rel, entity);
    if (!relationship) {
      console.warn('relationship not found', rel);
      return [];
    }
    return this.relationshipsCache.getLocalWorkspaceRelationships({
      entity,
      relationship,
    });
  }

  async clearRelationshipCache(
    entity: Entity,
    rel: Relationship | string,
  ): Promise<void> {
    const relationship = await this.asRelationship(rel, entity);
    if (!relationship) {
      console.warn('relationship not found', rel);
      return;
    }
    await this.relationshipsCache.clear({ entity, relationship });
  }

  async getConnectedEntities<T extends Entity>(
    entity: Entity,
    rel: Relationship | string,
    forceRefresh?: boolean,
  ): Promise<T[]> {
    const relationship = await this.asRelationship<T>(rel, entity);
    if (!relationship) {
      console.warn('relationship not found', rel);
      return [];
    }
    if (forceRefresh) {
      await this.relationshipCountCache.clear({ entity, relationship });
      await this.relationshipsCache.clear({ entity, relationship });
      this.shadowGraph.dropRelationships(
        entity,
        relationship.relationship_name,
      );
    }
    const { count, expired } = await this.getRelationshipCount(
      entity,
      relationship,
      forceRefresh,
    );
    if (!count && !expired) {
      return [];
    }
    if (expired) {
      const { count } = await this.getRelationshipCount(
        entity,
        relationship,
        true,
      );
      if (!count) {
        return [];
      }
    }
    let entities: Entity[] | undefined;
    if (!forceRefresh) {
      entities = this.shadowGraph.getConnectedEntities(
        entity,
        relationship.relationship_name,
      );
    }
    if (!entities) {
      entities = await this.relationshipsCache
        .getConnected({
          entity,
          relationship,
        })
        .then(connectedEntities => {
          this.shadowGraph.addConnectedEntities(
            entity,
            relationship.relationship_name,
            connectedEntities,
          );
          return connectedEntities;
        })
        .catch(e => {
          console.log('error fetching connected entities', e);
          throw e;
        });
    }

    return Promise.all(entities.map(e => this.getEntity<T>(e)));
  }

  private async asRelationship<T extends Entity>(
    rel: string | Relationship,
    entity: Entity,
  ) {
    return typeof rel === 'string'
      ? (await this.getRelationshipsForEntity(entity)).find(
          relationship => relationship.relationship_name === rel,
        )
      : rel;
  }

  private async relationshipsBetweenEntities(
    e1: Entity,
    e2: Entity,
  ): Promise<EConnection[]> {
    const e1Concept = await this.getConceptForEntity(e1);
    const e2Concept = await this.getConceptForEntity(e2);
    const e1conceptChain = [
      ...(e1Concept?.parents || []),
      ...[e1Concept?.concept_name],
    ];
    const e2conceptChain = [
      ...(e2Concept?.parents || []),
      ...[e2Concept?.concept_name],
    ];

    const eConns1to2 = (e1Concept?.relationships || [])
      .filter(
        r =>
          e1conceptChain.includes(r.concept) &&
          e2conceptChain.includes(r.target_concept),
      )
      .map(r => ({ entity1: e1, entity2: e2, relationship: r }));

    const eConns2to1 = (e2Concept?.relationships || [])
      .filter(
        r =>
          e2conceptChain.includes(r.concept) &&
          e1conceptChain.includes(r.target_concept),
      )
      .map(r => ({ entity1: e2, entity2: e1, relationship: r }));

    const econnections = _.uniqBy(
      [...eConns1to2, ...eConns2to1],
      e =>
        `${e.entity1.entity_type}|${e.entity2.entity_type}|${e.relationship.relationship_name}`,
    );

    const counted = await Promise.all(
      econnections.map(async e => {
        try {
          const { count } = await this.getRelationshipCount(
            e.entity1,
            e.relationship,
          );
          return count ? e : undefined;
        } catch (err) {
          console.log(err);
          return undefined;
        }
      }),
    );
    return counted.filter(e => e) as EConnection[];
  }

  private async discoverEdgesSmart(
    newEntities: Entity[],
    oldEntities: Entity[],
  ): Promise<EntityEdge[]> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias

    const allEntities = _.uniqBy(
      [...newEntities, ...oldEntities],
      e => e.entity_id,
    );
    if (allEntities.length <= 1) {
      return [];
    }

    const responses: Promise<RelationshipQueryResult[]>[] = [];

    for (const n of newEntities) {
      // eslint-disable-next-line no-await-in-loop
      const econnsN: EConnection[][] = await Promise.all(
        allEntities.flatMap(async e => this.relationshipsBetweenEntities(n, e)),
      );
      const econnsFlat: EConnection[] = _.flatten(econnsN);
      const econnsByRel: { [name: string]: EConnection[] } = _.groupBy(
        econnsFlat,
        e => e.relationship.relationship_name,
      );
      const queries: string[] = [];
      for (const [relName, econns] of Object.entries(econnsByRel)) {
        const e1s = _.uniqBy(
          econns.map(e => e.entity1),
          e => e.entity_id,
        );
        const e2s = _.uniqBy(
          econns.map(e => e.entity2),
          e => e.entity_id,
        );
        const rel = econns[0].relationship;
        const e1ids = e1s.map(e => SqlString.escape(e.entity_id)).join(',');
        const e2ids = e2s.map(e => SqlString.escape(e.entity_id)).join(',');
        const query = rel.is_inverse
          ? `SELECT /*+ dtimbr_join_type(inner) */ DISTINCT 
        t1.entity_id  AS entity_id_from,
        '${rel.inverse_name}' AS rel_name,
        "${rel.inverse_name}[${rel.concept}].entity_id" AS entity_id_to
        FROM dtimbr."${rel.target_concept}" AS t1
        WHERE "${rel.inverse_name}[${rel.concept}].entity_id" IN (${e1ids})
        AND t1.entity_id IN (${e2ids})`
          : `SELECT /*+ dtimbr_join_type(inner) */ DISTINCT
t1.entity_id AS entity_id_from,
'${relName}' AS rel_name,
"${relName}[${rel.target_concept}].entity_id" AS entity_id_to
FROM dtimbr."${e1s[0].entity_type}" AS t1
WHERE t1.entity_id IN (${e1ids})
AND "${relName}[${rel.target_concept}].entity_id"
IN (${e2ids})`
              .replaceAll('\n', ' ')
              .trim();
        queries.push(query);
      }
      queries
        .map(q =>
          this.sendQueryT<RelationshipQueryResult>(q).catch(e =>
            // should be elsewhere in the logs.
            [],
          ),
        )
        .forEach(respPromise => {
          responses.push(respPromise);
        });
    }
    const allEdges = await Promise.all(responses);

    const bigDataEdges = await this.toEntityEdges(allEdges.flat(), allEntities);
    const localEdges = await this.getLocalEntityEdges(allEntities);
    return [...bigDataEdges, ...localEdges];
  }

  async getLocalEntityEdges(entities: Entity[]): Promise<EntityEdge[]> {
    const results = await this.sendQueryT<WorkspaceRelationship>(
      this.getLocalRelationshipsQuery(entities),
    );
    const rels: RelationshipQueryResult[] = results.map(e => ({
      entity_id_from: e.os_entity_uid_from,
      rel_name: e.os_relationship_name,
      entity_id_to: e.os_entity_uid_to,
    }));
    return this.toEntityEdges(rels, entities);
  }

  private async toEntityEdges(
    allEdges: RelationshipQueryResult[],
    entities: Entity[],
  ): Promise<EntityEdge[]> {
    const map = entities.reduce((acc, e) => {
      acc[e.entity_id] = e;
      return acc;
    }, {});

    return Promise.all(
      allEdges.flat().map(async r => {
        const entityFrom = map[r.entity_id_from];
        const entityTo = map[r.entity_id_to];
        const rels = await this.getRelationshipsForEntity(entityFrom);

        const relationship = rels.find(
          x => x.relationship_name === r.rel_name,
        ) as Relationship;
        return {
          from: entityFrom,
          to: entityTo,
          relationship,
        } as EntityEdge;
      }),
    );
  }

  private getLocalRelationshipsQuery(entities: Entity[]): string {
    return `SELECT
      *
      FROM timbr.os_workspace_relationship
      WHERE (${entities
        .map(
          e =>
            `os_entity_type_from='${e.entity_type}' and os_entity_uid_from='${e.entity_id}'`,
        )
        .join(' OR ')})
      and (${entities
        .map(
          e =>
            `os_entity_type_to='${e.entity_type}' and os_entity_uid_to='${e.entity_id}'`,
        )
        .join(' OR ')})`;
  }

  async discoverEdgesOnAddingNodes(
    newEntities: Entity[],
    oldEntities: Entity[],
  ): Promise<EntityEdge[]> {
    /*
     * Obtain the minimal set of relationships that could be used to connect new->new, new->old nodes.
     * Iterate through the nodes and weed out any relationship that has counter=0.
     * Now you have the minimal set of relationships that could be expanded (with limit to present node ids)
     *
     * */
    const smart = await this.discoverEdgesSmart(newEntities, oldEntities);
    // console.log('smart edges: ', smart);
    return smart;
  }

  async consistentUUID(name: string, namespace?: string): Promise<string> {
    return uuidv5(name, namespace || 'octostar');
  }
}
