import React from 'react';
import { TabNode } from 'flexlayout-react';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks/useCurrentWorkspaceItem';
import { WorkspaceItem } from '@octostar/platform-types';
import { useAttachment } from 'src/octostar/hooks/useAttachment';
import { useTabNode } from 'src/octostar/hooks/useTabNode';
import { Spin } from 'antd5';
import ee, { clientCore } from 'src/octostar/interface';
import { isEqual } from 'lodash';
import useHandleErrorEffect from 'src/octostar/hooks/useHandleErrorEffect';
import { MERMAID_EXTENSIONS } from 'src/octostar/lib/workspaceOperations';
import Loading from '../Loading';
import { Mermaid } from './Mermaid';
import { BUILTIN_APPS } from '../layout/builtins';
import { MermaidEditor } from './MermaidEditor';

export const MermaidViewer = ({
  workspaceItem,
  inputText,
  node,
}: {
  workspaceItem?: WorkspaceItem;
  inputText?: string;
  node: TabNode;
}) => {
  const [chart, setChart] = React.useState('');
  const { loading, text, error, setItem, state } = useAttachment(
    workspaceItem,
    'text',
    '',
  );
  const { width, height } = useTabNode({ node });

  useHandleErrorEffect(
    workspaceItem,
    state,
    loading,
    error,
    BUILTIN_APPS.mermaidViewer,
  );

  React.useEffect(() => {
    if (typeof inputText === 'string') {
      setChart(inputText);
      return;
    }
    setChart(text || '');
  }, [inputText, text]);
  React.useEffect(() => {
    if (workspaceItem) {
      setItem(curr => (isEqual(curr, workspaceItem) ? curr : workspaceItem));
    }
  }, [setItem, workspaceItem]);

  if (loading) {
    <div className="content-container">
      <Spin />
    </div>;
  }
  if (error) {
    <div className="content-container">{error}</div>;
  }

  if (!chart && !loading) {
    return <MermaidEditor node={node} item={workspaceItem as WorkspaceItem} />;
  }

  return <Mermaid width={width} height={height} chart={chart} />;
};

export const MermaidViewerLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return <MermaidViewer workspaceItem={workspaceItem} node={node} />;
};
export const registerMermaidViewer = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.mermaidViewer.os_entity_uid,
    factory: (node: TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <MermaidViewerLoader item={item} node={node} />;
    },
  });
};

export const isMermaidFile = (fileType: string) =>
  MERMAID_EXTENSIONS.includes(fileType);
