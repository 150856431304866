import {
  Entity,
  OsNotification,
  ShowTabProps,
  Callback,
  Desktop,
  Unsubscribe,
  Workspace,
  WorkspaceIdentifier,
  WorkspaceItem,
  WorkspaceItemIdentifier,
  WorkspaceItemModel,
  OsConfirmProps,
  SaveOptions,
  WorkspaceRecordIdentifier,
  WorkspaceRecordWithRelationships,
  OsProgress,
  OsWorkspaceEntity,
  CreateEntityFormProps,
  CustomTemplate,
  AppServiceCallProps,
  SavedTemplate,
  SaveFileOptions,
  DesktopOpenParams,
  ModalTemplateProps,
  AttachmentType,
  GetAttachmentOptions,
  WithProgressBarOptions,
  StylerOption,
  Styler,
  DesktopStylerContext,
  Relationship,
  DesktopActionOptions,
  ContextMenuRequest,
  AddCommentParams,
  ExportOptions,
  ImportZipOptions,
  UserProfile,
  PasteContextOptions,
  OsTag,
  TagAttributes,
  TagWithRelationship,
  FileTreeOptions,
  WorkspacePermission,
  OS_CONFIRM_MODAL_RESPONSE,
  Whoami,
  WorkspacePermissionValue,
  SearchXperienceProps,
  CreateRelationsDialogProps,
  Concept,
  TemplateLayout,
  CopyOptions,
  SaveAsModalProps,
} from '@octostar/platform-types';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import ee, {
  FEATURE_RELATIONSHIP,
  IS_COMMENT_ABOUT_REL_NAME,
  TAGGED_RELATIONSHIP,
  VIRTUAL_ITEM_TYPE,
  apiCall,
  clientCore,
  getNotificationKey,
} from 'src/octostar/interface';
import { newWorkspaceItem, DeferredPromise } from 'src/octostar/lib/handy';
import { getTemplateForItem } from 'src/octostar/components/templates/custom/utils';
import { newCustomTemplate } from 'src/octostar/components/templates/custom/nodeps';
import { BUILTIN_APPS } from 'src/octostar/components/layout/builtins';
import shortid from 'shortid';
import _, { isEqual } from 'lodash';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { getStylerConfigs } from 'src/octostar/api/event-driven/desktop';
import { getFileAttachment } from 'src/octostar/hooks/useAttachment';
import {
  Watcher,
  WatchIntent,
} from 'src/octostar/components/Apps/watchers/types';
import {
  WATCH_INTENT_ENTITY_NAME,
  WATCH_INTENT_RELNAME_TOWARDS_OS_WORKSPACE_ITEM,
} from 'src/octostar/components/Apps/watchers/WatchersIO';
import { octostarReduxStore, setReadyForAppLayout } from 'src/octostar/store';
import { getFileType } from 'src/octostar/components/FileViewer/utils';
import { getMediaType } from 'src/octostar/components/MediaViewer';
import APIClient from '../APIClient';
import {
  isWorkspaceItem,
  toWorkspaceItemUUID,
  toWorkspaceUUID,
} from '../workspaceItemUtils';
import { EVENTS, MemoryStorageAdapter } from './MemoryStorageAdapter';
import { StorageItem, WorkspaceStorage } from './types';
import { SavedSettings } from './SavedSettings';
import { dbconsole } from '../dbconsole';
import { sortTags } from '../tags';
import { notifyEntityUpdated } from '../notifyEntityUpdated';
import { getPasteContext } from './PasteContext';
import { FocusList } from '../FocusList';
import { getIconCode } from '../getIconCode';
import { canEntityBeSaved, checkMissingFields } from '../EntityUtils';

const DEFAULT_GET_ATTACHMENT_OPTIONS = Object.freeze({
  default: '' as any,
  responseType: 'text',
  path: '',
});

// VSF01: Temporary measure to prevent unintended (re)loading.
let previouslyLoadedWorkspaceIds: string[];
export class DesktopAPI implements Desktop {
  settings: SavedSettings;

  storage: MemoryStorageAdapter;

  userProfile: UserProfile;

  focusList: FocusList;

  // the no-arg constructor is used only for creating a proxy,
  // not for operational use.
  constructor(storage?: WorkspaceStorage, userProfile?: UserProfile) {
    this.userProfile = userProfile || {
      email: 'user@example.com',
      firstName: 'User',
      lastName: 'Profile',
      username: 'userprofile',
    };
    this.settings = new SavedSettings(ee); // uses global ee
    if (storage) {
      this.storage = new MemoryStorageAdapter(storage, ee);
      // VSF01: Fix this init logic - START
      this.settings.onOpenWorkspaceIdsChanged(ids =>
        this.storage.setOpenWorkspaceIds(ids),
      );
      this.settings.getActiveWorkspaceId().then(active => {
        this.storage.setActiveWorkspaceId(active);
      });
      setTimeout(() => {
        this.settings.getOpenWorkspaceIds().then(ids => {
          if (
            previouslyLoadedWorkspaceIds &&
            isEqual(previouslyLoadedWorkspaceIds, ids)
          ) {
            // VSFQuery: I see 3 instances of DesktopAPI all executing this code, loading same workspaces.
            return;
          }
          previouslyLoadedWorkspaceIds = [...ids];
          this.storage.setOpenWorkspaceIds(ids);

          // VSF27: Tempoary code to load in different phases without redux
          Promise.all([
            this.storage.loadWorkspaceItems(ids),
            ...ids.map(id => this.storage.load(id)),
          ]).finally(() => {
            this.refresh();
            octostarReduxStore.dispatch(setReadyForAppLayout());
          });
        });
      }, 0);
      // VSF01: Fix this init logic - End
    }
    this.focusList = new FocusList();
  }

  async internalGetIconCode(
    x: string | Entity | Concept,
    defaultIcon?: string,
  ): Promise<string> {
    return getIconCode(x, defaultIcon);
  }

  async getTemplates(layout?: TemplateLayout): Promise<SavedTemplate[]> {
    const promises = this.storage
      .filterItems(
        item =>
          item.os_item_content_type === 'os_template' &&
          item.os_item_content &&
          (item.os_item_content.layout || 'default') === (layout || 'default'),
      )
      .map(item =>
        getTemplateForItem(
          item,
          `<div>missing template ${item.os_item_name}</div>`,
        ),
      );
    return Promise.all(promises);
  }

  async getStyler(
    name: string,
    context: DesktopStylerContext,
  ): Promise<Styler> {
    const styler = getStylerConfigs().find(x => x.name === name);
    if (!styler) {
      dbconsole.warn(`requested styler ${name} not found`);
    }
    return (
      styler?.init({
        ...{ selected: { nodes: [], edges: [] } },
        ...context,
        ...{ DesktopAPI: this, OntologyAPI },
        ee,
        eventTopicPrefix: '',
      }) || {
        getG6NodeStyle: async () => undefined,
        getG6EdgeStyle: async () => undefined,
      }
    );
  }

  async getStylerOptions(): Promise<StylerOption[]> {
    return getStylerConfigs().map(({ name, description }) => ({
      name,
      description,
    }));
  }

  async getTemplate(
    name: string,
    defaultTemplate?: string,
  ): Promise<CustomTemplate> {
    const t = await this.getTemplates().then(x => x.find(t => t.name === name));
    if (t) {
      return t;
    }

    return newCustomTemplate(name, defaultTemplate);
  }

  async getSchemaItems(os_item_content_type: string): Promise<WorkspaceItem[]> {
    const promises = this.storage.filterItems(
      item => item.os_item_content_type === os_item_content_type,
    );

    return Promise.all(promises);
  }

  // only for json exported rows for the moment
  async import(items: any[]): Promise<void> {
    return this.storage.import(items);
  }

  async importZip(file: File, options?: ImportZipOptions): Promise<void> {
    return this.storage.importZip(file, options).then(entities => {
      const ws = entities.find(
        x => x.entity_type === 'os_workspace',
      )?.os_entity_uid;
      if (ws) {
        this.openWorkspace(ws);
      }
      return undefined;
    });
  }

  async openWorkspace(ws: WorkspaceIdentifier): Promise<void> {
    const uuid = toWorkspaceUUID(ws);
    if (!uuid) {
      return;
    }
    const ids = this.storage.getOpenWorkspaceIds();
    if (ids.includes(uuid)) {
      return;
    }
    await this.setOpenWorkspaceIds([uuid, ...ids]);
    let ok = false;
    try {
      await this.storage.load(uuid);
      ok = true;
      this.getWorkspace(uuid).then(ws => {
        if (ws) {
          this.showTab({
            app: BUILTIN_APPS.workspaceTemplate,
            item: ws.workspace,
          });
        }
      });
    } finally {
      if (!ok) {
        this.setOpenWorkspaceIds(ids);
      }
    }
  }

  async getActiveWorkspace(prompt?: boolean): Promise<string | undefined> {
    const ws = await this.settings.getActiveWorkspaceId();
    if (!ws && prompt) {
      return apiCall<string>(BUILTINS_MESSAGE_TYPES.promptForWorkspace);
    }
    return ws;
  }

  async setActiveWorkspace(workspace: string | undefined): Promise<void> {
    this.settings.setActiveWorkspaceId(workspace);
    this.storage.setActiveWorkspaceId(workspace);
  }

  async closeWorkspace(ws: WorkspaceIdentifier): Promise<void> {
    const uuid = toWorkspaceUUID(ws);
    const ids = await this.settings.getOpenWorkspaceIds();
    const ids2 = ids.filter(id => id !== uuid);
    if (!_.isEqual(ids, ids2)) {
      await this.setOpenWorkspaceIds(ids2);
      const activeWorkspace = await this.getActiveWorkspace();
      if (activeWorkspace && ids2.includes(activeWorkspace)) {
        await this.setActiveWorkspace(undefined);
      }
    }
  }

  async listAllWorkspaces(
    permissions: WorkspacePermissionValue,
  ): Promise<WorkspaceItem[]> {
    const workspaces = await this.storage.list();
    if (!permissions) {
      return workspaces;
    }
    const workspacesPermissions = await this.getWorkspacePermission(
      workspaces.map(x => x.entity_id),
    );
    return workspaces.filter(
      w => workspacesPermissions[w.entity_id].value >= permissions,
    );
  }

  async getOpenWorkspaces(): Promise<Workspace[]> {
    return this.storage.getOpenWorkspaces();
  }

  async getItems(wi: WorkspaceItemIdentifier[]): Promise<WorkspaceItem[]> {
    return this.storage.getItems(wi.map(toWorkspaceItemUUID));
  }

  async removeTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    return this.storage.removeTag(os_workspace, tag, entity).then(async () => {
      await this.onTagsChanged(entity);
    });
  }

  private async onTagsChanged(entity: Entity | Entity[]) {
    const entities = Array.isArray(entity) ? entity : [entity];
    await Promise.all(
      entities.map(e =>
        OntologyAPI.getEntity(e).then(updated => {
          const newUpdated = { ...updated } as WorkspaceItem;
          (newUpdated as WorkspaceItem).os_last_updated_at =
            new Date().toISOString();
          // force refresh
          notifyEntityUpdated(newUpdated);
        }),
      ),
    );
  }

  async applyTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    await this.storage.applyTag(os_workspace, tag, entity);
    return this.onTagsChanged(entity);
  }

  async updateTag(tag: OsTag): Promise<OsTag> {
    const updated = await this.storage.updateTag(tag);
    const rel = (await OntologyAPI.getRelationshipsForEntity(tag)).find(
      x => x.inverse_name === TAGGED_RELATIONSHIP,
    );
    if (!rel) {
      throw new Error(`Relationship ${TAGGED_RELATIONSHIP} not found`);
    }
    setTimeout(async () => {
      const connected = await OntologyAPI.getConnectedEntities(
        updated,
        rel,
        true,
      );
      notifyEntityUpdated(updated);
      await Promise.all(
        connected.map(entity =>
          OntologyAPI.getConnectedEntities(entity, rel.inverse_name, true),
        ),
      );
      connected.forEach(entity => {
        notifyEntityUpdated(entity);
      });
    }, 0);
    return updated;
  }

  async getTags(entity: Entity): Promise<TagWithRelationship[]> {
    const item =
      entity.entity_type === VIRTUAL_ITEM_TYPE
        ? (entity as WorkspaceItem).os_item_content.entity
        : entity;

    // TODO: move this to the server
    const tags = await OntologyAPI.getConnectedEntities<OsTag>(
      item,
      TAGGED_RELATIONSHIP,
    );
    if (tags.length === 0) {
      return [];
    }

    const workspaces = await this.getOpenWorkspaceIds();

    const rels = (
      await OntologyAPI.getWorkspaceRelationshipRecords(
        entity,
        TAGGED_RELATIONSHIP,
      )
    ).filter(x => workspaces.includes(x.os_workspace));

    // Map tags by entity_id
    const tagMap = tags.reduce((acc, tag) => {
      acc[tag.entity_id] = tag;
      return acc;
    }, {} as Record<string, OsTag>);

    // TODO: sort rels
    const answer = rels
      .map(rel => ({
        os_workspace_relationship: rel,
        os_tag: tagMap[rel.os_entity_uid_to],
      }))
      .filter(x => x.os_tag !== undefined);
    return answer;
  }

  async getAvailableTags(
    entity: Entity,
    os_workspace?: string,
  ): Promise<OsTag[]> {
    const workspaces = this.storage.getOpenWorkspaces();
    const tags: OsTag[] = [];
    if (os_workspace) {
      workspaces
        .filter(
          ws =>
            [(entity as WorkspaceItem).os_workspace, os_workspace].includes(
              ws.workspace.entity_id,
            ) || ws.workspace.os_item_content?.isMaster,
        )
        .forEach(ws => {
          ws.tags?.forEach(tag => {
            if (
              !tags.map(tag => tag.entity_id).includes(tag.entity.entity_id)
            ) {
              tags.push(tag.entity);
            }
          });
        });
    } else {
      workspaces.forEach(ws => {
        ws.tags?.forEach(tag => {
          if (!tags.map(tag => tag.entity_id).includes(tag.entity.entity_id)) {
            tags.push(tag.entity);
          }
        });
      });
    }
    return sortTags(_.uniq(tags));
  }

  async onWorkspaceChanged(
    ws: WorkspaceIdentifier,
    callback: Callback<Workspace>,
  ): Promise<Unsubscribe> {
    const uuid = toWorkspaceUUID(ws);
    return this.onEvent(`${EVENTS.WORKSPACE_CHANGED}:${uuid}`, callback);
  }

  async onWorkspaceItemChanged(
    item: WorkspaceItemIdentifier,
    callback: Callback<WorkspaceItem>,
  ): Promise<Unsubscribe> {
    const uuid = toWorkspaceItemUUID(item);
    return this.onEvent(`${EVENTS.WORKSPACE_ITEM_CHANGED}:${uuid}`, callback);
  }

  async onOpenWorkspacesChanged(
    callback: Callback<Workspace[]>,
  ): Promise<Unsubscribe> {
    if (this.storage) {
      // VS: Why this timeout??
      setTimeout(() => callback(this.storage.getOpenWorkspaces()), 0);
    }
    return this.onEvent(EVENTS.OPEN_WORKSPACES_CHANGED, callback);
  }

  /**
   * Adds an event listener for a given topic and callback function.
   * Returns an async function that can be used to unsubscribe the event listener.
   * @param topic - The topic or event name to listen for.
   * @param callback - The callback function to be called when the event is emitted.
   * @returns - An async function to unsubscribe the event listener.
   */
  async onEvent(
    topic: string,
    callback: (...args: any[]) => void,
  ): Promise<Unsubscribe> {
    ee.on(topic, callback);
    return async () => {
      ee.off(topic, callback);
    };
  }

  async getWorkspace(ws: WorkspaceIdentifier): Promise<Workspace | undefined> {
    const wsuuid = toWorkspaceUUID(ws);
    if (!wsuuid) {
      return undefined;
    }
    const workspace = await this.storage.load(wsuuid);
    if (!workspace) {
      this.closeWorkspace(ws);
      return undefined;
    }
    return workspace;
  }

  async getItem<T>(wi: WorkspaceItemIdentifier): Promise<WorkspaceItem<T>> {
    if (isWorkspaceItem(wi)) {
      return wi;
    }
    return this.storage.getItem(toWorkspaceItemUUID(wi));
  }

  async createWorkspace(name: string): Promise<WorkspaceItem> {
    const ws = await this.storage.createWorkspace(name);
    if (!ws) {
      throw new Error(`failed to create or load workspace ${name}`);
    }
    await this.openWorkspace(ws.workspace.os_entity_uid);
    return ws.workspace;
  }

  async addWatchIntent(entity: Entity, watcher: Watcher) {
    const getMyJWT = async () => {
      const res = await fetch('/api/octostar/meta/whoami');
      const data = await res.json();
      return data?.os_jwt as string;
    };
    function cleanJWT(jwt: string) {
      return `${jwt.split('.').slice(0, 2).join('.')}.`;
    }
    const jwt = await getMyJWT().then(cleanJWT);
    if (!jwt) {
      throw new Error(`Cannot add watch intent without a jwt`);
    }
    const ws = (entity as OsWorkspaceEntity).os_workspace;
    const id = crypto.randomUUID(); // await OntologyAPI.consistentUUID(entity.entity_id + watcher.app_id + watcher.watcher_name);
    const name = `${watcher.app_name} - ${watcher.name} - ${entity.entity_label}`;
    const args = btoa(JSON.stringify(entity));
    const intent: WatchIntent & WorkspaceRecordIdentifier = {
      entity_id: id,
      os_entity_uid: id,
      os_item_type: WATCH_INTENT_ENTITY_NAME,
      os_item_name: name,
      entity_type: WATCH_INTENT_ENTITY_NAME,
      entity_label: name,
      app_id: watcher.app_id,
      app_name: watcher.app_name,
      watcher_name: watcher.name,
      os_workspace: ws,
      // os_item_name: `${watcher.app_name} - ${watcher.name} - ${entity.entity_label}`,
      interval: watcher.interval,
      arguments: args,
      last_run: '',
      jwt,
    };
    return this.save(intent)
      .then(result => {
        this.connect(
          WATCH_INTENT_RELNAME_TOWARDS_OS_WORKSPACE_ITEM,
          entity,
          intent,
        );
        return result;
      })
      .catch(e => {
        console.error(`Failed to add watch intent`, e);
        throw e;
      });
  }

  async removeWatchIntent(os_workspace: string, intent_id: string) {
    const wsr: WorkspaceRecordIdentifier = {
      entity_type: WATCH_INTENT_ENTITY_NAME,
      os_entity_uid: intent_id,
      os_workspace,
    };
    return this.delete(wsr);
  }

  async addComment(about: Entity, comment: AddCommentParams) {
    const id = crypto.randomUUID();
    const commentEntity: WorkspaceRecordIdentifier & Entity = {
      entity_label: '',
      os_entity_uid: id,
      entity_id: id,
      entity_type: 'comment',
      slug: comment.slug || `${(comment.contents || '').substring(0, 20)}...`,
      ...comment,
    };
    return this.save(commentEntity).then(result => {
      this.connect(IS_COMMENT_ABOUT_REL_NAME, commentEntity, about);
      return result;
    });
  }

  async removeComment(os_workspace: string, comment_id: string) {
    const wsr: WorkspaceRecordIdentifier = {
      entity_type: 'comment',
      os_entity_uid: comment_id,
      os_workspace,
    };
    return this.delete(wsr);
  }

  async connect(
    rel: Relationship | string,
    from: Entity,
    to: Entity,
  ): Promise<WorkspaceItem> {
    let relationship =
      typeof rel === 'string'
        ? (await OntologyAPI.getRelationshipsForEntity(from)).find(
            r => r.relationship_name === rel,
          )
        : rel;
    if (relationship === undefined) {
      throw new Error(`Relationship ${rel} not found`);
    }

    if (relationship.is_inverse) {
      // create with the forward relationship, if we can.
      const inverse = (await OntologyAPI.getRelationshipsForEntity(to)).find(
        r => r.relationship_name === relationship!.inverse_name,
      );
      if (inverse) {
        const tmp = to;
        // eslint-disable-next-line no-param-reassign
        to = from;
        // eslint-disable-next-line no-param-reassign
        from = tmp;
        relationship = inverse;
      }
    }
    const os_workspace = await this.getActiveWorkspace(true);

    const related = newWorkspaceItem({
      os_workspace,
      entity_type: 'os_workspace_relationship',
      os_entity_uid_from: from.entity_id,
      os_entity_type_from: from.entity_type,
      os_entity_uid_to: to.entity_id,
      os_entity_type_to: to.entity_type,
      os_relationship_name: relationship.relationship_name,
    } as any);
    return this.save(related).then(result => {
      setTimeout(() => {
        OntologyAPI.getConnectedEntities(from, relationship!, true);
        OntologyAPI.getConnectedEntities(to, relationship!.inverse_name, true);
        OntologyAPI.getEntity(from).then(x => {
          // eslint-disable-next-line no-param-reassign
          (x as any)['#updated'] = Date.now();
          notifyEntityUpdated(x);
        });
        OntologyAPI.getEntity(to).then(x => {
          // eslint-disable-next-line no-param-reassign
          (x as any)['#updated'] = Date.now();
          notifyEntityUpdated(x);
        });
      }, 0);
      return result;
    });
  }

  async copy(
    source: WorkspaceItem,
    target: WorkspaceItem,
    options?: CopyOptions,
  ): Promise<WorkspaceItem[]> {
    return this.storage.copy(source, target, options);
  }

  async save(
    item:
      | (Partial<WorkspaceItem> & WorkspaceItemModel)
      | (WorkspaceRecordIdentifier & Entity)
      | WorkspaceRecordWithRelationships,
    options?: SaveOptions,
  ): Promise<WorkspaceItem> {
    let entity =
      (item as WorkspaceRecordWithRelationships)?.entity ||
      (item as OsWorkspaceEntity);
    if ((entity as WorkspaceItem).os_item_type && !entity.entity_type) {
      entity = newWorkspaceItem(entity as any);
    }
    // Check if entity can be saved
    const canBeSaved = await canEntityBeSaved(entity);
    if (!canBeSaved.value) {
      // eslint-disable-next-line no-console
      console.log('Error saving entity: ', entity, canBeSaved.reason);
      this.showToast({
        message: `${
          entity.entity_label || entity.os_item_name
        } cannot be saved`,
        level: 'error',
        description: canBeSaved.reason,
      });
      return Promise.reject();
    }
    const newItem = await checkMissingFields(entity, options);
    if (!newItem) {
      return Promise.reject();
    }
    if ((item as WorkspaceRecordWithRelationships)?.entity) {
      return this.storage.save(newItem as StorageItem);
    }
    if (options?.path) {
      const wi = newItem as WorkspaceItem;
      const parts = options.path.split('/').filter(x => x);
      const ws = await this.storage.getWorkspace(wi.os_workspace);
      if (!ws) {
        throw new Error(
          `Cannot save item with path when the item does not belong to a workspace`,
        );
      }
      let parent = ws.workspace;
      const tosave: WorkspaceItem[] = [];
      parts.forEach((part, i) => {
        const folder = ws.items.find(
          x =>
            x.os_item_name === part &&
            x.os_parent_folder === parent.os_entity_uid,
        );
        if (folder) {
          if (folder.os_item_type !== 'os_folder') {
            throw new Error(
              `Path ${parts.slice(0, i).join('/')} already exists as a ${
                folder.os_item_type
              }`,
            );
          }
          parent = folder;
        } else {
          parent = newWorkspaceItem({
            os_item_type: 'os_folder',
            os_item_name: part,
            os_parent_folder: parent.os_entity_uid,
            os_workspace: wi.os_workspace,
          });
          tosave.push(parent);
        }
      });
      wi.os_parent_folder = parent.os_entity_uid;
      // TODO fix storage to allow an array of items to be saved (it is supported on the server)
      while (tosave.length) {
        // eslint-disable-next-line no-await-in-loop
        await this.storage.save(tosave.pop() as WorkspaceItem);
      }
    }
    if ((newItem as WorkspaceItem).os_parent_folder) {
      ee.emit(
        clientCore('expandFolder'),
        (newItem as WorkspaceItem).os_parent_folder,
      );
    }
    return this.storage.save(newItem as WorkspaceItem, options);
  }

  async saveFile(
    item: WorkspaceItem,
    file: File | string,
    options?: SaveFileOptions,
  ): Promise<WorkspaceItem> {
    this.storage.lock();
    try {
      if (!item.os_last_updated_at) {
        // eslint-disable-next-line no-param-reassign
        item.os_has_attachment = true;
      }
      const saved = await this.save(item, options);
      if (!saved['#uploadSecret']?.url) {
        throw new Error(`No #uploadSecret url in ${saved.os_item_name}`);
      }
      const secret = saved['#uploadSecret'];
      const { fields, url } = secret;
      delete saved['#uploadSecret'];
      const formData = new FormData();
      Object.entries(fields || {}).forEach(([k, v]) => {
        formData.append(k, v as string);
      });
      const dafile = (file as string).startsWith
        ? new File([new Blob([file as string])], item.os_item_name)
        : file;
      formData.append('file', dafile);
      const uploadResponse = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (uploadResponse.ok) {
        const {
          os_item_name,
          os_item_type,
          os_workspace,
          os_entity_uid,
          os_has_attachment,
          os_parent_folder,
        } = saved;
        const min = {
          os_item_name,
          os_item_type,
          os_workspace,
          os_parent_folder,
          os_entity_uid,
        };
        (min as any).os_has_attachment = os_has_attachment;
        const saved2 = await this.save(min);
        delete saved2['#uploadSecret'];
        return saved2;
      }
      throw new Error(`upload file failed: ${uploadResponse.status}`);
    } finally {
      this.storage.unlock();
    }
  }

  async export(
    item: WorkspaceItem | WorkspaceItem[],
    options?: ExportOptions,
  ): Promise<void> {
    const items = Array.isArray(item) ? item : [item];
    const exportOptions = {
      filename: `${
        items.length === 1 ? items[0].os_item_name : 'workspaces'
      }-${new Date().toISOString()}`,
      ...options,
    };

    const { filename } = exportOptions;
    return new Promise((resolve, reject) =>
      new APIClient()
        .fetch(`/api/octostar/workspace_data_api/export`, {
          method: 'POST',
          body: JSON.stringify({
            source: items.map(x => x.os_entity_uid).join(','),
            ...exportOptions,
          }),
        })
        .then(response => {
          if (response.ok) {
            return response;
          }
          throw new Error(`http status: ${response.status}`);
        })
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${filename}.zip`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
          resolve();
        })
        .catch(e =>
          reject(new Error(`Error exporting workspace item: ${e.message}`, e)),
        ),
    );
  }

  async delete(
    items: WorkspaceRecordIdentifier | WorkspaceRecordIdentifier[],
    recurse?: boolean,
  ): Promise<void> {
    return this.storage.delete(items, recurse);
  }

  async delete_workspace_records(workspace_id: string): Promise<void> {
    return this.storage.delete_workspace_records(workspace_id);
  }

  async delete_local_concept_records(
    workspace_id: string,
    base_concept: string,
  ): Promise<void> {
    return this.storage.delete_local_concept_records(
      workspace_id,
      base_concept,
    );
  }

  async getOpenWorkspaceIds(): Promise<string[]> {
    return this.settings.getOpenWorkspaceIds();
  }

  async setOpenWorkspaceIds(ids: string[]): Promise<void> {
    console.log(`xxxx setOpenWorkspaceIds(${ids})`);
    this.storage.setOpenWorkspaceIds(ids);
    return this.settings.setOpenWorkspaceIds(ids);
  }

  async onOpenWorkspaceIdsChanged(
    listener: (workspaceIds: string[]) => void,
  ): Promise<() => Promise<void>> {
    return this.settings.onOpenWorkspaceIdsChanged(listener);
  }

  async searchXperience(props?: SearchXperienceProps) {
    return apiCall<Entity[]>(BUILTINS_MESSAGE_TYPES.searchXperience, props);
  }

  async showContextMenu(props: ContextMenuRequest) {
    ee.emit(BUILTINS_MESSAGE_TYPES.contextMenuRequest, props);
  }

  async showCreateEntityForm(props: CreateEntityFormProps) {
    return apiCall<OsWorkspaceEntity[]>(
      BUILTINS_MESSAGE_TYPES.showCreateEntityForm,
      props,
    );
  }

  async showCreateRelationsDialog(props: CreateRelationsDialogProps) {
    return apiCall<void>(
      BUILTINS_MESSAGE_TYPES.showCreateRelationsDialog,
      props,
    );
  }

  async showSaveAsModal(
    props: SaveAsModalProps,
  ): Promise<WorkspaceItem | undefined> {
    return apiCall<WorkspaceItem>(
      BUILTINS_MESSAGE_TYPES.showSaveAsModal,
      props,
    );
  }

  async showModalTemplate(props: ModalTemplateProps) {
    return apiCall<void>(BUILTINS_MESSAGE_TYPES.showModalTemplate, props);
  }

  async showFileUpload(folder: WorkspaceItem) {
    return apiCall<WorkspaceItem[]>(
      BUILTINS_MESSAGE_TYPES.showFileUpload,
      folder,
    );
  }

  async showTab(props: ShowTabProps) {
    ee.emit(BUILTINS_MESSAGE_TYPES.showTab, props);
  }

  async closeTab(props: ShowTabProps) {
    ee.emit(BUILTINS_MESSAGE_TYPES.closeTab, props);
  }

  async callAppService(props: AppServiceCallProps) {
    return apiCall<any>(BUILTINS_MESSAGE_TYPES.callAppService, props);
  }

  async showToast(notification: OsNotification) {
    setTimeout(() => {
      ee.emit(BUILTINS_MESSAGE_TYPES.showToast, notification);
    }, 0);
  }

  async clearToast(id: string) {
    setTimeout(() => {
      ee.emit(BUILTINS_MESSAGE_TYPES.clearToast, id);
    }, 0);
  }

  async showProgress(progress: OsProgress, eventTopicPrefix?: string) {
    setTimeout(() => {
      ee.emit(BUILTINS_MESSAGE_TYPES.showProgress(eventTopicPrefix), progress);
    }, 0);
  }

  async withProgressBar<T>(
    promise: Promise<T>,
    options?: WithProgressBarOptions,
  ) {
    const job_type = options?.job_type || 'Processing';
    const label = options?.label || job_type;
    const key = `${shortid()}`;
    const progress: OsProgress = {
      key,
      label,
      job_type,
      status: 'active',
    };
    this.showProgress(progress, options?.eventTopicPrefix);
    try {
      const result = await promise;
      progress.status = 'success';
      this.showProgress(progress, options?.eventTopicPrefix);
      return result;
    } catch (e) {
      progress.status = 'exception';
      if (options?.errorToast) {
        const message =
          typeof options?.errorToast === 'string'
            ? options?.errorToast
            : `Error on ${options?.eventTopicPrefix}`;
        await this.showToast({
          message,
          level: 'error',
          description: e.message,
        });
      }
      await this.showProgress(progress, options?.eventTopicPrefix);
      throw e;
    }
  }

  async showConfirm(props: OsConfirmProps): Promise<OS_CONFIRM_MODAL_RESPONSE> {
    return apiCall<OS_CONFIRM_MODAL_RESPONSE>(
      BUILTINS_MESSAGE_TYPES.showConfirm,
      props,
    );
  }

  async open(
    records: Entity | Entity[],
    options?: DesktopActionOptions,
  ): Promise<void> {
    // eslint-disable-next-line no-underscore-dangle
    return this._open({
      items: Array.isArray(records) ? records : [records],
      options,
    });
  }

  private async _open(params: DesktopOpenParams): Promise<void> {
    const replyTopic = shortid();
    const deferred = new DeferredPromise<void>();
    ee.once(replyTopic, deferred.resolve);
    ee.emit(BUILTINS_MESSAGE_TYPES.open, params, replyTopic);
    return deferred.promise;
  }

  async refresh() {
    // fetch invoked on a timer until server message is implemented
    return this.storage.refresh();
  }

  async getPasteContext(options: PasteContextOptions): Promise<Entity[]> {
    return getPasteContext(options);
  }

  async getWorkspaceItems(os_item_name: string): Promise<WorkspaceItem[]> {
    return this.storage.filterItems(item => item.os_item_name === os_item_name);
  }

  async getWorkspacePermission(
    os_workspaces: string[],
  ): Promise<{ [os_workspace: string]: WorkspacePermission }> {
    return this.storage.getPermission(os_workspaces);
  }

  async getFilesTree(
    workspace_or_folder: WorkspaceItem,
    options?: FileTreeOptions,
  ): Promise<WorkspaceItem[]> {
    return this.storage.getFilesTree(workspace_or_folder, options);
  }

  async getAttachment<T extends AttachmentType>(
    entity: Entity,
    options: GetAttachmentOptions<T> = DEFAULT_GET_ATTACHMENT_OPTIONS,
  ): Promise<T> {
    // eslint-disable-next-line no-param-reassign
    options = { ...DEFAULT_GET_ATTACHMENT_OPTIONS, ...options };
    try {
      let item = (await OntologyAPI.getEntity(entity)) as WorkspaceItem;
      if (!_.isEmpty(options.path)) {
        const { os_workspace } = item;
        if (!os_workspace) {
          dbconsole.error(
            `cannot get attachment using path for ${item.entity_label} as it has no os_workspace property defined`,
          );
          return options.default as T;
        }
        const workspace = await this.getWorkspace(os_workspace);
        if (!workspace) {
          dbconsole.error(
            `cannot get attachment using path for ${item.entity_label} as it workspace ${os_workspace} not loaded`,
          );
          return options.default as T;
        }
        let path = (options.path || '').trim();
        let parent: WorkspaceItem | undefined;
        if (path.startsWith('/')) {
          path = path.substring(1);
          parent = workspace.workspace;
        }
        const parts = path
          .split('/')
          .map(x => x.trim())
          .filter(x => x.length);
        if (!parent) {
          if (['os_folder', 'os_workspace'].includes(item.os_item_type)) {
            parent = item;
          } else {
            parent = workspace.items.find(
              x => x.os_entity_uid === item.os_parent_folder,
            );
            if (!parent) {
              dbconsole.error(
                `cannot get attachment using path for ${item.entity_label} as it the parent folder "${item.os_parent_folder}" for ${item.entity_label} was not found in the workspace items`,
              );
              return options.default as T;
            }
          }
        }
        let child: WorkspaceItem | undefined;
        parts.forEach(p => {
          if (parent) {
            switch (p) {
              case '.':
                child = parent;
                break;
              case '..':
                child = workspace.items.find(
                  x => x.os_entity_uid === parent?.os_parent_folder,
                );
                break;
              default:
                child = workspace.items.find(
                  i =>
                    `${i.os_item_name}`.trim() === p &&
                    i.os_parent_folder === parent!.os_entity_uid,
                );
            }
            parent = child;
          }
        });
        if (!child) {
          // not found. return the default value
          return options.default as T;
        }
        item = child;
      }
      if (options.responseType === 'url') {
        const relativeURL = `/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${item.os_entity_uid}`;
        const a = document.createElement('a');
        a.href = relativeURL;
        return a.href as T;
      }
      const att = await getFileAttachment(item);
      switch (options.responseType) {
        case 'arrayBuffer':
          return (await att.getArrayBuffer()) as T;
        case 'blob':
          return (await att.getBlob()) as T;
        case 'json':
          return (await att.getJSON()) as T;
        default:
          return (await att.getText()) as T;
      }
    } catch (e) {
      dbconsole.log(
        `getAttachment(entity,options) failed for (${JSON.stringify(
          entity,
        )},${JSON.stringify(options)})`,
        e,
      );
      return options.default as T;
    }
  }

  async getUser(): Promise<UserProfile> {
    return this.userProfile;
  }

  async deployApp(
    app: WorkspaceItem,
    initialSecrets?: { [key: string]: string },
  ) {
    return new APIClient()
      .fetchData<WorkspaceItem>(
        `/api/octostar/jobs/deploy/${app.os_workspace}/${app.entity_id}`,
        {
          method: 'POST',
          body: JSON.stringify({ secrets: initialSecrets }),
        },
      )
      .then(item => {
        this.storage.replace([item]);
        ee.emit(getNotificationKey(item), item);
        return item;
      });
  }

  async undeployApp(app: WorkspaceItem) {
    return new APIClient()
      .fetchData<WorkspaceItem>(
        `/api/octostar/jobs/deploy/${app.os_workspace}/${app.entity_id}`,
        {
          method: 'DELETE',
        },
      )
      .then(item => {
        this.storage.replace([item]);
        ee.emit(getNotificationKey(item), item);
        return item;
      });
  }

  async whoami() {
    return new APIClient().fetchJson<Whoami>('/api/octostar/meta/whoami');
  }

  async getFocusList() {
    return this.focusList.getList();
  }

  async addToFocusList(
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ) {
    this.focusList.addEntities(entities, type, tabName);
    return this.focusList.getList();
  }

  async removeFromFocusList(
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ) {
    this.focusList.removeEntities(entities, type, tabName);
    return this.focusList.getList();
  }

  async getImages(entity: Entity): Promise<Entity[]> {
    if (!entity.entity_type) {
      return [];
    }
    if (entity.entity_type === 'os_file') {
      const fileType = getFileType(entity as WorkspaceItem);
      const mediaType = getMediaType(fileType);
      if (mediaType === 'image') {
        return [entity];
      }
    }
    const images: Entity[] = [];
    const connectedEntities = await OntologyAPI.getConnectedEntities(
      entity,
      FEATURE_RELATIONSHIP,
    );
    const relationships = await OntologyAPI.getWorkspaceRelationshipRecords(
      entity,
      FEATURE_RELATIONSHIP,
    );

    const relationshipsMap = relationships.reduce((map, rel) => {
      const date = rel?.os_last_updated_at
        ? new Date(rel.os_last_updated_at).getTime()
        : rel?.os_created_at
        ? new Date(rel.os_created_at).getTime()
        : 0;

      if (!map?.[rel.os_entity_uid_to] || map?.[rel.os_entity_uid_to] < date) {
        // eslint-disable-next-line no-param-reassign
        map[rel.os_entity_uid_to] = date;
      }
      return map;
    }, {} as Record<string, number>);

    connectedEntities.forEach((item: WorkspaceItem) => {
      const fileType = getFileType(item);
      const mediaType = getMediaType(fileType);
      if (
        mediaType === 'image' &&
        !images.map(x => x.entity_id).includes(item.entity_id)
      ) {
        images.push(item);
      }
    });

    return images.sort((a, b) => {
      const dateA = relationshipsMap?.[a?.entity_id] || 0;
      const dateB = relationshipsMap?.[b?.entity_id] || 0;
      return dateB - dateA;
    });
  }
}

export default DesktopAPI;
