import {
  Store,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import logger from 'src/middleware/loggerMiddleware';
import { initEnhancer } from 'src/reduxUtils';

export type AppState = {
  performingWSAction: boolean;
  readyForAppLayout: boolean;
  bootstrap: 'LOADING' | 'ONTOLOGY_LOADED' | 'ONTOLOGY_SELECTION';
};

export const Update_APP_STATE = 'Update_APP_STATE';

export type UpdateAppStateAction<T extends keyof AppState = keyof AppState> = {
  type: typeof Update_APP_STATE;
  payload: {
    prop: T;
    value: AppState[T];
  };
};

const appStateReducer = (
  appState: AppState = {
    performingWSAction: false, // To prevent unintended stale 'refresh' until backend API resolves (entities committed to Clickhouse)
    readyForAppLayout: false,
    bootstrap: 'LOADING',
  },
  action: UpdateAppStateAction,
): AppState => {
  if (action.type === Update_APP_STATE) {
    const { prop, value } = action.payload;
    return { ...appState, [prop]: value };
  }
  return appState;
};

const rootReducer = combineReducers({
  appState: appStateReducer,
  // TODO: Re-introduce upon code migration
  // workspaces: workspacesReducer,
  // workspaceRecords: workspaceRecordsReducer,
  // workspaceFilesystem: workspaceFilesystem,
  // workspaceTags: workspaceTagsReducer,
});

export type OctostarReduxStoreType = ReturnType<typeof rootReducer>;

export const octostarReduxStore: Store<OctostarReduxStoreType> = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(thunk, logger), // , octostarEvents), TODO: Reintroduce after fixes
    initEnhancer(false, {}, false, 'Octostar App State'),
  ),
);

export const wsUpdateStart = (): UpdateAppStateAction => ({
  type: Update_APP_STATE,
  payload: {
    prop: 'performingWSAction',
    value: true,
  },
});

export const wsUpdateFinish = (): UpdateAppStateAction => ({
  type: Update_APP_STATE,
  payload: {
    prop: 'performingWSAction',
    value: false,
  },
});

export const setReadyForAppLayout = (): UpdateAppStateAction => ({
  type: Update_APP_STATE,
  payload: {
    prop: 'readyForAppLayout',
    value: true,
  },
});

export const isPerformingWSAction = (state: OctostarReduxStoreType) =>
  state.appState.performingWSAction;

export const isReadyForAppLayout = (state: OctostarReduxStoreType) =>
  state.appState.readyForAppLayout;
