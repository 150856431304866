import { AddListener, Callback, Unsubscribe } from './callback';
import {
  Entity,
  OsTag,
  WorkspaceItem,
  WorkspaceItems,
  WorkspaceRelationship,
} from './model';

export type TagInfo = {
  tag: string;
  os_workspace: string;
  count: number;
  sample: Entity[];
  entity: OsTag;
};

export type TagWithRelationship = {
  os_workspace_relationship: WorkspaceRelationship;
  os_tag: OsTag;
};

export type Permission = WorkspaceItem & {
  os_username?: string;
  os_permission: number;
  os_role?: string;
};

export enum WorkspacePermissionValue {
  None = 0,
  Read = 1,
  Write = 2,
  Admin = 4,
}

export type WorkspacePermission = {
  value: WorkspacePermissionValue;
  label: string;
};

export type WorkspaceRecordInfo = {
  concept: string;
  count: number;
  max_last_updated: string;
  entities: Entity[];
};
export type WorkspaceRecords = {
  [key: string]: WorkspaceRecordInfo;
};

export type Workspace = {
  workspace: WorkspaceItem;
  items: WorkspaceItems;
  workspace_records?: WorkspaceRecords;
  tags?: TagInfo[];
  permission?: WorkspacePermission;
  isActive?: boolean;
};

export type uuid = string;
export type WorkspaceIdentifier<T = any> = uuid | WorkspaceItem<T>;
export type WorkspaceItemIdentifier<T = any> = WorkspaceIdentifier<T>; // Same as WorkspaceIdentifier for now.
export type WorkspaceRecordWithRelationships = {
  entity: WorkspaceItem;
  relationship_name?: string;
  relationships?: [WorkspaceRecordWithRelationships];
};
export interface WorkspaceChangeEmitter {
  onWorkspaceChanged: (
    ws: WorkspaceIdentifier,
    callback: Callback<Workspace>,
  ) => Promise<Unsubscribe>;

  onWorkspaceItemChanged: (
    item: WorkspaceItemIdentifier,
    callback: Callback<WorkspaceItem>,
  ) => Promise<Unsubscribe>;

  // provides an initial callback with the current open workspaces
  onOpenWorkspacesChanged: AddListener<Workspace[]>;
}

export type WorkspaceSettings = {
  isMaster?: boolean;
  os_icon?: string;
};
