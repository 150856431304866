import { WorkspaceItem, WorkspaceItemBase } from '@octostar/platform-types';

export function filterUniqueItems<T extends WorkspaceItemBase>(
  workspaceItems: T[],
  reverse = false,
): T[] {
  const uniqueItems: T[] = [];
  const seenIds = new Set<string>();

  function pushIfNotSeen(currentItem: T) {
    if (!seenIds.has(currentItem.os_entity_uid)) {
      seenIds.add(currentItem.os_entity_uid);
      uniqueItems.push(currentItem);
    }
  }

  if (reverse) {
    for (let i = workspaceItems.length - 1; i >= 0; i -= 1) {
      pushIfNotSeen(workspaceItems[i]);
    }
  } else {
    workspaceItems.forEach(pushIfNotSeen);
  }

  return uniqueItems;
}

export function sortWorkspaceItems(a: WorkspaceItem, b: WorkspaceItem) {
  return (
    (a.os_item_name || '').localeCompare(b.os_item_name || '') ||
    (a.os_item_type || '').localeCompare(b.os_item_type || '') ||
    (a.os_item_content_type || '').localeCompare(
      b.os_item_content_type || '',
    ) ||
    (a.os_created_at || '').localeCompare(b.os_created_at || '') ||
    a.os_entity_uid.localeCompare(b.os_entity_uid)
  );
}
