import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import {
  CommentWorkspaceItem,
  Entity,
  Relationship,
  WorkspaceItem,
} from '@octostar/platform-types';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import moment from 'moment/moment';
import { HAS_COMMENT_REL_NAME } from 'src/octostar/interface';
import { CommentItem } from './types';

export const entityToComment = (
  referredEntity: Entity,
  comment: CommentWorkspaceItem,
) =>
  ({
    os_workspace: comment.os_workspace,
    referred_entity: referredEntity,
    comment_id: comment.os_entity_uid,
    parent_comment: comment.os_parent_uid,
    text: comment.contents,
    author: comment.os_created_by,
    created_at: comment.os_created_at
      ? new Date(comment.os_created_at).getTime()
      : Date.now(),
  } as CommentItem);

export const getCommentsForEntity = async (
  entity: Entity,
  force: boolean,
): Promise<CommentItem[]> => {
  const fetchedComments =
    await OntologyAPI.getConnectedEntities<CommentWorkspaceItem>(
      entity,
      HAS_COMMENT_REL_NAME,
      force,
    );
  return fetchedComments.map(x => entityToComment(entity, x));
};

export const getCommentsCountForEntity = async (
  entity: Entity,
): Promise<number> => {
  const eRels = await OntologyAPI.getRelationshipsForEntity(entity);
  const commentRel = eRels.find(
    rel => rel.relationship_name.toLowerCase() === HAS_COMMENT_REL_NAME,
  ) as Relationship;
  if (!commentRel) {
    console.warn(
      `No comment relationship found for entity ${entity.entity_type} ${entity.entity_label}`,
    );
    return 0;
  }
  const commentsCount = await OntologyAPI.getRelationshipCount(
    entity,
    commentRel,
  );
  return commentsCount.count;
};

export const addComment = async (
  entity: Entity,
  os_workspace: string,
  comment_text: string,
  comment_parent_uid?: string,
): Promise<Entity> =>
  DesktopAPI.withProgressBar(
    DesktopAPI.addComment(entity, {
      os_workspace: os_workspace || (entity as WorkspaceItem).os_workspace,
      contents: comment_text,
      os_parent_uid: comment_parent_uid,
    }),
    {
      job_type: 'Adding a comment',
    },
  );

// Remove comment
export const removeComment = async (
  os_workspace: string,
  comment_id: string,
  referred_entity: Entity,
): Promise<void> => {
  const allComments = await getCommentsForEntity(referred_entity, true);
  const comment = allComments.find(c => c.comment_id === comment_id);
  if (!comment) {
    throw new Error(`Comment ${comment_id} not found for deletion`);
  }
  const toDelete = allComments.filter(c => c.parent_comment === comment_id);
  console.log(`Deleting ${toDelete.length + 1} comments`);
  toDelete.push(comment);

  await Promise.all(
    toDelete.map(async c => {
      await DesktopAPI.withProgressBar(
        DesktopAPI.removeComment(os_workspace, comment_id),
        {
          job_type: 'Removing a comment',
        },
      );
    }),
  );
};

export function sanitizeComment(body: string): string {
  // Create a new DOM parser
  const parser = new DOMParser();

  // Parse the string as HTML
  const doc = parser.parseFromString(body, 'text/html');

  // Use textContent to strip out any HTML tags, then re-add the links
  const textContent = doc.body.textContent || '';

  function replaceUrlWithLink(str: string): string {
    const linkRegex = /((https?:\/\/)?(www\.)?[^\s,]+(\.[^\s,]+)+)/g;

    function replacer(matched: string) {
      let withProtocol = matched;

      if (!withProtocol.startsWith('http')) {
        withProtocol = `https://${matched}`;
      }

      return `<a class="text-link" href="${withProtocol}">${matched}</a>`;
    }

    return str.replace(linkRegex, replacer);
  }
  return replaceUrlWithLink(textContent);
}
export const formatDate = (timestamp: number) => {
  const date = moment.unix(timestamp / 1000);
  const now = moment();

  let humanReadableDate;
  if (now.isSame(date, 'day')) {
    humanReadableDate = date.fromNow();
  } else if (now.subtract(1, 'days').isSame(date, 'day')) {
    humanReadableDate = 'yesterday';
  }

  const formattedTime = date.format('h:mm A');

  return `${
    humanReadableDate || date.format('MMMM D, YYYY')
  }, at ${formattedTime}`;
};
