import {
  Entity,
  OsWorkspaceEntity,
  ReasonedBoolean,
  SaveOptions,
  WorkspaceItem,
  WorkspacePermissionValue,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';
import {
  getLabelKeysByConcept,
  isWorkspaceBusinessRecord,
} from './ConceptUtils';
import DesktopAPI from '../api/event-driven/desktop';
import { OS_DRAFT_WORKSPACE } from '../interface';

export const FIELDS_INCOMPLETE = 'Incomplete';

export const hasUserPermission = async (os_workspaces: string[]) => {
  const permissions = await DesktopAPI.getWorkspacePermission(os_workspaces);
  return Object.values(permissions).find(
    x => x.value >= WorkspacePermissionValue.Write,
  );
};

export const parseNodeDataToBeSaved = async (
  node: Entity & WorkspaceRecordIdentifier,
  os_workspace: string,
): Promise<Entity & WorkspaceRecordIdentifier> => {
  const newEntity: any = {};
  newEntity.os_entity_uid = node.entity_id;
  newEntity.entity_label = node.entity_label;
  newEntity.os_workspace = os_workspace;
  newEntity.entity_type = node.entity_type;

  const concept = await OntologyAPI.getConceptByName(node.entity_type);
  concept?.columns.forEach(
    (key: keyof (Entity & WorkspaceRecordIdentifier)) => {
      if (node[key]) {
        newEntity[key] = node[key];
      }
    },
  );

  return newEntity;
};

export const checkIfEntityIsSaved = async (entity?: Entity) => {
  if (!(entity?.entity_type && entity.entity_id)) {
    return false;
  }
  if ((entity as WorkspaceItem)?.os_created_at) {
    return true;
  }
  try {
    const { entity_type, entity_id, entity_label } = entity;
    const gotten = await OntologyAPI.getEntity({
      entity_type,
      entity_id,
      entity_label,
    });
    return Object.keys(gotten).length > 3;
  } catch {
    return false;
  }
};

/**
 * Function to check if entity can be saved before saving or editing.
 * @param {OsWorkspaceEntity} entity - The entity to be validated.
 * @param {boolean} skipCheckEntitySave - If true, skip entity save check.
 * @param {boolean} skipCheckEntityEdit - If true, skip entity edit check.
 * @param {boolean} skipCheckEntityWorkspace - If true, skip entity workspace check.
 * @returns {Promise<ReasonedBoolean>} - Returns a ReasonedBoolean object.
 */

export const canEntityBeSaved = async (
  entity: OsWorkspaceEntity,
  checkIfWorspaceBusinessRecord?: boolean,
): Promise<ReasonedBoolean> => {
  try {
    // Check if entity_type is valid concept
    if (!entity?.entity_type) {
      throw new Error('Missing entity_type field');
    }
    const concept = await OntologyAPI.getConceptByName(entity.entity_type);
    if (!concept) {
      throw new Error(`Concept "${entity.entity_type}" not found`);
    }
    if (checkIfWorspaceBusinessRecord) {
      // Check if concept is a workspace business record
      const isBusinessRecord = await isWorkspaceBusinessRecord(concept);
      if (!isBusinessRecord) {
        throw new Error(
          `Concept "${entity.entity_type}" is not a business record`,
        );
      }
    }
    // Check if concept has label keys
    const label_keys = await getLabelKeysByConcept(concept);
    if (!label_keys) {
      throw new Error(
        `No label keys found for concept "${entity.entity_type}" which means concepts of this type should not be saved.`,
      );
    }
    // Check if entity is big data
    const isSaved = await checkIfEntityIsSaved(entity);
    if (isSaved && !entity?.os_workspace) {
      throw new Error('Entity is a big data');
    }
    // Check if user has write permission on workspace
    if (entity?.os_workspace && entity?.os_workspace !== OS_DRAFT_WORKSPACE) {
      const userCanEdit = await hasUserPermission([entity?.os_workspace]);
      if (!userCanEdit) {
        throw new Error('No write permission on workspace');
      }
    }
    return { value: true };
  } catch (error) {
    return { value: false, reason: error.message };
  }
};

export const hasFilledLabelKeys = (entity: any, labelKeys: string[]): boolean =>
  labelKeys.some(key => entity?.[key]);

export const checkFilledLabelKeys = async (entity: Entity) => {
  const concept = await OntologyAPI.getConceptByName(entity.entity_type);
  if (!concept) return false;
  const labelKeys = await getLabelKeysByConcept(concept);
  return hasFilledLabelKeys(entity, labelKeys);
};

/**
 * Checks if the given entity is missing any required fields and handles different cases
 * such as displaying a save as modal or create entity form.
 *
 * @param {OsWorkspaceEntity} entity - The entity to be checked.
 * @param {SaveOptions} [options] - Optional save options.
 * @returns {Promise<OsWorkspaceEntity | undefined>} - A promise that resolves to the updated entity or undefined if the save as modal, create entity form, or active workspace are closed.
 */

export const checkMissingFields = async (
  entity: OsWorkspaceEntity,
  options?: SaveOptions,
): Promise<OsWorkspaceEntity | undefined> => {
  let workspace: string | undefined = entity.os_workspace;
  const concept = await OntologyAPI.getConceptByName(entity?.entity_type);
  const isWorkspaceBusinessRecord = concept?.parents?.includes(
    'os_workspace_business_record',
  );
  // Check if entity has filled label keys
  const hasFilledLabelKeys = checkFilledLabelKeys(entity);
  if (!hasFilledLabelKeys && isWorkspaceBusinessRecord) {
    const newEntity = await DesktopAPI.showCreateEntityForm({
      entity,
      concept: entity.entity_type,
    });
    return newEntity?.[0];
  }
  if (!hasFilledLabelKeys || options?.saveAs) {
    return DesktopAPI.showSaveAsModal({
      item: entity as WorkspaceItem,
      app: options?.app,
    });
  }
  // Check if entity has os_workspace
  if (
    (!workspace || workspace === OS_DRAFT_WORKSPACE) &&
    (entity as WorkspaceItem).os_item_type !== 'os_workspace'
  ) {
    workspace = await DesktopAPI.getActiveWorkspace(true);
    if (!workspace) {
      return undefined;
    }
  }
  return {
    ...entity,
    os_workspace: workspace,
  } as WorkspaceItem;
};
