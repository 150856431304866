import _ from 'lodash';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { WorkspaceItem } from '@octostar/platform-types';
import { SchemaJSON, SchemaType, SchemaUiJSON } from './types';

export const saveSchema = async (
  conceptEntity: string,
  schema: SchemaJSON,
  schemaUI: SchemaUiJSON,
  schemaItem: WorkspaceItem,
  schemaUIItem: WorkspaceItem,
): Promise<void> => {
  if (_.isEmpty(schema) || _.isEmpty(schemaUI)) {
    throw new Error('Schema is empty');
  }
  if (
    JSON.stringify(schema) === `${SchemaType.SCHEMA}.${conceptEntity}.yaml` ||
    JSON.stringify(schemaUI) === `${SchemaType.SCHEMA_UI}.${conceptEntity}.yaml`
  ) {
    throw new Error('Schema is not modified');
  }
  const concept = await OntologyAPI.getConceptByName(conceptEntity);
  if (!concept || !concept.allProperties) {
    throw new Error(`Error getting concept: "${concept}"`);
  }
  if (!_.isEmpty(schema)) {
    const schemaItemFile = await DesktopAPI.saveFile(
      schemaItem,
      JSON.stringify(schema),
    );
    if (!schemaItemFile) {
      // eslint-disable-next-line no-console
      console.log(`problem saving ${schemaItem.entity_label}`, schemaItemFile);
      throw new Error('Error saving schema file');
    }
  }
  if (!_.isEmpty(schemaUI)) {
    const schemaUIItemFile = await DesktopAPI.saveFile(
      schemaUIItem,
      JSON.stringify(schemaUI),
    );
    if (!schemaUIItemFile) {
      // eslint-disable-next-line no-console
      console.log(
        `problem saving ${schemaUIItem.entity_label}`,
        schemaUIItemFile,
      );
      throw new Error('Error saving schemaUI file');
    }
  }
};
