import * as React from 'react';
import { Form, Space } from 'antd5';
import SearchField, { Field } from './SearchField';
import { ConceptCounts, ConceptsInheritance } from '../ConceptSelector/types';
import ConceptSelector from '../ConceptSelector/ConceptSelector';

export interface SearchState {
  label?: string | undefined;
  main?: string | undefined;
  concepts?: string[] | undefined;
}

export const DEFAULT_MAIN_FIELD = {
  field: 'os_textsearchfield',
  label: 'Main Record fields',
  placeholder: 'Search Main Records',
};
const DEFAULT_FIELDS = [
  { field: 'entity_label', label: 'Label', placeholder: 'Search on Label' },
  DEFAULT_MAIN_FIELD,
];
export interface SearchboxProps<T> {
  fields?: Field[] | undefined;
  /**
   * What to do when user the search changes:
   * User clicks search
   * User hits enter key on label or main records field
   * User changes
   */
  onSearch?: ((state: T) => void) | undefined;
  /**
   * How big is it?
   */
  size?: 'small' | 'middle' | 'large' | undefined;
  layout?: 'horizontal' | 'vertical' | 'inline' | undefined;
  concepts?: ConceptsInheritance | undefined;
  conceptCounts?: ConceptCounts | undefined;
  onConceptsChange?: (concepts: string[]) => void;
  onFieldChange?: (key: string, value: any) => void;
  defaultValue?: T | undefined;
  disableConceptSelector?: boolean | undefined;
}

export const Searchbox = (props: SearchboxProps<any>) => {
  const {
    size = 'large',
    layout,
    onConceptsChange,
    onSearch,
    onFieldChange,
    concepts,
    conceptCounts,
    defaultValue,
    fields,
    disableConceptSelector,
  } = props;
  const value = React.useRef(defaultValue || {});

  const onAntSearch = () => {
    onSearch?.(value.current);
  };

  /**
   * Handler called when fields change, including concepts.
   * @param field
   * @returns
   */
  const handleFieldChange = (field: string) => (searchValue: any) => {
    const newValue = (value.current = { ...value.current });
    delete newValue[field];
    if (searchValue !== undefined) {
      newValue[field] = searchValue;
    }
    value.current = newValue;
    onFieldChange?.(field, newValue);
  };

  /**
   * Handler called when concepts change
   * @param concepts
   */
  const handleConceptsChange = (concepts: string[]) => {
    value.current = { ...value.current, concepts };
    onConceptsChange?.(concepts);
    onFieldChange?.('concepts', value.current);
  };

  return (
    <Form layout={layout}>
      <Space style={{ width: '100%' }} direction="vertical" size="small">
        <Space style={{ width: '100%' }} direction="vertical" size="small">
          {
            // @ts-ignore-next-line
            (fields || DEFAULT_FIELDS).map(field => (
              <Form.Item key={field.field} label={field.label}>
                <SearchField
                  field={field}
                  size={size}
                  onSearch={onAntSearch}
                  onChange={handleFieldChange(field.field)}
                  defaultValue={defaultValue?.[field.field]}
                />
              </Form.Item>
            ))
          }
        </Space>
        {concepts && (
          <ConceptSelector
            size={size}
            concepts={concepts}
            conceptCounts={conceptCounts}
            onChange={handleConceptsChange}
            onSearch={onAntSearch}
            defaultValue={defaultValue?.concepts}
            disabled={disableConceptSelector}
          />
        )}
      </Space>
    </Form>
  );
};

export default Searchbox;
