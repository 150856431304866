import { Filter } from '@superset-ui/core';
import shortid from 'shortid';
import { Datasource, Layout, LayoutItem } from 'src/dashboard/types';
import {
  DASHBOARD_GRID_TYPE,
  DASHBOARD_HEADER_TYPE,
  DASHBOARD_ROOT_TYPE,
  TABS_TYPE,
  TAB_TYPE,
} from 'src/dashboard/util/componentTypes';
import {
  DASHBOARD_GRID_ID,
  DASHBOARD_HEADER_ID,
  DASHBOARD_ROOT_ID,
} from 'src/dashboard/util/constants';
import { useDashboard } from 'src/hooks/apiResources';
import Chart from 'src/types/Chart';

type Dashboard = ReturnType<typeof useDashboard>['result'];
export { Filter } from '@superset-ui/core';

export const VIRTUAL_TABS_PREFIX = 'TAB-virtual-';
export const VIRTUAL_TABS_ID = `${VIRTUAL_TABS_PREFIX}${shortid()}`;

/**
 * @returns {LayoutItem} parent for chart absent from dashboard layout
 */
export function getParentForStrayChart(
  orphanChart: Chart,
  rootParent: LayoutItem,
  dashLayout: Layout,
): LayoutItem {
  const { originDashIdOrSlug } = orphanChart;

  if (originDashIdOrSlug) {
    const parentTabKey = Object.keys(dashLayout).filter(
      key =>
        key.startsWith(VIRTUAL_TABS_PREFIX) &&
        key.endsWith(`${originDashIdOrSlug}`),
    );
    if (parentTabKey.length) {
      return dashLayout[parentTabKey[0]];
    }
  }

  return rootParent;
}

function getRootGrid(rootDash: Dashboard): Layout {
  return {
    [DASHBOARD_HEADER_ID]: JSON.parse(rootDash?.position_json || '{}')[
      DASHBOARD_HEADER_ID
    ],
    [DASHBOARD_GRID_ID]: {
      children: [VIRTUAL_TABS_ID],
      id: DASHBOARD_GRID_ID,
      parents: [DASHBOARD_ROOT_ID],
      type: DASHBOARD_GRID_TYPE,
    },
    [DASHBOARD_ROOT_ID]: {
      children: [DASHBOARD_GRID_ID],
      id: DASHBOARD_ROOT_ID,
      type: DASHBOARD_ROOT_TYPE,
    },
    [VIRTUAL_TABS_ID]: {
      children: [],
      id: VIRTUAL_TABS_ID,
      meta: {},
      parents: [DASHBOARD_ROOT_ID, DASHBOARD_GRID_ID],
      type: TABS_TYPE,
    },
  } as any;
}

/**
 * Merge all dashboards for an entity into one with multiple Tabs as separation
 */
export function mergeDashboards(
  dashboards: Dashboard[],
  savedFilters: Filter[] = [],
): Dashboard | null {
  if (!dashboards.length || !dashboards[0]) return null;

  const rootDashboard: Dashboard = { ...dashboards[0] };

  rootDashboard.metadata = JSON.parse(rootDashboard.json_metadata || '{}');
  rootDashboard.metadata.native_filter_configuration = [];

  const visitedDashboards = new Set();
  const rootLayout: Layout = getRootGrid(rootDashboard);
  const baseParents = [DASHBOARD_ROOT_ID, DASHBOARD_GRID_ID, VIRTUAL_TABS_ID];

  const rootTabsEle = rootLayout[VIRTUAL_TABS_ID];

  dashboards.forEach(dash => {
    if (!dash || visitedDashboards.has(dash.id)) return;
    visitedDashboards.add(dash.id);

    const dashLayout: Layout = JSON.parse(dash.position_json || '{}');
    const dashMetadata = JSON.parse(dash.json_metadata || '{}');

    // TODO: Look into chart_configuration, other json_metadata merging strategies
    // TODO: Look into possible de-duplication of 'contextually similar' filters?
    rootDashboard.metadata.native_filter_configuration.push(
      ...(dashMetadata.native_filter_configuration || []),
    );

    // If top level is Root -> Tabs then simply append to rootTabsEle.children & update all the parents from [Root -> Tabs -> ...] -> [...baseParents, ...]
    // If top level is Grid then simple create a Tab, append it to rootTabsEle.children & update all the parents from [Root -> Grid -> ...] -> [...baseParents, ...]

    const topLevelTabs =
      dashLayout[DASHBOARD_ROOT_ID].children[0] !== DASHBOARD_GRID_ID;
    let dashBaseParents = [...baseParents];
    if (topLevelTabs) {
      const tabsId = dashLayout[DASHBOARD_ROOT_ID].children[0];
      rootTabsEle.children.push(...dashLayout[tabsId].children);
      delete dashLayout[tabsId];
    } else {
      const newTabId = `${VIRTUAL_TABS_PREFIX}${dash.dashboard_title}-${
        dash.slug || dash.id
      }`;
      const newDashTab: LayoutItem = {
        children: dashLayout[DASHBOARD_GRID_ID].children,
        id: newTabId,
        meta: {
          defaultText: 'Tab title',
          placeholder: 'Tab title',
          text: dash.dashboard_title,
        } as any, // The meta type defition is incorrect in terms of marking optionals
        parents: dashBaseParents,
        type: TAB_TYPE,
      };
      dashLayout[newTabId] = newDashTab;
      rootTabsEle.children.push(newTabId);
      dashBaseParents = [...dashBaseParents, newTabId];
      delete dashLayout[DASHBOARD_GRID_ID];
    }

    Object.entries(dashLayout).reduce((acc, [id, item]) => {
      switch (item.type) {
        case DASHBOARD_HEADER_TYPE:
        case DASHBOARD_GRID_TYPE:
        case DASHBOARD_ROOT_TYPE:
          break;
        default:
          if (item.type) {
            // eslint-disable-next-line no-param-reassign
            item.parents = [...dashBaseParents, ...item.parents.slice(2)];
          }
          acc[id] = item;
          break;
      }
      return acc;
    }, rootLayout);
  });

  rootDashboard.metadata.native_filter_configuration.push(...savedFilters);

  rootDashboard.position_data = rootLayout;
  rootDashboard.position_json = JSON.stringify(rootLayout);
  rootDashboard.json_metadata = JSON.stringify(rootDashboard.metadata);

  return rootDashboard;
}

export function deDupeDatasets(datasets: Datasource[]): Datasource[] {
  return Object.values(
    datasets.reduce((acc, set) => {
      acc[set.uid] = set;
      return acc;
    }, {} as Record<string, Datasource>),
  ).map(dataset => dataset);
}
