import * as React from 'react';
import { css, styled } from '@superset-ui/core';
import { MoreOutlined } from '@ant-design/icons';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import ContextMenu from '../ContextMenu';

const cssHover = css`
  display: none;
  :hover {
    display: unset;
  }
  .ant-table-row-hover .os-table-context-menu {
    display: unset;
  }
`;

// const PaddedSpan = styled.span`
//   position: relative;
//   right: 15%;

//   padding: 5px;
//   .ant-checkbox-input {
//     padding: 5px;
//   }
// `;
export const TableContextMenu = ({
  children,
  record,
  checked,
  showOnHover,
}: {
  children: any;
  record: any;
  checked?: boolean;
  showOnHover?: boolean;
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [item, setItem] = React.useState<any>();
  React.useEffect(() => {
    OntologyAPI.getEntity(record).then(setItem);
  }, [record]);
  return (
    <div
      className={`os-table-context-menu ${
        menuOpen || !showOnHover ? 'os-table-context-menu-open' : ''
      } ${checked ? 'os-table-context-menu-checked' : ''}`}
    >
      <ContextMenu
        item={item}
        onMenuToggle={setMenuOpen}
        options={{
          groups: ['open', 'edit', 'transform', 'tags', 'addto'],
        }}
        trigger={['click', 'contextMenu']}
      >
        <MoreOutlined
          css={css`
            position: relative;
            right: 15%;
          `}
        />
        {children}
      </ContextMenu>
    </div>
  );
};
