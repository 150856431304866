import {
  Concept,
  Entity,
  RecordProvider,
  WorkspaceItem,
  WorkspaceItemModel,
} from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';

export class DeferredPromise<T> {
  promise;

  reject: (reason?: any) => any = () => undefined;

  resolve: (value: T | PromiseLike<T>) => void = () => undefined;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

// see https://stackoverflow.com/questions/28280920/convert-array-of-words-strings-to-regex-and-use-it-to-get-matches-on-a-string
export const regexFrom = (strings: string[], flags?: string) => {
  const pattern = strings
    // Escape special characters
    .map(s => s.replace(/[()[\]{}*+?^$|#.,/\\\s-]/g, '\\$&'))
    .join('.*');
  return new RegExp(pattern, flags);
};

export const newWorkspaceItem = (
  props: Partial<WorkspaceItem> & WorkspaceItemModel,
): WorkspaceItem => {
  const os_entity_uid = crypto.randomUUID();
  const entity_id = os_entity_uid;
  const entity_type = props.os_item_type;
  const entity_label = props.entity_label || props.os_item_name;
  const item: WorkspaceItem = {
    entity_id,
    entity_type,
    entity_label,
    os_entity_uid,
    os_workspace: os_entity_uid, // only applied for os_workspace
    ...props,
  };

  if (item.entity_type !== 'os_workspace' && !props.os_workspace) {
    console.log(`os_workspace was not set for new item ${item.entity_label}`);
    throw new Error(`os_workspace not set`);
  }
  return item;
};

export const getIcon = async (
  x: Entity | Concept | string,
): Promise<string> => {
  if ((x as any).os_item_content?.icon) {
    return Promise.resolve((x as any).os_item_content?.icon);
  }
  let concept = x as Concept | undefined;
  let conceptName: string | undefined;
  if (typeof x === 'string') {
    conceptName = x;
  } else if ((x as Entity).entity_type) {
    conceptName = (x as Entity).entity_type;
  }
  if (conceptName) {
    concept = await OntologyAPI.getConceptByName(conceptName);
  }
  const icon = concept?.tags.find(t => t.tag_name === 'icon')?.tag_value;
  if (icon) {
    return icon;
  }
  return 'file';
};

export const createRecordProvider = (
  records: Entity[],
  message?: string,
): RecordProvider => ({
  getCount: async () => records.length,

  getRecord: async (i: number) => {
    if (i < 0 || i >= records.length) {
      throw new Error('Index out of bounds');
    }
    return records[i];
  },

  onDelete: async (entity: Entity) => {
    const index = records.findIndex(
      record => record.entity_id === entity.entity_id,
    );
    if (index === -1) {
      throw new Error('Entity not found');
    }
    records.splice(index, 1);
  },
  getMessage: async () => message,
});

/**
 * A promise but also a function
 * @param fixedResponse optional to be returned
 * @returns the fixedResponse or arguments given to the function
 */
export const makeFunctionPromise = (fixedResponse?: any) => {
  const details: any = {};

  details.promise = new Promise((resolve, reject) => {
    details.resolve = resolve;
    details.reject = reject;
  });

  details.functionPromise = function (result: any) {
    details.resolve(fixedResponse === undefined ? result : fixedResponse);
  };

  details.functionPromise.promise = details.promise;

  return details.functionPromise;
};
