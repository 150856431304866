import * as React from 'react';
import { Button, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { SendOutlined } from '@ant-design/icons';

export const CommentEditor: React.FC<{
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
  value: string;
  loading?: boolean;
}> = ({ onChange, onSubmit, value, loading }) => (
  <>
    <Form.Item>
      <TextArea disabled={loading} rows={4} onChange={onChange} value={value} />
    </Form.Item>

    <Form.Item>
      <Button
        loading={loading}
        icon={<SendOutlined />}
        htmlType="submit"
        onClick={onSubmit}
        type="primary"
        className="comments-submit-btn"
      >
        Send
      </Button>
    </Form.Item>
  </>
);
