import * as React from 'react';
import { RegistryWidgetsType, WidgetProps } from '@rjsf/utils';
import { Col, ColorPicker, Form, Input, Row, Select } from 'antd5';
import { Color } from 'antd5/lib/color-picker';

const CustomSearchSelectWidget = (props: WidgetProps) => {
  const { id, options, value, required, disabled, readonly, onChange, schema } =
    props;

  const handleChange = React.useCallback(
    (value: any) => {
      onChange(value);
    },
    [onChange],
  );

  const selectOptions = ((schema as any)?.values || options?.enumOptions)?.map(
    (option: any) => (
      <Select.Option
        key={option?.value || option}
        value={option?.value || option}
      >
        {option?.label || option}
      </Select.Option>
    ),
  );

  return (
    <Form.Item>
      <Select
        id={id}
        showSearch
        value={value}
        onChange={handleChange}
        disabled={disabled || readonly}
        allowClear={!required}
      >
        {selectOptions}
      </Select>
    </Form.Item>
  );
};

const CustomColorPickerWidget = (props: WidgetProps) => {
  const { id, value, required, disabled, readonly, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleColorChange = (color: Color) => {
    onChange(color.toHexString());
  };

  return (
    <Form.Item>
      <Row gutter={2}>
        <Col span={18}>
          <Input
            id={id}
            value={value === null ? 'None' : value}
            onChange={handleChange}
            disabled={disabled || readonly}
            allowClear={!required}
          />
        </Col>
        <Col span={6}>
          <ColorPicker
            onChange={handleColorChange}
            disabled={disabled || readonly}
            allowClear={!required}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};

export const customWidgets: RegistryWidgetsType = {
  SelectWidget: CustomSearchSelectWidget,
  ColorPickerWidget: CustomColorPickerWidget,
};
