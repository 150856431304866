/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react';
import { FieldTemplateProps } from '@rjsf/utils';
import { Col, Row, Tooltip } from 'antd5';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './standard-template.css';

export function StandardFieldTemplate(props: FieldTemplateProps) {
  const {
    id,
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    readonly,
    formData,
    formContext,
  } = props;

  if (id === 'root') {
    return <div className={classNames}>{children}</div>;
  }

  if (id === `root_${label}`) {
    return (
      <div className={classNames} key={id}>
        {children}
      </div>
    );
  }

  const labelField = label.toLowerCase().startsWith('os ')
    ? label.replace(/ /g, '_').toLowerCase()
    : label.replace(/_/g, ' ');
  const labelKeys = formContext?.labelKeys;
  const isLabelKey = labelKeys?.includes(
    label.replace(/ /g, '_').toLowerCase(),
  );
  const descriptionField = description?.props?.description?.toString();

  const singleColumn = formContext?.singleColumn;
  const requiredFieldsOnly = formContext?.requiredFieldsOnly;

  if (singleColumn || requiredFieldsOnly) {
    return (
      <div key={id} className="form-column-style">
        <Row gutter={[24, 24]} justify="start">
          <Col span={8}>
            <label htmlFor={id} className="form-label-field">
              {labelField}
              {!requiredFieldsOnly && required ? (
                <span className="label-required">*</span>
              ) : (
                !requiredFieldsOnly && isLabelKey && '*'
              )}{' '}
              {!readonly && descriptionField !== '' && (
                <Tooltip title={description}>
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </label>
          </Col>
          <Col span={16}>
            {readonly ? (
              <div className="form-readonly-field">{formData}</div>
            ) : (
              <div className="form-input-field">
                {children}
                {errors}
                {help}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className={classNames} key={id}>
      <label htmlFor={id} className="form-label-field">
        {labelField}
        {required ? (
          <span className="label-required">*</span>
        ) : (
          isLabelKey && '*'
        )}{' '}
        {!readonly && descriptionField !== '' && (
          <Tooltip title={description}>
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </label>
      {readonly ? (
        <div className="form-readonly-field">{formData}</div>
      ) : (
        <div className="form-input-field">
          {children}
          {errors}
          {help}
        </div>
      )}
    </div>
  );
}
