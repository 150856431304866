import { formatConceptLabel } from './ConceptSelectorUtils';
import { ConceptCounts, TreeConcept } from './types';

/**
 * Asynchronously updates a tree of concepts based on an acceptance criterion.
 * This function iterates over each concept in the tree and checks if it is accepted by an asynchronous function.
 * For each concept, it updates the tree node with additional properties and recursively processes its children.
 * Nodes that are not accepted are still included in the tree but are marked accordingly.
 *
 * @param {TreeConcept[]} treeData - The array of tree concepts to be processed.
 * @param {ConceptCounts | undefined} conceptCounts - An optional object containing concept counts.
 * @param {(concept: string) => Promise<boolean>} accepts - An asynchronous function that determines whether a given concept is accepted.
 * @returns {Promise<TreeConcept[]>} A Promise that resolves to an array of updated tree concepts, where each concept has been checked against the acceptance function and updated accordingly.
 */
export const updateTreeDataAsync = (
  treeData: TreeConcept[],
  conceptCounts: ConceptCounts | undefined,
  accepts: (concept: string) => Promise<boolean>,
): Promise<TreeConcept[]> => {
  const promises = treeData.map(async (treeConcept: TreeConcept) => {
    const conceptName = treeConcept.value;
    if (!conceptName) return treeConcept;
    const acceptsConcept = await accepts?.(conceptName as string);

    const isLeaf =
      treeConcept.children === undefined || treeConcept.children.length === 0;

    const title = formatConceptLabel(
      conceptName,
      conceptCounts?.[conceptName],
      undefined,
      !acceptsConcept,
      isLeaf,
    );
    const newTreeConcept = {
      ...treeConcept,
      disabled: !acceptsConcept,
      isLeaf,
      title,
    };

    if (treeConcept.children?.length) {
      newTreeConcept.children = await updateTreeDataAsync(
        treeConcept.children as TreeConcept[],
        conceptCounts,
        accepts,
      );
    }

    return newTreeConcept;
  });

  return Promise.all(promises);
};

/**
 * Asynchronously filters a tree of concepts, returning a new tree consisting only of accepted concepts.
 * Each node in the tree is checked against an asynchronous acceptance function.
 * If a node is not accepted, it is excluded from the result, but its children are still processed and included if accepted.
 *
 * @param {TreeConcept[]} treeData - The array of tree concepts to be filtered.
 * @param {(concept: string) => Promise<boolean>} accepts - An asynchronous function that determines whether a given concept is accepted.
 * @returns {Promise<TreeConcept[]>} A Promise that resolves to an array of accepted tree concepts.
 */
export const filterAcceptedConcepts = async (
  treeData: TreeConcept[],
  accepts: (concept: string) => Promise<boolean>,
): Promise<TreeConcept[]> => {
  const processNode = async (
    node: TreeConcept,
  ): Promise<TreeConcept[] | null> => {
    const conceptName = node.value;
    const acceptsConcept = conceptName ? await accepts(conceptName) : false;

    let processedChildren: TreeConcept[] = [];
    if (node.children && node.children.length > 0) {
      const childrenPromises = node.children.map(child =>
        processNode(child as TreeConcept),
      );
      const childrenResults = await Promise.all(childrenPromises);
      processedChildren = childrenResults
        .flat()
        .filter((child): child is TreeConcept => child !== null);
    }
    if (!acceptsConcept) {
      return processedChildren.length > 0 ? processedChildren : null;
    }
    return [
      {
        ...node,
        children: processedChildren,
      },
    ];
  };
  const treePromises = treeData.map(node => processNode(node));
  const processedTree = await Promise.all(treePromises);
  return processedTree
    .flat()
    .filter((node): node is TreeConcept => node !== null);
};

export const removeConceptLabel = (value: string) =>
  value.replace(/\s+\(.*$/, '');

export const isChildKey = (childKey: string, parentKey: string) =>
  childKey?.startsWith(`${parentKey}:`);

export const findValueInTreeData = (
  treeData: TreeConcept[],
  value: string,
): TreeConcept | undefined => {
  const findValue = (nodes: TreeConcept[]): TreeConcept | undefined =>
    nodes.reduce<TreeConcept | undefined>((found, node) => {
      if (found) {
        return found;
      }
      if (node.value === value) {
        return node;
      }
      return node.children
        ? findValue(node.children as TreeConcept[])
        : undefined;
    }, undefined);

  return findValue(treeData);
};

export const findValueByKeyInTreeData = (
  treeData: TreeConcept[],
  key: string,
): string | undefined => {
  const findValueByKey = (nodes: TreeConcept[]): string | undefined =>
    nodes.reduce<string | undefined>((found, node) => {
      if (found) return found;
      if (node.key === key) return node.value;
      return node.children
        ? findValueByKey(node.children as TreeConcept[])
        : undefined;
    }, undefined);

  return findValueByKey(treeData);
};
