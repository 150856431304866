import { dbconsole } from './dbconsole';

export class APIClient {
  private static requestQueue: number[] = [];

  private static readonly requestLimit = 20; // max requests per interval

  private static readonly interval = 1000; // 1 second

  private static async enforceRateLimit() {
    const now = Date.now();
    APIClient.requestQueue = APIClient.requestQueue.filter(
      timestamp => now - timestamp < APIClient.interval,
    );
    if (APIClient.requestQueue.length >= APIClient.requestLimit) {
      await new Promise(resolve =>
        setTimeout(
          resolve,
          APIClient.interval - (now - APIClient.requestQueue[0]),
        ),
      );
      APIClient.enforceRateLimit();
    } else {
      APIClient.requestQueue.push(now);
      dbconsole.warn(
        `Rate limit excceded: > ${APIClient.requestLimit}/${APIClient.interval}ms. Requests queued to avoid DoS attack`,
      );
    }
  }

  private default_headers: HeadersInit = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  private ontology: string | undefined;

  private csrfToken: string | undefined =
    document.querySelector<HTMLInputElement>('#csrf_token')?.value;

  constructor(options?: { ontology?: string; headers?: HeadersInit }) {
    this.ontology = options?.ontology;
    if (options?.headers) {
      this.default_headers = { ...this.default_headers, ...options.headers };
      if (options.headers['Content-Type'] === 'multipart/form-data') {
        delete this.default_headers['Content-Type']; // browser will set it automatically
      }
    }
  }

  private addExtraHeaders(headers: HeadersInit): HeadersInit {
    const updatedHeaders = { ...this.default_headers, ...headers };

    if (this.ontology) {
      updatedHeaders['X-Ontology'] = this.ontology.trim();
    }
    if (this.csrfToken) {
      updatedHeaders['X-CSRFToken'] = this.csrfToken;
    }

    return updatedHeaders;
  }

  public setOntology(ontology: string) {
    this.ontology = ontology;
  }

  public async fetch(
    url: string,
    options: RequestInit = {},
  ): Promise<Response> {
    const updatedOptions: RequestInit = {
      ...options,
      headers: this.addExtraHeaders({
        ...options.headers,
      }),
    };

    return this.fetchWith502Retries(url, updatedOptions);
  }

  public async fetchJson<T>(
    url: string,
    options: RequestInit = {},
  ): Promise<T> {
    const response = await this.fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  }

  public async fetchData<T>(
    url: string,
    options: RequestInit = {},
  ): Promise<T> {
    const result = await this.fetchJson<{ status: string; data: T }>(
      url,
      options,
    );
    if (result.status === 'success' && result.data) {
      return result.data;
    }
    console.error(`HTTP problem no data! Status: ${result.status}`, result);
    throw new Error(`HTTP problem no data! Status: ${result.status}`);
  }

  private async fetchWith502Retries(
    url: string,
    options: RequestInit = {},
    retries = 3,
    backoff = 200,
  ): Promise<Response> {
    // await APIClient.enforceRateLimit();
    const response = await fetch(url, options);
    if (!response.ok && response.status === 502 && retries > 0) {
      await new Promise(resolve => setTimeout(resolve, backoff));
      return this.fetchWith502Retries(url, options, retries - 1, backoff * 2);
    }
    return response;
  }

  private async *readLines(stream: ReadableStream<Uint8Array>) {
    const reader = stream.getReader();
    const textDecoder = new TextDecoder();
    let partialLine = '';

    try {
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await reader.read();

        if (done) {
          if (partialLine.length > 0) {
            yield partialLine;
          }
          break;
        }

        const chunk = textDecoder.decode(value, { stream: true });
        const lines = (partialLine + chunk).split('\n');

        if (chunk && chunk[chunk.length - 1] === '\n') {
          partialLine = '';
        } else {
          partialLine = lines.pop() || '';
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const line of lines) {
          yield line;
        }
      }
    } finally {
      reader.releaseLock();
    }
  }

  public async streamFetch<T>(
    url: string,
    options: RequestInit = {},
    callback: (data: T) => void,
  ): Promise<void> {
    const response = await this.fetch(url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (!response.body) {
      throw new Error(`HTTP problem no body! Status: ${response.status}`);
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const line of this.readLines(response.body)) {
      const trimmedLine = line.trim();
      if (trimmedLine) {
        const data = JSON.parse(trimmedLine);
        callback(data);
      }
    }
  }
}

export default APIClient;
