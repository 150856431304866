import mime from 'mime';
import { WorkspaceItem } from '@octostar/platform-types';
import { isPDFFile } from './PDFViewer';
import { isExcelFile } from './ExcelViewer';

const SAME_FILETYPES: Record<string, string> = {
  // TODO: add configuration support for this
  mpga: 'mp3',
};

export const getFileType = (item: WorkspaceItem) => {
  if (item?.os_item_name?.includes('.')) {
    const fileExtension = item.os_item_name.split('.').pop();
    if (fileExtension) {
      const mimeType = mime.getType(fileExtension);
      if (mimeType) {
        const fileType = mime.getExtension(mimeType);
        if (fileType) {
          if (SAME_FILETYPES[fileType]) {
            return SAME_FILETYPES[fileType];
          }
          return fileType;
        }
      }
    }
  }
  const fileType =
    mime.getExtension(item.os_item_content_type || 'text/plain') || 'txt';
  if (SAME_FILETYPES[fileType]) {
    return SAME_FILETYPES[fileType];
  }
  return fileType;
};

export const isZipFile = (fileType: string) => fileType === 'zip';

export const isSupportedPreviewFile = (fileType: string) =>
  !isExcelFile(fileType) && !isZipFile(fileType) && !isPDFFile(fileType);
