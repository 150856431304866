import * as React from 'react';
import { isEqual } from 'lodash';
import {
  WorkspaceItem,
  WorkspaceItemIdentifier,
} from '@octostar/platform-types';
import { DesktopAPI } from '../api/event-driven/desktop';
import { EPHEMERAL_ITEMS, MAGIC_FOLDER_OS_CONTENT_TYPE } from '../interface';

export const useCurrentWorkspaceItem = (item?: WorkspaceItemIdentifier) => {
  const [currentItem, setCurrentItem] = React.useState<
    WorkspaceItem | undefined
  >(item && typeof item === 'string' ? undefined : (item as WorkspaceItem));
  const [uid, setUid] = React.useState<string>();
  React.useEffect(() => {
    if (
      EPHEMERAL_ITEMS.includes((item as WorkspaceItem)?.os_item_type) ||
      [`${MAGIC_FOLDER_OS_CONTENT_TYPE}.os_concept`].includes(
        (item as WorkspaceItem)?.os_item_content?.os_item_content_type,
      )
    ) {
      setUid((item as WorkspaceItem).entity_id);
      setCurrentItem(item as WorkspaceItem);
      return;
    }
    const id = typeof item === 'string' ? item : item?.os_entity_uid;
    if (id !== uid) {
      setUid(id);
      setCurrentItem(undefined);
      if (id) {
        DesktopAPI.getItem(id)
          .then(setCurrentItem)
          .catch(e => {
            if ((item as WorkspaceItem)?.__uiState?.draft) {
              setCurrentItem(item as WorkspaceItem);
            } else {
              console.log(`problem fetching ${id}`, e);
            }
          });
      }
    }
  }, [item, uid]);

  React.useEffect(() => {
    if (!uid) return undefined;
    const promise = DesktopAPI.onWorkspaceItemChanged(uid, it =>
      setCurrentItem(curr => (isEqual(it, curr) ? curr : it)),
    );
    return () => {
      promise.then(unsub => unsub());
    };
  }, [uid]);

  return currentItem;
};
