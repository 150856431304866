import * as React from 'react';
import { Badge, Tag } from 'antd5';
import { DEFAULT_COLOR } from 'src/octostar/hooks/useCurrentWorkspaceItemColor';
import { WorkspaceItem } from '@octostar/platform-types';
import ConfiguredIcon from 'src/octostar/components/ConfiguredIcon';
import { EyeOutlined } from '@ant-design/icons';
import { useWatchersContext } from './WatchersContext';

export const WatcherIcon = ({ index }: { index: number }) => (
  <EyeOutlined
    style={{
      color: [
        '#722ed1',
        '#eb2f96',
        '#13c2c2',
        '#52c41a',
        '#faad14',
        '#fa8c16',
        '#fa541c',
        '#f5222d',
        '#a0d911',
        '#2f54eb',
      ][index % 10],
    }}
    className="octo-app-icon"
  />
);

export const WatchersCounterBadge = () => {
  const { intents } = useWatchersContext();
  const watchersCount = intents?.length || 0;
  return watchersCount ? <Badge color="blue" count={watchersCount} /> : <></>;
};

export const WatchersCounterDot = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { intents } = useWatchersContext();
  const watchersCount = intents?.length || 0;
  return (
    <Badge dot count={watchersCount} color="blue" offset={[0, 2]}>
      {children}
    </Badge>
  );
};

export const WatchIntentWorkspace = ({ value }: { value: WorkspaceItem }) => {
  const color = value?.os_item_content?.color || DEFAULT_COLOR;
  return value?.entity_label ? (
    <Tag color={color}>
      <ConfiguredIcon hint={value} />
      {value.entity_label}
    </Tag>
  ) : (
    <></>
  );
};
