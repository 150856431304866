import React from 'react';
// @ts-ignore
import FileViewer from 'react-file-viewer';
import { Space } from 'antd5';
import { Entity, WorkspaceItem } from '@octostar/platform-types';
import Loading from 'src/components/Loading';
import ee, { clientCore } from 'src/octostar/interface';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks';
import { TabNode } from 'flexlayout-react';
import _, { isEqual } from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import IconButton from 'src/dashboard/components/IconButton';
import {
  BranchesOutlined,
  CodeOutlined,
  StopOutlined,
  TagOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { BUILTIN_APPS } from './layout/builtins';
import DesktopAPI from '../api/event-driven/desktop';
import { useTabNode } from '../hooks/useTabNode';
import { getFileType } from './FileViewer/utils';
import MediaViewer, { MediaType, getMediaType } from './MediaViewer';
import { CodeViewer, isTextOrCodeFile } from './CodeViewer';
import { MarkdownViewer, isMarkdownFile } from './MarkdownViewer';
import ExcelViewer, { isExcelFile } from './FileViewer/ExcelViewer';
import PDFViewer, { isPDFFile } from './FileViewer/PDFViewer';
import { getPreference, setPreference } from '../api/preferences';
import { RichtextViewer } from './RichtextViewer';
import { WrapperRightSidebar } from './flexlayout/wrappers/WrapperRightSidebar';
import { useRightSidebarControllerContext } from './flexlayout/wrappers/WrapperRightSidebarContext';
import { useFocusList } from '../hooks/useFocusList';
import { Metadata } from './Entity/Metadata';
import { RelationshipsBorder } from './Entity/RelationshipsBorder';
import { Tags } from './Entity/TagsBorder';
import './UploadedFileViewer.css';
import { MermaidViewerLoader, isMermaidFile } from './mermaid/MermaidViewer';
import { MERMAID_CONTENT_TYPE } from '../lib/workspaceOperations';

type ScaleState = { scale?: number; positionX?: number; positionY?: number };
type InitialScaleState = {
  initialPositionX?: number;
  initialPositionY?: number;
  initialScale?: number;
};
const ErrorComponent = () => <div>Sorry. The file couldn't be displayed.</div>;
// TODO: get rid if the react-viewer
const PG_VIEWER_CONTAINER_ID = 'pg-viewer';

const borderTabs = [
  {
    name: 'Relationships',
    component: () => <RelationshipsBorder />,
    icon: <BranchesOutlined />,
  },
  {
    name: 'Tags',
    component: () => <Tags />,
    icon: <TagOutlined />,
  },
  {
    name: 'Metadata',
    component: () => <Metadata />,
    icon: <CodeOutlined />,
  },
];

export const UploadedFileViewer = ({
  item,
  node,
  defaultWidth,
  defaultHeight,
  preview,
  setEntities,
}: {
  item: WorkspaceItem;
  node?: TabNode;
  defaultWidth?: number;
  defaultHeight?: number;
  preview?: boolean;
  setEntities?: React.Dispatch<React.SetStateAction<Entity[]>>;
}) => {
  const { width, height } = useTabNode({ node });
  const [fileType, setFileType] = React.useState(getFileType(item));
  const [mediaType, setMediaType] = React.useState<MediaType | null>();
  const [urlType, setUrlType] = React.useState<MediaType | null>();
  const [initialScaleState, setInitialScaleState] =
    React.useState<InitialScaleState>();
  const [showError, setShowError] = React.useState(false);

  useFocusList([item]);

  React.useEffect(() => {
    if (item) {
      const newValue = [item];
      setEntities?.(curr => (isEqual(curr, newValue) ? curr : newValue));
    }
  }, [item, setEntities]);

  React.useEffect(() => {
    const fileType = getFileType(item);
    setFileType(fileType);
    setMediaType(getMediaType(fileType) || null);
    if (
      (item as any)?.url &&
      ['image', 'video', 'audio'].includes(item.entity_type)
    ) {
      setUrlType(item.entity_type as MediaType);
    }
  }, [item]);

  React.useEffect(() => {
    if (!item?.os_entity_uid) {
      return;
    }
    getPreference<InitialScaleState>(
      `fileViewerScale#${item.os_entity_uid}`,
      {},
    ).then(setInitialScaleState);
  }, [item?.os_entity_uid]);

  React.useEffect(() => {
    // todo: get rid of the react-viewer
    document
      .querySelectorAll(`#${PG_VIEWER_CONTAINER_ID}`)
      .forEach((el, index) => {
        el.setAttribute('id', `${PG_VIEWER_CONTAINER_ID}_renamed_${index}`);
      });
  }, []);

  const saveScaleState = React.useCallback(
    _.debounce((ref: any, state: ScaleState) => {
      if (!item?.os_entity_uid) {
        return;
      }
      const st: InitialScaleState = {
        initialScale: state.scale,
        initialPositionX: state.positionX,
        initialPositionY: state.positionY,
      };
      setPreference(`fileViewerScale#${item.os_entity_uid}`, st);
    }, 1000),
    [item],
  );

  const onError = React.useCallback(
    (e: any) => {
      console.log('error in file-viewer', e);
      DesktopAPI.showToast({
        level: 'error',
        message: `Failed to display ${
          item?.os_item_name || item?.entity_label
        }`,
        description: 'Check logs for more details',
      });
      DesktopAPI.closeTab({
        app: BUILTIN_APPS.fileViewer,
        item,
      });
      setShowError(true);
    },
    [item],
  );

  if (showError) {
    return <ErrorComponent />;
  }
  if (!fileType || mediaType === undefined) {
    return <Loading />;
  }
  if (fileType === 'rtf') {
    return <RichtextViewer item={item} node={node} />;
  }
  if (
    isMermaidFile(fileType) ||
    item.os_item_content_type === MERMAID_CONTENT_TYPE
  ) {
    return <MermaidViewerLoader item={item} node={node as TabNode} />;
  }
  if (isMarkdownFile(fileType)) {
    return <MarkdownViewer workspaceItem={item} node={node} />;
  }
  if (isExcelFile(fileType)) {
    return <ExcelViewer item={item} fileType={fileType} />;
  }
  if (isPDFFile(fileType)) {
    return (
      <PDFViewer
        pdfURL={`/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${item.os_entity_uid}?content_disposition=inline&content_type=application/pdf`}
        defaultWidht={defaultWidth || width}
        defaultHeight={defaultHeight || height}
      />
    );
  }
  if (isTextOrCodeFile(fileType, item) && !urlType) {
    return (
      <CodeViewer
        item={item}
        height={defaultHeight || height}
        fileType={
          item.os_item_content_type?.startsWith('text/') ? 'text' : fileType
        }
      />
    );
  }
  if (!initialScaleState) {
    return <Loading />;
  }
  return (
    <div
      style={{
        width: defaultWidth || width,
        height: defaultHeight || height,
      }}
    >
      <TransformWrapper
        {...initialScaleState}
        centerOnInit
        onTransformed={saveScaleState}
      >
        {({ zoomIn, zoomOut, setTransform }) => (
          <div className="upload-file-viewer-content">
            <div
              className={`upload-file-viewer-controls ${
                setEntities ? 'upload-file-viewer-controls-right-sidebar' : ''
              }`}
            >
              <Space>
                <IconButton
                  onClick={() => zoomIn()}
                  icon={<ZoomInOutlined />}
                />
                <IconButton
                  onClick={() => zoomOut()}
                  icon={<ZoomOutOutlined />}
                />
                <IconButton
                  onClick={() => setTransform(0, 0, 1)}
                  icon={<StopOutlined />}
                />
              </Space>
            </div>
            <TransformComponent>
              <div
                style={{
                  width: defaultWidth || width,
                  height: defaultHeight || height,
                }}
              >
                <div className="upload-file-viewer">
                  {urlType && !mediaType && (
                    <MediaViewer
                      type={urlType}
                      src={(item as any).url}
                      alt={item.os_item_name}
                      width={defaultWidth || width}
                      height="100%"
                      onError={onError}
                      preview={preview}
                    />
                  )}
                  {mediaType && !urlType && (
                    <MediaViewer
                      type={mediaType}
                      src={`/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${item.os_entity_uid}`}
                      alt={item.os_item_name}
                      width="100%"
                      height="100%"
                      onError={onError}
                      preview={preview}
                    />
                  )}
                  {!urlType && !mediaType && fileType && (
                    <div
                      id={PG_VIEWER_CONTAINER_ID}
                      style={{
                        width: defaultWidth || width,
                        height: defaultHeight || height,
                      }}
                    >
                      <FileViewer
                        key={item.os_entity_uid}
                        fileType={fileType}
                        filePath={`/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${item.os_entity_uid}`}
                        errorComponent={ErrorComponent}
                        onError={onError}
                      />
                    </div>
                  )}
                </div>
              </div>
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>
    </div>
  );
};

const UploadedFileViewerWithContext = ({
  item,
  node,
}: {
  item: WorkspaceItem;
  node: TabNode;
}) => {
  const { setEntities } = useRightSidebarControllerContext();
  return (
    <UploadedFileViewer
      item={item}
      node={node}
      setEntities={setEntities}
      preview={false}
    />
  );
};

export const UploadedFileViewerLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return (
    <WrapperRightSidebar borderTabs={borderTabs} withEntities>
      <UploadedFileViewerWithContext item={workspaceItem} node={node} />
    </WrapperRightSidebar>
  );
};
export const registerUploadedFileViewer = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.fileViewer.os_entity_uid,
    factory: (node: TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <UploadedFileViewerLoader item={item} node={node} />;
    },
  });
};
