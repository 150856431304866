import { DataMask, makeApi, t } from '@superset-ui/core';
import { Datasource } from 'src/dashboard/types';
import { Chart } from 'src/types/Chart';
import { useDashboard } from 'src/hooks/apiResources';
import { WorkspaceItem } from '@octostar/platform-types';
import { Dispatch } from 'redux';
import { updateDataMask } from 'src/dataMask/actions';
import shortid from 'shortid';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { mergeDashboards, deDupeDatasets, Filter } from './mergeDashboards';

type Resources = Dashboard | Chart[] | Datasource[];

type VirtualResource = {
  idsOrSlugs: (string | number)[];
  filters: Filter[];
  crossFilters: DataMask[];
};

type ErrorResponse = { error: Error };
function isErrorResponse(obj: any): obj is ErrorResponse {
  return (obj as ErrorResponse).error !== undefined;
}

type Dashboard = ReturnType<typeof useDashboard>['result'];

const getDashboardEndpoint = (idOrSlug: string | number) =>
  `/api/v1/dashboard/${idOrSlug}`;

const getResourceEndpoint = (idOrSlug: string | number, resource: string) =>
  `/api/v1/dashboard/${idOrSlug}/${resource}`;

const extractResult = (res: any) => res.result;

export const setDashboardSlug = (id: number, slug: string): Promise<string> =>
  makeApi<{}, { result: Dashboard }>({
    method: 'GET',
    endpoint: getDashboardEndpoint(id),
  })({}).then(({ result }) => {
    if (result?.slug) {
      return result.slug;
    }
    return makeApi<Partial<Dashboard>, { result: Dashboard }>({
      method: 'PUT',
      endpoint: getDashboardEndpoint(id),
    })({ slug })
      .then(() => slug)
      .catch(e => {
        console.error(`could not set slug for dashboard ${id}`, e);
        return `${id}`;
      });
  });

function handleErrors<T>(response: Array<T | ErrorResponse>): T[] {
  return response.reduce<T[]>((acc, val) => {
    if (isErrorResponse(val)) {
      console.error(val);
      DesktopAPI.showToast({
        level: 'error',
        message: t(`Failed to load resource`),
        description: 'Check logs for more details',
      });
    } else {
      acc.push(val);
    }
    return acc;
  }, []);
}

async function fetchVirtualResources<T>(
  endpointsToHit: string[],
  processResponse = (result: T[], index: number) => result,
): Promise<T[]> {
  const promises = endpointsToHit.map((endpoint, index) =>
    makeApi<{}, T>({
      method: 'GET',
      endpoint,
    })({})
      .then(extractResult)
      .then(result => processResponse(result, index))
      .catch(e => [{ error: e }]),
  );

  const objects = await Promise.all(promises);
  return handleErrors<T>(objects.flat());
}

export function encodeVirtualResource(
  dashboards: WorkspaceItem[],
  filters: Filter[] = [],
  crossFilters: DataMask[] = [],
) {
  const virtualResources: VirtualResource = {
    idsOrSlugs: dashboards.map(ele => ele.os_item_content.idOrSlug),
    filters,
    crossFilters,
  };
  return `virtualized:${encodeURIComponent(JSON.stringify(virtualResources))}`;
}

export function decodeVirtualResource(
  virtualizedPath: string,
): VirtualResource {
  const [token, virtualResources] = virtualizedPath.split(/:(.*)/s);
  if (token !== 'virtualized')
    throw new Error(`Malformed virtual path: ${virtualizedPath}`);

  return JSON.parse(decodeURIComponent(virtualResources));
}

function extractIdOrSlugs(virtualizedPath: string): (string | number)[] {
  return decodeVirtualResource(virtualizedPath).idsOrSlugs;
}

// TODO: Add generics?
export async function handleVirtualResource(
  virtualEndPoint: string,
  dispatch: Dispatch<any>,
): Promise<{ result: Resources }> {
  const parts = virtualEndPoint.split('/');
  const endpointType = parts.pop();
  if (!endpointType) throw new Error(`No endpoint found: ${virtualEndPoint}`);

  if (endpointType === 'charts') {
    const dashIdOrSlugs = extractIdOrSlugs(parts.pop() as string);
    const endpointsToHit = dashIdOrSlugs.map(ele =>
      getResourceEndpoint(ele, endpointType),
    );
    const processResponse = (result: Chart[], index: number) =>
      result.map(ele => {
        // eslint-disable-next-line no-param-reassign
        ele.originDashIdOrSlug = dashIdOrSlugs[index];
        return ele;
      });

    const charts = await fetchVirtualResources<Chart>(
      endpointsToHit,
      processResponse,
    );
    return { result: charts };
  }
  if (endpointType === 'datasets') {
    const endpointsToHit = extractIdOrSlugs(parts.pop() as string).map(ele =>
      getResourceEndpoint(ele, endpointType),
    );

    const datasources = await fetchVirtualResources<Datasource>(endpointsToHit);
    return { result: deDupeDatasets(datasources) };
  }

  const { idsOrSlugs, filters, crossFilters } =
    decodeVirtualResource(endpointType);
  const endpointsToHit = idsOrSlugs.map(ele => getDashboardEndpoint(ele));

  const dashboards = await fetchVirtualResources<Dashboard>(endpointsToHit);
  const mergedDashboard = mergeDashboards(dashboards, filters);
  crossFilters.forEach(filter => {
    dispatch(updateDataMask((filter as any).id || shortid(), filter));
  });
  if (!mergedDashboard) throw new Error('No dashboards to merge');

  return { result: mergedDashboard };
}
