import {
  WorkspaceItem,
  DesktopActionMenuItem,
  DesktopActionContext,
  DraftWorkspaceItem,
  Workspace,
  WorkspaceItemTitleProps,
} from '@octostar/platform-types';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { newWorkspaceItem } from 'src/octostar/lib/handy';
import mime from 'mime';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import _, { set } from 'lodash';
import { DataMaskWithId } from '@superset-ui/core';
import SqlString from 'sqlstring';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import {
  WORKSPACE_RECORDS_MAGIC_FOLDER,
  MAGIC_FOLDER_OS_CONTENT_TYPE,
  OS_DRAFT_WORKSPACE,
  VIRTUAL_ITEM_TYPE,
  clientCore,
  isFile,
} from '../interface';
import { BUILTIN_APPS } from '../components/layout/builtins';
import { asSavedSearch, isSavedSearch } from '../components/SavedSearch/utils';
import { touchFile } from './S3Operations';
import { getConceptIcon } from '../components/ConfiguredIcon';
import { DATASET_CONTENT_TYPE } from '../components/DatasetViewer/constants';

export const acceptsAnyWorkspaceItem = () => true;
export const all =
  (...filters: ((props: DesktopActionContext) => boolean)[]) =>
  (props: DesktopActionContext) =>
    filters.every(x => x(props));
export const any =
  (...filters: ((props: DesktopActionContext) => boolean)[]) =>
  (props: DesktopActionContext) =>
    filters.some(x => x(props));

export const acceptsTypes =
  (types: string[], multi?: boolean) => (props: DesktopActionContext) => {
    const filter = (item: WorkspaceItem) =>
      [item.entity_type, item.os_item_type, item.os_item_content_type].some(
        t => types.indexOf(t as string) >= 0,
      );
    if (props.item) {
      return filter(props.item);
    }
    if (multi && props.items) {
      return props.items.every(filter);
    }
    return false;
  };
export const acceptsWorkspacesAndFolders = acceptsTypes([
  'os_workspace',
  'os_folder',
]);
export const not =
  (filter: (props: DesktopActionContext) => boolean) =>
  (props: DesktopActionContext) =>
    !filter(props);

export const isNotOsSomething = (props: DesktopActionContext) => {
  if (props.item?.os_item_type?.startsWith('os_')) {
    return false;
  }

  if (
    (props.items || []).filter((x: WorkspaceItem) =>
      x.os_item_type?.startsWith('os_'),
    ).length
  ) {
    return false;
  }
  return true;
};
export const isNotDraft = (props: DesktopActionContext) => {
  if ((props.item as DraftWorkspaceItem)?.os_draft_item) {
    return false;
  }
  return true;
};
export const hasOsItemContentEntities = (x: any) =>
  x?.item?.os_item_content?.entities;

export const acceptsOsItemSavedSearch = (x: any) => isSavedSearch(x?.item);

export const acceptsNotTypes =
  (types: string[]) => (props: DesktopActionContext) =>
    !acceptsTypes(types)(props);

const getExtension = (item?: WorkspaceItem) =>
  mime.getExtension(item?.os_item_content_type || 'unknown') || 'nope';

export const MARKDOWN_EXTENSIONS = ['md', 'markdown'];
export const MARKDOWN_EDIT_EXTENSIONS = ['md', 'markdown'];
export const MERMAID_EXTENSIONS = ['mmd', 'mermaid'];
export const MERMAID_CONTENT_TYPE = 'text/vnd.mermaid';
export const EXCEL_EXTENSIONS = ['xls', 'xlsx', 'csv'];
export const OS_RICHTEXT_CONTENT_TYPE = 'text/os_richtext';
export const isRichText = (props: DesktopActionContext) =>
  (props as DesktopActionContext).item?.os_item_content_type ===
  OS_RICHTEXT_CONTENT_TYPE;
export const acceptsFile =
  (multi?: boolean) => (props: DesktopActionContext) => {
    if (props.item) {
      return isFile(props.item);
    }
    if (multi && props.items) {
      return props.items.every(isFile);
    }
    return false;
  };
export const isAcceptable =
  (extensions: string[]) => (props: DesktopActionContext) => {
    if (!acceptsFile()(props)) {
      return false;
    }
    const extension = getExtension((props as DesktopActionContext).item);
    if (extensions.includes(extension)) {
      return true;
    }
    const fileExtension = props.item?.os_item_name?.split('.')?.pop();
    return extensions.includes((fileExtension || '').toLocaleLowerCase());
  };

export const isMermaid = (props: DesktopActionContext) =>
  (props as DesktopActionContext).item?.os_item_content_type ===
    MERMAID_CONTENT_TYPE || isAcceptable(MERMAID_EXTENSIONS)(props);
export const isTextEditable = (props: DesktopActionContext) => {
  if (!acceptsFile(true)(props)) {
    return false;
  }
  if (
    isAcceptable(MARKDOWN_EDIT_EXTENSIONS)(props) ||
    isRichText(props) ||
    isMermaid(props)
  ) {
    return false;
  }
  const txtTypes =
    `.as .asp .aspx .bat .c .cc .cfg .coffee .conf .cpp .cs .css .csv .cxx .diff .dockerfile 
            .ejs .f .go .graphql .groovy .h .handlebars .hh .hpp .htm .html .htaccess .hxx .ini .java 
            .jar .jenkinsfile .js .json .jsp .jsx .less .log .lua .m .makefile .markdown .md-SKIP .mdx .mm 
            .njs .npmignore .php .pl .properties .proto .py .rb .rc .rs .rss .rst .sass .scala .scss 
            .sh .sln .sql .styl .svg .swift .tcl .tex .tgz .toml .ts .tsx .txt .vb .vbs .vm .vue 
            .webmanifest .xml .xsl .yaml .yml`
      .split(/\s+/)
      .map(x => x.replace('.', ''));
  const filter = (item: WorkspaceItem) =>
    txtTypes.indexOf(getExtension(item)) >= 0 ||
    txtTypes.indexOf(
      (item.os_item_name.split('.').pop() || 'nope').toLocaleLowerCase(),
    ) >= 0;

  if (props.item) {
    return filter(props.item);
  }
  if (props.items) {
    return props.items.every(filter);
  }
  return false;
};

export const notImplemented = async (props: DesktopActionContext) => {
  if (props.item) {
    DesktopAPI.showToast(
      `feature not implemented for ${props.item.os_item_name}`,
    );
  } else {
    DesktopAPI.showToast(`The feature is not implemented`);
  }
};

export const removeFileExtension = (filename: string) => {
  const lastIndex = filename.lastIndexOf('.');

  if (lastIndex > 0) {
    return {
      baseName: filename.substring(0, lastIndex),
      extension: filename.substring(lastIndex + 1),
    };
  }
  return {
    baseName: filename,
    extension: '',
  };
};

const extractNumberFromFilename = filename => {
  const regex = /\((\d+)\)/;
  const match = filename.match(regex);
  return match ? parseInt(match[1], 10) : null;
};

export const getUniqueName = async (
  os_parent_folder: string,
  os_item_name: string,
) => {
  const fileName = removeFileExtension(os_item_name);

  const query = `SELECT os_item_name FROM timbr.os_wsfs_object WHERE os_parent_folder=${SqlString.escape(
    os_parent_folder,
  )} AND os_item_name LIKE ${SqlString.escape(`${fileName.baseName}%`)}`;

  const result = await OntologyAPI.sendQueryT<{ os_item_name: string }>(query);

  const isOriginalNameUsed = result.some(
    item => item.os_item_name === os_item_name,
  );
  if (!isOriginalNameUsed) {
    return os_item_name;
  }

  const numbers = result
    .map(item => extractNumberFromFilename(item.os_item_name))
    .filter(num => num !== null)
    .sort((a, b) => a - b);

  const maxNumber = numbers.length > 0 ? numbers[numbers.length - 1] : 0;

  const newName = `${fileName.baseName} (${maxNumber + 1}).${
    fileName.extension
  }`;
  return newName;
};

export const simpleAddNewItemToFolder =
  (
    settings: Partial<WorkspaceItem> & {
      os_item_type: string;
      os_item_name: string;
    },
    options?: {
      draft?: boolean;
      // Is ignored if draft = true
      touchS3?: boolean;
      renameOnCreate?: boolean;
      doNotOpen?: boolean;
      initialContent?: string;
      openWith?: string;
    },
  ) =>
  async (props: DesktopActionContext) => {
    const clonedSettings = _.cloneDeep(settings);
    const newName = await getUniqueName(
      props.item?.entity_id as string,
      settings.os_item_name,
    );

    const openFolderMaybe = async (wi: WorkspaceItem) => {
      if (clonedSettings.os_item_type !== 'os_folder' && !options?.doNotOpen) {
        DesktopAPI.open(wi, { with: options?.openWith });
      }
      if (wi.os_parent_folder) {
        setTimeout(() => {
          props.ee.emit(clientCore('expandFolder'), wi.os_parent_folder);
        }, 500);
      }
    };
    clonedSettings.os_entity_uid = crypto.randomUUID();
    clonedSettings.entity_id = clonedSettings.os_entity_uid;
    clonedSettings.entity_label =
      newName || clonedSettings.entity_label || clonedSettings.os_item_name;
    clonedSettings.os_item_name =
      newName || clonedSettings.entity_label || clonedSettings.os_item_name;
    clonedSettings.os_workspace = props.item!.os_workspace;
    clonedSettings.os_parent_folder = props.item!.os_entity_uid;
    if (
      props.item!.entity_type === 'os_workspace' &&
      !clonedSettings.os_workspace
    ) {
      clonedSettings.os_workspace = props.item!.os_entity_uid;
    }

    if (options?.renameOnCreate) {
      set(clonedSettings, '__uiState.rename', true);
    }

    props.ee.emit(clientCore('expandFolder'), props.item!.os_entity_uid);
    const promise = options?.initialContent
      ? DesktopAPI.saveFile(clonedSettings, options.initialContent)
      : DesktopAPI.save(clonedSettings, { draft: options?.draft })
          .then(async (wi: WorkspaceItem) => {
            if (!options?.draft && options?.touchS3 && wi['#uploadSecret']) {
              await touchFile(wi);
            }
            // DesktopAPI.showToast(`created ${wi.os_item_name}`);
            openFolderMaybe(wi);
            return wi;
          })
          .catch(e => {
            if (e) {
              console.log(e);
              DesktopAPI.showToast({
                message: `saving item failed, see log for details`,
                level: 'error',
              });
            } else {
              DesktopAPI.showToast({
                message: `saving item failed, for an unknown reason`,
                level: 'error',
              });
            }
            return undefined;
          });

    if (!options?.doNotOpen) {
      setTimeout(() => {
        openFolderMaybe(clonedSettings as WorkspaceItem);
      }, 200);
    }
    return promise;
  };
export const invokeShowTab =
  (
    app: WorkspaceItem,
    icon?: string,
    setInitialState?: (item: WorkspaceItem) => Record<string, any>,
  ) =>
  async ({ ee, item, options }: DesktopActionContext, renameTab?: boolean) => {
    if (item && renameTab) {
      set(item, '__uiState.rename', true);
    }

    if (item && setInitialState) {
      set(item, '__uiState.initialState', setInitialState(item));
    }
    const os_icon = icon || item?.os_icon;
    // TODO: fix the type
    ee.emit(BUILTINS_MESSAGE_TYPES.showTab, {
      app,
      item: { ...item, os_icon },
      options,
    });
  };

export const openDatasetViewer =
  ({
    concept,
    item,
    items,
    workspace,
    ee,
    options,
    crossFilters,
    initialCount,
  }: DesktopActionContext & {
    crossFilters?: DataMaskWithId[];
    initialCount?: number;
  }) =>
  async () => {
    // const entities = item ? [item] : items || undefined;
    const { os_item_content, __uiState, entity_id, os_entity_uid, ...rest } =
      item || {};
    try {
      const wo = asSavedSearch({
        item: newWorkspaceItem({
          ...{
            os_item_name: concept || 'Dataset',
            os_icon: await getConceptIcon(concept),
          },
          ...rest,
          os_item_type: VIRTUAL_ITEM_TYPE,
          os_item_content_type: DATASET_CONTENT_TYPE,
          os_workspace:
            workspace?.workspace.os_entity_uid || OS_DRAFT_WORKSPACE,
          __uiState: {
            draft: true,
          },
        }),
        crossFilters,
        concept,
        initialCount,
      });
      invokeShowTab(BUILTIN_APPS.datasetViewer)({ ee, item: wo, options });
    } catch (e) {
      console.log(`Error opening dataset ${concept}`, e);
      DesktopAPI.showToast({
        message: 'Error opening dataset',
        description: `The dataset viewer for ${concept} could not be opened. See log for details`,
        level: 'error',
      });
    }
  };
export const openRecordEditor =
  ({ item, items, workspace, ee, options }: DesktopActionContext) =>
  async () => {
    const entity = item || (items?.length === 1 ? items[0] : undefined);
    const wo = entity
      ? newWorkspaceItem({
          ...entity,
          os_item_name: entity?.entity_label || 'Record Viewer',
          os_icon: entity.os_icon || (await getConceptIcon(entity.entity_type)),
          os_workspace: entity.os_workspace || OS_DRAFT_WORKSPACE,
        })
      : newWorkspaceItem({
          os_item_type: VIRTUAL_ITEM_TYPE,
          os_item_content_type: DATASET_CONTENT_TYPE,
          os_item_name: 'Record Viewer',
          os_workspace:
            workspace?.workspace.os_entity_uid || OS_DRAFT_WORKSPACE,
          os_item_content: { entities: items },
        });
    return invokeShowTab(BUILTIN_APPS.recordeditor)({ ee, item: wo, options });
  };

let linkchartCounter = 0;
export const openNewLinkChart =
  ({ item, items, workspace, ee, options }: DesktopActionContext) =>
  async () => {
    const entities = items || [item] || undefined;
    const wo = newWorkspaceItem({
      os_item_type: 'os_linkchart',
      os_item_name: `New Linkchart ${(linkchartCounter += 1)}`,
      os_workspace: workspace?.workspace.os_entity_uid || OS_DRAFT_WORKSPACE,
      os_parent_folder:
        workspace?.workspace.os_entity_uid || OS_DRAFT_WORKSPACE,
      os_item_content: { addToGraph: { nodes: entities } },
    });
    const wi = await DesktopAPI.save(wo, { draft: true });
    return invokeShowTab(BUILTIN_APPS.linkcharteditor)({
      ee,
      item: wi,
      options,
    });
  };

export const isMagicTemplatesFolder = (props: {
  item?: WorkspaceItem;
  workspace?: Workspace;
}) =>
  props.item?.os_item_type === 'os_folder' &&
  ['templates', '.templates'].indexOf(
    `${props.item.os_item_name}`.toLocaleLowerCase(),
  ) >= 0 &&
  props.item?.os_parent_folder === props.workspace?.workspace.os_entity_uid;

export const isMagicTempatesConceptFolder = (props: {
  item?: WorkspaceItem;
  workspace?: Workspace;
}) => {
  if (props.item?.os_item_type !== 'os_folder') {
    return false;
  }
  const parent = props.workspace?.items
    .filter(x => x.os_entity_uid === props.item?.os_parent_folder)
    .shift();
  if (!parent) {
    return false;
  }
  return isMagicTemplatesFolder({ ...props, item: parent });
};

export const isMagicFolder = (props: DesktopActionContext) => {
  if (props.item?.os_item_type !== 'os_folder') {
    return false;
  }
  if (
    `${props.item?.os_item_content_type}`.startsWith(
      MAGIC_FOLDER_OS_CONTENT_TYPE,
    )
  ) {
    return true;
  }

  if (
    props.item?.os_parent_folder !== props.workspace?.workspace.os_entity_uid
  ) {
    return false;
  }

  return isMagicTempatesConceptFolder(props);
};

export const isSchemaYaml = (props: DesktopActionContext) => {
  if (!isFile(props.item)) {
    return false;
  }
  if (props.item!.os_item_content_type !== 'application/yaml') {
    return false;
  }
  if (
    !props.item!.os_item_name.startsWith('schema') ||
    !props.item!.os_item_name.endsWith('.yaml')
  ) {
    return false;
  }
  const parent = props.workspace?.items
    .filter(x => x.os_entity_uid === props.item?.os_parent_folder)
    .shift();
  if (!parent) {
    return false;
  }
  return isMagicTempatesConceptFolder({ ...props, item: parent });
};

export const isWorkspaceOrNonMagicFolder = all(
  acceptsTypes(['os_folder', 'os_workspace']),
  not(isMagicFolder),
);
export const isNonMagicFolder = all(
  acceptsTypes(['os_folder']),
  not(isMagicFolder),
);

const filesDontExist =
  (...names: string[]) =>
  (props: DesktopActionContext) => {
    const { workspace } = props as DesktopActionContext;
    return (
      workspace?.items?.filter(
        x => names.indexOf(`${x.os_item_name}`.toLocaleLowerCase()) >= 0,
      ).length === 0
    );
  };
export const RENAME_ACTION: DesktopActionMenuItem = {
  label: 'Rename',
  key: 'rename',
  icon: 'fa-pen-to-square',
  accepts: all(
    not(isMagicFolder),
    acceptsNotTypes([VIRTUAL_ITEM_TYPE]),
    not(({ graph }) => !!graph),
  ),
  invoke: async (props: DesktopActionContext) =>
    props.ee.emit(
      clientCore(`clickRename/${props.item!.os_entity_uid}`),
      props,
    ),
};

export const deleteItemWithNotification = (
  item: WorkspaceItem | WorkspaceItem[],
  recurse?: boolean,
) => {
  DesktopAPI.delete(item!, recurse).catch(e => {
    console.log(e);
    DesktopAPI.showToast({
      message: `deleting item failed, see log for details`,
      level: 'error',
    });
  });
};

export const acceptsWorkspaceRecordsMagicFolder = (
  props: WorkspaceItemTitleProps,
) => {
  if (props.item?.os_item_content_type !== WORKSPACE_RECORDS_MAGIC_FOLDER) {
    return false;
  }
  if (props.workspace?.workspace_records) {
    const workspaceRecords = props.workspace.workspace_records;
    return (
      Object.values(workspaceRecords)
        .map(x => x.count || 0)
        .reduce((a, b) => a + b, 0) > 0
    );
  }
  return false;
};

export const collectWorkspaceRecords = (
  workspace: Workspace,
  concepts?: Set<string>,
): WorkspaceItem[] =>
  Object.values(workspace.workspace_records || {})
    .filter(x => !concepts || concepts.has(x.concept))
    .map(info => info.entities || [])
    .flat()
    .map(x => ({
      ...x,
      os_item_type: x.entity_id,
      os_item_name: x.entity_label,
      os_workspace: workspace.workspace.entity_id,
      os_entity_uid: x.entity_id,
    }));
