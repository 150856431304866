import { Rect, TabNode } from 'flexlayout-react';
import * as React from 'react';

export const useTabNode = ({ node }: { node?: TabNode | undefined }) => {
  const [height, setHeight] = React.useState<number>(
    node ? node.getRect()?.height : 0,
  );
  const [width, setWidth] = React.useState<number>(
    node ? node?.getRect()?.width : 0,
  );
  React.useEffect(() => {
    if (!node) return;
    node.setEventListener('resize', ({ rect }: { rect: Rect }) => {
      setWidth(rect.width);
      setHeight(rect.height);
    });
  }, [node]);
  return { width, height };
};
