import * as React from 'react';
import { Concept, Entity, WorkspaceItem } from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { useRightSidebarControllerContext } from '../flexlayout/wrappers/WrapperRightSidebarContext';
import { BorderTabHead } from '../flexlayout/wrappers/BorderTabHead';
import { EmptyBorder } from '../flexlayout/wrappers/EmptyBorder';
import Relationships from '../Relationships/Relationships';
import './BorderTab.css';

const EntityRelationships = ({
  entity,
  updateTabCounter,
}: {
  entity: Entity;
  updateTabCounter: (badgeCounter?: number | undefined) => void;
}) => {
  const [concept, setConcept] = React.useState<Concept | undefined>(undefined);

  React.useEffect(() => {
    if (entity?.entity_type) {
      OntologyAPI.getConceptByName(entity.entity_type).then(concept => {
        setConcept(concept);
      });
    }
  }, [entity?.entity_type]);

  if (!concept) {
    return null;
  }

  return (
    <div className="flexlayout-border-tab-item">
      <div className="flexlayout-border-tab-item-header">
        <span className="flexlayout-border-tab-item-title">
          <span className="flexlayout-border-tab-item-label">{`${entity.entity_label}`}</span>
        </span>
      </div>
      <Relationships
        entity={entity}
        os_workspace={(entity as WorkspaceItem).os_workspace}
        updateTabCounter={updateTabCounter}
      />
    </div>
  );
};

export const RelationshipsBorder = () => {
  const { entities, setTabCounter, setTabContent, detailsTabsContentMap } =
    useRightSidebarControllerContext();

  const updateTabCounter = React.useCallback(
    (count?: number | undefined) => {
      if (!detailsTabsContentMap.has('Relationships')) {
        const content = <RelationshipsBorder />;
        setTabContent('Relationships', content, count);
      } else if (
        detailsTabsContentMap.get('Relationships')?.badgeCounter !== count
      ) {
        setTabCounter('Relationships', count);
      }
    },
    [detailsTabsContentMap, setTabContent, setTabCounter],
  );

  return (
    <div title="Relationships">
      <BorderTabHead
        title="Relationships"
        tabName="Relationships"
        showCloseBorderButton
        items={[]}
      />
      {entities.length === 0 ? (
        <EmptyBorder description="Select any nodes to see all their relationships" />
      ) : (
        <>
          {entities.map(entity => (
            <div
              className="flexlayout-border-tab-container"
              key={entity.entity_id}
            >
              <EntityRelationships
                key={entity.entity_id}
                entity={entity}
                updateTabCounter={updateTabCounter}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
