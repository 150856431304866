import { Entity, OsWorkspaceEntity } from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { RelationshipCountStatus } from './types';

export const withEntityRelationshipCounts = async (
  entity: Entity,
  setStatus: (status: RelationshipCountStatus) => void,
) => {
  OntologyAPI.getRelationshipsForEntity(entity).then(relationships => {
    relationships.map(async relationship => {
      const status = {
        entity_id: entity.entity_id,
        relationship,
        count: 0,
        fetching: true,
      };
      try {
        setStatus(status);
        let counts = await OntologyAPI.getRelationshipCount(
          entity,
          relationship,
        );
        status.count = counts.count;
        setStatus({
          ...status,
          fetching: !!counts.expired,
        });
        if (counts.expired) {
          counts = await OntologyAPI.getRelationshipCount(
            entity,
            relationship,
            true,
          );
          status.count = counts.count;
          setStatus({ ...status, fetching: false });
        }
      } catch (e) {
        console.log('Error getting relationship count', e);
        setStatus({ ...status, fetching: false });
      }
    });
  });
};

export const parseFormData = (
  formData: any,
  entity: OsWorkspaceEntity,
  labelKeys: string[],
  os_workspace?: string,
): OsWorkspaceEntity => {
  const newEntity: any = {};
  newEntity.os_entity_uid = entity?.entity_id || crypto.randomUUID();
  if (!entity?.entity_id) {
    newEntity.entity_id = newEntity.os_entity_uid;
  }
  let entityLabel = '';
  if (!entity.entity_label || entity?.entity_label === '') {
    labelKeys?.forEach((key, index) => {
      if (formData?.[key]) {
        entityLabel += formData?.[key];
        if (index < labelKeys.length - 1) {
          entityLabel += ' ';
        }
      }
    });
  }
  // Set undefined values to null
  Object.keys(formData).forEach((key: string) => {
    if (typeof formData[key] === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      formData[key] = null;
    }
  });
  return {
    ...formData,
    ...newEntity,
    entity_id: newEntity?.entity_id || entity.entity_id,
    entity_label: newEntity?.entity_label || entityLabel,
    entity_type: entity.entity_type,
    os_entity_uid: newEntity?.os_entity_uid,
    os_workspace: entity?.os_workspace || os_workspace,
  };
};

export const asPanelLabel = (property: string) =>
  property.charAt(0).toUpperCase() + property.slice(1).replace(/_/g, ' ');
