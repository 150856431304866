import React from 'react';

import { Select, Input } from 'antd5';
import { CloseCircleOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd5/es/select';
import type { BaseSelectRef } from 'rc-select';

export interface Field {
  field: string;
  label: string;
  placeholder?: string | undefined;
  tags?: boolean | undefined;
  multiple?: boolean | undefined;
  options?: DefaultOptionType[] | undefined;
}

export type SearchFieldRef = BaseSelectRef;

type SearchFieldProps = {
  field: Field;
  onChange: (value: string | number | readonly string[] | undefined) => void;
  onSearch?: (value: string | number | readonly string[] | undefined) => void;
  size?: 'small' | 'middle' | 'large' | undefined;
  defaultValue: string | number | readonly string[] | undefined;
};
const { Search } = Input;

const SearchField = React.forwardRef(
  (props: SearchFieldProps, ref: React.Ref<SearchFieldRef>) => {
    const { field, onChange, onSearch, defaultValue, size } = props;
    const value = React.useRef<string | number | readonly string[] | undefined>(
      defaultValue,
    );
    const clearSelection = () => {
      value.current = undefined;
      onChange(undefined);
    };
    const onChangeValue = (
      newValue: string | number | readonly string[] | undefined,
    ) => {
      onChange(newValue);
    };
    const onChangeInput = (e: any) => {
      onChangeValue(e?.target?.value);
    };

    if (field.tags || field.multiple || field.options) {
      return (
        <Select
          ref={ref}
          mode={field.tags ? 'tags' : field.multiple ? 'multiple' : undefined}
          placeholder={field.placeholder}
          size={size}
          style={{ minWidth: '200px' }}
          onChange={onChangeValue}
          options={field.options}
          defaultValue={defaultValue}
          suffixIcon={
            value.current ? (
              <CloseCircleOutlined onClick={clearSelection} />
            ) : undefined
          }
        />
      );
    }
    return (
      <Search
        placeholder={field.placeholder}
        allowClear
        size={size}
        onSearch={onSearch}
        onChange={onChangeInput}
        defaultValue={defaultValue}
      />
    );
  },
);
export default SearchField;
