import { EventEmitterType, DesktopSettings } from '@octostar/platform-types';
import { isEqual, uniq } from 'lodash';
import { getPreference, setPreference } from '../../api/preferences';

const WORKSPACE_IDS_KEY = 'desktop:openWorkspaces';
const WORKSPACE_ACTIVE_KEY = 'desktop:activeWorkspace';
export class SavedSettings implements DesktopSettings {
  private ee: EventEmitterType;

  private openWorkspaceIds: string[] = [];

  private activeWorkspaceId: string | undefined;

  private init: Promise<void[]>;

  constructor(ee: EventEmitterType) {
    this.ee = ee;
    this.init = Promise.all([
      getPreference<string[]>(WORKSPACE_IDS_KEY, [])
        .then(ids => {
          this.openWorkspaceIds = ids;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.ee.emit('workspaceIdsChanged', [...this.openWorkspaceIds]);
        }),
      getPreference<string | undefined>(WORKSPACE_ACTIVE_KEY, undefined)
        .then(id => {
          this.activeWorkspaceId = id;
        })
        .catch(err => {
          console.error(err);
        }),
    ]);
  }

  async getOpenWorkspaceIds(): Promise<string[]> {
    await this.init;
    return [...this.openWorkspaceIds];
  }

  async setOpenWorkspaceIds(workspaceIds: string[]): Promise<void> {
    await this.init;
    const newWorkspaceIds = uniq(workspaceIds);
    if (!isEqual(this.openWorkspaceIds, newWorkspaceIds)) {
      this.openWorkspaceIds = [...workspaceIds];
      setPreference(WORKSPACE_IDS_KEY, newWorkspaceIds).catch(err => {
        console.error(`Problem saving workspace ids`, err);
      });
    }
  }

  async onOpenWorkspaceIdsChanged(
    listener: (workspaceIds: string[]) => void,
  ): Promise<() => Promise<void>> {
    this.ee.on('workspaceIdsChanged', listener);
    return async () => {
      this.ee.off('workspaceIdsChanged', listener);
    };
  }

  async getActiveWorkspaceId(): Promise<string | undefined> {
    await this.init;
    return this.activeWorkspaceId;
  }

  async setActiveWorkspaceId(workspaceId: string | undefined): Promise<void> {
    await this.init;
    if (this.activeWorkspaceId !== workspaceId) {
      this.activeWorkspaceId = workspaceId;
      setPreference(WORKSPACE_ACTIVE_KEY, workspaceId).catch(err => {
        console.error(`Problem saving active workspace id`, err);
      });
    }
  }
}
