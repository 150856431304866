import ReactDOM from 'react-dom';
import React from 'react';
import { Entity, Concept } from '@octostar/platform-types';
import { memoize } from 'lodash';
import shortid from 'shortid';
import { getIcon } from '../components/ConfiguredIcon';
import { Icon } from '../components/layout/Icon';

// Function to serialize computed styles into a style string
function serializeWithStyle(element: HTMLElement) {
  const isFontAwesome =
    element.classList.contains('fa') ||
    element.classList.contains('fas') ||
    element.classList.contains('far') ||
    element.classList.contains('fab');

  if (isFontAwesome) {
    return element.outerHTML;
  }
  const uniqueClassName = shortid();

  const computedStyle = window.getComputedStyle(element);

  let gotClass = false;
  let styleContent = `.${uniqueClassName} {`;
  // Handle pseudo-elements styles
  const pseudoElements = [':before', ':after'];
  pseudoElements.forEach(pseudo => {
    const pseudoStyle = window.getComputedStyle(element, pseudo);
    const content = pseudoStyle.getPropertyValue('content');
    if (content && content !== 'none' && content !== '') {
      gotClass = true;
      styleContent += `:${pseudo} { content: ${content};`;
      Array.from(pseudoStyle).forEach(property => {
        styleContent += `${property}: ${pseudoStyle.getPropertyValue(
          property,
        )};`;
      });
      styleContent += '}'; // Close the pseudo-element style block
    }
  });
  styleContent += '}'; // Close the main style block
  let styleElement = '';
  if (gotClass) {
    styleElement = `<style>${styleContent}</style>`;
    // eslint-disable-next-line no-param-reassign
    element.className = uniqueClassName;
  }
  // Apply and build the style string for the main element
  Array.from(computedStyle).forEach(property => {
    // eslint-disable-next-line no-param-reassign
    element.style[property] = computedStyle.getPropertyValue(property);
  });

  return styleElement + element.outerHTML;
}

const getIconHtml = memoize(async (icon: string): Promise<string> => {
  // let's memo the below part
  const container = document.createElement('div');
  container.style.display = 'none'; // Ensure it does not affect page layout
  document.body.appendChild(container);

  return new Promise((resolve, reject) => {
    ReactDOM.render(<Icon icon={icon} />, container, () => {
      const iconElement = container.firstElementChild;
      if (iconElement) {
        resolve(serializeWithStyle(iconElement as HTMLElement));
      } else {
        resolve(`<!-- icon render failed for ${icon} -->`);
      }
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  });
});

export const getIconCode = async (
  x: Entity | Concept | string,
  defaultIcon?: string,
): Promise<string> => {
  const icon = await getIcon(
    x,
    typeof x === 'string' ? x : defaultIcon || 'question',
  );
  return getIconHtml(icon);
};
