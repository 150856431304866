import * as React from 'react';
import { useRightSidebarControllerContext } from '../flexlayout/wrappers/WrapperRightSidebarContext';
import { CurrentRelationshipsInfo } from './CurrentRelationshipsInfo';
import { BorderTabHead } from '../flexlayout/wrappers/BorderTabHead';
import { EmptyBorder } from '../flexlayout/wrappers/EmptyBorder';
import { CurrentEntitiesInfo } from './CurrentEntitiesInfo';
import { WorkspaceContext } from '../WorkspaceContext';
import './RecordDetails.css';

export const SelectedEntitiesWsItem = 'Selected Entities';

export const RecordDetails = () => {
  const { entities, relationships } = useRightSidebarControllerContext();
  const { os_workspace } = React.useContext(WorkspaceContext);

  return (
    <div title="Details">
      <BorderTabHead
        title="Record Details"
        tabName="Details"
        items={[]}
        showCloseBorderButton
      />
      {entities.length === 0 && relationships.length === 0 && (
        <EmptyBorder description="Select any nodes or edges to see all their details" />
      )}
      {entities?.length ? (
        <CurrentEntitiesInfo entities={entities} os_workspace={os_workspace} />
      ) : null}
      {relationships?.length ? (
        <CurrentRelationshipsInfo relationships={relationships} />
      ) : null}
    </div>
  );
};
