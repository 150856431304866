import _ from 'lodash';
import { WorkspaceItem } from '@octostar/platform-types';

export const CUSTOM_TEMPLATE_SCHEMA = 'os_custom_template_schema';
export const CUSTOM_TEMPLATE_SCHEMA_UI = 'os_custom_template_schema_ui';

const conceptsToRemove = [
  'thing',
  'os_searchable',
  'os_workspace_item',
  'os_business_workspace_record',
];

export const asLabel = (property: string) =>
  property.replace(/\b\w/g, match => match.toUpperCase()).replace(/_/g, ' ');

export const sortByLastUpdatedAt = (a: WorkspaceItem, b: WorkspaceItem) => {
  const lastUpdatedAtA =
    a?.os_last_updated_at && a?.os_created_at
      ? a.os_last_updated_at > a.os_created_at
        ? a.os_last_updated_at
        : a.os_created_at
      : a?.os_last_updated_at || a?.os_created_at;
  const lastUpdatedAtB =
    b?.os_last_updated_at && b?.os_created_at
      ? b.os_last_updated_at > b.os_created_at
        ? b.os_last_updated_at
        : b.os_created_at
      : b?.os_last_updated_at || b?.os_created_at;

  if (lastUpdatedAtA && lastUpdatedAtB) {
    return lastUpdatedAtA > lastUpdatedAtB ? -1 : 1;
  }
  return 0;
};

export const getParentsConcept = (
  parentsConcept: string[],
  conceptEntity: string,
): string[] => {
  const concepts: string[] =
    parentsConcept[0] === 'thing'
      ? _.uniq(parentsConcept)
      : _.uniq(parentsConcept).reverse();
  if (!concepts.includes(conceptEntity)) {
    concepts.push(conceptEntity);
  }
  return concepts.filter(concept => !conceptsToRemove.includes(concept));
};

export const sortSchemaObject = (obj: any) => {
  if (typeof obj !== 'object' || obj === null || _.isEmpty(obj)) {
    return obj;
  }
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};
