import React, { MouseEvent, MouseEventHandler } from 'react';
import shortid from 'shortid';
import { css } from '@superset-ui/core';

import {
  ContextMenuParams,
  ContextMenuRequest,
} from '@octostar/platform-types';
import { BUILTINS_MESSAGE_TYPES } from '../api/messagesTypes';
import ee from '../interface';

export const ContextMenu = (args: ContextMenuParams) => {
  const { children, trigger, onMenuToggle, ...context } = args;
  const elRef = React.useRef<HTMLDivElement>(null);
  const onCloseEmit = React.useMemo(
    () => BUILTINS_MESSAGE_TYPES.contextMenuOnClose(shortid()),
    [],
  );
  const [isOpen, setIsOpen] = React.useState(false);

  const publishRequest = React.useCallback((request: ContextMenuRequest) => {
    ee.emit(BUILTINS_MESSAGE_TYPES.contextMenuRequest, request);
  }, []);
  const publishMouseEvent = React.useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (isOpen) {
        // Toggle off
        publishRequest({ clearContextMenu: true });
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      const x = event.clientX;
      const y = elRef.current?.getBoundingClientRect().y || event.clientY;

      onMenuToggle?.(true);
      publishRequest({
        x,
        y,
        onCloseEmit,
        ...context,
      });
      setIsOpen(true);
    },
    [isOpen, onMenuToggle, publishRequest, onCloseEmit, context],
  );
  const handleRightClick = React.useCallback<MouseEventHandler<HTMLDivElement>>(
    e => {
      if (trigger?.includes('contextMenu')) {
        publishMouseEvent(e);
      }
    },
    [publishMouseEvent, trigger],
  );

  React.useEffect(() => {
    const handler = () => {
      setTimeout(() => {
        onMenuToggle?.(false);
        setIsOpen(false);
        // 100 ms cooldown to handle toggle functionality
      }, 100);
    };
    ee.on(onCloseEmit, handler);
    return () => {
      ee.off(onCloseEmit, handler);
    };
  }, [onCloseEmit, onMenuToggle]);

  const onMouseEnter = React.useCallback(
    e => {
      if (trigger?.includes('hover')) {
        e.preventDefault();
        publishMouseEvent(e);
      }
    },
    [publishMouseEvent, trigger],
  );
  const handleClick = React.useCallback(
    e => {
      if (trigger?.includes('click')) {
        e.preventDefault();
        publishMouseEvent(e);
      }
    },
    [publishMouseEvent, trigger],
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      css={css`
        cursor: pointer;
        width: 100%;
      `}
      ref={elRef}
      onMouseEnter={onMouseEnter}
      onContextMenu={handleRightClick}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
export default ContextMenu;
