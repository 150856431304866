import _ from 'lodash';
import { OsTag, TagInfo } from '@octostar/platform-types';

export const sortTagInfo = (tags: TagInfo[]) =>
  _.orderBy(
    tags,
    [
      t => (t.entity.group || t.entity.entity_label).toLowerCase(),
      t => t.entity.order || 0,
      t => t.entity.entity_label.toLowerCase(),
      t => t.entity.entity_id,
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
export const sortTags = (tags: OsTag[]) =>
  _.orderBy(
    tags,
    [
      t => (t.group || t.entity_label).toLowerCase(),
      t => t.order || 0,
      t => t.entity_label.toLowerCase(),
      t => t.entity_id,
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
