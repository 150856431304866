import * as React from 'react';
import { Button, Dropdown, MenuProps } from 'antd5';
import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { useRightSidebarControllerContext } from './WrapperRightSidebarContext';
import './BorderTabHead.css';

interface BorderTabHeadProps {
  title: string;
  tabName?: string;
  showCloseBorderButton?: boolean;
  items: MenuProps['items'];
}

export const BorderTabHead = ({
  title,
  tabName,
  showCloseBorderButton,
  items,
}: BorderTabHeadProps) => {
  const { layoutModel, borders } = useRightSidebarControllerContext();
  const [showTabHead, setShowTabHead] = React.useState(false);

  React.useEffect(() => {
    if (
      tabName &&
      borders
        .filter(border => border.isBorderTab)
        .map(border => border.name)
        .includes(tabName)
    ) {
      setShowTabHead(true);
    } else {
      setShowTabHead(false);
    }
  }, [borders, tabName]);

  const closeBorder = React.useCallback(() => {
    if (!layoutModel) {
      return;
    }
    const currentJsonModel = layoutModel.toJson();
    const tabNode = currentJsonModel?.borders?.[0]?.children?.find(
      child => child.name === tabName || child.name === title,
    );
    layoutModel.doAction({
      type: 'FlexLayout_SelectTab',
      data: { tabNode: tabNode?.id },
    });
  }, [layoutModel, tabName, title]);

  if (!showTabHead) {
    return <></>;
  }

  return (
    <div className="btab-page">
      <div className="btab-page-header">
        <h1 className="btab-page-title">{title}</h1>
        <div className="btab-page-buttons">
          {showCloseBorderButton && (
            <Button
              className="btab-page-close-button"
              icon={<CloseOutlined />}
              onClick={() => closeBorder()}
            />
          )}
          {items?.length ? (
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
