import { TabNode } from 'flexlayout-react';
// import { ReactNode } from 'react';
import { ButtonProps } from 'antd5';
import { AddListener } from './callback';
// import { IconProps } from '../components/layout/Icon';

import {
  WorkspaceItem,
  Entity,
  WorkspaceItemModel,
  WorkspaceRecordIdentifier,
  OsWorkspaceEntity,
  Relationship,
  OsTag,
  TagAttributes,
  ConceptFilter,
  FocusListItem,
  Concept,
} from './model';
import {
  TagWithRelationship,
  Workspace,
  WorkspaceChangeEmitter,
  WorkspaceIdentifier,
  WorkspacePermission,
  WorkspacePermissionValue,
  WorkspaceRecordWithRelationships,
} from './workspace';
import { EventEmitterType } from './event';
import {
  ActionContext,
  ContextMenuGroup,
  ContextMenuOptions,
  ContextMenuRequest,
} from './action';
import {
  CustomTemplate,
  ModalTemplateProps,
  SavedTemplate,
  TemplateLayout,
} from './templates';
import { AppServiceCallProps } from './app';
import { SearchXperienceProps } from './search';
import { Styler, StylerConfig, StylerOption } from './styling';
import { GraphSpec } from './graph';
// import { Watcher } from '../components/Apps/watchers/types';
import { UserProfile } from './user';
import { ReactCssProperties, ReactRef } from './react-types';

export type IconProps = {
  icon: string;
  className?: string;
  rotate?: number;
  spin?: boolean;
  style?: ReactCssProperties;
  iconRef?: ReactRef<HTMLSpanElement>;
};

type ReactNode = any; // TODO: might require a better type

export type Watcher = {
  app_id: string;
  app_name: string;
  watcher_name: string;
  const: [key: string, value: any][];
  description: string;
  file: string;
  interval: string;
  name: string;
  params: [key: string, value: any][];
  semantically_bound: string[];
};

export type SaveFileOptions = {
  path?: string | undefined;
};

export type SaveOptions = SaveFileOptions & {
  draft?: boolean;
  saveAs?: boolean;
  app?: WorkspaceItem;
};

export type AddCommentParams = {
  os_workspace: string;
  os_parent_uid?: string;
  contents: string;
  slug?: string;
};

export interface DesktopSettings {
  getOpenWorkspaceIds: () => Promise<string[]>;
  setOpenWorkspaceIds: (ids: string[]) => Promise<void>;
  onOpenWorkspaceIdsChanged: AddListener<string[]>;
}

export type DesktopActionOptions = {
  tabId?: string;
  besideTabId?: string;
  autoSelectTab?: boolean;
  onCloseTab?: () => void;
  with?: string;
  initialState?: any;
};

export interface DesktopActionContext extends ActionContext {
  ee: EventEmitterType;
  eventTopicPrefix?: string;
  options?: DesktopActionOptions;
}

export type Icon = IconProps | string;
export type DesktopActionMenuItemGroup = ContextMenuGroup;
export type DesktopActionFilter = (context: DesktopActionContext) => boolean;
export type DesktopAction = (context: DesktopActionContext) => any;
export type DesktopActionMenuItem = {
  label: ReactNode;
  key: string;
  icon: Icon;
  service?: string;
  with?: string;
  /**
   * Menu group to which the item is added.
   * Default is 'general'
   */
  group?: DesktopActionMenuItemGroup;
  subgroup?: string;
  order?: number;
  children?:
    | DesktopActionMenuItem[]
    | ((props: DesktopActionContext) => DesktopActionMenuItem[]);
  accepts: DesktopActionFilter;
  invoke: DesktopAction;
};

export type OsNotification =
  | string
  | {
      id?: string;
      message: string | ReactNode;
      description?: string;
      level?: 'info' | 'success' | 'error' | 'warning';
      placement?:
        | 'top'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight';
    };

export type ShowTabProps = {
  app: WorkspaceItem;
  item?: WorkspaceItem;
  options?: DesktopActionOptions;
};
export type OsProgress = {
  key: string;
  label?: ReactNode;
  job_type?: string;
  status?: 'active' | 'success' | 'exception' | 'normal' | undefined;
};

export const OS_CONFIRM_MODAL_YES = true;
export const OS_CONFIRM_MODAL_NO = false;
export const OS_CONFIRM_MODAL_CANCEL = undefined;
export type OS_CONFIRM_MODAL_RESPONSE =
  | typeof OS_CONFIRM_MODAL_YES
  | typeof OS_CONFIRM_MODAL_NO
  | typeof OS_CONFIRM_MODAL_CANCEL;

export type OsConfirmProps =
  | string
  | {
      title: string;
      icon?: string;
      content?: string;
      okText?: string;
      okButtonProps?: ButtonProps;
      cancelText?: string;
      taskID?: string;
    };

export type CreateEntityFormProps = {
  entity?: Entity;
  os_workspace?: string;
  concept?: string;
};

export type CreateRelationsDialogProps = {
  source?: Entity;
  os_workspace?: string;
  target?: FocusListItem;
  createRecord?: boolean;
  relationName?: string;
  defaultTargetConcept?: string;
};

export type SaveAsModalProps = {
  item: WorkspaceItem;
  app?: WorkspaceItem;
};

export type EntityPasteContext = {
  count: () => Promise<number>;
  fetch: (limit?: number) => Promise<Entity[]>;
};

export type DesktopOpenParams = {
  options?: DesktopActionOptions;
  items: Entity[];
};

export type TaskCleanupReason = 'canceled' | 'completed';
export type AttachmentType = string | Blob | ArrayBuffer | Record<string, any>;
export type AttachmentResponseType =
  | 'text'
  | 'blob'
  | 'arrayBuffer'
  | 'json'
  | 'url';
export interface GetAttachmentOptions<T extends AttachmentType> {
  path?: string;
  default?: T;
  responseType?: AttachmentResponseType;
}
export type WithProgressBarOptions = {
  job_type: string;
  label?: string | JSX.Element;
  eventTopicPrefix?: string;
  errorToast?: string | boolean;
};
export type DesktopStylerContext = {
  graph: GraphSpec;
  selected?: GraphSpec;
};
export type ExportOptions = {
  filename?: string;
};
export type ImportZipOptions = {
  overwrite?: boolean;
  target?: string;
  include_content?: boolean;
  include_dashboards?: boolean | 'if_missing';
};
export type PasteContextOptions = {
  limit1?: number;
  limit2?: number;
  record?: Entity;
  records?: Entity[];
  query?: ConceptFilter;
};
export type DragStartParams = {
  record: Entity;
  records: Entity[];
  dragEndTopic: string;
};
export type DragEndParams = {
  record: Entity;
  records: Entity[];
  x: number;
  y: number;
};

export type FolderPasteOptions = {
  folders?: boolean;
  files?: boolean;
  recursive?: boolean;
};

export type FileTreeOptions = {
  recurse?: boolean;
  exclude_root?: boolean;
  flat?: boolean;
  minimal?: boolean;
  structure?: boolean;
};

export type Whoami = {
  async_channel: string;
  email: string | null;
  os_jwt: string;
  username: string;
  timbr_roles: string[];
};
export type CopyOptions = {
  os_item_name?: string;
};
export interface Desktop extends DesktopSettings, WorkspaceChangeEmitter {
  refresh: () => Promise<void>;
  openWorkspace: (ws: WorkspaceIdentifier) => Promise<void>;
  getActiveWorkspace: (prompt?: boolean) => Promise<string | undefined>;
  setActiveWorkspace: (workspace: string | undefined) => Promise<void>;
  closeWorkspace: (ws: WorkspaceIdentifier) => Promise<void>;
  copy: (
    source: WorkspaceItem,
    target: WorkspaceItem,
    options?: CopyOptions,
  ) => Promise<WorkspaceItem[]>;

  listAllWorkspaces: (
    permissions?: WorkspacePermissionValue,
  ) => Promise<WorkspaceItem[]>;
  /**
   * This might not be enough, add a listener to {@link Desktop.onOpenWorkspacesChanged} to keep getting updated.
   * In react components, prefer {@link useDesktopWorkspaces} hook.
   */
  getOpenWorkspaces: () => Promise<Workspace[]>;

  /**
   * Fetches an attachment based on the provided workspace identifier and path or ID.
   *
   * @param workspace - The identifier for the workspace.
   * @param path - The path or ID of the attachment.
   * @param defaultValue - The default value to return if the attachment doesn't exist or there's an error.
   *                        An empty string is used if not provided.
   * @returns A promise that resolves to the attachment of type T.
   */
  getAttachment: <T extends AttachmentType>(
    entity: Entity,
    options?: GetAttachmentOptions<T>,
  ) => Promise<T>;
  getStylerOptions: () => Promise<StylerOption[]>;
  getStyler: (name: string, context: DesktopStylerContext) => Promise<Styler>;
  getWorkspace: (ws: WorkspaceIdentifier) => Promise<Workspace | undefined>;
  getWorkspaceItems(os_item_name: string): Promise<WorkspaceItem[]>;
  applyTag: (
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ) => Promise<void>;
  removeTag: (
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ) => Promise<void>;
  updateTag: (tag: OsTag) => Promise<OsTag>;
  getItem: <T>(ws: WorkspaceIdentifier<T>) => Promise<WorkspaceItem<T>>;
  getItems: (ws: WorkspaceIdentifier[]) => Promise<WorkspaceItem[]>;
  getTags: (entity: Entity) => Promise<TagWithRelationship[]>;
  getAvailableTags: (entity: Entity, workspace?: string) => Promise<OsTag[]>;
  getTemplates: (layout?: TemplateLayout) => Promise<SavedTemplate[]>;
  getTemplate: (
    name: string,
    defaultTemplate?: string,
  ) => Promise<CustomTemplate>;
  getSchemaItems(os_item_content_type: string): Promise<WorkspaceItem[]>;
  createWorkspace: (name: string) => Promise<WorkspaceItem>;
  connect: (
    relationship: Relationship | string,
    from: Entity,
    to: Entity,
    os_workspace?: string,
  ) => Promise<WorkspaceItem>;
  save: (
    item:
      | (Partial<WorkspaceItem> & WorkspaceItemModel)
      | WorkspaceRecordIdentifier
      | WorkspaceRecordWithRelationships,
    options?: SaveOptions,
  ) => Promise<WorkspaceItem>;
  saveFile: (
    item: WorkspaceItem,
    file: File | string,
    options?: SaveFileOptions,
  ) => Promise<WorkspaceItem>;
  import: (items: any[]) => Promise<void>;
  importZip: (file: File, options?: ImportZipOptions) => Promise<void>;
  export: (
    item: WorkspaceItem | WorkspaceItem[],
    options?: ExportOptions,
  ) => Promise<void>;
  getFilesTree: (
    workspace_or_folder: WorkspaceItem,
    options?: FileTreeOptions,
  ) => Promise<WorkspaceItem[]>;
  // open with the default action

  open(
    records: Entity | Entity[],
    options?: DesktopActionOptions,
  ): Promise<void>;
  delete: (
    item: WorkspaceRecordIdentifier | WorkspaceRecordIdentifier[],
    recurse?: boolean,
  ) => Promise<void>;
  delete_workspace_records: (workspace_id: string) => Promise<void>;
  delete_local_concept_records: (
    workspace_id: string,
    base_concept: string,
  ) => Promise<void>;
  searchXperience: (options?: SearchXperienceProps) => Promise<Entity[]>;
  showTab: (props: ShowTabProps) => Promise<void>;
  closeTab: (props: ShowTabProps) => Promise<void>;
  callAppService: (props: AppServiceCallProps) => Promise<any>;
  showContextMenu: (props: ContextMenuRequest) => Promise<void>;
  showToast: (notification: OsNotification) => Promise<void>;
  clearToast: (id: string) => Promise<void>;
  showProgress: (progress: OsProgress) => Promise<void>;
  withProgressBar: <T>(
    promise: Promise<T>,
    options?: WithProgressBarOptions,
  ) => Promise<T>;
  showConfirm: (props: OsConfirmProps) => Promise<OS_CONFIRM_MODAL_RESPONSE>;
  showFileUpload: (folder: WorkspaceItem) => Promise<WorkspaceItem[]>;
  showCreateEntityForm: (
    props: CreateEntityFormProps,
  ) => Promise<OsWorkspaceEntity[]>;
  showCreateRelationsDialog: (
    props: CreateRelationsDialogProps,
  ) => Promise<void>;
  showSaveAsModal: (
    props: SaveAsModalProps,
  ) => Promise<WorkspaceItem | undefined>;
  getPasteContext: (options: PasteContextOptions) => Promise<Entity[]>;
  showModalTemplate: (props: ModalTemplateProps) => Promise<void>;
  addComment: (about: Entity, comment: AddCommentParams) => Promise<Entity>;
  removeComment: (os_workspace: string, comment_id: string) => Promise<void>;
  addWatchIntent: (entity: Entity, watcher: Watcher) => Promise<Entity>;
  removeWatchIntent: (os_workspace: string, intent_id: string) => Promise<void>;
  getWorkspacePermission: (
    os_worpsaces: string[],
  ) => Promise<{ [os_workspace: string]: WorkspacePermission }>;
  getUser: () => Promise<UserProfile>;
  deployApp: (
    app: WorkspaceItem,
    initialSecrets?: { [key: string]: string },
  ) => Promise<WorkspaceItem>;
  undeployApp: (app: WorkspaceItem) => Promise<WorkspaceItem>;
  whoami: () => Promise<Whoami>;
  getFocusList: () => Promise<FocusListItem[]>;
  addToFocusList: (
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ) => Promise<FocusListItem[]>;
  removeFromFocusList: (
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ) => Promise<FocusListItem[]>;
  getImages: (entity: Entity) => Promise<Entity[]>;
  internalGetIconCode: (
    x: Entity | Concept | string,
    defaultIcon?: string,
  ) => Promise<string>;
}
export type AppRegistry = {
  registerTabComponent: ({
    component,
    factory,
  }: {
    component: string;
    factory: (node: TabNode) => ReactNode;
  }) => void;
  registerAction: (action: DesktopActionMenuItem) => void;
  registerStyler: (styler: StylerConfig) => () => void;
};

export type WorkspaceItemTitleProps = DesktopActionContext & {
  showHiddenChildren?: 'workspace' | boolean;
  menuOptions?: ContextMenuOptions;
  expanded?: boolean;
};
