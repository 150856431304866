import React from 'react';
import { Image } from 'antd5';
import ReactPlayer from 'react-player';
import './MediaViewer.css';

export type MediaType = 'image' | 'video' | 'audio';

const mediaTypeMap: Record<string, MediaType> = {
  // Images
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  svg: 'image',
  webp: 'image',

  // Videos
  mp4: 'video',
  mkv: 'video',
  webm: 'video',
  mov: 'video',
  avi: 'video',
  flv: 'video',
  m4v: 'video',
  qt: 'video',
  wmv: 'video',
  mpeg: 'video',
  mpg: 'video',
  '3gp': 'video',
  '3g2': 'video',
  rm: 'video',
  rmvb: 'video',
  ts: 'video',
  vob: 'video',
  asf: 'video',
  divx: 'video',
  f4v: 'video',

  // Audio
  mp3: 'audio',
  wav: 'audio',
  ogg: 'audio',
  flac: 'audio',
  m4a: 'audio',
  aac: 'audio',
};

export const getMediaType = (fileExtension: string): MediaType | undefined =>
  mediaTypeMap[fileExtension];

interface MediaViewerProps {
  type: 'image' | 'video' | 'audio';
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  preview?: boolean | any;
  aspectRatio?: 'square' | 'landscape' | 'portrait';
  expandToFill?: boolean;
  onError?: (e: any) => void;
}

const MediaViewer: React.FC<MediaViewerProps> = ({
  type,
  src,
  alt,
  width = '100%',
  height = 'auto',
  preview = false,
  aspectRatio,
  expandToFill,
  onError,
}) => {
  const mediaStyle = `${preview ? 'media-viewer-preview' : 'media-viewer'} ${
    expandToFill
      ? 'media-viewer-object-fit-cover'
      : 'media-viewer-object-fit-contain'
  } ${aspectRatio ? `media-viewer-aspect-ratio-${aspectRatio}` : ''}`;
  switch (type) {
    case 'image':
      return (
        <Image
          src={src}
          alt={alt || 'Media content'}
          width={width}
          height={height}
          className={mediaStyle}
          preview={preview}
          onError={onError}
        />
      );
    case 'video':
    case 'audio':
      return (
        <ReactPlayer
          url={src}
          width={width}
          height={height}
          controls
          className={mediaStyle}
          onError={onError}
        />
      );
    default:
      return <div>Unsupported media type</div>;
  }
};

export default MediaViewer;
