import _ from 'lodash';
import { WATCH_INTENT_PAUSED } from './WatchersIO';
import { WatchIntent, Watcher } from './types';

export const isPaused = (intent: WatchIntent) =>
  intent.description === WATCH_INTENT_PAUSED;

export const calculateRun = (
  startTime?: string,
  interval?: string,
): { run: number; last_run: string } => {
  if (!startTime || !interval) {
    return { run: 0, last_run: '' };
  }
  const start = new Date(startTime).getTime();
  const now = new Date().getTime();
  const diff = now - start;
  const intervalUnit = interval.slice(-1);
  const intervalValue = parseInt(interval.slice(0, -1), 10);

  if (intervalUnit === 'h') {
    const intervalMs = intervalValue * 60 * 60 * 1000;
    const run = Math.floor(diff / intervalMs);
    return { run, last_run: new Date(start + run * intervalMs).toISOString() };
  }
  if (intervalUnit === 'm') {
    const intervalMs = intervalValue * 60 * 1000;
    const run = Math.floor(diff / intervalMs);
    return { run, last_run: new Date(start + run * intervalMs).toISOString() };
  }
  const intervalMs = intervalValue * 1000;
  const run = Math.floor(diff / intervalMs);
  return { run, last_run: new Date(start + run * intervalMs).toISOString() };
};

// parse jwt to get user name
export const parseJwt = (token: string) => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    return null;
  }
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const getEntityFromIntent = (intent: WatchIntent) => {
  if (!intent.arguments) {
    return null;
  }
  let entity = null;

  try {
    entity = _.isEmpty(intent.arguments)
      ? null
      : JSON.parse(atob(intent.arguments));
    return entity;
  } catch (e) {
    console.error(
      `Cannot deserialize intent arguments: ${intent.arguments}`,
      e,
    );
  }

  return entity;
};

export const prepareIntentsForTable = (intents: WatchIntent[]) =>
  intents.map(intent => {
    const entity = getEntityFromIntent(intent);
    const { run, last_run } = calculateRun(
      intent.os_created_at,
      intent.interval,
    );
    return {
      ...intent,
      record: entity?.entity_label,
      run,
      lastest_alert: intent.last_run || last_run,
      start_time: intent?.os_created_at,
      action: intent.os_entity_uid,
    };
  });

export const parseToWatcher = (obj: any): Watcher => ({
  app_id: obj['os-job-name'],
  app_name: obj['os-app-title'],
  watcher_name: obj.name,
  const: obj.const,
  description: obj.description,
  file: obj.file,
  interval: obj.interval,
  name: obj.name,
  params: obj.params,
  semantically_bound: obj.semantically_bound,
  os_workspace: obj['os-workspace'],
});

export const getIntentBeforeSave = (intent: WatchIntent) => ({
  ...intent,
  workspace: undefined,
  os_workspace: intent.workspace?.entity_id || intent.os_workspace,
});
