import { get } from 'lodash';

export const addOntologyContextToFormData = function (
  formData: Record<string, any>,
  controlsState: { datasource: { datasource: Record<string, any> } },
) {
  const backend = get(controlsState, 'datasource.datasource.database.backend');
  const schema = get(controlsState, 'datasource.datasource.schema');
  const table_name = get(controlsState, 'datasource.datasource.table_name');

  if (backend && schema && table_name) {
    // eslint-disable-next-line no-param-reassign
    formData.ontology_context = {
      backend,
      schema,
      table_name,
    };
  }
  return formData;
};
