import { Entity, RelationshipCountResult } from '@octostar/platform-types';

export type RelationshipCountResultWithExpires = RelationshipCountResult & {
  expires: number;
};
type ShadowGraphNodeId = string;
type ShadowRelationshipInfo = {
  count?: RelationshipCountResultWithExpires;
  ids?: ShadowGraphNodeId[];
};
type ShadowGraphNode = {
  id: ShadowGraphNodeId;
  relationships: Map<string, ShadowRelationshipInfo>;
};

interface IShadowGraph {
  getConnectedEntities: (
    entity: Entity,
    relationship_name: string,
  ) => Entity[] | undefined;

  getReationshipCount: (
    entity: Entity,
    relationship_name: string,
  ) => RelationshipCountResult | undefined;

  dropRelationships: (from: Entity, relationship_name?: string) => void;
  dropNode: (entity: Entity) => void;
}

// ... [ShadowGraphNodeId, ShadowGraphNode, and GraphProvider definitions] ...

const entityToNodeId = (entity: Entity): ShadowGraphNodeId =>
  `${entity.entity_type}:${entity.entity_id}`;
const nodeIdToEntity = (id: ShadowGraphNodeId): Entity => {
  const [entity_type, entity_id] = id.split(':');
  return {
    entity_type,
    entity_id,
    entity_label: '',
  };
};

export class ShadowGraph implements IShadowGraph {
  nodes: Map<ShadowGraphNodeId, ShadowGraphNode> = new Map();

  private cacheNodeData(entity: Entity | string) {
    const id = typeof entity === 'string' ? entity : entityToNodeId(entity);
    if (!this.nodes.has(id)) {
      this.nodes.set(id, {
        id,
        relationships: new Map(),
      });
    }
    return this.nodes.get(id) as ShadowGraphNode;
  }

  getConnectedEntities(
    entity: Entity,
    relationship_name: string,
  ): Entity[] | undefined {
    const node = this.cacheNodeData(entity);
    return node.relationships
      .get(relationship_name)
      ?.ids?.map(id => nodeIdToEntity(id));
  }

  getReationshipCount(
    entity: Entity,
    relationship_name: string,
  ): RelationshipCountResultWithExpires | undefined {
    const node = this.cacheNodeData(entity);
    return node.relationships.get(relationship_name)?.count;
  }

  setRelationshipCount(
    from: Entity,
    relationship_name: string,
    count: RelationshipCountResultWithExpires,
  ): void {
    const node = this.cacheNodeData(from);
    const info = node.relationships.get(relationship_name) || {};
    info.count = count;
    node.relationships.set(relationship_name, info);
  }

  addConnectedEntities(
    from: Entity,
    relationship_name: string,
    to: Entity[],
  ): void {
    const node = this.cacheNodeData(from);
    const info = node.relationships.get(relationship_name) || {};
    info.ids = info.ids || [];
    to.forEach(entity => {
      const id = entityToNodeId(entity);
      if (!info.ids!.includes(id)) {
        info.ids!.push(id);
      }
    });
    node.relationships.set(relationship_name, info);
  }

  dropRelationships(from: Entity, relationship_name?: string): void {
    const node = this.cacheNodeData(from);
    if (relationship_name) {
      node.relationships.delete(relationship_name);
      node.relationships.delete(relationship_name);
    } else {
      node.relationships.clear();
      node.relationships.clear();
    }
  }

  dropNode(entity: Entity): void {
    const node = this.cacheNodeData(entity);
    this.nodes.delete(node.id);
  }
}
