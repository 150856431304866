import * as React from 'react';
import { Row, Col } from 'antd5';
import {
  ObjectFieldTemplatePropertyType,
  ObjectFieldTemplateProps,
} from '@rjsf/utils';
import { Concept } from '@octostar/platform-types';
import './standard-template.css';

const getPropertiesFiltered = (
  properties: ObjectFieldTemplatePropertyType[],
  formData: any,
  concept: Concept,
  readonly?: boolean,
  labelKeys?: string[],
): ObjectFieldTemplatePropertyType[] => {
  if (readonly) {
    return properties.filter(
      property =>
        property.name === concept.concept_name ||
        (property.name !== concept.concept_name &&
          formData?.[property.name] &&
          (!property.name.startsWith('os_') ||
            labelKeys?.includes(property.name)) &&
          !property.name.startsWith('entity_')),
    );
  }
  return properties.filter(
    property =>
      (!property.name.startsWith('os_') ||
        labelKeys?.includes(property.name)) &&
      !property.name.startsWith('entity_'),
  );
};

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  const tabSetRef = React.useRef(null);
  const [panelWidth, setPanelWidth] = React.useState(0);
  const { readonly, properties, formContext, formData, uiSchema } = props;
  const requiredFieldsOnly = formContext?.requiredFieldsOnly;
  const concept: Concept = formContext?.concept;
  const labelKeys = formContext?.labelKeys;
  const propertiesFiltered = getPropertiesFiltered(
    properties,
    formData,
    concept,
    readonly,
    labelKeys,
  );

  React.useEffect(() => {
    const { current } = tabSetRef;
    // Check if ResizeObserver is supported
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(entries => {
        const newPanelWidth = entries?.[0].contentRect.width;
        setPanelWidth(curr => (curr === newPanelWidth ? curr : newPanelWidth));
      });

      if (current) {
        resizeObserver.observe(current);
      }

      return () => {
        if (current) {
          resizeObserver.unobserve(current);
        }
      };
    }
    // eslint-disable-next-line no-console
    console.log(
      'MultiRecordViewer-ResizeObserver is not supported in this browser.',
    );
    return () => {};
  }, []);

  const singleColumn = formContext?.singleColumn;

  const getDynamicColProps = (
    width: number,
    colProps?: any,
  ): {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  } => {
    if (!colProps || width === 0) {
      return { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };
    }
    const { xs, sm, md, lg, xl, xxl } = colProps;
    // One column on small screens
    // Minimum width 24
    if (width < 300) {
      return { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 };
    }
    // Two columns on medium screens
    // Minimum width 12
    if (width < 450) {
      return {
        xs: xs < 12 ? 12 : xs,
        sm: sm < 12 ? 12 : sm,
        md: md < 12 ? 12 : md,
        lg: lg < 12 ? 12 : lg,
        xl: xl < 12 ? 12 : xl,
        xxl: xxl < 12 ? 12 : xxl,
      };
    }
    // Three columns on medium screens
    // Minimum width 8
    if (width < 600) {
      return {
        xs: xs < 8 ? 8 : xs,
        sm: sm < 8 ? 8 : sm,
        md: md < 8 ? 8 : md,
        lg: lg < 8 ? 8 : lg,
        xl: xl < 8 ? 8 : xl,
        xxl: xxl < 8 ? 8 : xxl,
      };
    }
    // No columns limit on large screens
    return { xs, sm, md, lg, xl, xxl };
  };

  if (singleColumn || requiredFieldsOnly) {
    return (
      <div>
        {propertiesFiltered.map(
          (element: ObjectFieldTemplatePropertyType, index) => {
            const { content } = element;
            return (
              <div className="property-wrapper" key={index}>
                {content}
              </div>
            );
          },
        )}
      </div>
    );
  }

  return (
    <div ref={tabSetRef}>
      <Row
        gutter={[24, 24]}
        style={{
          width: panelWidth,
        }}
        className="record-editor-form-row-style"
      >
        {propertiesFiltered.map(
          (element: ObjectFieldTemplatePropertyType, index) => {
            const { content } = element;
            const colProps = uiSchema?.[element.name]?.['ui:col'];
            const dynamicColProps = getDynamicColProps(panelWidth, colProps);
            return (
              <Col key={index} {...dynamicColProps}>
                <div className="property-wrapper">{content}</div>
              </Col>
            );
          },
        )}
      </Row>
    </div>
  );
}
