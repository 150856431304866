import { css, t } from '@superset-ui/core';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import Loading from 'src/components/Loading';
import { RootState } from 'src/dashboard/types';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Button, Form, Input, Modal, Select, Space, TreeSelect } from 'antd5';
import { useDesktopWorkspaces } from 'src/octostar/hooks/useDesktopWorkspacesHook';
import {
  Workspace,
  WorkspaceItem,
  WorkspaceItems,
} from '@octostar/platform-types';
import { newWorkspaceItem } from 'src/octostar/lib/handy';
import { asSavedSearch, extractFilters, isSavedSearch } from './utils';
import { BUILTIN_APPS } from '../layout/builtins';
import { SavedSearchContent } from './types';
import { getConceptIcon } from '../ConfiguredIcon';

type TreeConcept = {
  value: string;
  title: string | JSX.Element;
  children?: TreeConcept[];
};

function buildFolderTree(
  items: WorkspaceItems,
  folders: Record<string, TreeConcept>,
) {
  items.forEach(item => {
    if (item.entity_type === 'os_folder') {
      const existingProps = folders[item.os_entity_uid];
      // eslint-disable-next-line no-param-reassign
      folders[item.os_entity_uid] = {
        value: item.os_entity_uid,
        title: item.entity_label,
        children: existingProps?.children || [],
      };
      const parentFolder = folders[item!.os_parent_folder as string] || {
        value: item.os_parent_folder,
        title: item.os_parent_folder,
        children: [],
      };
      parentFolder.children!.push(folders[item.os_entity_uid]);
      // eslint-disable-next-line no-param-reassign
      folders[item!.os_parent_folder as string] = parentFolder;
    }
  });
}

function toFolderTree(ws?: Workspace): TreeConcept[] {
  if (!ws) return [];
  const { items } = ws;
  const rootFolder: TreeConcept = {
    value: ws.workspace.os_entity_uid,
    title: `Root (${ws.workspace.entity_label})`,
    children: [],
  };
  const folders = {
    [rootFolder.value]: rootFolder,
  };
  buildFolderTree(items, folders);
  // eslint-disable-next-line consistent-return
  return [rootFolder];
}

const showToast = (e: Error) => {
  console.error(e);
  DesktopAPI.showToast({
    message: t('Saved search could not be created, check logs'),
    level: 'error',
  });
};

type SavedEntityModalProps = {
  nativeFilters: RootState['nativeFilters'];
  dataMask: RootState['dataMask'];
  item: WorkspaceItem;
  onHide: () => void;
};

const SavedSearchModal = (props: SavedEntityModalProps) => {
  const { item } = props;
  const datasources = useSelector((state: RootState) => state.datasources);
  const workspaces = useDesktopWorkspaces();
  const workspaceOptions = React.useMemo(
    () =>
      workspaces.map(({ workspace: { entity_label, os_entity_uid } }) => ({
        label: entity_label,
        value: os_entity_uid,
      })) || [],
    [workspaces],
  );

  const [isSaving, setIsSaving] = React.useState(false);
  const saveAsNew = React.useMemo(() => !isSavedSearch(item), [item]);

  const [selectedWS, setSelectedWS] = React.useState<string>();
  const [selectedFolder, setSelectedFolder] = React.useState<string>();

  React.useEffect(() => {
    if (item) {
      if (saveAsNew) {
        const firstWS =
          (workspaceOptions.length && workspaceOptions[0]) || undefined;
        setSelectedWS(currentValue => {
          if (currentValue) return currentValue;
          return firstWS?.value;
        });
        setSelectedFolder(currentValue => {
          if (currentValue) return currentValue;
          return firstWS?.value;
        });
      } else {
        setSelectedWS(item.os_workspace);
        setSelectedFolder(item.os_parent_folder);
      }
    }
  }, [item, saveAsNew, workspaceOptions]);

  const onWorkspaceChange = React.useCallback((newWs: string) => {
    setSelectedWS(newWs);
    setSelectedFolder(newWs);
  }, []);

  const isLoading = !item;

  const folderTree = React.useMemo(() => {
    if (!selectedWS) return [];

    const ws = workspaces.find(ws => ws.workspace.os_entity_uid === selectedWS);
    return toFolderTree(ws);
  }, [selectedWS, workspaces]);

  const onSave = React.useCallback(
    async form => {
      try {
        setIsSaving(true);
        if (!item) throw new Error('Workspace item undefined');
        if (!selectedWS) throw new Error('No workspace selected');
        const { filters, crossFilters } = extractFilters(
          props.nativeFilters,
          props.dataMask,
        );

        const savedSearch: WorkspaceItem<SavedSearchContent> = asSavedSearch({
          item: {
            ...item,
            os_item_name: form.os_item_name,
            os_workspace: selectedWS,
            os_parent_folder: selectedFolder,
          },
          filters,
          crossFilters,
          datasources,
        });

        let payload = savedSearch;
        if (saveAsNew) {
          savedSearch.os_icon = await getConceptIcon(
            item.os_item_content?.concept,
          );
          savedSearch.os_entity_uid = crypto.randomUUID();
          savedSearch.entity_id = savedSearch.os_entity_uid;
          payload = newWorkspaceItem(savedSearch);
        }
        const savedItem = await DesktopAPI.save(payload);

        if (saveAsNew) {
          DesktopAPI.showTab({
            app: BUILTIN_APPS.datasetViewer,
            item: savedItem,
          });
        }
        props.onHide();
      } catch (e) {
        showToast(e);
      }
      setIsSaving(false);
    },
    [item, selectedWS, selectedFolder, props, datasources, saveAsNew],
  );

  const renderBody = React.useCallback(
    () => (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          // os_workspace: selectedWS,
          os_item_name: (!saveAsNew && item?.os_item_name) || null,
        }}
        onFinish={onSave}
        requiredMark={false}
      >
        <Form.Item label={t('Workspace')}>
          <Select
            autoFocus
            disabled={!saveAsNew}
            options={workspaceOptions}
            onChange={onWorkspaceChange}
            value={selectedWS}
            placeholder={t('Select workspace...')}
            showSearch
          />
        </Form.Item>
        <Form.Item label={t('Folder')}>
          <TreeSelect
            disabled={folderTree.length === 0 || !saveAsNew}
            treeDefaultExpandAll
            value={selectedFolder}
            onChange={val => setSelectedFolder(val)}
            placeholder={t('Select folder...')}
            treeData={folderTree}
            showArrow
          />
        </Form.Item>
        <Form.Item
          label={t('Search Name')}
          name="os_item_name"
          rules={[
            {
              required: true,
              message: t('Please name the search'),
            },
          ]}
        >
          <Input autoFocus onFocus={e => e.target.select()} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 20, span: 20 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {t('Save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    ),
    [
      folderTree,
      isSaving,
      item?.os_item_name,
      onSave,
      onWorkspaceChange,
      saveAsNew,
      selectedFolder,
      selectedWS,
      workspaceOptions,
    ],
  );

  return (
    <Modal
      open
      onCancel={props.onHide}
      title={saveAsNew ? t('Save a New Search') : t('Save Search')}
      footer={null}
    >
      {isLoading ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Loading position="normal" />
        </div>
      ) : (
        renderBody()
      )}
    </Modal>
  );
};

function mapStateToProps({ nativeFilters, dataMask }: RootState) {
  return {
    nativeFilters,
    dataMask,
  };
}

export default connect(mapStateToProps)(SavedSearchModal);
