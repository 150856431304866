import { Button } from 'antd5';
import * as React from 'react';

export const Controls = ({
  disabled,
  saving,
  onSave,
  onCancel,
}: {
  disabled?: boolean;
  saving?: boolean;
  onSave: () => void;
  onCancel: () => void;
}) => (
  <div>
    <Button disabled={disabled} onClick={onSave} loading={saving}>
      Save
    </Button>
    <Button disabled={disabled || saving} onClick={onCancel}>
      Cancel
    </Button>
  </div>
);
