import { t } from '@superset-ui/core';
import { FILESYSTEM_ENTITY_CONCEPT } from 'src/octostar/interface';
import { Entity, WorkspaceItem } from '@octostar/platform-types';

export const toWorkspaceItem = (
  entity: Entity & Partial<WorkspaceItem>,
): WorkspaceItem => ({
  ...entity,
  os_entity_uid: entity.entity_id,
  os_item_name: entity.entity_label,
  os_created_at: new Date().toISOString(),
  os_created_by: 'builtin',
  os_last_updated_by: 'builtin',
  os_last_updated_at: new Date().toISOString(),
  os_workspace: 'builtin',
  os_item_type: 'os_builtin_app',
});

export const BUILTIN_APPS = Object.freeze({
  home: toWorkspaceItem({
    os_icon: 'fa-house',
    entity_id: '9f454b54-b459-471c-a023-7e487a4ca88b',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Homepage'),
  }),
  workspaceTemplate: toWorkspaceItem({
    os_icon: 'fa-folder-tree',
    entity_id: '4f669589-106f-4083-ba6a-b4ee52a9f77e',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Workspace'),
  }),
  dashboard: toWorkspaceItem({
    os_icon: 'fa-chart-bar',
    entity_id: '1ff9f8be-b432-452e-b705-6d37cf9186d2',
    entity_type: 'app',
    entity_label: t('Dashboard'),
  }),
  linkcharteditor: toWorkspaceItem({
    os_icon: 'fa-connectdevelop',
    entity_id: 'c6c72490-7b9e-4bcc-b9e5-9809bbb566ab',
    entity_type: 'app',
    entity_label: t('Link Chart Editor'),
  }),
  iframeapp: toWorkspaceItem({
    entity_id: '61b2b6dc-edbd-4bbd-824f-c74f165b5834',
    entity_type: 'app',
    entity_label: t('Iframe App'),
  }),
  iframeappedit: toWorkspaceItem({
    entity_id: 'f5ea431f-9521-403e-96c3-9d172b98542c',
    entity_type: 'app',
    entity_label: t('Iframe App Editor'),
  }),
  recordeditor: toWorkspaceItem({
    entity_id: 'c92b36a3-a175-42de-8028-1f3de810f5ed',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Record Viewer'),
  }),
  workspaceSettings: toWorkspaceItem({
    os_icon: 'fa-cog',
    entity_id: 'c7099673-4b3c-47b2-843e-06dac359784f',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Workspace Settings'),
  }),
  datasetViewer: toWorkspaceItem({
    os_icon: 'fa-database',
    entity_id: 'c7a5592f-399e-41bb-ad64-89d996e08f49',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Dataset Viewer'),
  }),
  fusionCenter: toWorkspaceItem({
    os_icon: 'fa-atom',
    entity_id: 'fbfd969f-cd93-4747-b47e-fd26b532637c',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Fusion Center'),
  }),
  settings: toWorkspaceItem({
    os_icon: 'fa-gear',
    entity_id: 'user-settings:e73647ee-f1dd-465f-98be-7ea99f714cfc',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Settings'),
  }),
  fileViewer: toWorkspaceItem({
    os_icon: 'fa-file',
    entity_id: '82a7a8cc-8c50-4752-8a73-7905094ede07',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('File Viewer'),
  }),
  monacoEditor: toWorkspaceItem({
    os_icon: 'fa-file-code',
    entity_id: '48763cb4-91ee-4a8d-ad29-e6a5e106cbbf',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Monaco Editor'),
  }),
  markdownViewer: toWorkspaceItem({
    os_icon: 'fa-markdown',
    entity_id: '66810db5-8994-48ba-b0cc-b4d9a55ecada',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Markdown Viewer'),
  }),
  markdownEditor: toWorkspaceItem({
    os_icon: 'fa-spinner',
    entity_id: 'ad8c86af-4465-4cae-b1b5-2bddc9e5c2c7',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Markdown Editor'),
  }),
  richtextViewer: toWorkspaceItem({
    os_icon: 'fa-file-word',
    entity_id: '44e260e4-54fe-4e6b-aac2-94324118ad94',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Richtext Viewer'),
  }),
  richtextEditor: toWorkspaceItem({
    os_icon: 'fa-file-word',
    entity_id: '1a9c318a-10a5-4bc5-956c-c49ef39e5133',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Richtext Editor'),
  }),
  customSchemaEditor: toWorkspaceItem({
    entity_id: '2fbbc2eb-e41f-4dfa-b931-bb1b6a93705d',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Schema Editor'),
    os_item_content: {
      settings: {
        rename: false,
      },
    },
  }),
  appManifestEditor: toWorkspaceItem({
    entity_id: '68984d02-b35b-4bab-8b6f-ab9d19079daf',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Manifest Editor'),
    os_item_content: {
      settings: {
        rename: false,
      },
    },
  }),
  customTemplateCreator: toWorkspaceItem({
    os_icon: 'fa-pencil',
    entity_id: '4b5410e1-2b7a-4576-887a-5eaf176067fb',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Template Editor'),
  }),
  customTemplateEditor: toWorkspaceItem({
    os_icon: 'fa-pencil',
    entity_id: 'f43cadda-925c-4c2e-b04f-1b79d2830d17',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Template Editor'),
  }),
  mermaidViewer: toWorkspaceItem({
    os_icon: 'fa-anchor',
    entity_id: 'ec69636e-81df-4928-be96-9430f4232cbb',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Mermaid Viewer'),
  }),

  mermaidEditor: toWorkspaceItem({
    os_icon: 'fa-anchor',
    entity_id: '1330252a-29f7-4911-9a6f-7b7df964c680',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Mermaid Editor'),
  }),

  jobs_manager: toWorkspaceItem({
    os_icon: 'fa-spinner',
    entity_id: 'bf09691b-2ac7-4c04-9057-a4045190bb85',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Jobs Manager'),
  }),

  watchers_manager: toWorkspaceItem({
    os_icon: 'fa-eye',
    entity_id: '82f2a66d-d637-4b0f-b9e3-c7c2a5bc79d6',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Watchers Manager'),
  }),

  notifications_manager: toWorkspaceItem({
    os_icon: 'fa-bell',
    entity_id: '82933ef6-11bf-4117-9bd6-7f7e483647a2',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Notifications Manager'),
  }),
  opensearch: toWorkspaceItem({
    os_icon: 'fa-magnifying-glass',
    entity_id: '03590be1-3b25-4d89-85f1-909cc0794137',
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    entity_label: t('Search'),
  }),
});
