import LRU from 'lru-cache';
import SqlString from 'sqlstring';
import { Entity, Ontology } from '@octostar/platform-types';
import { CachinQueryExecutor } from './CachingQueryExecutor';

const CACHE_TTL = 1000 * 60 * 60; // 60 minutes

const NO_DATA_ENTITY: Entity = {
  entity_id: 'ENTITY_TYPE_CACHE_NO_DATA_ENTITY',
  entity_type: 'ENTITY_TYPE_CACHE_INTERNAL_USE_ONLY',
  entity_label: '😭 you should not be seeing this',
};

export class EntityTypeCache {
  cache: CachinQueryExecutor<Entity, Entity>;

  constructor(private ontology: Ontology) {
    this.cache = new CachinQueryExecutor<Entity, Entity>({
      cache: new LRU<string, any>({ max: 100000, ttl: CACHE_TTL }),
      getEntity: x => x,
      queryExecutor: ontology,
      toSql: async (entity_type, entity_ids) => {
        const entityIds = entity_ids.map(x => SqlString.escape(x)).join(', ');
        return `SELECT entity_id, entity_type FROM timbr.${entity_type} as t1
       WHERE t1.entity_id in (${entityIds})`.trim();
      },
      getDefaultValue: () => ({ ...NO_DATA_ENTITY }),
    });
  }

  async toEntity(entity_type: string, entity_id: string): Promise<Entity> {
    const input: Entity = { entity_type, entity_id, entity_label: '' };
    let found = await this.cache.get(input);
    if (found.entity_id === NO_DATA_ENTITY.entity_id) {
      found = input;
    }
    return this.ontology.getEntity(found);
  }
}
