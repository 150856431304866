import * as React from 'react';
import { Button, Table, Tag } from 'antd5';
import { ColumnsType } from 'antd5/es/table';
import moment from 'moment/moment';
import {
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { WorkspaceItem } from '@octostar/platform-types';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { WatchIntent } from './types';
import {
  pauseWatchIntent,
  removeWatchIntent,
  startWatchIntent,
} from './WatchersIO';
import {
  getIntentBeforeSave,
  isPaused,
  parseJwt,
  prepareIntentsForTable,
} from './utils';
import { WatchIntentWorkspace } from './WatchersUtils';
import './WatchersManager.css';

const COLUMN_KEYS_TO_REMOVE_FOR_TINY = ['jwt', 'app_name', 'start_time'];
const COLUMN_KEYS_TO_REMOVE_FOR_RIGHT_SIDEBAR = [
  'jwt',
  'app_name',
  'record',
  'start_time',
];

const renderActionButton = (
  intent: WatchIntent,
  refresh: () => Promise<void>,
  tiny?: boolean,
) => {
  const paused = isPaused(intent);

  const handleDelete = async () => {
    await DesktopAPI.withProgressBar(
      removeWatchIntent(intent).then(() => {
        refresh();
      }),
      { job_type: 'Delete Watcher ', label: intent?.watcher_name },
    );
  };

  const handlePause = async () => {
    await DesktopAPI.withProgressBar(
      pauseWatchIntent(getIntentBeforeSave(intent)).then(() => {
        refresh();
      }),
      { job_type: 'Pause Watcher ', label: intent?.watcher_name },
    );
  };

  const handleStart = async () => {
    await DesktopAPI.withProgressBar(
      startWatchIntent(getIntentBeforeSave(intent)).then(() => {
        refresh();
      }),
      { job_type: 'Start Watcher ', label: intent?.watcher_name },
    );
  };

  return (
    <div className="watchers-table-actions">
      {tiny ? (
        <>
          {paused ? (
            <Button
              icon={<PlayCircleOutlined />}
              type="primary"
              onClick={handleStart}
            />
          ) : (
            <Button
              icon={<PauseCircleOutlined />}
              type="primary"
              onClick={handlePause}
            />
          )}
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            danger
            onClick={handleDelete}
          />
        </>
      ) : (
        <>
          {paused ? (
            <Button
              icon={<PlayCircleOutlined />}
              type="primary"
              onClick={handleStart}
            />
          ) : (
            <Button
              icon={<PauseCircleOutlined />}
              type="primary"
              onClick={handlePause}
            />
          )}
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            danger
            onClick={handleDelete}
          />
        </>
      )}
    </div>
  );
};

const mkColumns = (
  intents: WatchIntent[],
  refresh: () => Promise<void>,
  tiny?: boolean,
  rightSidebar?: boolean,
): ColumnsType<WatchIntent> =>
  [
    {
      title: 'User',
      dataIndex: 'jwt',
      filterMode: 'tree',
      filterSearch: true,
      key: 'jwt',
      render: (value: string, item: WatchIntent) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {parseJwt(item.jwt).username}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'watcher_name',
      filterMode: 'tree',
      filterSearch: true,
      key: 'watcher_name',
      render: (value: string) => (
        <span className="watchers-table-name">{value}</span>
      ),
      sorter: (a: WatchIntent, b: WatchIntent) =>
        a.watcher_name.localeCompare(b.watcher_name),
      onFilter: (value: string, record: WatchIntent) =>
        record.watcher_name.startsWith(value),
    },
    {
      title: 'Record',
      dataIndex: 'record',
      key: 'record',
      render: (entity: any) => <Tag>{entity}</Tag>,
    },
    {
      title: 'Workspace',
      dataIndex: 'workspace',
      key: 'workspace',
      render: (value: WorkspaceItem) => <WatchIntentWorkspace value={value} />,
    },
    {
      title: 'App',
      dataIndex: 'app_name',
      filterMode: 'tree',
      filterSearch: true,
      key: 'app_name',
      sorter: (a: WatchIntent, b: WatchIntent) =>
        a.app_name.localeCompare(b.app_name),
      onFilter: (value: string, record: WatchIntent) =>
        record.app_name.startsWith(value),
    },
    {
      title: 'Latest alert',
      dataIndex: 'lastest_alert',
      key: 'lastest_alert',
      render: (value: string) => (
        <span
          style={{ whiteSpace: 'nowrap' }}
          title={value ? moment(value).format('DD MMM YYYY - HH:mm:ss') : ''}
        >
          {value ? moment(value).fromNow() : ''}
        </span>
      ),
    },
    {
      title: 'Run',
      dataIndex: 'run',
      key: 'run',
      sorter: (a: WatchIntent, b: WatchIntent) =>
        a.os_created_at?.localeCompare(b.os_created_at || ''),
    },
    {
      title: 'Started',
      key: 'start_time',
      dataIndex: 'start_time',
      filterMode: 'tree',
      filterSearch: true,
      defaultSortOrder: 'descend',
      sorter: (a: WatchIntent, b: WatchIntent) =>
        a.os_created_at?.localeCompare(b.os_created_at || ''),
      render: (value: string) => (
        <span
          style={{ whiteSpace: 'nowrap' }}
          title={
            value
              ? moment(new Date(value)).format('DD MMM YYYY - HH:mm:ss')
              : ''
          }
        >
          {value ? moment(new Date(value)).fromNow() : ''}
        </span>
      ),
      onFilter: (value: string, record: WatchIntent) =>
        record.os_created_at?.startsWith(value),
      filtered: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value: string) =>
        renderActionButton(
          intents.filter(intent => intent.os_entity_uid === value)[0],
          refresh,
          tiny,
        ),
    },
  ].filter(
    x =>
      !(
        (rightSidebar &&
          COLUMN_KEYS_TO_REMOVE_FOR_RIGHT_SIDEBAR.includes(x.key)) ||
        (!rightSidebar &&
          tiny &&
          COLUMN_KEYS_TO_REMOVE_FOR_TINY.includes(x.key))
      ),
  ) as ColumnsType<WatchIntent>;

interface WatchersTableProps {
  intents: WatchIntent[];
  refresh: () => Promise<void>;
  tiny?: boolean;
  rightSidebar?: boolean;
}

export const WatchersTable = ({
  intents,
  refresh,
  tiny,
  rightSidebar,
}: WatchersTableProps) => {
  const preparedData = prepareIntentsForTable(intents);

  if (intents.length === 0) {
    return (
      <div className="watchers-table-empty">
        <span>
          No watchers found. Please set up watchers to receive updates.
        </span>
      </div>
    );
  }

  return (
    <Table
      showHeader
      rowKey={record => record.entity_id}
      columns={mkColumns(intents, refresh, tiny, rightSidebar)}
      dataSource={preparedData}
      pagination={tiny ? false : { pageSize: 50 }}
      scroll={tiny ? undefined : { x: 1300 }}
      className="watchers-table"
    />
  );
};
