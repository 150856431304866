import React from 'react';
import { BootstrapUser } from 'src/types/bootstrapTypes';

export type UserContextType = {
  user: BootstrapUser | undefined;
};
const UserContext = React.createContext<UserContextType>({
  user: undefined,
});

export default UserContext;
