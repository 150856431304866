import React from 'react';
import mermaid from 'mermaid';
import { Spin } from 'antd5';
import shortid from 'shortid';

mermaid.initialize({
  startOnLoad: false,
  theme: 'default',
  securityLevel: 'loose',
});

export const Mermaid = ({
  chart,
  width,
  height,
}: {
  chart: string;
  width: number;
  height: number;
}) => {
  const [diagram, setDiagram] = React.useState('');
  const [error, setError] = React.useState<string | undefined>();
  const [graphDiv] = React.useState(`id_${shortid()}`);

  React.useEffect(() => {
    setError(undefined);
    if (chart) {
      mermaid
        .render(graphDiv, chart)
        .then(({ svg }) => {
          setDiagram(svg);
          setError(undefined);
        })
        .catch(e => {
          setError(e.message);
        });
    }
  }, [chart, graphDiv]);

  if (!error && (!diagram || !`${diagram}`.trim())) {
    <div className="content-container">
      <Spin />
    </div>;
  }

  return (
    <div
      style={{ width, height }}
      dangerouslySetInnerHTML={{ __html: error || diagram }}
    />
  );
};
