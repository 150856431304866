import * as React from 'react';
import { Entity } from '@octostar/platform-types';
import { useRightSidebarControllerContext } from '../flexlayout/wrappers/WrapperRightSidebarContext';
import { BorderTabHead } from '../flexlayout/wrappers/BorderTabHead';
import { EmptyBorder } from '../flexlayout/wrappers/EmptyBorder';
import { ItemTags } from '../tags/ItemTags';
import './BorderTab.css';

const EntityTags = ({
  entity,
  updateTabCounter,
}: {
  entity: Entity;
  updateTabCounter: (badgeCounter?: number | undefined) => void;
}) => (
  <div className="flexlayout-border-tab-item">
    <div className="flexlayout-border-tab-item-header">
      <span className="flexlayout-border-tab-item-title">
        <span className="flexlayout-border-tab-item-label">{`${entity.entity_label}`}</span>
        <ItemTags
          item={entity}
          label="Add Tag"
          updateTabCounter={updateTabCounter}
        />
      </span>
    </div>
  </div>
);

export const Tags = () => {
  const { entities, setTabCounter, setTabContent, detailsTabsContentMap } =
    useRightSidebarControllerContext();

  const updateTabCounter = React.useCallback(
    (count?: number | undefined) => {
      if (!detailsTabsContentMap.has('Tags')) {
        const content = <Tags />;
        setTabContent('Tags', content, count);
      } else if (detailsTabsContentMap.get('Tags')?.badgeCounter !== count) {
        setTabCounter('Tags', count);
      }
    },
    [detailsTabsContentMap, setTabContent, setTabCounter],
  );

  return (
    <div title="Tags">
      <BorderTabHead
        title="Tags"
        tabName="Tags"
        showCloseBorderButton
        items={[]}
      />
      {entities.length === 0 ? (
        <EmptyBorder description="Select any nodes to see all their tags" />
      ) : (
        <>
          {entities.map(entity => (
            <div
              className="flexlayout-border-tab-container"
              key={entity.entity_id}
            >
              <EntityTags
                key={entity.entity_id}
                entity={entity}
                updateTabCounter={updateTabCounter}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
