import * as React from 'react';
import { Avatar, Button, Comment } from 'antd';
import { CommentItem } from './types';
import { formatDate, removeComment, sanitizeComment } from './CommentsIO';
import { CommentEditor } from './CommentEditor';

export const ThreadedComment: React.FC<{
  comment: CommentItem;
  allComments: CommentItem[];
  parent_id?: string;
  appendComment: (comment: string, parent_id?: string) => Promise<void>;
  refresh: (force: boolean) => void;
}> = ({ comment, allComments, parent_id, appendComment, refresh }) => {
  const [replyingTo, setReplyingTo] = React.useState<string | null>(null);
  const [replyText, setReplyText] = React.useState<string>('');
  const [sending, setSending] = React.useState(false);

  const handleReplyClick = () => {
    setReplyingTo(comment.author);
    setReplyText(`@${comment.author} `);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(e.target.value);
  };

  const handleDeleteClick = () => {
    removeComment(
      comment.os_workspace,
      comment.comment_id,
      comment.referred_entity,
    ).then(() => {
      refresh(true);
    });
  };
  const handleSubmitReply = () => {
    setSending(true);
    appendComment(replyText, parent_id).finally(() => {
      setReplyText('');
      setReplyingTo(null);
      setSending(false);
    });
  };

  const childComments = allComments
    .filter(c => c.parent_comment === comment.comment_id)
    .sort((a, b) => a.created_at - b.created_at);
  const avatarString = (comment?.author ? comment?.author : 'O').toUpperCase();
  return (
    <Comment
      actions={[
        <Button
          type="link"
          size="small"
          key="comment-nested-reply-to"
          onClick={handleReplyClick}
          className="comments-reply-btn"
        >
          Reply
        </Button>,
        <Button
          type="link"
          size="small"
          key="comment-nested-delete"
          onClick={handleDeleteClick}
          className="comments-delete-btn"
        >
          {parent_id ? 'Delete' : 'Delete thread'}
        </Button>,
      ]}
      author={<span>{comment.author}</span>}
      avatar={
        <Avatar size={40} alt={comment.author}>
          {avatarString.slice(0, 1)}
        </Avatar>
      }
      content={
        <p
          dangerouslySetInnerHTML={{ __html: sanitizeComment(comment.text) }}
        />
      }
      datetime={<span>{formatDate(comment.created_at)}</span>}
    >
      {replyingTo && (
        <CommentEditor
          loading={sending}
          onChange={handleTextChange}
          onSubmit={handleSubmitReply}
          value={replyText}
        />
      )}
      {childComments.map(childComment => (
        <ThreadedComment
          key={childComment.comment_id}
          comment={childComment}
          allComments={allComments}
          appendComment={appendComment}
          parent_id={comment.comment_id}
          refresh={refresh}
        />
      ))}
    </Comment>
  );
};
