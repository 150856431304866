/* eslint-disable theme-colors/no-literal-colors */
import * as React from 'react';
import { isEqual } from 'lodash';

import {
  Button,
  Drawer,
  Input,
  InputRef,
  Select,
  Space,
  Table as AntdTable,
  Checkbox,
} from 'antd5';
import type {
  ColumnType,
  CompareFn,
  FilterConfirmProps,
  FilterValue,
  SorterResult,
  TableRowSelection,
} from 'antd5/es/table/interface';
import type { TablePaginationConfig } from 'antd5/es/table';
import { DefaultOptionType } from 'antd5/es/select';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { TableCurrentDataSource } from 'antd/lib/table/interface';
import { SearchCondition } from '../search';
import { ResizableTable } from './antd/ResizableTable';
import ConfiguredIcon from '../ConfiguredIcon';
import './Table.css';
import { TableContextMenu } from './TableContextMenu';
import { OpenTabLink } from '../OpenTabLink';

const DEFAULT_PAGE_SIZE = 5;
function humanize(str: string) {
  return `${str}`
    .replace(/_/g, ' ')
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/\b\w/g, (m: string) => m.toUpperCase());
}

export interface Column {
  dataIndex: string;
  title?: string | undefined;
  width?: number | undefined;
}

export interface Sorting {
  field: string;
  order: 'ASC' | 'DESC';
}
export interface DataRequestParams {
  page: number;
  pageSize: number;
  columns?: string[] | undefined;
  sorting?: Sorting[] | undefined;
  conditions?: SearchCondition[] | undefined;
}

export interface RowKey {
  entity_type: string;
  entity_id: string;
}

export interface PageData<T> {
  data: T[] | undefined;
  totalResults?: number | undefined;
  currentPage?: number | undefined;
  loading?: boolean | undefined;
}

export interface StyleConceptMap {
  [key: string]: { color: string; colorWithOpacity: string };
}
export interface TableProps<T, V> {
  pageData: PageData<T> | undefined;
  pageSize?: number | undefined;
  columns?: string[] | Column[] | undefined;
  allColumns?: string[] | Column[] | undefined;
  onDataRequest?: (params: DataRequestParams) => void | undefined;
  size?: 'small' | 'middle' | 'large' | undefined;
  onSelectionChanged?: (RowKey: T[]) => void | undefined;
  onDisplaySelectionChanged?: (displayColumns: string[]) => void;
  externalSelection?: string[]; // `${entity_type}:${entity_id}`
  contextMenu?: boolean;
  showContextMenuOnHover?: boolean;
  scrollY?: number;
  footer?: React.ReactNode;
  enableLoadingSpinner?: boolean;
  useEntityIcons?: boolean;
  useRelationshipIcons?: boolean;
  relationshipContextMenu?: boolean;
  openTabLinkRelationships?: boolean;
  styleConceptMap?: StyleConceptMap;
  unpaginated?: boolean;
  filterByGrouped?: boolean;
  onColumnFilterApplied?: (applied: boolean) => void;
  clearColumnFilters?: boolean;
}

interface ColumnSelectProps {
  columns: Column[];
  allColumns: Column[];
  onChange: (selectedColumns: Column[]) => void;
}

interface ColumnDrawerProps extends ColumnSelectProps {
  onClose: () => void;
  open: boolean;
}

function getAvailableColumns(
  allColumns: Column[],
  selected: string[] = [],
): DefaultOptionType[] {
  return allColumns
    .filter(x => !selected.includes(x.dataIndex))
    .map(x => ({ value: x.dataIndex, label: x.title }));
}

const ColumnsDropdown = (props: ColumnSelectProps) => {
  const { columns, allColumns, onChange } = props;
  const [selectedColumns, setSelectedColumns] = React.useState<string[]>(
    columns.map(c => c.dataIndex),
  );
  const [availableColumns, setAvailableColumns] = React.useState<
    DefaultOptionType[]
  >([]);

  const onColumnsChanged = (
    selected: string[],
    // Can't use this because some selected elements come out undefined/empty {}
    selectedOptions: DefaultOptionType[],
  ) => {
    setSelectedColumns(curr => (isEqual(curr, selected) ? curr : selected));
    onChange(
      selected.map(ele => ({
        dataIndex: ele,
        title: ele,
      })),
    );
  };

  React.useEffect(() => {
    // Hide Already Selected
    const avaiable = getAvailableColumns(allColumns, selectedColumns);
    setAvailableColumns(curr => (isEqual(curr, avaiable) ? curr : avaiable));
  }, [allColumns, selectedColumns, setAvailableColumns]);

  return (
    <Select
      mode="multiple"
      placeholder="Inserted are removed"
      value={selectedColumns}
      onChange={onColumnsChanged}
      style={{ width: '100%' }}
      options={availableColumns}
    />
  );
};

const ColumnDrawer = (props: ColumnDrawerProps) => {
  const { open, onClose, columns, allColumns, onChange } = props;
  const [selectedColumns, setSelectedColumns] = React.useState<Column[]>([
    ...columns,
  ]);

  const onSave = () => {
    onChange(selectedColumns);
    onClose();
  };
  const onCancel = () => {
    setSelectedColumns([...columns]);
    onClose();
  };
  return (
    <Drawer
      title="Table Columns"
      placement="top"
      closable
      onClose={onSave}
      open={open}
      getContainer={false}
      footer={
        <Space>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      }
      footerStyle={{ marginLeft: 'auto' }}
    >
      <ColumnsDropdown
        columns={columns}
        allColumns={allColumns}
        onChange={setSelectedColumns}
      />
    </Drawer>
  );
};

const getColumnNames = (data: any[]): string[] => {
  const colNames: string[] = [];
  data.forEach(x =>
    Object.keys(x).forEach(k => {
      if (colNames.indexOf(k) < 0) {
        colNames.push(k);
      }
    }),
  );
  return colNames;
};

function isEmpty(s: any) {
  return s ? false : s !== 0;
}
const genericSort =
  (key: string): CompareFn<any> =>
  (oa: any, ob: any) => {
    const a = oa[key];
    const b = ob[key];
    if (isEmpty(a)) {
      if (isEmpty(b)) {
        return 0;
      }
      return -1;
    }
    if (isEmpty(b)) {
      return 1;
    }
    return `${a}`.localeCompare(`${b}`, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  };
const haveColumnsChanged = (a: Column[] | undefined, b: Column[]): boolean => {
  if (a === b) {
    return false;
  }
  if (a === undefined || b === undefined) {
    return true;
  }
  if (a.length !== b.length) {
    return true;
  }
  return a.filter((v, i) => b[i].dataIndex !== v.dataIndex).length > 0;
};

function isStringArray(columns: Array<string | Column>): columns is string[] {
  return columns.length > 0 && typeof columns[0] === 'string';
}

function mapColumnNames(colNames: string[]): Column[] {
  return colNames.map(x => ({ title: humanize(x), dataIndex: x }));
}

function formatColumns(columns: string[] | Column[]): Column[] {
  if (columns?.length) {
    return isStringArray(columns) ? mapColumnNames(columns) : columns;
  }
  return [];
}

export const Table = (props: TableProps<any, any>) => {
  const {
    columns,
    allColumns,
    pageData,
    size,
    pageSize: initialPageSize,
    onDataRequest,
    onSelectionChanged,
    onDisplaySelectionChanged,
    externalSelection,
    contextMenu,
    showContextMenuOnHover,
    scrollY,
    enableLoadingSpinner = true,
    useEntityIcons = true,
    useRelationshipIcons = true,
    relationshipContextMenu = true,
    openTabLinkRelationships = true,
    styleConceptMap = {},
    unpaginated,
    filterByGrouped = false,
    onColumnFilterApplied,
    clearColumnFilters = false,
  } = props;
  const [selectedColumns, setSelectedColumns] = React.useState<Column[]>(
    formatColumns(columns || []),
  );
  const allCols = React.useRef<Column[] | undefined>();
  const [data, setData] = React.useState<object[] | undefined>();
  const [pagination, setPagination] = React.useState<
    TablePaginationConfig | false
  >(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [pageSize, setPageSize] = React.useState<number>();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [sorting, setSorting] = React.useState<Sorting[] | undefined>();
  const [optionsOpen, setOptionsOpen] = React.useState(false);
  // https://github.com/ant-design/ant-design/issues/18001
  const [tableKey, setTableKey] = React.useState<number>(Math.random());
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
    externalSelection || [],
  );
  const [searchConditions, setSearchConditions] = React.useState<{
    [field: string]: SearchCondition;
  }>({});
  const searchInput = React.useRef<InputRef>(null);

  React.useEffect(() => {
    const selected = formatColumns(columns || []);
    setSelectedColumns(curr => (isEqual(curr, selected) ? curr : selected));
  }, [columns]);

  // TODO: Implement external search conditions to fix out-of-sync
  // Bugs in dashboards and allow for global search to highlight within Table
  const updateSearchCondition = React.useCallback(
    (field: string, selectedKeys: string[]) => {
      setSearchConditions(searchConditions => {
        const copy = { ...searchConditions };
        if (!selectedKeys?.length) {
          delete copy[field];
        } else {
          copy[field] = {
            field,
            operator: 'ILIKE',
            value: selectedKeys,
          };
        }
        return copy;
      });
      // Go back to first page
      setCurrentPage(1);
      if (onColumnFilterApplied) {
        onColumnFilterApplied(selectedKeys?.length > 0);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (clearColumnFilters) {
      setSearchConditions({});
    }
  }, [clearColumnFilters]);

  const handleSearch = React.useCallback(
    (
      selectedKeys: string[],
      confirm: (param?: FilterConfirmProps) => void,
      dataIndex: string,
    ) => {
      confirm();
      updateSearchCondition(dataIndex, selectedKeys);
    },
    [updateSearchCondition],
  );

  const valuesMap: { [key: string]: { string: number } } = filterByGrouped
    ? React.useMemo<{
        [key: string]: { string: number };
      }>(() => {
        const valueGroupsPerColumn = {};
        columns?.forEach(column =>
          typeof column === 'string'
            ? (valueGroupsPerColumn[column] = {})
            : (valueGroupsPerColumn[column.dataIndex] = {}),
        );
        data?.forEach(row => {
          Object.keys(valueGroupsPerColumn).forEach(column => {
            // consider only non null/undefined/empty values
            if (row[column] && typeof row[column] !== 'object') {
              if (!valueGroupsPerColumn[column][row[column]]) {
                valueGroupsPerColumn[column][row[column]] = 0;
              }
              valueGroupsPerColumn[column][row[column]] += 1;
            }
          });
        });
        return valueGroupsPerColumn;
      }, [data, columns])
    : {}; // dont calculate if filterByGrouped === false

  const getColumnSearchProps = React.useCallback(
    (dataIndex: string, label: string): ColumnType<any> => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${label}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setSelectedKeys([]);
                clearFilters?.();
                handleSearch([], confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                updateSearchCondition(
                  dataIndex,
                  (selectedKeys as string[]) || [],
                );
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
          {filterByGrouped && valuesMap?.[dataIndex] ? (
            <Space
              className="table-grouped-filter-values"
              onChange={e => {
                const selectedValues: string[] = Array.prototype.filter
                  .call(
                    e.currentTarget.getElementsByTagName('input'),
                    (element: HTMLInputElement) => element.checked,
                  )
                  .map((element: HTMLInputElement) => element.value);
                setSelectedKeys(selectedValues);
                confirm({ closeDropdown: false });
                updateSearchCondition(dataIndex, selectedValues);
              }}
            >
              {Object.entries(valuesMap[dataIndex])
                .sort((nameCount1, nameCount2) => {
                  if (nameCount1[1] === nameCount2[1]) {
                    return nameCount2[0].localeCompare(nameCount1[0]);
                  }
                  return nameCount2[1] - nameCount1[1];
                })
                .map(value => {
                  return (
                    <Checkbox
                      key={`${dataIndex}-${value[0]}`}
                      value={value[0]}
                      checked={selectedKeys.includes(value[0]) ? true : false}
                    >
                      <b>[{value[1]}]</b> {value[0]}
                    </Checkbox>
                  );
                })}
            </Space>
          ) : null}
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        `${record[dataIndex]}`
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text, record) => {
        let component = searchConditions?.[dataIndex] ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={searchConditions?.[dataIndex].value || []}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        );
        // if record is an entity and force use icons or relationship and force use icons
        if (
          dataIndex === 'entity_type' &&
          ((useEntityIcons && record.entity_type) ||
            (useRelationshipIcons && !record.entity_type))
        ) {
          component = (
            <Space>
              <ConfiguredIcon hint={record} />
              {component}
            </Space>
          );
        }
        if (
          // if record is an entity
          (record.entity_type || openTabLinkRelationships) &&
          selectedColumns.length &&
          dataIndex === selectedColumns[selectedColumns.length - 1]?.dataIndex
        ) {
          component = (
            <OpenTabLink record={record} positionAbsolute>
              {component}
            </OpenTabLink>
          );
        }
        return {
          props: {
            style:
              record.entity_type &&
              styleConceptMap[record.entity_type] &&
              styleConceptMap[record.entity_type].colorWithOpacity
                ? {
                    background:
                      styleConceptMap[record.entity_type].colorWithOpacity,
                  }
                : {},
          },
          children: component,
        };
      },
    }),
    [
      handleSearch,
      searchConditions,
      selectedColumns,
      updateSearchCondition,
      valuesMap,
    ],
  );

  const onPaginationChange = React.useCallback(
    (page: number, pageSize: number) => {
      setPageSize(pageSize);
      setCurrentPage(page);
    },
    [],
  );

  React.useEffect(() => {
    setPageSize(initialPageSize || DEFAULT_PAGE_SIZE);
  }, [initialPageSize, setPageSize]);

  React.useEffect(() => {
    if (!unpaginated) {
      setPagination(curr => {
        const copy = { ...curr };
        copy.current = currentPage;
        copy.pageSize = pageSize;
        copy.total = pageData?.totalResults;
        copy.onChange = onPaginationChange;
        return isEqual(curr, copy) ? curr : copy;
      });
    }
  }, [
    currentPage,
    pageSize,
    pageData?.totalResults,
    onPaginationChange,
    unpaginated,
  ]);

  React.useEffect(() => {
    if (allColumns?.length) {
      if (typeof allColumns[0] === 'string') {
        // @ts-ignore-next-line
        allCols.current = mapColumnNames(allColumns);
      } else {
        // @ts-ignore-next-line
        allCols.current = allColumns;
      }
      return;
    }
    if (allCols.current) {
      return;
    }
    if (!pageData?.data?.length) {
      return;
    }
    // TODO: The component expects pageData to have all columns
    // Perhaps we should be firing requests with selected columns
    // and fetch again if/when user adds more columns
    const colNames: string[] = getColumnNames(pageData.data);
    // @ts-ignore-next-line

    allCols.current = mapColumnNames(colNames);
  }, [pageData, allColumns]);

  React.useEffect(() => {
    const newValue = pageData?.data?.map((x, i) => ({
      key: x.entity_id && x.entity_type ? `${x.entity_type}:${x.entity_id}` : i,
      ...x,
    }));
    setData(curr => (isEqual(curr, newValue) ? curr : newValue));
    if (pageData) {
      setLoading(false);
      setTableKey(x => x + 1);
    }
  }, [pageData]);

  const onSelectRowChange = React.useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChanged?.(
        newSelectedRowKeys
          .map(k => k.toString().split(':'))
          .map(([entity_type, ...entity_id]) => ({
            entity_type,
            entity_id: entity_id.join(':'),
          })),
      );
    },
    [setSelectedRowKeys, onSelectionChanged],
  );

  const hideOptions = React.useCallback(() => {
    setOptionsOpen(false);
  }, []);
  const showOptions = React.useCallback(() => {
    setOptionsOpen(true);
  }, []);

  React.useEffect(() => {
    if (onDataRequest) {
      setLoading(true);
      onDataRequest({
        page: currentPage,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
        columns: selectedColumns?.map(x => x.dataIndex),
        sorting,
        conditions: searchConditions
          ? Object.values(searchConditions)
          : undefined,
      });
    }
    // We're currently requesting all columns, no need to send another fetch on change to 'selectedColumns'
  }, [
    currentPage,
    pageSize,
    sorting,
    searchConditions,
    onDataRequest,
    selectedColumns,
  ]);

  const onColumnsChanged = React.useCallback(
    (selectedColumns: Column[]) => {
      setSelectedColumns(selectedColumns);
      onDisplaySelectionChanged?.(selectedColumns.map(e => e.dataIndex));
    },
    [onDisplaySelectionChanged],
  );

  const rowSelection: TableRowSelection<any> | undefined = onSelectionChanged
    ? {
        selectedRowKeys: externalSelection || selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: onSelectRowChange,
        renderCell: (checked, record, index, originNode) => {
          let torender = originNode;
          if (contextMenu && relationshipContextMenu) {
            torender = (
              <TableContextMenu
                checked={checked}
                record={record}
                showOnHover={showContextMenuOnHover}
              >
                {torender}
              </TableContextMenu>
            );
          }
          return torender;
        },
        selections: [
          AntdTable.SELECTION_INVERT,
          AntdTable.SELECTION_NONE,
          {
            key: 'clearfliters',
            text: 'Remove filters',
            onSelect: () => {
              setSearchConditions({});
              setTableKey(x => x + 1);
            },
          },
          {
            key: 'options',
            text: (
              <Button type="primary" onClick={showOptions}>
                Choose Columns
              </Button>
            ),
            onSelect: () => {},
          },
        ],
      }
    : undefined;

  const onTableChange = React.useCallback(
    (
      // @ts-ignore-next-line
      pagination: TablePaginationConfig,
      // @ts-ignore-next-line
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<object> | SorterResult<object>[] | undefined,
      { action }: TableCurrentDataSource<any>,
    ) => {
      if (action === 'sort') {
        const asorter = sorter
          ? (Array.isArray(sorter) ? sorter : [sorter])
              .map(({ field, order }) => ({
                field: `${field}`,
                order: order
                  ? { ascend: 'ASC', descend: 'DESC' }[order]
                  : undefined,
              }))
              .filter(x => x.order)
          : undefined;
        // @ts-ignore-next-line
        setSorting(asorter);
      }
    },
    [],
  );

  return (
    <div>
      <ColumnDrawer
        onChange={onColumnsChanged}
        onClose={hideOptions}
        open={optionsOpen}
        columns={selectedColumns}
        allColumns={allCols.current || []}
      />
      <ResizableTable
        key={tableKey}
        scroll={scrollY ? { y: scrollY } : undefined}
        size={size || 'middle'}
        columns={selectedColumns.map(
          ({ title, dataIndex, width, ...other }) => ({
            title: title || humanize(dataIndex),
            dataIndex,
            key: dataIndex,
            sorter: onDataRequest ? true : genericSort(dataIndex), // for local search
            sortOrder:
              (sorting?.length &&
                sorting[0].field === dataIndex &&
                { ASC: 'ascend', DESC: 'descend' }[sorting[0].order]) ||
              null,
            ...getColumnSearchProps(dataIndex, title || humanize(dataIndex)),
            width: width || 120, // width is required to make columns resizable
            ...other,
          }),
        )}
        dataSource={data}
        onChange={onTableChange}
        rowSelection={rowSelection}
        pagination={pagination}
        loading={enableLoadingSpinner && (loading || pageData?.loading)}
        footer={props.footer ? () => props.footer : undefined}
      />
    </div>
  );
};

export default Table;
