import {
  CustomTemplate,
  Desktop,
  WorkspaceItem,
} from '@octostar/platform-types';

export function newCustomTemplate(name: string, template?: string) {
  const t: CustomTemplate = {
    name,
    description: '',
    concepts: [],
    variant: 'antd',
    type: 'javascript',
    template: template !== undefined ? template : `<i>template ${name}</i>`,
    parameterNames: [],
  };
  return t;
}

export const getTemplateFilesMap = async ({
  desktop,
  os_entity_uid,
  os_workspace,
}: {
  desktop: Desktop;
  os_workspace?: string;
  os_entity_uid?: string;
}): Promise<Record<string, string>> => {
  const buildFilesMap = (
    items: WorkspaceItem[],
    parent_folder: string,
    base_path?: string,
    map: Record<string, string> = {},
  ): Record<string, string> => {
    items
      .filter(i => i.os_parent_folder === parent_folder)
      .forEach(i => {
        if (i.os_item_type === 'os_folder') {
          buildFilesMap(
            items,
            i.os_entity_uid,
            `${base_path || ''}/${i.os_item_name}`,
            map,
          );
        } else if (
          i.os_has_attachment
        ) {
          const key = base_path
            ? `${base_path}/${i.os_item_name}`
            : i.os_item_name;
          // eslint-disable-next-line no-param-reassign
          map[
            key
          ] = `/api/octostar/workspace_data_api/attachments/${i.os_workspace}/${i.os_entity_uid}`;
        }
      });
    return map;
  };
  const target = await desktop.getWorkspace(os_workspace!).then(w => {
    if (!w) {
      return undefined;
    }
    const item = w.items.find(i => i.os_entity_uid === os_entity_uid);
    if (!item?.os_parent_folder) return undefined;
    return buildFilesMap(w.items, item.os_parent_folder);
  });
  return target || {};
};
export const osUrl =
  ({
    desktop,
    os_entity_uid,
    os_workspace,
  }: {
    desktop: Desktop;
    os_workspace?: string;
    os_entity_uid?: string;
  }) =>
  async (path: string) => {
    // used by templates, to not add depenency to workspace
    // as this may be called in an iframe without desktop loaded.
    // TODO: find a better way to do this
    // which doesn't involve the overhead of listing
    // all the workspace items over the post-robot connection
    if (!(desktop && os_entity_uid && os_workspace)) {
      return `/not/found/${path}`;
    }
    const target = await desktop.getWorkspace(os_workspace).then(w => {
      if (!w) {
        return undefined;
      }
      const item = w.items.find(i => i.os_entity_uid === os_entity_uid);
      if (!item) return undefined;
      let parent: WorkspaceItem | undefined = w.items.find(
        i => i.os_entity_uid === item.os_parent_folder,
      );
      let child: WorkspaceItem | undefined;
      path.split('/').forEach(p => {
        if (parent) {
          child = w.items.find(
            i =>
              i.os_item_name === p &&
              i.os_parent_folder === parent!.os_entity_uid,
          );
          parent = child;
        }
      });
      return child;
    });
    if (target) {
      return `/api/octostar/workspace_data_api/attachments/${target.os_workspace}/${target.os_entity_uid}`;
    }
    return `/not/found/${path}`;
  };
