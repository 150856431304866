import * as React from 'react';
import { Empty, Input, Tooltip } from 'antd5';
import { InfoCircleOutlined } from '@ant-design/icons';
import { t } from '@superset-ui/core';
import { isEqual } from 'lodash';
import { Watcher } from './types';
import { WatchersModal } from './WatchersModal';
import { WatcherIcon } from './WatchersUtils';

interface WatcherMenuProps {
  watchers: Watcher[] | undefined;
  showModal?: boolean;
  showSearch?: boolean;
  select?: (watcher: Watcher) => void;
}

export const WatcherMenu = ({
  watchers,
  showModal,
  showSearch = true,
  select,
}: WatcherMenuProps) => {
  const [filterWatchers, setFilterWatchers] = React.useState<Watcher[]>();
  const [filter, setFilter] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedWatcher, setSelectedWatcher] = React.useState<Watcher>();

  React.useEffect(() => {
    if (!filter) {
      const newValue = watchers || [];
      setFilterWatchers(curr => (isEqual(curr, newValue) ? curr : newValue));
      return;
    }
    const tokens = filter.split(/\s+/g).map(x => x.toLowerCase());
    const newValue = (watchers || []).filter(watcher => {
      const words = `${watcher.watcher_name || ''} ${watcher.app_name} ${
        watcher.description || ''
      }`
        .split(/\s+/g)
        .map(x => x.toLowerCase());
      return tokens.every(token => words.some(word => word.includes(token)));
    });
    setFilterWatchers(curr => (isEqual(curr, newValue) ? curr : newValue));
  }, [watchers, filter]);

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(e.target.value);
    },
    [],
  );

  const renderNoAppsFound = () => (
    <div className="octo-empty-container-large">
      <Empty description="No app found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );

  const handleAdd = (watcher: Watcher) => {
    select?.(watcher);
    setSelectedWatcher(watcher);
    if (showModal) {
      setOpen(true);
    }
  };

  if (!filterWatchers) {
    return null;
  }

  return (
    <div>
      {showSearch && (
        <div className="octo-search-container">
          <Input
            placeholder="Search watchers"
            allowClear
            onChange={onChangeSearch}
            value={filter}
          />
        </div>
      )}

      <div className="octo-apps-container">
        {
          filterWatchers.length > 0
            ? filterWatchers.map((watcher, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  key={watcher.app_id}
                  onClick={() => handleAdd(watcher)}
                  className="octo-app-container"
                >
                  <div
                    className="octo-app-icon-container"
                    key={index}
                    style={{
                      backgroundColor: [
                        '#f9f0ff',
                        '#fff0f6',
                        '#e6fffb',
                        '#f6ffed',
                        '#fffbe6',
                        '#fff7e6',
                        '#fff2e8',
                        '#fff1f0',
                        '#fcffe6',
                        '#f0f5ff',
                      ][index % 10],
                      border: [
                        '1px solid #d3adf7',
                        '1px solid #ffadd2',
                        '1px solid #87e8de',
                        '1px solid #b7eb8f',
                        '1px solid #ffe58f',
                        '1px solid #ffd591',
                        '1px solid #ffbb96',
                        '1px solid #ffa39e',
                        '1px solid #eaff8f',
                        '1px solid #adc6ff',
                      ][index % 10],
                    }}
                  >
                    <WatcherIcon index={index} />
                  </div>
                  <div className="octo-app-text-container">
                    <div className="octo-app-title-container">
                      <span className="octo-app-title">
                        {watcher.watcher_name || watcher.app_name}
                      </span>
                      <Tooltip
                        placement="top"
                        title={`${watcher.app_name} ${watcher.interval}`}
                      >
                        <InfoCircleOutlined className="octo-app-title-info" />
                      </Tooltip>
                    </div>
                    <span className="octo-app-description">
                      {watcher.description || t('No description')}
                    </span>
                  </div>
                </div>
              ))
            : renderNoAppsFound() // This will render when filterApps array is empty
        }
        {showModal && (
          <WatchersModal
            open={open}
            label={
              selectedWatcher
                ? `${t('Create new')} ${selectedWatcher.watcher_name}`
                : t('Create new Watcher')
            }
            setOpen={setOpen}
            defaultWatcher={selectedWatcher}
          />
        )}
      </div>
    </div>
  );
};
