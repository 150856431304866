import * as React from 'react';
import { isEqual } from 'lodash';

import { Badge, Button, Divider, Flex, Row, Space, Switch } from 'antd5';
import { LoadingOutlined } from '@ant-design/icons';
import { useDebounce } from 'react-use';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { ConceptMap } from '@octostar/platform-types'; // todo move to a package
import { bigNumberFormat } from 'src/octostar/lib/Formatters';
import {
  ConceptCounts,
  ConceptsInheritance,
} from 'src/octostar/components/ConceptSelector/types';
import Searchbox from './Searchbox';
import { Field } from './SearchField';
import {
  SearchState,
  BaseQuery,
  ENTITY_LABEL,
  generateBaseQuery,
  MAIN_RECORD_FIELD,
  SEARCHABLE_CONCEPT,
  state2conditions,
} from './SearchQuery';
import { SearchCondition } from './SearchCondition';

interface SearchOptionProps {
  onClearAll: () => void;
  autosearch: boolean;
  setAutosearch: (on: boolean) => void;
  onUserSearch: () => void;
  baseQueryResultsCount?: number;
}

const SearchOption = ({
  onClearAll,
  autosearch,
  setAutosearch,
  onUserSearch,
  baseQueryResultsCount,
}: SearchOptionProps) => (
  <Row>
    <Flex style={{ width: '100%' }} justify="space-between" align="center">
      <Space>
        <Space>
          <Switch
            onChange={setAutosearch}
            defaultChecked
            checked={autosearch}
          />
          Autosearch
        </Space>
        {autosearch && (
          <>
            <Divider type="vertical" />
            <Space>
              Results:
              <Badge
                count={
                  baseQueryResultsCount === undefined ||
                  baseQueryResultsCount < 0 ||
                  !autosearch
                    ? undefined
                    : bigNumberFormat(baseQueryResultsCount)
                }
                showZero={
                  baseQueryResultsCount !== undefined &&
                  baseQueryResultsCount >= 0
                }
                overflowCount={9999}
              />
            </Space>
          </>
        )}
      </Space>
      <Space>
        <Button onClick={onClearAll}>Clear all</Button>
        <Button
          type="primary"
          onClick={onUserSearch}
          style={autosearch ? { display: 'none' } : {}}
        >
          Search
          {autosearch &&
            baseQueryResultsCount !== undefined &&
            baseQueryResultsCount < 0 && <LoadingOutlined />}
        </Button>
      </Space>
    </Flex>
  </Row>
);

export interface SearchFormProps {
  onSearch: (conditions: SearchCondition[] | undefined) => void | undefined;
  autosearch: boolean;
  defaultConcept?: string[] | undefined;
  disableConceptSelector?: boolean | undefined;
  onClearAll: () => void;
  setAutosearch: (on: boolean) => void;
  defaultSearchFields?: { entity_label?: string; os_textsearchfield?: string };
}
const SearchForm = (props: SearchFormProps) => {
  const {
    onSearch,
    autosearch,
    defaultConcept,
    disableConceptSelector,
    onClearAll,
    setAutosearch,
    defaultSearchFields,
  } = props;
  const [concepts, setConcepts] = React.useState<[string, string][] | string[]>(
    [],
  );
  const [searchFields, setSearchFields] = React.useState<Field[]>([
    ENTITY_LABEL,
    MAIN_RECORD_FIELD,
  ]);
  const [ontologyConcepts, setOntologyConcepts] = React.useState<ConceptMap>();
  const [searchState, setSearchState] = React.useState<SearchState>({
    ...(defaultConcept ? { concepts: defaultConcept } : {}),
    ...defaultSearchFields,
  });
  const [baseQuery, setBaseQuery] = React.useState<BaseQuery | undefined>();
  const [conceptCounts, setConceptCounts] = React.useState<ConceptCounts>({});
  const [baseQueryResultsCount, setBaseQueryResultsCount] = React.useState<
    number | undefined
  >();
  const userSearchRequested = React.useRef(false);
  const context = `searchForm`;

  React.useEffect(() => {
    OntologyAPI.getSysInheritance()
      .then(a => {
        let mapped: [string, string][] = a.map(
          ({ base_concept, derived_concept }) => [
            base_concept,
            derived_concept,
          ],
        );
        if (
          mapped
            .map(([base_concept]) => base_concept)
            .indexOf(SEARCHABLE_CONCEPT) >= 0
        ) {
          setSearchFields([ENTITY_LABEL, MAIN_RECORD_FIELD]);
        } else {
          setSearchFields([ENTITY_LABEL]);
        }
        // remove os_* concepts from the tree
        const togo = mapped.filter(([, derived]) => derived.startsWith('os_'));
        const remap: { [key: string]: string } = togo.reduce(
          (map, [base, derived]) => {
            // eslint-disable-next-line no-param-reassign
            map[derived] = base;
            return map;
          },
          {} as { [key: string]: string },
        );
        // remap without the os_* concepts, removing any duplicates in the result.
        const duplicates: { [key: string]: boolean } = {};
        mapped = mapped
          .filter(
            ([base, derived]) =>
              !derived.startsWith('os_') && !base.startsWith('os_'),
          )
          .map(
            ([base, derived]) =>
              [remap[base] || base, derived] as [string, string],
          )
          .filter(([base, derived]) => {
            const key = `${base}|${derived}`;
            if (duplicates[key]) {
              return false;
            }
            duplicates[key] = true;
            return true;
          });
        setConcepts(mapped);
      })
      .catch(e => {
        console.log('problems mapping concepts', e);
        // TODO let user know a problem.
      });

    OntologyAPI.getConcepts()
      .then(x => {
        setOntologyConcepts(x);
      })
      .catch(e => {
        console.log('problems getting ontology concepts', e);
        // TODO let user know a problem.
      });
  }, []);

  const setBaseQueryMaybe = (
    time: number,
    state: SearchState,
  ): SearchCondition[] | undefined => {
    const conditions = state2conditions(state);
    if (time >= (baseQuery?.time || 0)) {
      const bq = generateBaseQuery(time, conditions || [], ontologyConcepts);
      if (bq?.query !== baseQuery?.query) {
        // only if it has changed
        setBaseQuery(bq);
      }
      return bq?.conditions;
    }
    return undefined;
  };

  const onUserSearch = () => {
    const conditions = setBaseQueryMaybe(new Date().getTime(), searchState);
    if (conditions) {
      userSearchRequested.current = true;
      onSearch(conditions);
    }
  };
  /**
   * How long to wait after user stops typing (milliseconds)
   */
  const DEBOUNCE_DELAY = 400;
  useDebounce(
    () => {
      const time = new Date().getTime() - DEBOUNCE_DELAY;
      const conditions = setBaseQueryMaybe(time, searchState);
      if (autosearch) {
        onSearch(conditions);
      }
    },
    DEBOUNCE_DELAY,
    [searchState, autosearch],
  );

  const handleSearchRequest = (state: any) => {
    const conditions = setBaseQueryMaybe(new Date().getTime(), state);
    if (conditions && autosearch) {
      onSearch(conditions);
    }
  };

  React.useEffect(() => {
    if (!baseQuery) {
      return;
    }
    if (!(autosearch || userSearchRequested.current)) {
      return;
    }
    userSearchRequested.current = false;
    OntologyAPI.cancelQueries(context);

    function updateBaseQueryResultsCount() {
      if (!baseQuery) {
        return;
      }
      setBaseQueryResultsCount(-1);
      OntologyAPI.sendQueryT<{ count: string }>(baseQuery.resultsCountQuery, {
        context,
      })
        .then(results => {
          if (results?.length && Array.isArray(results)) {
            setBaseQueryResultsCount(parseInt(results[0].count, 10));
          } else {
            setBaseQueryResultsCount(0);
          }
        })
        .catch(() => true);
    }
    function updateConceptCounts() {
      if (!baseQuery) {
        return;
      }
      const resetConceptsIfList = (newConcepts?: string[] | undefined) => {
        // was experimenting between tree and list concepts
        const newValue = newConcepts?.length
          ? newConcepts
          : Object.keys(ontologyConcepts || {});
        setConcepts(curr =>
          typeof curr?.[0] === 'string'
            ? isEqual(curr, newValue)
              ? curr
              : newValue
            : curr,
        );
      };

      OntologyAPI.sendQueryT<{ entity_type: string; count: string }>(
        baseQuery.conceptCountsQuery,
        {
          context,
        },
      )
        .then(async results => {
          setConceptCounts({});
          resetConceptsIfList();
          if (results?.length && Array.isArray(results)) {
            const counts: ConceptCounts = {};
            results.forEach(r => {
              counts[r.entity_type] = parseInt(r.count, 10);
            });
            const recurseUpdateParentCounts = (
              conceptName: string,
              count: number,
              seen: string[],
            ) => {
              seen.push(conceptName);
              ontologyConcepts?.get(conceptName)?.parents.forEach(parent => {
                if (seen.indexOf(parent) < 0) {
                  counts[parent] = (counts[parent] || 0) + count;
                  recurseUpdateParentCounts(parent, count, seen);
                }
              });
            };
            results.forEach(row => {
              const seen: string[] = [];
              recurseUpdateParentCounts(
                row.entity_type,
                parseInt(row.count, 10),
                seen,
              );
            });
            // other counts to zero so to be greyed out in concept selector
            ontologyConcepts?.forEach((_, conceptName) => {
              counts[conceptName] = counts[conceptName] || 0;
            });
            setConceptCounts(curr => (isEqual(curr, counts) ? curr : counts));
            resetConceptsIfList(Object.keys(counts));
          }
        })
        .catch(() => true);
    }
    updateConceptCounts();
    updateBaseQueryResultsCount();
  }, [autosearch, baseQuery, context, ontologyConcepts]);

  const onFieldChange = (field: string, state: any) => {
    setSearchState(state);
  };

  return (
    <>
      <Space style={{ width: '100%' }} direction="vertical" size="middle">
        <Searchbox
          size="middle"
          layout="vertical"
          defaultValue={searchState}
          concepts={concepts as ConceptsInheritance}
          fields={searchFields}
          onSearch={handleSearchRequest}
          onFieldChange={onFieldChange}
          conceptCounts={conceptCounts}
          disableConceptSelector={disableConceptSelector}
        />
        <SearchOption
          onClearAll={onClearAll}
          autosearch={autosearch}
          setAutosearch={setAutosearch}
          onUserSearch={onUserSearch}
          baseQueryResultsCount={baseQueryResultsCount}
        />
      </Space>
    </>
  );
};
export default SearchForm;
