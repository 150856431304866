import * as React from 'react';

import { Table, TableProps } from 'antd';
import { useAntdResizableHeader } from '@minko-fe/use-antd-resizable-header';
import '@minko-fe/use-antd-resizable-header/dist/style.css';

type ResizableTableProps<T> = TableProps<T>;
const DEFAULT_WIDTH = 120;
export const ResizableTable = (props: ResizableTableProps<any>) => {
  const { columns, pagination, ...other } = props;
  const tableRef = React.useRef<HTMLDivElement>(null);
  const { components, resizableColumns, tableWidth } = useAntdResizableHeader({
    columns: React.useMemo(() => columns as any, [columns]),
    cache: true,
    defaultWidth: DEFAULT_WIDTH,
    columnsState: {
      // TODO: user preferences api?
      persistenceKey: 'octostar-table-column-widths=000',
      persistenceType: 'localStorage',
    },
  });

  React.useLayoutEffect(() => {
    // overcomes a small bug in useAntdResizableHeader when column widths
    // are less than table width.
    tableRef.current?.querySelectorAll('.resizable-box').forEach(el => {
      const width = el.parentElement?.offsetWidth || 0;
      if (width && (el as HTMLDivElement).offsetWidth !== width) {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLDivElement).style.width = `${width}px`;
      }
    });
  });

  return (
    <Table
      ref={tableRef}
      // columns={resizableColumns}
      columns={resizableColumns.map(col => ({
        ...col,
        onCell: () => ({
          style: {
            padding: '0px',
            lineHeight: '8px',
          },
        }),
      }))}
      components={components}
      scroll={{ x: tableWidth }}
      pagination={pagination}
      {...other}
      sticky
    />
  );
};
