import * as React from 'react';
import {
  Actions,
  BorderNode,
  DockLocation,
  IJsonModel,
  IJsonTabNode,
  IJsonTabSetNode,
  ITabSetRenderValues,
  Model,
  TabNode,
  TabSetNode,
} from 'flexlayout-react';
import { Entity, EntityEdge } from '@octostar/platform-types';
import { isEqual } from 'lodash';
import {
  BorderTab,
  RelationshipData,
  RightSidebarController,
  TabDetails,
} from './types';
import { getRelationshipsDataFromEdges, nameToIndex } from './utils';

export const RightSidebarControllerContext = React.createContext<
  RightSidebarController | undefined
>(undefined);

export const useRightSidebarControllerContext = (): RightSidebarController => {
  const context = React.useContext(RightSidebarControllerContext);
  if (!context) {
    throw new Error(
      'useRightSidebarController must be used within a RightSidebarControllerProvider',
    );
  }
  return context;
};

export const RightSidebarControllerProvider: React.FC = ({ children }) => {
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [relationships, setRelationships] = React.useState<RelationshipData[]>(
    [],
  );
  const [borders, setBordersTab] = React.useState<BorderTab[]>([]);
  const [layoutModel, setLayoutModel] = React.useState<Model | undefined>();
  const [stateFlexlayoutBorders, setStateFlexlayoutBorders] =
    React.useState(true);
  const [detailsTabsContentMap, setDetailsTabsContentMap] = React.useState<
    Map<string, TabDetails>
  >(new Map());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabNodesMap = new Map<string, TabNode>();
  const previousLayout = React.useRef<IJsonModel>();

  const setTabContent = React.useCallback(
    (name: string, content: JSX.Element, badgeCounter?: number) => {
      const currentJsonModel = layoutModel?.toJson();
      const tabIndex = nameToIndex(name, currentJsonModel);
      if (tabIndex === -1) {
        return;
      }
      setDetailsTabsContentMap(x => {
        const old = new Map(x);
        old.set(name, { content, badgeCounter: badgeCounter || 0 });
        return old;
      });
    },
    [layoutModel],
  );

  const setTabCounter = React.useCallback(
    (name: string, badgeCounter?: number) => {
      if (!detailsTabsContentMap.has(name)) {
        return;
      }
      setTabContent(
        name,
        detailsTabsContentMap.get(name)?.content || <></>,
        badgeCounter,
      );
    },
    [detailsTabsContentMap, setTabContent],
  );

  const selectBorderTab = React.useCallback(
    (name: string | -1) => {
      const currentJsonModel = layoutModel?.toJson();
      if (!currentJsonModel?.borders?.[0]) {
        return;
      }
      // @ts-ignore
      currentJsonModel.borders[0].selected = nameToIndex(
        name,
        currentJsonModel,
      );
      if (!currentJsonModel) {
        return;
      }
      const newModel = Model.fromJson(currentJsonModel);
      setLayoutModel(newModel);
    },
    [layoutModel],
  );

  const setSelectedTabContent = React.useCallback(
    (name: string, content: JSX.Element, badgeCounter?: number) =>
      setTabContent(name, content, badgeCounter),
    [setTabContent],
  );

  const setSelectedTab = React.useCallback(
    (name: string | -1) => selectBorderTab(name),
    [selectBorderTab],
  );
  const getTabIndex = (): number => {
    if (!layoutModel) {
      return -1;
    }
    const currentJsonModel = layoutModel.toJson();
    const border = currentJsonModel.borders?.[0];
    if (border?.selected !== undefined) {
      return border.selected;
    }
    return -1;
  };
  const changeFlexlayoutBorders = React.useCallback(async () => {
    if (!layoutModel) {
      return;
    }
    const currentJsonModel = layoutModel.toJson();

    if (stateFlexlayoutBorders) {
      if (currentJsonModel) {
        previousLayout.current = currentJsonModel;
      }
      // Remove all the tabs and add them to the right border
      const tabsToMove: IJsonTabNode[] = [];
      layoutModel.visitNodes(node => {
        if (
          node &&
          node.getType() === 'tabset' &&
          node.getId() !== 'flexwrapper-tabset'
        ) {
          const tabset = node.toJson() as IJsonTabSetNode;
          if (tabset?.children?.length) {
            tabset.children.forEach(tab => {
              if (tab.type === 'tab') {
                tabsToMove.push(tab);
              }
            });
          }
        }
      });
      tabsToMove.forEach((tab: IJsonTabSetNode) => {
        if (tab?.id) {
          layoutModel.doAction(
            Actions.moveNode(
              tab.id,
              tab.config,
              'border_right',
              DockLocation.CENTER,
              -1,
            ),
          );
        }
      });

      setStateFlexlayoutBorders(false);
    } else {
      // restore tabs with previous layout
      if (previousLayout.current) {
        setLayoutModel(Model.fromJson(previousLayout.current));
        const tabsPreviousModel: string[] = [];
        layoutModel.visitNodes(node => {
          if (node.getType() === 'tab') {
            const tab = node.toJson() as IJsonTabNode;
            if (tab.name) {
              tabsPreviousModel.push(tab.name);
            }
          }
        });
        const bordersUpdted = borders.map(border => {
          if (tabsPreviousModel.includes(border.name)) {
            return {
              ...border,
              isBorderTab: false,
            };
          }
          return border;
        });
        setBordersTab(bordersUpdted);
      }

      setStateFlexlayoutBorders(true);
    }
  }, [borders, layoutModel, stateFlexlayoutBorders]);

  const setEdges = React.useCallback((edges: EntityEdge[]) => {
    getRelationshipsDataFromEdges(edges).then(relationships =>
      setRelationships(curr => {
        const newRelationships = relationships.reduce(
          (acc: RelationshipData[], current) => {
            const isDuplicate = acc.some(
              item => item.entity_id === current.entity_id,
            );
            if (!isDuplicate) {
              acc.push(current);
            }
            return acc;
          },
          [],
        );
        const relationshipsIds = newRelationships.map(x => x.entity_id).sort();
        const currIds = curr.map(x => x.entity_id).sort();
        if (isEqual(currIds, relationshipsIds)) {
          return curr;
        }
        return newRelationships;
      }),
    );
  }, []);

  return (
    <RightSidebarControllerContext.Provider
      value={{
        entities,
        setEntities,
        relationships,
        setEdges,
        borders,
        setBordersTab,
        layoutModel,
        setLayoutModel,
        setSelectedTabContent,
        setSelectedTab,
        getTabIndex,
        detailsTabsContentMap,
        setDetailsTabsContentMap,
        tabNodesMap,
        stateFlexlayoutBorders,
        changeFlexlayoutBorders,
        setTabContent,
        setTabCounter,
      }}
    >
      {children}
    </RightSidebarControllerContext.Provider>
  );
};

export const fixTabsetHeight = (
  tabSetNode: TabSetNode | BorderNode,
  renderValues: ITabSetRenderValues,
) => {
  if (tabSetNode.getId() === 'flexwrapper-tabset') {
    // eslint-disable-next-line no-param-reassign
    renderValues.headerContent = <div>oops</div>;
  }
};

export const padComponent = (component: JSX.Element) => (
  <div className="flexwrapper-bg">
    <div className="flexwrapper-padded">{component}</div>
  </div>
);
