import _ from 'lodash';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { getAttachmentText } from 'src/octostar/hooks/useAttachment';
import { isFile } from 'src/octostar/interface';
import { Concept, ConceptMap, WorkspaceItem } from '@octostar/platform-types';
import { getLabelKeys } from '../ConceptUtils';
import { resolveSchema, resolveSchemaUI } from './Schema';
import { SchemaJSON, SchemaType, SchemaUiJSON } from './types';
import {
  CUSTOM_TEMPLATE_SCHEMA,
  CUSTOM_TEMPLATE_SCHEMA_UI,
  getParentsConcept,
  sortByLastUpdatedAt,
  sortSchemaObject,
} from './util';

const mergeSchema = (
  schema: SchemaJSON,
  schemaParent: SchemaJSON,
  labelKeys: string[],
  requiredFieldsOnly?: boolean,
): SchemaJSON => {
  const newSchema: SchemaJSON = {
    ...schema,
    required: [...schema.required],
    properties: { ...schema.properties },
    dependencies: { ...schema.dependencies },
    allOf: [...schema.allOf],
  };

  schemaParent.required?.forEach((required: string) => {
    if (!newSchema.required.includes(required)) {
      newSchema.required.push(required);
    }
  });

  if (requiredFieldsOnly) {
    newSchema.properties = Object.keys(schemaParent.properties).reduce(
      (acc, property) => {
        if (
          newSchema.required.includes(property) ||
          labelKeys.includes(property)
        ) {
          acc[property] = schemaParent.properties[property];
        }
        return acc;
      },
      newSchema.properties,
    );
  } else {
    Object.assign(newSchema.properties, schemaParent.properties);
  }

  newSchema.properties = sortSchemaObject(newSchema.properties);
  newSchema.dependencies = sortSchemaObject({
    ...newSchema.dependencies,
    ...schemaParent.dependencies,
  });

  if (schemaParent.allOf?.length > 0) {
    newSchema.allOf = newSchema.allOf.concat(schemaParent.allOf);
  }

  return newSchema;
};

const mergeSchemaUI = (
  schemaUI: SchemaUiJSON,
  schemaUIParent: SchemaUiJSON,
): SchemaUiJSON => {
  const newSchemaUI: SchemaUiJSON = { ...schemaUI };

  if (!_.isEmpty(schemaUIParent)) {
    Object.keys(schemaUIParent).forEach(property => {
      if (newSchemaUI[property]) {
        newSchemaUI[property] = {
          ...newSchemaUI[property],
          ...schemaUIParent[property],
        };
      } else {
        newSchemaUI[property] = schemaUIParent[property];
      }
    });

    if (schemaUIParent['ui:order']?.length) {
      newSchemaUI['ui:order'] = newSchemaUI['ui:order']?.length
        ? _.uniq([...newSchemaUI['ui:order'], ...schemaUIParent['ui:order']])
        : _.uniq([...schemaUIParent['ui:order']]);
    }
  }

  return sortSchemaObject(newSchemaUI);
};

export const resolveSchemaFromAttachment = async (
  conceptName: string,
  schemaType: SchemaType,
  schemaItems: WorkspaceItem[],
): Promise<SchemaJSON | SchemaUiJSON | undefined> => {
  let os_item_content_type = '';
  let os_item_name = '';
  if (schemaType === SchemaType.SCHEMA) {
    os_item_content_type = CUSTOM_TEMPLATE_SCHEMA;
    os_item_name = `${SchemaType.SCHEMA}.${conceptName}.yaml`;
  } else if (schemaType === SchemaType.SCHEMA_UI) {
    os_item_content_type = CUSTOM_TEMPLATE_SCHEMA_UI;
    os_item_name = `${SchemaType.SCHEMA_UI}.${conceptName}.yaml`;
  } else {
    return undefined;
  }
  const schemaItem: WorkspaceItem | undefined = schemaItems
    .filter(
      x =>
        x.os_item_name === os_item_name &&
        isFile(x) &&
        x.os_item_content_type === os_item_content_type,
    )
    .sort(sortByLastUpdatedAt)
    .shift();
  if (!schemaItem) {
    return undefined;
  }
  const schema = await getAttachmentText(
    schemaItem,
    schemaItem.entity_label,
    true,
  );
  if (schema && schema !== schemaItem.entity_label) {
    return JSON.parse(schema);
  }
  return undefined;
};

export const resolveSchemaUiFromOntology = (
  concept: Concept,
): SchemaUiJSON | undefined => resolveSchemaUI(concept);

/**
 * Resolves {@link schemaUI} inheritance for a concept entity by merging UI schemas
 * from its parent concepts.
 *
 * @param {string} conceptEntity - The concept entity for which to resolve schema UI inheritance.
 * @param {boolean} [excludeEntityConcept] - Optional flag to exclude the entity concept from the resolved schema UI.
 * @returns {Promise<SchemaUiJSON>} - A Promise that resolves to the merged schema UI for the concept entity.
 */
export const resolveSchemaUiInheritance = async (
  conceptEntity: string,
  conceptsMap: ConceptMap,
): Promise<SchemaUiJSON> => {
  let schemaUI: SchemaUiJSON = {};
  const schemaUiItems = await DesktopAPI.getSchemaItems(
    CUSTOM_TEMPLATE_SCHEMA_UI,
  );
  const concept = conceptsMap.get(conceptEntity) as Concept;
  const parentsConcept = getParentsConcept(concept.parents, conceptEntity);

  await Promise.all(
    parentsConcept.map(async parentName => {
      const concept = conceptsMap.get(parentName) as Concept;
      if (!concept?.properties?.length) {
        return;
      }
      const schemaUiFromAttachment = await resolveSchemaFromAttachment(
        parentName,
        SchemaType.SCHEMA_UI,
        schemaUiItems,
      );
      if (schemaUiFromAttachment) {
        schemaUI = mergeSchemaUI(
          schemaUI,
          schemaUiFromAttachment as SchemaUiJSON,
        );
      } else {
        const schemaUiFromOntology = resolveSchemaUiFromOntology(concept);
        if (schemaUiFromOntology) {
          schemaUI = mergeSchemaUI(schemaUI, schemaUiFromOntology);
        }
      }
    }),
  );

  return schemaUI;
};

export const resolveSchemaFromOntology = async (
  concept: Concept,
): Promise<SchemaJSON | undefined> => {
  if (!concept || !concept?.properties?.length) {
    return undefined;
  }
  return resolveSchema(concept);
};

/**
 * Resolves {@link schema} inheritance for a concept entity by merging schemas
 * from its parent concepts.
 *
 * @param {string} conceptEntity - The concept entity for which to resolve schema inheritance.
 * @param {boolean} [requiredFieldsOnly] - Optional flag to include only required fields in the resolved schema.
 * @param {boolean} [excludeEntityConcept] - Optional flag to exclude the entity concept from the resolved schema.
 * @returns {Promise<SchemaJSON>} - A Promise that resolves to the merged schema for the concept entity.
 */
export const resolveSchemaInheritance = async (
  conceptEntity: string,
  conceptsMap: ConceptMap,
  requiredFieldsOnly?: boolean,
): Promise<SchemaJSON> => {
  let schema: SchemaJSON = {
    title: conceptEntity.toLowerCase(),
    description: '',
    type: 'object',
    required: [],
    properties: {},
    dependencies: {},
    allOf: [],
  };
  const schemaItems = await DesktopAPI.getSchemaItems(CUSTOM_TEMPLATE_SCHEMA);
  const concept = conceptsMap.get(conceptEntity) as Concept;
  const labelKeys = getLabelKeys(concept, conceptsMap);
  const parentsConcept = getParentsConcept(concept.parents, conceptEntity);

  await Promise.all(
    parentsConcept.map(async parentName => {
      const concept = conceptsMap.get(parentName) as Concept;
      if (!concept?.properties?.length) {
        return;
      }
      const schemaFromAttachment = await resolveSchemaFromAttachment(
        parentName,
        SchemaType.SCHEMA,
        schemaItems,
      );
      if (schemaFromAttachment) {
        schema = mergeSchema(
          schema,
          schemaFromAttachment as SchemaJSON,
          labelKeys,
          requiredFieldsOnly,
        );
      } else {
        const schemaFromOntology = await resolveSchemaFromOntology(concept);
        if (schemaFromOntology) {
          schema = mergeSchema(
            schema,
            schemaFromOntology,
            labelKeys,
            requiredFieldsOnly,
          );
        }
      }
    }),
  );

  if (schema.allOf?.length === 0) {
    delete schema.allOf;
  }
  return schema;
};
