/* eslint-disable no-param-reassign */
import * as React from 'react';
import { CommentOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Space, Tag, Tooltip } from 'antd5';
import {
  Action,
  BorderNode,
  ITabSetRenderValues,
  Layout,
  Model,
  TabNode,
  TabSetNode,
} from 'flexlayout-react';
import { ITabRenderValues } from 'flexlayout-react/types';
import _ from 'lodash';
import { WatchersAndIntentsForEntities } from 'src/octostar/components/Apps/watchers/IntentsForEntities';
import { EntityCommentsCollection } from 'src/octostar/components/Entity/Comments/EntityCommentsCollection';
import {
  RightSidebarControllerProvider,
  fixTabsetHeight,
  padComponent,
  useRightSidebarControllerContext,
} from './WrapperRightSidebarContext';
import { BorderTab } from './types';
import {
  SIDEBAR_ID,
  addIfNotFound,
  addTabToModel,
  getColorBadgeBorder,
  getJsonModel,
} from './utils';
import './WrapperRightSidebar.css';

export const MIN_SIDEBAR_WIDTH_FRACTION = 0.3;

export const WrapperRightSidebarController = ({
  borderTabs,
  withEntities,
  children,
}: {
  borderTabs: BorderTab[];
  withEntities?: boolean;
  children: JSX.Element | ((node: TabNode) => JSX.Element);
  onRenderTabset?: (
    tabSetNode: TabSetNode | BorderNode,
    renderValues: ITabSetRenderValues,
  ) => void;
}) => {
  if (withEntities) {
    addIfNotFound(
      'Comments',
      {
        name: 'Comments',
        component: () => <EntityCommentsCollection />,
        icon: <CommentOutlined />,
        componentUnwrapped: true,
      },
      borderTabs,
    );

    addIfNotFound(
      'Watchers',
      {
        name: 'Watchers',
        component: () => <WatchersAndIntentsForEntities />,
        icon: <EyeOutlined />,
      },
      borderTabs,
    );
  }

  const jsonModel = getJsonModel(borderTabs);
  borderTabs.forEach(tab => {
    addTabToModel(tab, jsonModel);
  });

  const {
    layoutModel,
    setLayoutModel,
    detailsTabsContentMap,
    tabNodesMap,
    borders,
    setBordersTab,
  } = useRightSidebarControllerContext();

  React.useEffect(() => {
    if (borders.length === 0) {
      setBordersTab(borderTabs);
    }

    if (!layoutModel) {
      setLayoutModel(curr =>
        !curr
          ? Model.fromJson(jsonModel)
          : _.isEqual(curr?.toJson(), jsonModel)
          ? curr
          : Model.fromJson(jsonModel),
      );
    }
  }, [
    borderTabs,
    borders.length,
    jsonModel,
    layoutModel,
    setBordersTab,
    setLayoutModel,
  ]);

  const factory =
    () =>
    (node: TabNode): any => {
      const childContent =
        typeof children === 'function' ? children(node) : children;

      const component = node.getComponent();
      if (component === 'content') {
        return node.getConfig()?.componentUnwrapped
          ? childContent
          : padComponent(childContent);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const tab of borderTabs) {
        if (component === tab.name) {
          tabNodesMap.set(tab.name, node);
          const comp =
            detailsTabsContentMap.get(tab.name)?.content || tab.component(node);
          return node.getConfig()?.componentUnwrapped
            ? comp
            : padComponent(comp);
        }
      }
      return <span>THIS IS A BUG, please report! {component}</span>; // not working
    };

  const theFactory = React.useMemo(
    () => factory(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailsTabsContentMap],
  );

  const onRenderTab = React.useCallback(
    (node: TabNode, renderValues: ITabRenderValues) => {
      const name = node.getName();
      const config = node.getConfig();
      const customIcon = config?.customIcon;
      const isBorderTab = node.getParent() instanceof BorderNode;
      const isBorderBottom =
        borderTabs.find(tab => tab.name === name)?.position === 'bottom';
      const badgeCounter = detailsTabsContentMap.get(name)?.badgeCounter || 0;

      const labelCss = {
        transform:
          customIcon && isBorderTab && !isBorderBottom
            ? 'rotate(-90deg)'
            : undefined,
        fontSize: customIcon && isBorderTab ? '16px' : undefined,
      };

      const labelContent =
        isBorderTab && customIcon ? (
          isBorderBottom ? (
            <div className="custom-flexlayout-border-label-content">
              {customIcon}
              {name}
            </div>
          ) : (
            customIcon
          )
        ) : (
          <Space size="small">
            {customIcon}
            {name}
          </Space>
        );

      const tooltipContent =
        isBorderTab && customIcon ? (
          <Tooltip placement="left" title={name}>
            <div
              className={
                isBorderBottom
                  ? 'flexlayout-tab-icon-label'
                  : 'flexlayout-tab-icon'
              }
              style={labelCss}
            >
              {isBorderBottom ? (
                <div className="flexlayout-tab-label-counter">
                  {labelContent}
                  {badgeCounter > 0 && <Tag>{badgeCounter}</Tag>}
                </div>
              ) : (
                <Badge
                  showZero={false}
                  size="small"
                  count={badgeCounter}
                  offset={[6, 0]}
                  color={getColorBadgeBorder(name)}
                >
                  {labelContent}
                </Badge>
              )}
            </div>
          </Tooltip>
        ) : (
          <div style={labelCss}>
            <Badge
              showZero={false}
              size="small"
              count={badgeCounter}
              offset={[6, 0]}
              color={getColorBadgeBorder(name)}
            >
              {labelContent}
            </Badge>
          </div>
        );

      // eslint-disable-next-line no-param-reassign
      renderValues.content = tooltipContent;
    },
    [borderTabs, detailsTabsContentMap],
  );

  const onModelChange = (model: Model, action: Action) => {
    if (
      action.type === 'FlexLayout_MoveNode' ||
      action.type === 'FlexLayout_SelectTab'
    ) {
      const currentJsonModel = model?.toJson();
      const bordersTab = borders;
      if (!currentJsonModel?.borders?.[0]) {
        // eslint-disable-next-line no-return-assign
        const newBorders = bordersTab.map(tab => ({
          ...tab,
          isBorderTab: false,
        }));
        setBordersTab(newBorders);
      } else {
        const newBorders = bordersTab.map(tab => {
          if (
            currentJsonModel?.borders?.[0].children.find(
              child => child.name === tab.name,
            )
          ) {
            return { ...tab, isBorderTab: true };
          }
          return { ...tab, isBorderTab: false };
        });
        setBordersTab(newBorders);
      }
    }
    setLayoutModel(curr => (_.isEqual(curr, model) ? curr : model));
  };

  const actionHandler = (action: Action): Action | undefined => {
    if (action.data && action.type === 'FlexLayout_AdjustSplit') {
      if (action.data.pixelWidth1 && action.data.pixelWidth2) {
        if (action.data.node2 !== SIDEBAR_ID) {
          return action;
        }
        const totalPx = action.data.pixelWidth1 + action.data.pixelWidth2;
        const totalWeight = action.data.weight1 + action.data.weight2;
        if (action.data.pixelWidth2 / totalPx < MIN_SIDEBAR_WIDTH_FRACTION) {
          // if width is less than min value set min width
          action.data.pixelWidth2 = MIN_SIDEBAR_WIDTH_FRACTION * totalPx;
          action.data.pixelWidth1 = totalPx - action.data.pixelWidth2;
          action.data.weight2 = MIN_SIDEBAR_WIDTH_FRACTION * totalWeight;
          action.data.weight1 = totalWeight - action.data.weight2;
        }
        return action;
      }
    }
    return action;
  };

  if (!layoutModel) {
    return <></>;
  }

  return (
    <div className="custom-wrapper-flexlayout">
      <Layout
        model={layoutModel}
        factory={theFactory}
        onModelChange={onModelChange}
        onRenderTabSet={fixTabsetHeight}
        onRenderTab={onRenderTab}
        onAction={actionHandler}
      />
    </div>
  );
};

export const WrapperRightSidebar = ({
  borderTabs,
  withEntities,
  children,
}: {
  borderTabs: BorderTab[];
  withEntities?: boolean;
  children: JSX.Element | ((node: TabNode) => JSX.Element);
  onRenderTabset?: (
    tabSetNode: TabSetNode | BorderNode,
    renderValues: ITabSetRenderValues,
  ) => void;
}) => (
  <RightSidebarControllerProvider>
    <WrapperRightSidebarController
      borderTabs={borderTabs}
      withEntities={withEntities}
    >
      {children}
    </WrapperRightSidebarController>
  </RightSidebarControllerProvider>
);
