import * as React from 'react';
import { JSONTree } from 'react-json-tree';
import { Entity } from '@octostar/platform-types';
import { useRightSidebarControllerContext } from '../flexlayout/wrappers/WrapperRightSidebarContext';
import { RelationshipData } from '../flexlayout/wrappers/types';
import { BorderTabHead } from '../flexlayout/wrappers/BorderTabHead';
import { EmptyBorder } from '../flexlayout/wrappers/EmptyBorder';
import './BorderTab.css';

const shouldExpandNode = (keyName: any, data: any, level: number) =>
  level === 1;

const MetadataItem = ({
  item,
  label,
}: {
  item: Entity | RelationshipData;
  label: string;
}) => (
  <div className="flexlayout-border-tab-item">
    <div className="flexlayout-border-tab-item-header">
      <span className="flexlayout-border-tab-item-title">
        <span className="flexlayout-border-tab-item-label">{`${label}`}</span>
      </span>
    </div>
    <JSONTree
      data={item}
      theme="bright"
      shouldExpandNode={shouldExpandNode}
      hideRoot
      sortObjectKeys
    />
  </div>
);

export const Metadata = () => {
  const { entities, relationships } = useRightSidebarControllerContext();

  return (
    <div title="Metadata">
      <BorderTabHead
        title="Metadata"
        tabName="Metadata"
        showCloseBorderButton
        items={[]}
      />
      {entities.length === 0 && relationships.length === 0 ? (
        <EmptyBorder description="Select any nodes or edges to see all their metadata" />
      ) : (
        <>
          {entities.map(entity => (
            <div
              className="flexlayout-border-tab-container"
              key={entity.entity_id || crypto.randomUUID()}
            >
              <MetadataItem item={entity} label={entity.entity_label} />
            </div>
          ))}
          {relationships.map(relationship => (
            <div
              className="flexlayout-border-tab-container"
              key={relationship.entity_id || crypto.randomUUID()}
            >
              <MetadataItem
                item={relationship}
                label={relationship.os_relationship_name}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
