import * as React from 'react';
import { Empty } from 'antd5';

export const EmptyBorder = ({
  image,
  description,
}: {
  image?: string;
  description?: string;
}) => (
  <div className="entity-placeholder">
    <Empty
      image={image || Empty.PRESENTED_IMAGE_SIMPLE}
      description={description || 'Select any nodes to see all their contents'}
    />
  </div>
);
