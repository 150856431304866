import * as React from 'react';
import { BootstrapUser } from 'src/types/bootstrapTypes';
import { useSelector } from 'react-redux';
import { Desktop, Entity } from '@octostar/platform-types';
import DesktopAPI from '../lib/DesktopAPI/DesktopAPI';
import OntologyContext from './OntologyContext';
import { registerServer } from '../api/event-driven';
import ee, { clientCore } from '../interface';
import { BUILTINS_MESSAGE_TYPES, DESKTOP_PREFIX } from '../api/messagesTypes';
import { TimbrStorage } from '../lib/DesktopAPI/TimbrStorage';
import { getIcon } from './ConfiguredIcon';
import { Icon } from './layout/Icon';
import UserContext from './UserContext';
import OntologyAPI from '../lib/OntologyAPI';
import { isPerformingWSAction } from '../store';

export const DesktopContext = React.createContext<Desktop>({} as Desktop);

const ICON_CONFIGS = {};
const IconConfigurationRequestHandler = React.memo(
  ({ entity, topic }: { entity: Entity; topic: string }) => {
    const [icon, setIcon] = React.useState<string>();
    const renderRef = React.useCallback(
      (node: HTMLDivElement) => {
        if (node) {
          // TODO: for antd icons which are svg see
          // https://gist.github.com/jennyknuth/222825e315d45a738ed9d6e04c7a88d0

          // for now this works only for font awesome icons
          const computedStyle = getComputedStyle(node, ':before');
          const value = computedStyle.getPropertyValue('content');

          const fontFamily = computedStyle.getPropertyValue('font-family');
          const config = {
            type: 'text',
            value: `${value}`.replace(/"/g, ''),
            fontFamily,
          };
          ICON_CONFIGS[icon!] = config;
          ee.emit(topic, { status: 'success', data: { ...config } });
        }
      },
      [icon, topic],
    );
    React.useEffect(() => {
      const gettheIcon = async () => {
        const icon = await getIcon(entity, topic);
        if (icon === topic) {
          console.log('icon not found for entity', entity);
          ee.emit(topic, { status: 'success', data: {} }); // TODO it wasn't successfull, was it.
        } else if (ICON_CONFIGS[icon]) {
          ee.emit(topic, {
            status: 'success',
            data: { ...ICON_CONFIGS[icon] },
          });
        } else {
          setIcon(icon);
        }
      };
      gettheIcon();
    }, [entity, topic]);

    if (!icon) {
      return <></>;
    }
    return <Icon icon={icon} iconRef={renderRef} />;
  },
);

const IconConfigurationService = () => {
  const [handlers, setHandlers] = React.useState<
    { topic: string; entity: Entity }[]
  >([]);
  React.useEffect(() => {
    const handler = (topic: string, entity: Entity) => {
      ee.once(topic, () => {
        setHandlers(x => x.filter(y => y.topic !== topic));
      });
      setHandlers(x => [...x, { topic, entity }]);
    };
    ee.emit(
      clientCore('apiRegister'),
      BUILTINS_MESSAGE_TYPES.getIconConfiguration,
      `{
        entity: Entity;
      }`,
    );
    ee.on(BUILTINS_MESSAGE_TYPES.getIconConfiguration, handler);
    return () => {
      ee.off(BUILTINS_MESSAGE_TYPES.getIconConfiguration, handler);
    };
  }, []);
  return (
    <div style={{ visibility: 'hidden' }}>
      {handlers.map(x => (
        <IconConfigurationRequestHandler
          key={x.topic}
          topic={x.topic}
          entity={x.entity}
        />
      ))}
    </div>
  );
};

function instantiateDesktopApi(
  ontologyApi?: OntologyAPI,
  user?: BootstrapUser,
) {
  if (ontologyApi) {
    return new DesktopAPI(new TimbrStorage(ontologyApi), {
      email: '',
      firstName: '',
      lastName: '',
      username: '',
      ...user,
    });
  }
  return new DesktopAPI();
}

export const DesktopContextProvider = ({ children }: { children: any }) => {
  const { ontologyAPI } = React.useContext(OntologyContext);
  const { user } = React.useContext(UserContext);
  const isPerformingAction = useSelector(isPerformingWSAction);

  // VSFQuery: Looks like this provider is being rendered 3x, causing 3x execution of
  const [desktop, setDesktop] = React.useState<Desktop>(
    instantiateDesktopApi(ontologyAPI, user),
  );
  const timer = React.useRef(setTimeout(() => undefined, 0));

  React.useEffect(() => {
    if (!desktop) {
      return undefined;
    }
    const handler = (e: { message: string[] }) => {
      console.log('🤡 Something changed in the workspace!');
      const workspace_ids: string[] = e.message;
      desktop.getOpenWorkspaceIds().then(open_workspace_ids => {
        if (open_workspace_ids.some(x => workspace_ids.includes(x))) {
          desktop?.refresh();
        }
      });
    };
    // VSF27
    const topic = BUILTINS_MESSAGE_TYPES.workspaceObjectUpdate;
    ee.on(topic, handler);

    return () => {
      ee.off(topic, handler);
    };
  }, [desktop]);

  React.useEffect(() => {
    // VSF27: Block this if WS is updating
    if (isPerformingAction) return () => undefined;

    const oldTimer = (timer.current = setInterval(() => {
      if (desktop) {
        (desktop as DesktopAPI)?.refresh();
      }
    }, 30000));
    return () => {
      clearTimeout(oldTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop, isPerformingAction]);

  React.useEffect(() => {
    if (ontologyAPI) {
      const newDesktopApi = instantiateDesktopApi(ontologyAPI, user);
      setDesktop(newDesktopApi);
      // VS: Isn't that called a service/dao/listener, reads very confusing as to what it does.
      registerServer(newDesktopApi, DesktopAPI, DESKTOP_PREFIX, ee);
    }
    // VS: Shouldn't we have a dependency on user as well?
  }, [ontologyAPI, user]);

  console.log(`rendering desktop`, desktop);
  return (
    <DesktopContext.Provider value={desktop}>
      {children}
      <IconConfigurationService />
    </DesktopContext.Provider>
  );
};
