import * as React from 'react';
import {
  WorkspaceItem,
  WorkspaceItemIdentifier,
} from '@octostar/platform-types';
import DesktopAPI from '../api/event-driven/desktop';

export const DEFAULT_COLOR = '#0C66E4'; // TODO doesn't belong here.
// tried using getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
// but didn't work as expected.

export const useCurrentWorkspaceItemColor = (
  item?: WorkspaceItemIdentifier,
  useDefault?: boolean,
) => {
  const [color, setColor] = React.useState(DEFAULT_COLOR);
  const [wi, setWi] = React.useState<WorkspaceItem>();
  React.useEffect(() => {
    if (
      item === undefined ||
      useDefault ||
      (typeof (item !== 'string') && !(item as WorkspaceItem)?.os_entity_uid)
    ) {
      setColor(DEFAULT_COLOR);
      return undefined;
    }

    // react to change in the workspace color
    DesktopAPI.getItem(item)
      .then(setWi)
      .catch(e => {
        console.error(e);
        return undefined;
      });
    const promise = DesktopAPI.onWorkspaceItemChanged(item, setWi);
    return () => {
      promise.then(unsub => unsub());
    };
  }, [item, useDefault]);

  React.useEffect(() => {
    if (useDefault) {
      return undefined;
    }
    if (!wi) {
      return undefined;
    }
    if (wi?.os_item_content?.color) {
      setColor(wi.os_item_content.color);
      return undefined;
    }
    if (wi.os_item_type === 'os_workspace') {
      return undefined;
    }
    const uid = wi.os_workspace;
    if (!uid) {
      setColor(curr => curr || DEFAULT_COLOR);
      return undefined;
    }
    const setColorFromWorkspace = (workspace: WorkspaceItem) => {
      if (workspace?.os_item_content?.color) {
        setColor(workspace.os_item_content.color);
      }
    };

    // react to change in the workspace color
    DesktopAPI.getItem(uid)
      .then(setColorFromWorkspace)
      .catch(e => {
        console.error(e);
        return undefined;
      });
    const promise = DesktopAPI.onWorkspaceItemChanged(
      uid,
      setColorFromWorkspace,
    );
    return () => {
      promise.then(unsub => unsub());
    };
  }, [wi, useDefault]);
  return color;
};

export default useCurrentWorkspaceItemColor;
