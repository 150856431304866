import { Entity, EventEmitterType } from '@octostar/platform-types';
import EE, { getNotificationKey } from '../interface';

export class NotifyDebouncer {
  private ee = EE;

  private debounceTime = 50;

  constructor(ee?: EventEmitterType, debounceTime?: number) {
    this.ee = ee || EE;
    this.debounceTime = debounceTime || 50;
  }

  private debounceMap = new Map<
    string,
    { timeout: NodeJS.Timeout; value: any }
  >();

  notifyAsync(entity: Entity) {
    const topic = getNotificationKey(entity);
    this.notifyAsyncGeneric(topic, entity);
  }

  notifyAsyncGeneric(topic: string, value: any) {
    const existing = this.debounceMap.get(topic);
    if (existing) {
      clearTimeout(existing.timeout);
    }

    const timeout = setTimeout(() => {
      this.debounceMap.delete(topic);
      this.ee.emit(topic, value);
    }, this.debounceTime);

    this.debounceMap.set(topic, { timeout, value });
  }
}

// Example usage
const debouncer = new NotifyDebouncer();
export const notifyEntityUpdated = (entity: Entity) => {
  debouncer.notifyAsync(entity);
};
