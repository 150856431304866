const DEBOUNCE_MINUTES = 5; // Time to debounce in minutes
const COOLING_PERIOD = DEBOUNCE_MINUTES * 60 * 1000; // minutes in milliseconds
const timeBuckets = Array.from({ length: DEBOUNCE_MINUTES }, () => ({
  timebucket: 0,
  keys: new Set(),
}));

export const dbconsole = Object.freeze({
  log: debounceConsole('log'),
  warn: debounceConsole('warn'),
  error: debounceConsole('error'),
});

function debounceConsole(method: string) {
  return function (message: string, ...args: any[]) {
    const now = Date.now();
    const key = `${method}:${message}`;

    cleanupOldEntries(now);

    for (let bucket of timeBuckets) {
      if (bucket.keys.has(key)) {
        return; // If the key exists in any bucket, don't log and exit early.
      }
    }

    console[method](message, ...args);
    console.log(
      `(the above message will not be repeated for at ${DEBOUNCE_MINUTES} minutes)`,
    );
    timeBuckets[DEBOUNCE_MINUTES - 1].keys.add(key); // Add to the latest bucket
  };
}

function cleanupOldEntries(currentTime: number) {
  const oldestAllowedTime = currentTime - COOLING_PERIOD;
  while (timeBuckets.length && timeBuckets[0].timebucket < oldestAllowedTime) {
    // Shift the oldest bucket out and add a new one at the end
    timeBuckets.shift();
    timeBuckets.push({ timebucket: currentTime, keys: new Set() });
  }
}
