import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import { SavedSearchAPI } from 'src/octostar/components/SavedSearch/api';
import { isSavedSearch } from 'src/octostar/components/SavedSearch/utils';
import {
  TEMPORARY_ITEM_TYPE,
  VIRTUAL_ITEM_TYPE,
  apiCall,
} from 'src/octostar/interface';
import {
  ConceptFilter,
  Entity,
  EntityPasteContext,
  PasteContextOptions,
  WorkspaceItem,
} from '@octostar/platform-types';
import { memoize } from 'lodash';
import { getEntities, getEntitiesCount } from '../query';

export const toFolderPasteContext = async (
  folder: WorkspaceItem,
): Promise<EntityPasteContext> => {
  const filter = await apiCall<ConceptFilter>(
    BUILTINS_MESSAGE_TYPES.promptForFolderPasteOptions,
    folder,
  );
  if (!filter) return { count: async () => 0, fetch: async () => [] };
  return {
    count: memoize(getEntitiesCount(filter)),
    fetch: memoize(getEntities(filter)),
  };
};

class PasteContext {
  async getPasteContext(options: PasteContextOptions): Promise<Entity[]> {
    if (!(options.record || options.records)) {
      return [];
    }
    if (options.record) {
      const record = await this.getPasteContextRecord(options.record);
      return this.resolvePasteContext({ ...options, record }).then(entities =>
        Promise.all(entities.map(e => OntologyAPI.getEntity(e))),
      );
    }
    return this.resolvePasteContext(options).then(entities =>
      Promise.all(entities.map(e => OntologyAPI.getEntity(e))),
    );
  }

  private async getPasteContextRecord(record: Entity): Promise<Entity> {
    if ([TEMPORARY_ITEM_TYPE].includes(record.entity_type)) {
      const entity = (record as WorkspaceItem).os_item_content?.entity;
      if (entity) {
        return entity;
      }
    }
    const conceptExists = await OntologyAPI.getConceptByName(
      record.entity_type,
    );
    if (!conceptExists) return record;

    const updated = await DesktopAPI.getItem(record.entity_id).catch(
      () => undefined,
    );
    return updated || record;
  }

  private async resolvePasteContext(
    options: PasteContextOptions,
  ): Promise<Entity[]> {
    let entities: Entity[] | undefined = options.record
      ? undefined
      : options.records;
    let pasteContext: EntityPasteContext;
    if (!entities) {
      pasteContext = this.getDefaultPasteContext(options.record);

      if ([TEMPORARY_ITEM_TYPE].includes(options.record!.entity_type)) {
        entities = (options.record as WorkspaceItem).os_item_content
          ?.entities as Entity[];
      }
    }

    if (entities) {
      pasteContext = {
        count: async () => entities!.length,
        fetch: async () => entities!,
      };
    } else if (
      (options.record as WorkspaceItem)?.os_workspace &&
      (options.record as WorkspaceItem).os_entity_uid
    ) {
      pasteContext = await this.determinePasteContextBasedOnCopyContext(
        options.record as WorkspaceItem,
      );
    }

    const count = await pasteContext!.count();
    if (!options?.limit1 || count <= options.limit1) {
      return pasteContext!.fetch(options?.limit1);
    }

    return this.getEntitiesAfterUserPrompt(count, options, pasteContext!);
  }

  private getDefaultPasteContext(record?: Entity): EntityPasteContext {
    return record
      ? {
          count: async () => 1,
          fetch: async () => [record],
        }
      : {
          count: async () => 0,
          fetch: async () => [],
        };
  }

  private async determinePasteContextBasedOnCopyContext(
    record: WorkspaceItem,
  ): Promise<EntityPasteContext> {
    if (isSavedSearch(record)) {
      return SavedSearchAPI.getSavedSearchPasteContext(record);
    }
    if (
      record.entity_type === 'os_folder' &&
      !record.__uiState?.query &&
      !record.__uiState?.getEntities
    ) {
      return this.buildOsFolderPasteContext(record);
    }

    if (record.__uiState?.query && !record.__uiState?.getEntities) {
      const it = record;
      it.__uiState = {
        ...it.__uiState,
        getEntities: memoize(getEntities(record.__uiState.query)),
        getEntitiesCount: memoize(getEntitiesCount(record.__uiState.query)),
      };
    }

    if (record.__uiState?.getEntities) {
      return this.buildUiStatePasteContext(record);
    }
    return this.buildDefaultPasteContext([record]);
  }

  private buildUiStatePasteContext(
    copyContext: WorkspaceItem,
  ): EntityPasteContext {
    return {
      count: () =>
        copyContext.__uiState?.getEntitiesCount?.() ||
        copyContext.__uiState
          ?.getEntities?.()
          ?.then(entities => entities.length) ||
        Promise.resolve(0),
      fetch: (limit?: number) =>
        copyContext.__uiState?.getEntities?.(limit) || Promise.resolve([]),
    };
  }

  private async buildOsFolderPasteContext(
    record: WorkspaceItem,
  ): Promise<EntityPasteContext> {
    return toFolderPasteContext(record);
  }

  private buildDefaultPasteContext(items: Entity[]): EntityPasteContext {
    return {
      count: async () => items.length,
      fetch: async (limit?: number) =>
        Promise.all(
          (limit ? items.slice(0, limit) : items).map(item =>
            OntologyAPI.getEntity(item),
          ),
        ),
    };
  }

  private async getEntitiesAfterUserPrompt(
    count: number,
    options: PasteContextOptions,
    context: EntityPasteContext,
  ): Promise<Entity[]> {
    const { limit1, limit2 } = options;
    try {
      const { count: apiCount } = await apiCall<{ count: number }>(
        BUILTINS_MESSAGE_TYPES.getPasteContextCount,
        { count, limit1, limit2 },
      );
      if (apiCount > 0) {
        return context.fetch(apiCount);
      }
    } catch (e) {
      console.log(`Error getting paste context`, e);
      DesktopAPI.showToast({
        message: `Error getting paste context`,
        description: e.message || `${e}`,
        level: 'error',
      });
    }
    return [];
  }
}

export const getPasteContext = (
  options: PasteContextOptions,
): Promise<Entity[]> => new PasteContext().getPasteContext(options);
