import * as React from 'react';
import { isFile, isMedia } from 'src/octostar/interface';
import { Entity, WorkspaceItem } from '@octostar/platform-types';
import { Col, Row } from 'antd5';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { ItemTags } from '../tags/ItemTags';
import { UploadedFileViewer } from '../UploadedFileViewer';
import { OpenTabLink } from '../OpenTabLink';
import { getFileType, isSupportedPreviewFile } from '../FileViewer/utils';

export default function MediaAndFileEntity({ entity }: { entity: Entity }) {
  const [isMediaOrFile, setIsMediaOrFile] = React.useState(false);
  const [fileType, setFileType] = React.useState('');
  const [panelWidth, setPanelWidth] = React.useState(0);
  const [featured, setFeatured] = React.useState<Entity | undefined>();
  const tabSetRef = React.useRef(null);
  const mounted = React.useRef(1);

  React.useEffect(() => {
    const { current } = tabSetRef;
    // Check if ResizeObserver is supported
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(entries => {
        const newPanelWidth = entries?.[0].contentRect.width;
        setPanelWidth(curr => (curr === newPanelWidth ? curr : newPanelWidth));
      });
      if (current) {
        resizeObserver.observe(current);
      }
      return () => {
        if (current) {
          resizeObserver.unobserve(current);
        }
      };
    }
    // eslint-disable-next-line no-console
    console.log('ResizeObserver is not supported in this browser.');
    return () => {};
  }, []);

  React.useEffect(() => {
    const entityFileType = getFileType(entity as WorkspaceItem);
    setFileType(entityFileType);
  }, [entity]);

  React.useEffect(() => {
    if (isFile(entity as WorkspaceItem) || isMedia(entity)) {
      setIsMediaOrFile(true);
    }
  }, [entity]);

  React.useEffect(() => {
    const { current } = mounted;

    DesktopAPI.getImages(entity).then(images => {
      if (images?.length && current === mounted.current) {
        setFeatured(images[0]);
      }
    });
  }, [entity]);

  React.useEffect(
    () => () => {
      mounted.current += 1;
    },
    [],
  );

  return (
    <div ref={tabSetRef}>
      {(isMediaOrFile || featured) && (
        <div>
          <span className="table-title">
            <span className="record-label">
              <Row justify="space-between" gutter={6}>
                <Col>{`${entity.entity_label}`}</Col>
                <Col>
                  <OpenTabLink record={featured || entity}> </OpenTabLink>
                </Col>
              </Row>
            </span>
            {isMediaOrFile && <ItemTags item={entity as WorkspaceItem} />}
            {featured && <span>{featured.entity_label}</span>}
          </span>
          {isMediaOrFile && isSupportedPreviewFile(fileType) && (
            <div className="file-preview">
              <UploadedFileViewer
                key={entity.entity_id}
                item={entity as WorkspaceItem}
                defaultHeight={200}
                defaultWidth={panelWidth || 250}
                preview
              />
            </div>
          )}
          {featured && (
            <div className="file-preview">
              <UploadedFileViewer
                key={featured.entity_id}
                item={featured as WorkspaceItem}
                defaultHeight={200}
                defaultWidth={panelWidth || 250}
                preview
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
