import {
  Entity,
  WorkspaceIdentifier,
  WorkspaceItem,
  WorkspaceItemIdentifier,
} from '@octostar/platform-types';
import { EPHEMERAL_ITEMS, VirtualOrTemporaryItem } from '../interface';

export function toWorkspaceUUID(ws: WorkspaceIdentifier) {
  if (typeof ws === 'string') {
    return ws;
  }
  return ws?.os_workspace;
}
export function toWorkspaceItemUUID(wi: WorkspaceItemIdentifier) {
  if (typeof wi === 'string') {
    return wi;
  }
  return wi.os_entity_uid;
}

/**
 * Only EPHEMERAL_ITEMS are (supposed to) passed as WorkspaceItem, rest use uuid
 */
export function isWorkspaceItem(
  wi: WorkspaceItemIdentifier | undefined,
): wi is WorkspaceItem {
  return (
    typeof wi === 'object' &&
    'os_item_type' in wi &&
    EPHEMERAL_ITEMS.includes(wi.os_item_type)
  );
}

// TODO: Let's strive to minimize special cases, handle them implicitly via abstraction, encapsulation.
export function isVirtualOrTemporaryItem(
  item?: Entity,
): item is VirtualOrTemporaryItem {
  return (
    !!item &&
    'os_item_type' in item &&
    EPHEMERAL_ITEMS.includes(item.os_item_type as string)
  );
}

export function getConceptFromNodeConfig(nodeConfig: any): string | undefined {
  return isVirtualOrTemporaryItem(nodeConfig)
    ? nodeConfig.os_item_content?.concept
    : nodeConfig?.concept;
}
