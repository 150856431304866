import {
  Entity,
  EventEmitterType,
  WorkspaceRecords,
  WorkspaceRecordIdentifier,
  WorkspaceRecordWithRelationships,
  SaveOptions,
  Workspace,
  WorkspaceItem,
  ImportZipOptions,
  TagInfo,
  OsTag,
  TagAttributes,
  WorkspacePermission,
  FileTreeOptions,
  CopyOptions,
} from '@octostar/platform-types';
import { debounce, defer, isEqual, uniq } from 'lodash';
import {
  FILESYSTEM_ENTITY_CONCEPT,
  OS_DRAFT_WORKSPACE,
  TAGGED_RELATIONSHIP,
  isFile,
} from 'src/octostar/interface';
import { getPreference, setPreference } from 'src/octostar/api/preferences';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { StorageItem, WorkspaceStorage } from './types';
import { wsfsOperator } from './WSFSOperator';
import { sortTagInfo } from '../tags';
import { filterUniqueItems, sortWorkspaceItems } from './utils';
import { NotifyDebouncer } from '../notifyEntityUpdated';

const EVENT_PREFIX = 'octostar.internal.memorystorage.';
export const EVENTS = Object.freeze({
  OPEN_WORKSPACES_CHANGED: `${EVENT_PREFIX}openWorkspacesChanged`,
  WORKSPACE_ITEM_CHANGED: `${EVENT_PREFIX}workspaceItem`,
  WORKSPACE_CHANGED: `${EVENT_PREFIX}workspaceChanged`,
});
export type Draft = {
  os_draft_item?: boolean;
};

export class MemoryStorageAdapter implements WorkspaceStorage {
  private draftWorkspace: WorkspaceItem = Object.freeze({
    entity_id: 'draft',
    entity_type: 'os_workspace',
    entity_label: 'draft',
    os_entity_uid: 'draft',
    os_item_name: 'draft',
    os_item_type: 'os_workspace',
    os_workspace: OS_DRAFT_WORKSPACE,
  });

  private activeWorkspaceId: string | undefined;

  private items: WorkspaceItem[] = [this.draftWorkspace];

  private storage: WorkspaceStorage;

  private debounceNotify: NotifyDebouncer;

  private updatedItems: WorkspaceItem[] = [];

  private openWorkspaceIds: string[] = [];

  private workspacesChanged: string[] = [];

  private workspaceRecords: { [os_workspace: string]: WorkspaceRecords } = {};

  private tags: { [os_workspace: string]: TagInfo[] } = {};

  private permissions: { [os_workspace: string]: WorkspacePermission } = {};

  private ready: Promise<void>;

  private lastUpdatedAt: { [key: string]: number } = {};

  private nlocks = 0;

  private refresh_on_unlock = false;

  constructor(storage: WorkspaceStorage, ee: EventEmitterType) {
    this.storage = storage;
    this.debounceNotify = new NotifyDebouncer(ee, 50);
    this.refresh = debounce(this.refresh.bind(this), 100);
    this.sendNotifications = debounce(this.sendNotifications.bind(this), 50);
    this.notifyWorkspacesChanged = debounce(
      this.notifyWorkspacesChanged.bind(this),
      50,
    );
    ee.on(`${EVENTS.WORKSPACE_CHANGED}:${OS_DRAFT_WORKSPACE}`, workspace => {
      // eslint-disable-next-line no-param-reassign
      workspace.items = this.items.filter(
        x =>
          x.os_workspace === OS_DRAFT_WORKSPACE || (x as Draft).os_draft_item,
      );
      setPreference('draftWorkspace', workspace);
    });
    this.ready = getPreference<Workspace | undefined>(
      'draftWorkspace',
      undefined,
    )
      .then(workspace => {
        if (workspace) {
          workspace.items.forEach(item => {
            this.items.push(item);
          });
        }
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        this.refresh();
      });
  }

  getLastUpdatedAt(
    workspace_ids: string[],
  ): Promise<{ [key: string]: number }> {
    throw new Error('Method not implemented.');
  }

  async createWorkspace(name: string): Promise<Workspace | undefined> {
    return this.storage.createWorkspace(name);
  }

  async fetchWorkspaceRecords(
    os_workspaces: string[],
    previous?: { [os_workspace: string]: WorkspaceRecords },
    limit?: number,
  ): Promise<{ [os_workspace: string]: WorkspaceRecords }> {
    const records = await this.storage.fetchWorkspaceRecords(
      os_workspaces,
      previous || this.workspaceRecords,
      limit || 100,
    );
    Object.entries(records).forEach(([os_workspace, record]) => {
      this.workspaceRecords[os_workspace] = record;
    });
    // remove any possibly deleted workspaces
    Object.keys(this.workspaceRecords).forEach(os_workspace => {
      if (!this.openWorkspaceIds.includes(os_workspace)) {
        delete this.workspaceRecords[os_workspace];
      }
    });
    return this.workspaceRecords;
  }

  async fetchTags(os_workspaces: string[], limit?: number): Promise<TagInfo[]> {
    const tags = this.storage.fetchTags(os_workspaces, limit);
    const tagsChangedIn: string[] = [];
    const promises = os_workspaces.map(os_workspace =>
      tags.then(tags => {
        const newSortedTags = sortTagInfo(
          tags.filter(tag => tag.os_workspace === os_workspace),
        );
        const tagsChanged = !isEqual(newSortedTags, this.tags[os_workspace]);
        if (tagsChanged) {
          tagsChangedIn.push(os_workspace);
        }

        this.tags[os_workspace] = newSortedTags;
      }),
    );
    return Promise.all(promises).then(() => {
      if (tagsChangedIn.length > 0) {
        this.workspacesChanged = uniq([
          ...this.workspacesChanged,
          ...tagsChangedIn,
        ]);
        defer(() => this.sendNotifications());
      }

      return tags;
    });
  }

  async removeTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    const entities = Array.isArray(entity) ? entity : [entity];
    return this.storage
      .removeTag(os_workspace, tag, entities)
      .then(() =>
        Promise.all(
          entities.map(e =>
            OntologyAPI.clearRelationshipCache(e, TAGGED_RELATIONSHIP),
          ),
        ).then(() => undefined),
      )
      .finally(() => {
        let workspaces = [tag, ...entities]
          .map(x => (x as WorkspaceItem).os_workspace)
          .filter(x => x);
        workspaces.push(os_workspace);
        workspaces = uniq(workspaces);
        const entity_ids = entities.map(x => x.entity_id);
        workspaces.forEach(os_workspace => {
          // remove from the tags list for quick UI response
          const tags = this.tags[os_workspace];
          if (tags) {
            const info = tags.find(
              x => x.entity.entity_id === (tag as Entity).entity_id,
            );
            if (info) {
              info.sample = info.sample.filter(
                x => !entity_ids.includes(x.entity_id),
              );
            }
          }
        });
        entities.forEach(e => {
          OntologyAPI.getConnectedEntities(e, TAGGED_RELATIONSHIP, true);
        });
        this.workspacesChanged = uniq([
          ...this.workspacesChanged,
          ...workspaces,
        ]);
        this.refresh();
        this.sendNotifications();
      });
  }

  async applyTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    const entities = Array.isArray(entity) ? entity : [entity];
    return this.storage
      .applyTag(os_workspace, tag, entities)
      .then(() =>
        Promise.all(
          entities.map(e =>
            OntologyAPI.clearRelationshipCache(e, TAGGED_RELATIONSHIP),
          ),
        ).then(() => undefined),
      )
      .finally(() => {
        const workspaces = [tag, ...entities]
          .map(x => (x as WorkspaceItem).os_workspace)
          .filter(x => x);
        const entity_ids = entities.map(x => x.entity_id);
        workspaces.forEach(os_workspace => {
          // add to the tags list for quick UI response
          const tags = this.tags[os_workspace];
          if (tags) {
            const info = tags.find(
              x => x.entity.entity_id === (tag as Entity).entity_id,
            );
            if (info) {
              info.sample = info.sample.filter(
                x => !entity_ids.includes(x.entity_id),
              );
              entities.forEach(entity => {
                info.sample.push(entity);
              });
            } else {
              tags.push({
                count: entities.length,
                entity: tag as OsTag,
                os_workspace,
                tag: tag.os_item_name,
                sample: entities,
              });
            }
          }
        });

        this.workspacesChanged = uniq([
          ...this.workspacesChanged,
          ...workspaces,
        ]);
        this.refresh();
        this.sendNotifications();
      });
  }

  async updateTag(tag: OsTag): Promise<OsTag> {
    return this.storage.updateTag(tag).then(updated => {
      const tags = this.tags[updated.os_workspace];
      if (tags) {
        const info = tags.find(x => x.entity.entity_id === updated.entity_id);
        if (info) {
          info.entity = updated;
        }
      }
      return updated;
    });
  }

  import(data: any[]): Promise<void> {
    return this.storage.import(data).then(() => this.refresh());
  }

  setOpenWorkspaceIds(ids: string[]) {
    if (!isEqual(ids, this.openWorkspaceIds)) {
      console.log(`setOpenWorkspaceIds: ${ids}
      `);
      const newIds = ids.filter(id => !this.openWorkspaceIds.includes(id));
      this.openWorkspaceIds = ids;
      Promise.all(newIds.map(id => this.load(id))).then(() => {
        this.notifyWorkspacesChanged();
      });
    }
  }

  getOpenWorkspaceIds() {
    return [...this.openWorkspaceIds];
  }

  async loadWorkspaceItems(uuids: string[]): Promise<WorkspaceItem[]> {
    const workspaceItems = await this.storage
      .loadWorkspaceItems(uuids)
      .catch(e => {
        console.log(`could not load workspace(s) ${uuids}`, e);
      });

    if (!workspaceItems) return [];

    this.appendItems(workspaceItems);
    this.workspacesChanged = uniq([...this.workspacesChanged, ...uuids]);
    defer(() => this.sendNotifications());
    return workspaceItems;
  }

  async load(uuid: string): Promise<Workspace | undefined> {
    if (!uuid) {
      return undefined;
    }
    if (uuid === OS_DRAFT_WORKSPACE) {
      return {
        workspace: this.draftWorkspace,
        items: this.items.filter(x => x.os_workspace === OS_DRAFT_WORKSPACE),
        workspace_records: {},
      };
    }
    const workspace = await this.storage.load(uuid).catch(e => {
      console.log(`could not load workspace ${uuid}`, e);
      return undefined;
    });
    if (!workspace) {
      return undefined;
    }

    this.appendItems([workspace.workspace, ...workspace.items]);

    this.workspacesChanged = uniq([...this.workspacesChanged, ...uuid]);
    defer(() => this.sendNotifications());

    return workspace;
  }

  async delete(
    items: WorkspaceRecordIdentifier | WorkspaceRecordIdentifier[],
    recurse?: boolean,
  ): Promise<void> {
    let itemsToDelete = Array.isArray(items) ? items : [items];
    const draftIds = new Set(
      itemsToDelete
        .filter(
          item =>
            item.os_workspace === OS_DRAFT_WORKSPACE ||
            (item as Draft).os_draft_item,
        )
        .map(item => item.os_entity_uid),
    );
    if (draftIds.size) {
      this.items = this.items.filter(item => !draftIds.has(item.os_entity_uid));
      // eslint-disable-next-line no-param-reassign
      itemsToDelete = itemsToDelete.filter(
        item => !draftIds.has(item.os_entity_uid),
      );
      this.workspacesChanged = uniq([
        ...this.workspacesChanged,
        OS_DRAFT_WORKSPACE,
      ]);
    }

    const { operationId, changedWorkspaces, remainingItems } =
      wsfsOperator.deleteItems(
        itemsToDelete,
        this.items,
        this.workspaceRecords,
      );
    this.items = remainingItems;
    this.workspacesChanged = uniq([
      ...this.workspacesChanged,
      ...changedWorkspaces,
    ]);
    this.notifyWorkspacesChanged();
    this.lock();
    try {
      return this.storage
        .delete(itemsToDelete, recurse)
        .then(() => {
          const deletedTags = itemsToDelete
            .filter(x => x.entity_type === 'os_tag')
            .map(x => x.os_workspace)
            .filter(x => x);
          if (deletedTags.length) {
            this.fetchTags(uniq(deletedTags));
          }
        })
        .then(() => wsfsOperator.cleanup(operationId))
        .catch(e => {
          const { itemsToRestore, changedWorkspaces } =
            wsfsOperator.revertOperation(operationId, this.workspaceRecords);
          this.items.push(...itemsToRestore);
          this.workspacesChanged = uniq([
            ...this.workspacesChanged,
            ...changedWorkspaces,
          ]);
          this.notifyWorkspacesChanged();
          throw e;
        });
    } finally {
      this.unlock();
    }
    // could catch exception but other operations my have occurred in the meantime
  }

  async delete_workspace_records(workspace_id: string): Promise<void> {
    await this.storage.delete_workspace_records(workspace_id);
    this.workspacesChanged = uniq([...this.workspacesChanged, workspace_id]);
    this.notifyWorkspacesChanged();
  }

  async delete_local_concept_records(
    workspace_id: string,
    base_concept: string,
  ): Promise<void> {
    await this.storage.delete_local_concept_records(workspace_id, base_concept);
    this.workspacesChanged = uniq([...this.workspacesChanged, workspace_id]);
    this.notifyWorkspacesChanged();
  }

  async list(): Promise<WorkspaceItem[]> {
    return this.storage.list();
  }

  async copy(
    source: WorkspaceItem,
    target: WorkspaceItem,
    options?: CopyOptions,
  ): Promise<WorkspaceItem[]> {
    return this.storage.copy(source, target, options).then(items => {
      this.appendItems(items);
      return items;
    });
  }

  async importZip(file: File, options?: ImportZipOptions) {
    return this.storage.importZip(file, options).then(items => {
      if (options?.target) {
        this.appendItems(items);
      }
      const workspaces = uniq(items.map(x => x.os_workspace));
      workspaces.forEach(workspace => {
        this.notifyWorkspaceChanged(workspace);
      });
      return items;
    });
  }

  async save(item: StorageItem, options?: SaveOptions): Promise<WorkspaceItem> {
    // let the name look nice on the ui
    if ((item as WorkspaceItem).os_entity_uid) {
      const tmp = item as WorkspaceItem;
      const found = this.items.find(x => x.os_entity_uid === tmp.os_entity_uid);
      if (
        found &&
        (found.entity_label !== tmp.os_item_name ||
          found?.os_parent_folder !== tmp.os_parent_folder)
      ) {
        const copy = found;
        copy.entity_label = tmp.os_item_name;
        copy.os_item_name = tmp.os_item_name;
        copy.os_parent_folder = tmp.os_parent_folder;
        this.appendItems([copy]);

        this.notifyWorkspaceItemChanged(tmp);
        // this.notifyWorkspaceChanged(copy.os_workspace);
      }
    }
    const isFileObject = async (item: Entity) =>
      isFile(item) ||
      item?.entity_type === FILESYSTEM_ENTITY_CONCEPT ||
      OntologyAPI.getConceptForEntity(item).then(concept =>
        concept?.parents?.includes(FILESYSTEM_ENTITY_CONCEPT),
      );
    if (options?.draft) {
      if ((item as WorkspaceRecordWithRelationships).entity?.entity_type) {
        throw new Error('Cannot save entity with relationships as draft');
      }
      const copy = JSON.parse(JSON.stringify(item));
      copy.os_draft_item = true;
      copy.os_entity_uid = copy.os_entity_uid || crypto.randomUUID();
      copy.entity_id = copy.entity_id || copy.os_entity_uid;
      copy.os_workspace = copy.os_workspace || OS_DRAFT_WORKSPACE;
      if (await isFileObject(copy)) {
        this.appendItems([copy]);
      }
      return copy;
    }
    // eslint-disable-next-line no-param-reassign
    delete (item as Draft).os_draft_item;
    const isAFIle = await isFileObject(item as WorkspaceItem);
    if (isAFIle) {
      // fast UI response
      const wi = { ...item } as WorkspaceItem;
      const isNewToBeRenamed = wi.__uiState?.rename && !wi.os_created_at;
      wi.os_created_at = new Date(0).toISOString();
      const updated = new Date(
        (item as WorkspaceItem).os_last_updated_at ||
          Date.now() - 60 * 60 * 1000,
      ).getTime();
      wi.os_last_updated_at = new Date(updated + 1).toISOString();
      this.appendItems([wi]);
      this.notifyWorkspaceChanged(wi.os_workspace);
      if (isNewToBeRenamed) {
        // return wi;
      }
    }

    this.lock();
    return this.storage
      .save(this.#sanitizeItem(item))
      .then(updated => {
        const curr = this.items.find(
          x => x.os_entity_uid === updated.os_entity_uid,
        );
        if (curr?.__uiState) {
          // eslint-disable-next-line no-param-reassign
          updated.__uiState = curr.__uiState;
        }
        isFileObject(updated).then(isFile => {
          if (isFile) this.appendItems([updated]);
        });
        return updated;
      })
      .finally(() => {
        this.unlock();
        defer(() => this.refresh());
      });
  }

  private notifyWorkspaceChanged(os_workspace: string) {
    const workspace = this.getWorkspace(os_workspace);
    this.debounceNotify.notifyAsyncGeneric(
      `${EVENTS.WORKSPACE_CHANGED}:${os_workspace}`,
      workspace,
    );
  }

  async getItemsChangedSince(
    item: WorkspaceItem,
    workspace_ids: string[],
  ): Promise<WorkspaceItem[]> {
    return this.storage
      .getItemsChangedSince(item, workspace_ids)
      .then(changedItems => {
        // if any of the changedItems are workspaces,
        // those workspaces will have been reloaded and
        // are in the list of changed items.
        // ROB POSSIBLE ERROR HERE
        changedItems
          .filter(x => x.os_entity_uid === x.os_workspace)
          .forEach(x => {
            this.items = this.items.filter(
              i => i.os_workspace !== x.os_workspace,
            );
          });
        if (changedItems.length === 0) {
          return {};
        }
        if (this.nlocks > 0) {
          this.refresh_on_unlock = true;
          return [];
        }
        this.appendItems(changedItems);

        // refresh workspace records for any of these workspaces
        const wsids = uniq(changedItems.map(i => i.os_workspace));
        const tofetch = [...this.openWorkspaceIds].filter(
          x => wsids.includes(x) || !this.workspaceRecords[x],
        );
        return this.fetchWorkspaceRecords(tofetch);
      })
      .then(() =>
        this.items.filter(
          workspaceItem =>
            workspaceItem.os_last_updated_at! > item!.os_last_updated_at!,
        ),
      );
  }

  private refreshing = 0;

  async refresh() {
    // VSF27: Lots of paranormal activity going on in refresh.
    // I also see multiple invocations on single execution chain, review all invocations of it.
    if (this.nlocks > 0) {
      this.refresh_on_unlock = true;
      return;
    }
    this.refreshing += 1;
    if (this.refreshing > 1) {
      return;
    }
    try {
      if (this.items.length) {
        this.items.sort((a, b) =>
          a.os_last_updated_at === b.os_last_updated_at
            ? 0
            : a.os_last_updated_at! > b.os_last_updated_at!
            ? -1
            : 1,
        );
        const wsids = uniq(
          this.items
            .map(i => i.os_workspace)
            .filter(x => x !== OS_DRAFT_WORKSPACE),
        );
        if (wsids.length) {
          const before = this.lastUpdatedAt;
          this.lastUpdatedAt = await this.storage.getLastUpdatedAt(wsids);
          const toUpdate = Object.keys(this.lastUpdatedAt).filter(
            key => this.lastUpdatedAt[key] !== before[key],
          );
          if (toUpdate.length) {
            await Promise.all([
              this.fetchWorkspaceRecords(toUpdate),
              this.getPermission(toUpdate),
            ]);
            if (this.nlocks > 0) {
              this.refresh_on_unlock = true;
              return;
            }
            this.workspacesChanged = uniq([
              ...this.workspacesChanged,
              ...toUpdate,
            ]);
            defer(() => this.sendNotifications());
          }
          if (this.refreshing > 1) {
            return;
          }
          const latest = this.items.filter(x => x.os_last_updated_at).shift();
          if (latest) {
            await Promise.all([
              this.getItemsChangedSince(latest, wsids),
              this.fetchTags(wsids),
            ]);
            if (this.nlocks > 0) {
              this.refresh_on_unlock = true;
            }
          }
        }
      }
    } finally {
      const pendingRefresh = this.refreshing > 1;
      this.refreshing = 0;
      if (pendingRefresh) {
        defer(() => this.refresh());
      }
    }
  }

  async getItem(uuid: string): Promise<WorkspaceItem> {
    await this.ready;
    const draft = this.items.find(x => x.os_entity_uid === uuid);
    if (
      draft &&
      (draft.os_workspace === OS_DRAFT_WORKSPACE ||
        (draft as Draft).os_draft_item)
    ) {
      return draft;
    }
    const items = await this.getItems([uuid]);
    if (items.length === 0) {
      throw new Error(`Workspace item with os_entity_uid="${uuid}" not found.`);
    }
    return items[0];
  }

  async getItems(uuids: string[]): Promise<WorkspaceItem[]> {
    const items = this.items.filter(item => uuids.includes(item.os_entity_uid));
    const seen = items.map(item => item.os_entity_uid);
    const wanted = uuids.filter(uuid => !seen.includes(uuid));
    if (wanted.length === 0) {
      return items;
    }
    return this.storage.getItems(wanted).then(otherItems => {
      this.appendItems(otherItems);
      return [...items, ...otherItems];
    });
  }

  async getPermission(
    os_workspaces: string[],
  ): Promise<{ [os_workspace: string]: WorkspacePermission }> {
    const permission = await this.storage.getPermission(os_workspaces);
    this.permissions = { ...this.permissions, ...permission };
    return permission;
  }

  filterItems(predicate: (item: WorkspaceItem) => boolean): WorkspaceItem[] {
    return this.items.filter(predicate);
  }

  private appendItems(items: WorkspaceItem[]): void {
    // filter out items that are not in open workspaces
    // eslint-disable-next-line no-param-reassign
    items = items.filter(item =>
      this.openWorkspaceIds.includes(item.os_workspace),
    );

    const keyIndexMap = new Map<string, number>();
    this.items.forEach((item, index) => {
      keyIndexMap.set(item.os_entity_uid, index);
    });
    items.forEach(newItem => {
      const existingItemIndex = keyIndexMap.get(newItem.os_entity_uid) || -1;
      if (existingItemIndex !== -1) {
        // If item with same os_entity_uid already exists, check it is newer
        if (
          // handle the anomoly of a new file being renamed before the first refresh
          (newItem.os_last_updated_at !== newItem.os_created_at &&
            newItem.os_last_updated_at) ||
          (this.items[existingItemIndex].os_last_updated_at || '0') < '1'
        ) {
          // Replace only if the item is newer
          // eslint-disable-next-line no-param-reassign
          newItem.__uiState = this.items[existingItemIndex].__uiState;
          this.items[existingItemIndex] = newItem;
          this.updatedItems.push(newItem);
        }
      } else {
        // If item with same os_entity_uid does not exist, append to the end
        this.items.push(newItem);
        keyIndexMap.set(newItem.os_entity_uid, this.items.length - 1);
        this.updatedItems.push(newItem);
      }
    });

    this.sendNotifications();
  }

  private sendNotifications() {
    if (this.nlocks > 0) {
      return;
    }
    const allItemsMap = this.items.reduce<Record<string, WorkspaceItem>>(
      (acc, item) => {
        acc[item.entity_id] = item;
        return acc;
      },
      {},
    );

    const workspaceItems = filterUniqueItems(this.updatedItems, true);
    this.updatedItems = [];

    workspaceItems.forEach(item => this.notifyWorkspaceItemChanged(item));
    const changedWorkspaceUUIds = uniq([
      ...workspaceItems.map(item => item.os_workspace),
      ...this.workspacesChanged,
    ]);
    if (workspaceItems.filter(x => (x as Draft).os_draft_item).length) {
      if (changedWorkspaceUUIds.indexOf(OS_DRAFT_WORKSPACE) === -1) {
        changedWorkspaceUUIds.push(OS_DRAFT_WORKSPACE);
      }
    }
    let workspacesChanged = false;
    this.workspacesChanged = [];
    changedWorkspaceUUIds.forEach(uid => {
      const workspace = {
        workspace: allItemsMap[uid],
        items: this.items
          .filter(
            item => item.os_workspace === uid && item.os_entity_uid !== uid,
          )
          .sort(sortWorkspaceItems),
        workspace_records: this.workspaceRecords[uid],
        tags: this.tags[uid] || [],
      };
      if (workspace.workspace) {
        this.debounceNotify.notifyAsyncGeneric(
          `${EVENTS.WORKSPACE_CHANGED}:${uid}`,
          workspace,
        );
        workspacesChanged = true;
      }
    });
    if (workspacesChanged) {
      this.notifyWorkspacesChanged(); // may be harsh to do this here...
    }
  }

  notifyWorkspaceItemChanged(item: WorkspaceItem) {
    this.debounceNotify.notifyAsyncGeneric(
      `${EVENTS.WORKSPACE_ITEM_CHANGED}:${item.os_entity_uid}`,
      item,
    );
  }

  private notifyWorkspacesChanged() {
    const workspaces = this.getOpenWorkspaces();
    this.debounceNotify.notifyAsyncGeneric(
      EVENTS.OPEN_WORKSPACES_CHANGED,
      workspaces,
    );
  }

  private withWorkspaceActiveAttributes(workspace: Workspace): Workspace {
    const { workspace: ws, ...rest } = workspace;
    const isActive = ws.entity_id === this.activeWorkspaceId;
    if (isActive) {
      ws.__uiState = {
        ...ws.__uiState,
        highlight: true,
      };
    } else if (workspace.workspace.__uiState?.highlight) {
      delete ws.__uiState?.highlight;
    }
    return { workspace: ws, ...rest, isActive };
  }

  getWorkspace(uid: string): Workspace | undefined {
    const workspace = this.items.find(item => item.os_entity_uid === uid);
    if (!workspace) {
      return undefined;
    }

    return this.withWorkspaceActiveAttributes({
      workspace,
      items: this.items
        .filter(item => item.os_workspace === uid && item.os_entity_uid !== uid)
        .sort(sortWorkspaceItems),
      workspace_records: this.workspaceRecords[uid] || {},
      tags: this.tags[uid] || [],
      permission: this.permissions[uid],
    });
  }

  getOpenWorkspaces(): Workspace[] {
    return this.openWorkspaceIds
      .map(uid => this.getWorkspace(uid))
      .filter(ws => ws?.workspace) as Workspace[];
  }

  setActiveWorkspaceId(id: string | undefined) {
    const changed = uniq([this.activeWorkspaceId, id]).filter(
      x => x !== undefined,
    ) as string[];
    this.activeWorkspaceId = id;
    this.workspacesChanged = uniq([...this.workspacesChanged, ...changed]);
    if (this.workspacesChanged.length) {
      this.sendNotifications();
    }
  }

  async getFilesTree(
    workspace_or_folder: WorkspaceItem,
    options?: FileTreeOptions,
  ): Promise<WorkspaceItem[]> {
    return this.storage.getFilesTree(workspace_or_folder, options);
  }

  lock(): void {
    this.nlocks += 1;
  }

  unlock(): void {
    if (this.nlocks > 0) {
      this.nlocks -= 1;
    }
    if (this.nlocks === 0) {
      defer(() => this.sendNotifications());
      if (this.refresh_on_unlock) {
        this.refresh_on_unlock = false;
        defer(() => this.refresh());
      }
    }
  }

  async replace(items: WorkspaceItem[]) {
    items.forEach(item => {
      const index = this.items.findIndex(
        x => x.os_entity_uid === item.os_entity_uid,
      );
      if (index !== -1) {
        this.items[index] = item;
      } else {
        this.items.push(item);
      }
      this.updatedItems.push(item);
    });
    this.sendNotifications();
  }

  #sanitizeItem<T extends Object>(item: T): T {
    const { __uiState, ...rest } = item as any;
    return rest as T;
  }
}

export default MemoryStorageAdapter;
