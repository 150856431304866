import SqlString from 'sqlstring';
import { ConceptMap } from '@octostar/platform-types'; // todo move to a package
import { DataRequestParams, Sorting } from '../Table';
import { Field } from './SearchField';
import { SearchCondition } from './SearchCondition';

export const ENTITY_LABEL: Field = { field: 'entity_label', label: 'Label' };
export const ENTITY_TYPE: Field = { field: 'entity_type', label: 'Concepts' };
export const MAIN_RECORD_FIELD: Field = {
  field: 'os_textsearchfield',
  label: 'Main Record',
};
export const SEARCHABLE_CONCEPT = 'os_searchable';
export const PRIMARY_CONCEPT = 'thing';
export const REQUIRED_SEARCH_FIELDS = [
  'entity_id',
  'entity_type',
  'entity_label',
];
export const RANK_COLUMN = 'os_rank';
const DEFAULT_ORDERING: Sorting[] = [
  { field: 'entity_label', order: 'ASC' },
  { field: 'entity_type', order: 'ASC' },
  { field: 'entity_id', order: 'ASC' },
];
const DEFAULT_ORDERING_WITH_RANK: Sorting[] = [
  { field: 'os_rank', order: 'DESC' },
  ...DEFAULT_ORDERING,
];

const getDistinctConcepts = (conceptMap: ConceptMap, concepts: string[]) => {
  const unwanted: string[] = [];
  concepts.forEach(c => {
    if (!conceptMap.has(c)) {
      unwanted.push(c);
    }
    if (conceptMap.get(c)!.parents.some(p => concepts.includes(p))) {
      unwanted.push(c);
    }
  });
  return concepts.filter(c => !unwanted.includes(c));
};
export interface SearchState {
  concepts?: string[] | undefined;
  entity_label?: string | undefined;
  os_textsearchfield?: string | undefined;
}

export const state2conditions = (
  state: SearchState | undefined,
): SearchCondition[] | undefined => {
  const toLikeConditions = (
    field: string,
    value: string | undefined,
  ): SearchCondition[] => {
    const answer: SearchCondition[] = [];
    if (!value?.trim().length) {
      return answer;
    }
    return value
      .trim()
      .split(/\s+/)
      .map(text => ({ field, operator: 'LIKE', value: [text] }));
  };
  const conditions: SearchCondition[] = [
    ...toLikeConditions(ENTITY_LABEL.field, state?.entity_label),
    ...toLikeConditions(MAIN_RECORD_FIELD.field, state?.os_textsearchfield),
  ];
  if (state?.concepts) {
    // in tree select model, concept is at:the:end
    const filterConcepts = (state.concepts || []).map(s => s.split(':').pop());
    if (filterConcepts.length) {
      conditions.push({
        field: ENTITY_TYPE.field,
        operator: 'IN',
        value: [...filterConcepts],
      });
    }
  }
  return conditions.length ? conditions : undefined;
};
export const getQueryConcepts = (
  concepts: ConceptMap,
  conditions?: SearchCondition[] | undefined,
): { queryConcepts: string[]; otherConditions: SearchCondition[] } => {
  const sought: string[] = [];
  conditions
    ?.filter(x => x.field === ENTITY_TYPE.field)
    .forEach(condition => {
      switch (condition.operator) {
        case 'IN':
          condition.value?.forEach(x => sought.push(x));
          break;

        case 'LIKE':
        case 'ILIKE':
          break;
        default:
          throw new Error(
            `condition ${condition} is not supported for ${ENTITY_TYPE.field}`,
          );
      }
    });
  let queryConcepts = sought.filter((s, i, a) => a.indexOf(s) === i);
  queryConcepts = getDistinctConcepts(concepts, queryConcepts);
  const otherConditions = (conditions || []).filter(
    x => x.field !== ENTITY_TYPE.field || x.operator !== 'IN',
  );
  // return the unique values
  return { queryConcepts, otherConditions };
};
const condition2sql = (condition: SearchCondition): string => {
  let sql;
  switch (condition.operator) {
    case 'ILIKE': // not supported in timbr
    case 'LIKE':
      sql = (condition.value || []).map(
        c => `${condition.field} LIKE ${SqlString.escape(`%${c}%`)}`,
      );
      break;
    default:
      throw new Error(`Not implemented for ${condition.operator}`);
  }
  if (sql.length > 1) {
    return `(${sql.join(' AND ')})`;
  }
  return sql[0];
};

const countToSum = (field: string) => field.replace('count(1)', 'sum(count)');
const osRankFromInner = (field: string) => {
  if (field.endsWith('as "os_rank"')) {
    return 'os_rank';
  }
  return field;
};

const columnsForConcept = (
  concepts: ConceptMap,
  concept: string,
  columns: string[],
  specific?: boolean,
): string[] =>
  columns.map((col: string) =>
    col.toLocaleLowerCase().indexOf(' as ') >= 0
      ? col
      : (concepts.get(concept) || { columns: [''] }).columns.indexOf(col) >= 0
      ? `"${col}"`
      : `null as "${col}"`,
  );

export const params2sql = (
  params: DataRequestParams,
  concepts: ConceptMap,
  options?: { count?: boolean; groupByConcept?: boolean },
): string => {
  const { queryConcepts, otherConditions } = getQueryConcepts(
    concepts,
    params.conditions,
  );
  const table = otherConditions
    .map(x => x.field)
    .includes(MAIN_RECORD_FIELD.field)
    ? SEARCHABLE_CONCEPT
    : PRIMARY_CONCEPT;
  const where = otherConditions.length
    ? ` WHERE ${otherConditions.map(x => condition2sql(x)).join('    \nAND ')}`
    : '';

  const allColumns = [
    ...REQUIRED_SEARCH_FIELDS,
    ...(params.columns || []),
  ].filter((s, i, a) => a.indexOf(s) === i);
  let columns = queryConcepts.length
    ? allColumns
    : columnsForConcept(concepts, table, allColumns);
  let ranking: string[] = [];
  if (!params.sorting?.length) {
    const field_name = ENTITY_LABEL.field;
    const toColParts = (text: string) => {
      const t1 = SqlString.escape(text.toLocaleLowerCase());
      const t2 = SqlString.escape(`${text.toLocaleLowerCase()}%`);
      const t3 = SqlString.escape(`%${text.toLocaleLowerCase()}%`);
      const t4 = SqlString.escape(`%${text.split(/\s+/).join('%')}%`);
      return [
        `if(lower(${field_name}) = ${t1},1,0)`,
        `if(lower(${field_name}) LIKE ${t2},1,0)`,
        `if(lower(${field_name}) LIKE ${t3},1,0)`,
        `if(lower(${field_name}) LIKE ${t4},1,0)`,
      ];
    };
    // #DEV-144
    const entityCondition = otherConditions.filter(
      x =>
        [MAIN_RECORD_FIELD.field, ENTITY_LABEL.field].indexOf(x.field) >= 0 &&
        ['LIKE', 'ILIKE'].indexOf(x.operator) >= 0,
    );
    if (entityCondition.length) {
      entityCondition.forEach(condition =>
        condition.value?.forEach(value => {
          ranking = [...(ranking || []), ...toColParts(value)];
        }),
      );
    }
    if (ranking.length) {
      columns.push(`${ranking.join('+')} as "${RANK_COLUMN}"`);
    }
  }
  let groupby = '';
  const order = (
    params.sorting?.length
      ? params.sorting
      : ranking.length
      ? DEFAULT_ORDERING_WITH_RANK
      : DEFAULT_ORDERING
  )
    .map(({ field, order }) => `${field} ${order}`)
    .join(', ');

  let ordering = ` ORDER BY ${order}\n    LIMIT ${params.pageSize} OFFSET ${
    (params.page - 1) * params.pageSize
  }`;

  if (options?.count) {
    ordering = '';
    columns = ['count(1) AS count'];
    if (options.groupByConcept) {
      columns.push(ENTITY_TYPE.field);
      groupby = `\n     GROUP BY ${ENTITY_TYPE.field}`;
    }
  }

  let sql = `SELECT ${columns.join(
    ', ',
  )} FROM timbr."${table}" ${where}${ordering}${groupby}`;
  if (queryConcepts.length && !options?.groupByConcept) {
    // query instead the specific concepts
    let conceptOrder = '';
    if (!options?.count) {
      conceptOrder = ` ORDER BY ${order}\n    LIMIT ${
        params.page * params.pageSize
      }`;
    }
    sql = queryConcepts
      .map(
        concept =>
          `\nSELECT ${columnsForConcept(concepts, concept, columns, true).join(
            ', ',
          )} FROM timbr."${concept}" ${where}${conceptOrder}${groupby}`,
      )
      .map((s, i) =>
        conceptOrder ? `\n  select * from (${s}\n  ) concept${i}` : s,
      )
      .join('\n    UNION ALL');
    sql = `SELECT ${options?.count ? '' : 'DISTINCT'} ${columns
      .map(countToSum)
      .map(osRankFromInner)
      .map(column =>
        column.indexOf('"') >= 0 ||
        column.indexOf('(') >= 0 ||
        column.toLowerCase().indexOf(' as ') >= 0
          ? column
          : `"${column}"`,
      )
      .join(', ')} FROM (${sql}\n    ) q ${ordering}${groupby}`;
  }
  return sql;
};

export const generateBaseQuery = (
  time: number,
  conditions: SearchCondition[],
  ontologyConcepts: ConceptMap | undefined,
): BaseQuery | undefined => {
  if (ontologyConcepts === undefined) {
    return undefined;
  }
  const params: DataRequestParams = {
    page: 1,
    pageSize: 5,
    conditions,
  };

  return {
    time,
    conditions,
    query: params2sql(params, ontologyConcepts),
    conceptCountsQuery: params2sql(params, ontologyConcepts, {
      count: true,
      groupByConcept: true,
    }),
    resultsCountQuery: params2sql(params, ontologyConcepts, {
      count: true,
      groupByConcept: false,
    }),
  };
};
export type BaseQuery = {
  time: number;
  conditions: SearchCondition[];
  conceptCountsQuery: string;
  resultsCountQuery: string;
  query: string;
};
