import * as React from 'react';
import { Alert, Spin } from 'antd5';

export type LoadingProps = {
  title?: string;
  detail?: string;
};
const Loading = (props: LoadingProps) => {
  const { title, detail } = props;
  const container = (
    <Alert
      message={title || 'Loading'}
      description={detail || 'Fetching everything we need'}
      type="info"
    />
  );

  return (
    <div style={{ padding: '2rem' }}>
      <div>
        <Spin spinning delay={1000}>
          {container}
        </Spin>
      </div>
    </div>
  );
};

export default Loading;
