import * as React from 'react';
import { debounce, isEqual } from 'lodash';
import localforage from 'localforage';
import { DraftWorkspaceItem, WorkspaceItem } from '@octostar/platform-types';
import { isFile } from '../interface';

export type AttachmentType = 'text' | 'json' | 'blob';
export type AttachmentState = 'loading' | 'error' | 'ready';
export type AttachmentContent = {
  text?: string;
  blob?: Blob;
  json?: any;
};
export type Attachment = AttachmentContent & {
  state: AttachmentState;
  error?: string;
  loading?: boolean;
  setItem: React.Dispatch<React.SetStateAction<WorkspaceItem | undefined>>;
  item: WorkspaceItem | undefined;
};
class ResponseWrapper {
  private blob: Blob;

  constructor(blob: Blob) {
    this.blob = blob;
  }

  async getText(): Promise<string> {
    return this.blob.text();
  }

  async getJSON<T = any>(): Promise<T> {
    const text = await this.getText();
    return JSON.parse(text) as T;
  }

  getBlob(): Blob {
    return this.blob;
  }

  async getArrayBuffer(): Promise<ArrayBuffer> {
    return this.blob.arrayBuffer();
  }

  async toAttachmentContent(wanted: string): Promise<AttachmentContent> {
    return {
      text: wanted === 'text' ? await this.getText() : undefined,
      blob: wanted === 'blob' ? await this.getBlob() : undefined,
      json: wanted === 'json' ? await this.getJSON() : undefined,
    };
  }
}
type CachedAttachment = {
  os_last_updated: string;
  blob: Blob;
};
type CachedError = {
  timestamp: number;
  os_last_updated: string;
  error: string;
};

class ErrorCache {
  async get(item: WorkspaceItem): Promise<string | null> {
    const key = `err:${getKey(item)}`;
    const cachedError = await localforage.getItem<CachedError>(key);

    // If the error is less than 5 minutes old, return it
    if (
      cachedError &&
      cachedError.os_last_updated === item.os_last_updated_at &&
      Date.now() - cachedError.timestamp < 5 * 60 * 1000
    ) {
      return cachedError.error;
    }
    return null;
  }

  async set(item: WorkspaceItem, error: string) {
    const key = `err:${getKey(item)}`;
    await localforage.setItem(key, {
      timestamp: Date.now(),
      os_last_updated: item.os_last_updated_at,
      error,
    });
  }
}

const errorCache = new ErrorCache();

const getKey = (item: WorkspaceItem) => `att:${item.os_entity_uid}`;

class AttachmentCache {
  async get(item: WorkspaceItem): Promise<ResponseWrapper | null> {
    const key = getKey(item);
    const cachedItem = await localforage.getItem<CachedAttachment>(key);

    if (cachedItem && cachedItem?.os_last_updated === item.os_last_updated_at) {
      return new ResponseWrapper(cachedItem.blob);
    }

    return null;
  }

  async set(item: WorkspaceItem, attachment: ResponseWrapper) {
    const key = getKey(item);
    await localforage.setItem(key, {
      os_last_updated: item.os_last_updated_at,
      blob: attachment.getBlob(),
    });
  }
}

const attachmentCache = new AttachmentCache();

async function privateFetchAttachement(
  item: WorkspaceItem,
): Promise<ResponseWrapper> {
  return fetch(
    `/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${
      item.os_entity_uid
    }?cb=${Date.now()}`,
  ).then(response => {
    if (!response.ok) {
      const errorMessage = `HTTP response was not ok: ${response.status}`;
      errorCache.set(item, errorMessage); // Save the error into the cache
      throw new Error(errorMessage);
    }
    return response.blob().then(blob => new ResponseWrapper(blob));
  });
}

async function fetchAttachmentWithRetries(
  item: WorkspaceItem,
  retries = 3,
): Promise<ResponseWrapper> {
  return privateFetchAttachement(item).catch(e => {
    if (retries > 0) {
      // wait 2 seconds before retrying
      return new Promise(resolve => setTimeout(resolve, 2000)).then(() =>
        fetchAttachmentWithRetries(item, retries - 1),
      );
    }
    throw e;
  });
}
async function fetchAttachment(
  item: WorkspaceItem,
  disableRetries?: boolean,
): Promise<ResponseWrapper> {
  if (disableRetries) {
    return privateFetchAttachement(item).catch(e => {
      throw e;
    });
  }
  return fetchAttachmentWithRetries(item);
}

export async function getFileAttachment(
  item: WorkspaceItem,
  disableRetries?: boolean,
): Promise<ResponseWrapper> {
  const cachedAttachment = await attachmentCache.get(item);
  if (cachedAttachment) {
    return cachedAttachment;
  }

  const cachedError = await errorCache.get(item);
  if (cachedError) {
    throw new Error(cachedError);
  }

  return fetchAttachment(item, disableRetries).then(async wrapper => {
    await attachmentCache.set(item, wrapper);
    return wrapper;
  });
}

export async function getAttachment(
  item: WorkspaceItem,
  wanted: AttachmentType,
  defaultValue?: any,
): Promise<AttachmentContent> {
  // Check if the attachment is in the cache
  return getFileAttachment(item)
    .then(wrapper => wrapper.toAttachmentContent(wanted))
    .then(async fetchedAttachment => fetchedAttachment)
    .catch(e => {
      if (defaultValue !== undefined) {
        const result = {};
        result[wanted] = defaultValue;
        return result;
      }
      throw e;
    });
}

export async function getAttachmentText(
  item: WorkspaceItem,
  defaultValue?: any,
  disableRetries?: boolean,
): Promise<string> {
  // Check if the attachment is in the cache
  return getFileAttachment(item, disableRetries)
    .then(wrapper => wrapper.getText())
    .then(async fetchedAttachment => fetchedAttachment)
    .catch(e => {
      if (defaultValue !== undefined) {
        return defaultValue;
      }
      throw e;
    });
}

export async function getAttachmentContent<T>(
  item: WorkspaceItem,
  wanted: AttachmentType,
  defaultValue?: any,
): Promise<T> {
  const att = await getAttachment(item, wanted, defaultValue);
  switch (wanted) {
    case 'blob':
      return att.blob as T;
    case 'text':
      return att.text as T;
    default:
      return att.json as T;
  }
}
export const useAttachment = (
  workspaceItem: WorkspaceItem | undefined,
  wanted: AttachmentType,
  defaultValue?: any,
): Attachment => {
  const [item, setItem] = React.useState<WorkspaceItem | undefined>(
    workspaceItem,
  );
  const [attachment, setAttachment] = React.useState<Attachment>({
    loading: true,
    state: 'loading',
    setItem,
    item: workspaceItem,
  });
  React.useEffect(() => {
    setItem(curr => (isEqual(curr, workspaceItem) ? curr : workspaceItem));
  }, [workspaceItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cbSetAttachment = React.useCallback(
    debounce((att: Partial<Attachment> & { state: AttachmentState }) => {
      const newValue = { ...att, setItem, item };
      setAttachment(curr => (isEqual(curr, newValue) ? curr : newValue));
    }, 50),
    [item],
  );
  React.useEffect(() => {
    if (!item) {
      cbSetAttachment({ error: 'No item', state: 'error' });
      return;
    }
    if (!isFile(item)) {
      cbSetAttachment({ error: 'Not a file', state: 'error' });
      return;
    }

    if ((item as DraftWorkspaceItem).os_draft_item) {
      let att: Partial<Attachment> = {
        loading: false,
        error: `Unknown attachment type ${wanted}`,
        state: 'error',
      };
      switch (wanted) {
        case 'text':
          att = { loading: false, text: defaultValue || '', state: 'ready' };
          break;
        case 'json':
          att = { loading: false, json: defaultValue || {}, state: 'ready' };
          break;
        case 'blob':
          if (defaultValue) {
            att = { loading: false, blob: defaultValue, state: 'ready' };
          }
          break;
        default:
          break;
      }
      setAttachment(a => ({ ...a, ...att }));
      return;
    }
    getAttachment(item, wanted)
      .then(attachment => {
        setAttachment(att => ({
          ...att,
          ...attachment,
          error: '',
          state: 'ready',
          loading: false,
        }));
      })
      .catch(e => {
        console.log(e);
        setAttachment(att => {
          const value: Attachment = {
            ...att,
            loading: false,
            error: `Unknown attachment type ${wanted}`,
            state: 'error',
          };
          let returnValue = defaultValue;
          if (returnValue === undefined) {
            if (wanted === 'text') {
              returnValue = '';
            } else if (wanted === 'json') {
              returnValue = {};
            }
          }
          if (returnValue !== undefined) {
            value[wanted] = returnValue;
            value.error = '';
            value.state = 'ready';
          }
          return value;
        });
      });
  }, [wanted, item, cbSetAttachment, defaultValue]);
  return attachment;
};

export async function blobToArrayBuffer(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error('Failed to read blob to array buffer'));
    };

    reader.readAsArrayBuffer(blob);
  });
}
