/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  Entity,
  WorkspaceRecords,
  WorkspaceRecordIdentifier,
  WorkspaceRecordWithRelationships,
  Ontology,
  Workspace,
  WorkspaceItem,
  WorkspaceRelationship,
  ImportZipOptions,
  TagInfo,
  OsTag,
  TagAttributes,
  WorkspacePermission,
  FileTreeOptions,
  CopyOptions,
} from '@octostar/platform-types';
import SqlString from 'sqlstring';
import _, { isEmpty, uniq } from 'lodash';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import {
  FILESYSTEM_ENTITY_CONCEPT,
  RELATIONSHIPS_CONCEPT,
  TAGGED_RELATIONSHIP,
  TAGS_CONCEPT,
  WORKSPACE_RECORDS_CONCEPT,
  isFile,
} from 'src/octostar/interface';
import {
  CreateWorkspaceResponse,
  StorageItem,
  WorkspaceStorage,
} from './types';
import APIClient from '../APIClient';
import { getConceptSelectAllFields } from '../query';

const DEBOUNCE_DELAY_MS = 20;
type DebounceCol = 'os_workspace' | 'os_entity_uid';
type Handler = { uuids: string[]; callback: (items: WorkspaceItem[]) => void };

/**
 * To bypass the timbr pushdown optimisation which was harming thie
 * `os_workspace in ()` queries.
 */
const BYPASS = '1=2 OR';

export class TimbrStorage implements WorkspaceStorage {
  private ontology: Ontology | undefined;

  private _initApiClient: Promise<void>;

  private _apiClient: APIClient;

  private async forceRelationshipsRefresh(entities: WorkspaceRelationship[]) {
    entities.forEach(e => {
      const from: Entity = {
        entity_id: e.os_entity_uid_from,
        entity_type: e.os_entity_type_from,
        entity_label: '',
      };
      const to: Entity = {
        entity_id: e.os_entity_uid_to,
        entity_type: e.os_entity_type_to,
        entity_label: '',
      };
      this.ontology
        ?.getRelationshipsForEntity(from)
        .then(rels => {
          const relationship = rels.find(
            x => x.relationship_name === e.os_relationship_name,
          );
          if (!relationship) {
            console.error(
              `Relationship ${e.os_relationship_name} not found for  ${RELATIONSHIPS_CONCEPT}(entity_id='${e.os_entity_uid}')`,
            );
            return undefined;
          }
          // force cache refresh for this relationship for the from entity.
          this.ontology?.getRelationshipCount(from, relationship, true);
          this.ontology?.getConnectedEntities(from, relationship, true);
          return relationship;
        })
        .then(rel => {
          if (!rel) {
            return;
          }
          this.ontology?.getRelationshipsForEntity(to).then(rels => {
            const relationship = rels.find(
              x => x.relationship_name === rel.inverse_name,
            );
            if (relationship) {
              // force cache refresh for this relationship for the to entity.
              this.ontology?.getRelationshipCount(to, relationship, true);
              this.ontology?.getConnectedEntities(to, relationship, true);
            }
          });
        });
    });
  }

  constructor(ontology?: Ontology) {
    this.ontology = ontology;
    this._apiClient = new APIClient({
      headers: { 'Cache-Control': 'no-cache' },
    });

    this._initApiClient = ontology
      ? ontology
          .getOntologyName()
          .then(ontology => this._apiClient.setOntology(ontology))
      : Promise.resolve();

    this.debouncedGetItems = _.debounce(
      this.debouncedGetItems.bind(this),
      DEBOUNCE_DELAY_MS,
    );
  }

  /**
   * Return instantiated {@link APIClient}
   */
  private async getApiClient(): Promise<APIClient> {
    await this._initApiClient;
    return this._apiClient;
  }

  private accumulated: { [key: string]: Handler[] } = {};

  private async debouncedGetItems() {
    const { accumulated } = this;
    this.accumulated = {};
    Object.keys(accumulated).forEach(column => {
      const uniqueUuids = uniq(
        [...(accumulated[column] || [])].map(x => x.uuids).flat(),
      );
      if (!uniqueUuids.length) {
        return;
      }
      const query = `select entity_type, entity_id, os_last_updated_at from timbr.${FILESYSTEM_ENTITY_CONCEPT} where ${column} in (${uniqueUuids
        .map(uid => SqlString.escape(uid))
        .join(',')})`;
      this.ontology!.sendQueryT<WorkspaceItem>(query)
        .then(items =>
          Promise.all(
            items.map(x => this.ontology!.getEntity<WorkspaceItem>(x)),
          ),
        )
        .then(items => {
          const arr: Handler[] = accumulated[column];
          arr.forEach(({ uuids, callback }) => {
            const filtered = items.filter(item => uuids.includes(item[column]));
            setTimeout(() => callback(filtered), 0);
          });
        });
    });
  }

  async load(uuid: string): Promise<Workspace | undefined> {
    return this.fetchWorkspace(uuid);
  }

  async list(): Promise<WorkspaceItem[]> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    const fields = await getConceptSelectAllFields(FILESYSTEM_ENTITY_CONCEPT);
    const entities: WorkspaceItem[] = await this.ontology.sendQueryT(
      `select ${fields} from timbr.${FILESYSTEM_ENTITY_CONCEPT} where os_item_type='os_workspace'
        order by os_last_updated_at desc`,
    );
    return entities;
  }

  async getLastUpdatedAt(
    workspace_ids: string[],
  ): Promise<{ [key: string]: number }> {
    return workspace_ids.length === 0
      ? {}
      : OntologyAPI.sendQueryT<{
          os_workspace: string;
          os_last_updated_at: number;
        }>(
          `
    SELECT os_workspace, max(os_last_updated_at) as os_last_updated_at 
    FROM timbr.os_workspace_item 
    WHERE ${BYPASS} os_workspace is not null AND 
    os_workspace in (${workspace_ids.map(x => SqlString.escape(x)).join(',')})
    GROUP BY os_workspace`,
        ).then(rows =>
          rows.reduce(
            (acc, row) => ({
              ...acc,
              [row.os_workspace]: row.os_last_updated_at,
            }),
            {},
          ),
        );
  }

  private async queryItems(
    uuid: string,
    column: DebounceCol,
  ): Promise<WorkspaceItem[]> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    return new Promise<WorkspaceItem[]>((resolve, reject) => {
      this.accumulated[column] = this.accumulated[column] || [];
      this.accumulated[column].push({ uuids: [uuid], callback: resolve });
      this.debouncedGetItems();
    });
  }

  async getItems(uuids: string[]): Promise<WorkspaceItem[]> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    return new Promise<WorkspaceItem[]>((resolve, reject) => {
      const column = 'os_entity_uid';
      this.accumulated[column] = this.accumulated[column] || [];
      this.accumulated[column].push({ uuids, callback: resolve });
      this.debouncedGetItems();
    });
  }

  async getItem(uuid: string): Promise<WorkspaceItem> {
    const item = (await this.queryItems(uuid, 'os_entity_uid')).shift();
    if (item) return item;
    throw new Error(`Workspace item os_entity_uid=${uuid} not found.`);
  }

  async copy(
    source: WorkspaceItem,
    target: WorkspaceItem,
    options?: CopyOptions,
  ): Promise<WorkspaceItem[]> {
    const apiClient = await this.getApiClient();
    return new Promise((resolve, reject) => {
      apiClient
        .fetch(`/api/octostar/workspace_data_api/copy`, {
          method: 'POST',
          body: JSON.stringify({
            source: source.os_entity_uid,
            target: target.os_entity_uid,
            options,
          }),
        })
        .then(result => {
          if (!result.ok) {
            reject(
              new Error(`Error copying workspace item: ${result.statusText}`),
            );
            return;
          }
          // eslint-disable-next-line consistent-return
          return result
            .json()
            .then(j => {
              if (j.status !== 'success') {
                console.error(
                  'problem copying workspace item',
                  JSON.stringify(j, null, 2),
                );
                reject(
                  new Error(
                    `Error copying workspace item: see console for details`,
                  ),
                );
              }
              return j.entities as WorkspaceItem[];
            })
            .then(resolve);
        })
        .catch(e =>
          reject(new Error(`Error saving workspace item: ${e.message}`)),
        );
    });
  }

  async importZip(file: File, options?: ImportZipOptions) {
    // TODO: make it part of DesktopAPI???
    const formData = new FormData();
    // map all the options to the form data
    formData.append('zip', file);

    Object.entries(options || {}).forEach(([key, value]) => {
      if (value === false || !isEmpty(value)) {
        formData.append(key, `${value}`);
      }
    });
    return new APIClient({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .fetch('/api/octostar/workspace_data_api/zip_import', {
        method: 'POST',
        body: formData,
      })
      .then(result => {
        if (!result.ok) {
          if (result.status >= 400 && result.status < 500) {
            return result.json().then(errorJson => {
              const errorMessage =
                errorJson.exception || 'Unknown error occurred';
              throw new Error(`Error importing zip: ${errorMessage}`);
            });
          }
          // For non-400 range errors, use the statusText
          throw new Error(`Error importing zip: ${result.statusText}`);
        }
        return result.json().then(j => {
          if (j.status !== 'success') {
            console.error('problem importing zip', JSON.stringify(j, null, 2));
            if (j.exception) {
              throw new Error(j.exception);
            } else if (j.message) {
              throw new Error(j.message);
            }
          }
          if (j.entities) {
            return Promise.all(
              j.entities.map((entity: Entity) =>
                this.ontology?.getEntity(entity),
              ),
            );
          }
          throw new Error(
            `unexpected response on zip import: ${JSON.stringify(j, null, 2)}`,
          );
        });
      });
  }

  async import(data: any[]): Promise<void> {
    const apiClient = await this.getApiClient();
    return new Promise((resolve, reject) => {
      apiClient
        .fetch('/api/octostar/workspace_data_api/entity?asis=true', {
          method: 'POST',
          body: JSON.stringify(data),
        })
        .then(result => {
          if (!result.ok) {
            reject(
              new Error(`Error saving workspace item: ${result.statusText}`),
            );
            return undefined;
          }

          return result
            .json()
            .then(j => {
              if (j.status !== 'success') {
                console.error(
                  'problem saving workspace item',
                  JSON.stringify(j, null, 2),
                );
                reject(
                  new Error(
                    `Error saving workspace item: see console for details`,
                  ),
                );
              }
            })
            .then(resolve);
        })
        .catch(e =>
          reject(new Error(`Error saving workspace item: ${e.message}`)),
        );
    });
  }

  async createWorkspace(name: string): Promise<Workspace | undefined> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    const apiClient = await this.getApiClient();
    return apiClient
      .fetch('/api/octostar/workspace_data_api/create_workspace', {
        method: 'POST',
        body: JSON.stringify({ os_item_name: name }),
      })
      .then(result => {
        if (!result.ok) {
          throw new Error(`Error creating workspace: ${result.statusText}`);
        }
        return result.json().then((res: CreateWorkspaceResponse) => {
          if (res.status === 'success') {
            return this.load(res.data.entity_id);
          }
          console.log(`Error creating workspace: ${name}`, res);
          throw new Error(`Error creating workspace: ${name}`);
        });
      });
  }

  async save(item: StorageItem): Promise<WorkspaceItem> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    const apiClient = await this.getApiClient();
    // TODO: remove this
    if ((item as any).os_item_type === 'os_big_file') {
      // eslint-disable-next-line no-param-reassign
      (item as any).os_item_type = 'os_file';
    }
    return new Promise((resolve, reject) => {
      apiClient
        .fetchJson<{
          status: string;
          s3_urls: {};
          entities: (Entity & WorkspaceItem)[];
        }>('/api/octostar/workspace_data_api/entity', {
          method: 'POST',
          body: JSON.stringify(item),
        })
        .then(async result => {
          result.entities.forEach(entity => {
            const uploadSecret = result.s3_urls?.[entity.entity_id];
            if (uploadSecret) {
              entity['#uploadSecret'] = uploadSecret;
            }
          });
          return this.ontology
            ?.updateCache(result.entities)
            .then(entities => resolve(entities[0]));
        })

        .catch(reject);
    });
  }

  async delete(
    items: WorkspaceRecordIdentifier | WorkspaceRecordIdentifier[],
    recurse?: boolean,
  ): Promise<void> {
    const apiClient = await this.getApiClient();
    return new Promise((resolve, reject) => {
      apiClient
        .fetch(
          `/api/octostar/workspace_data_api/entity${
            recurse ? '?recurse=true' : ''
          }`,
          {
            method: 'DELETE',
            body: JSON.stringify(items),
          },
        )
        .then(result => {
          if (!result.ok) {
            reject(
              new Error(`Error deleting workspace item: ${result.statusText}`),
            );
          }
          resolve();
        })
        .catch(reject);
    });
  }

  async delete_workspace_records(workspace_id: string): Promise<void> {
    const apiClient = await this.getApiClient();
    return new Promise((resolve, reject) => {
      apiClient
        .fetch(`/api/v1/octostar/workspace-records/${workspace_id}`, {
          method: 'DELETE',
        })
        .then(result => {
          if (!result.ok) {
            reject(
              new Error(
                `Error deleting workspace records: ${result.statusText}`,
              ),
            );
          }
          resolve();
        })
        .catch(reject);
    });
  }

  async delete_local_concept_records(
    workspace_id: string,
    base_concept: string,
  ): Promise<void> {
    const apiClient = await this.getApiClient();
    return new Promise((resolve, reject) => {
      apiClient
        .fetch(
          `/api/v1/octostar/workspace-records/${workspace_id}/${base_concept}`,
          {
            method: 'DELETE',
          },
        )
        .then(result => {
          if (!result.ok) {
            reject(
              new Error(
                `Error deleting workspace records: ${result.statusText}`,
              ),
            );
          }
          resolve();
        })
        .catch(reject);
    });
  }

  async getItemsChangedSince(
    item: WorkspaceItem,
    workspace_ids: string[],
  ): Promise<WorkspaceItem[]> {
    if (workspace_ids.length === 0) return [];
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    const wsids = workspace_ids.map(id => SqlString.escape(id)).join(', ');
    const query = (concept: string, fields: string) =>
      `select ${fields} from timbr.${concept} where ${BYPASS} os_workspace is not null AND
      os_workspace in (${wsids}) and os_last_updated_at > ${
        new Date(item.os_last_updated_at || 1).getTime() / 1000
      }
      and entity_type not in ('os_workspace_relationship')`;
    // clear cache for any changed local relationships.
    const fields = await getConceptSelectAllFields(RELATIONSHIPS_CONCEPT);
    this.ontology
      .sendQueryT<WorkspaceRelationship>(query(RELATIONSHIPS_CONCEPT, fields))
      .then(entities => this.forceRelationshipsRefresh(entities));

    return this.ontology
      .sendQuery(
        query(
          FILESYSTEM_ENTITY_CONCEPT,
          'entity_type, entity_id, entity_label',
        ),
      )
      .then(async items => {
        const entities = await Promise.all(
          items.map(item =>
            this.ontology!.getEntity<WorkspaceItem>(item, true),
          ),
        );

        // reload any updated workspaces
        const toReload: string[] = entities
          .filter(wi => wi.os_entity_uid === wi.os_workspace)
          .map(x => x.os_entity_uid);
        const reloaded = await this.fetchWorkspaceEntities(toReload);
        const all = [...reloaded, ...entities];
        const ids = all.map(x => x.os_entity_uid);
        return all.filter((e, i) => ids.indexOf(e.os_entity_uid) === i);
      });
  }

  async loadWorkspaceItems(uuids: string[]): Promise<WorkspaceItem[]> {
    if (uuids.length === 0) return [];
    if (this.ontology === undefined) throw new Error('Ontology not defined.');

    const escapedIds = uuids.map(uuid => SqlString.escape(uuid)).join(', ');
    const fields = await getConceptSelectAllFields(FILESYSTEM_ENTITY_CONCEPT);
    const workspaceItems: WorkspaceItem[] = await this.ontology.sendQueryT(
      `select ${fields} from timbr.${FILESYSTEM_ENTITY_CONCEPT} where os_item_type='os_workspace' and os_workspace in (${escapedIds})
        order by os_last_updated_at desc`,
    );
    return workspaceItems;
  }

  async fetchWorkspace(uuid: string): Promise<Workspace | undefined> {
    if (!this.ontology) return;
    const entities: WorkspaceItem[] = await this.fetchWorkspaceEntities(uuid);
    const workspace = (entities as WorkspaceItem[]).find(
      item => item.entity_type === 'os_workspace',
    );
    if (!workspace) {
      throw new Error(`Workspace ${uuid} not found.`);
    }
    const items = (entities as WorkspaceItem[]).filter(
      item => item !== workspace,
    );
    // eslint-disable-next-line consistent-return
    return { workspace, items };
  }

  async getPermissionXXXRedoThisAsPost(
    os_workspaces: string[],
  ): Promise<{ [os_workspace: string]: WorkspacePermission }> {
    const apiClient = await this.getApiClient();
    return apiClient.fetchData(
      `/api/v1/octostar/workspace-permissions/?os_workspaces=${encodeURIComponent(
        os_workspaces.join(','),
      )}`,
    );
  }

  async getPermission(
    os_workspaces: string[],
  ): Promise<{ [os_workspace: string]: WorkspacePermission }> {
    const apiClient = await this.getApiClient();
    const chunkSize = 50;

    // Split os_workspaces into chunks
    const chunks = [];
    for (let i = 0; i < os_workspaces.length; i += chunkSize) {
      chunks.push(os_workspaces.slice(i, i + chunkSize));
    }

    // Create an array of promises
    const requests = chunks.map(chunk =>
      apiClient.fetchData(
        `/api/v1/octostar/workspace-permissions/?os_workspaces=${encodeURIComponent(
          chunk.join(','),
        )}`,
      ),
    );

    // Execute all requests in parallel
    const results = await Promise.all(requests);

    // Merge all results into a single object
    const permissions: { [os_workspace: string]: WorkspacePermission } = {};
    results.forEach(result => {
      Object.assign(permissions, result);
    });

    return permissions;
  }

  private async fetchWorkspaceEntities(
    uuids: string | string[],
  ): Promise<WorkspaceItem[]> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    if (typeof uuids === 'string') {
      // eslint-disable-next-line no-param-reassign
      uuids = [uuids];
    }
    if (uuids.length === 0) {
      return [];
    }
    return this.ontology
      .sendQueryT<Entity & { os_last_updated_at: string }>(
        `select entity_id, entity_type, entity_label, os_last_updated_at from timbr.${FILESYSTEM_ENTITY_CONCEPT} 
        where ${BYPASS} os_workspace is not null AND
        os_workspace in (${uuids
          .map(uuid => SqlString.escape(uuid))
          .join(', ')})`,
      )
      .then(entities =>
        Promise.all(
          entities.map(entity =>
            this.ontology!.getEntity<WorkspaceItem>(entity, false).then(e => {
              if (e.os_last_updated_at !== entity.os_last_updated_at) {
                return this.ontology!.getEntity<WorkspaceItem>(entity, true);
              }
              return e;
            }),
          ),
        ),
      );
  }

  async fetchTags(os_workspaces: string[], limit = 100): Promise<TagInfo[]> {
    // NB: limit intentionall unused until we have a superset way to get the related items.
    const apiClient = await this.getApiClient();
    const p1 = apiClient.fetchData<
      (OsTag & {
        tag: string;
        cardinality: number;
        tagged_os_workspace: string;
      })[]
    >(
      `/api/v1/octostar/workspace-tags/?os_workspaces=${encodeURIComponent(
        os_workspaces.join(','),
      )}`,
    );
    const p2 = this.ontology?.sendQueryT<{
      os_workspace: string;
      tag: string;
      entity_type: string;
      entity_id: string;
      tag_entity_id: string;
    }>(`SELECT tagged.os_workspace os_workspace,
    os_entity_type_from AS entity_type, 
    os_entity_uid_from AS entity_id,
    os_entity_uid_to AS tag_entity_id,
    tag.entity_label AS tag
    FROM timbr.${RELATIONSHIPS_CONCEPT} tagged
    LEFT JOIN timbr.${TAGS_CONCEPT} tag ON os_entity_uid_to=tag.entity_id
    WHERE 
    os_relationship_name='${TAGGED_RELATIONSHIP}'
    AND tagged.os_workspace IN (${os_workspaces
      .map(uuid => SqlString.escape(uuid))
      .join(', ')})`);

    // TODO later do not load them all at once but rather on demand for each tag
    // for the open folders, like unsaved search. Hope @Varun will take care of it.

    const [counts, sample] = await Promise.all([p1, p2]);
    const tags: TagInfo[] = counts.map(
      ({ tag, cardinality, tagged_os_workspace, ...os_tag }) => {
        const sample_entities = (sample || []).filter(
          e =>
            e.os_workspace === tagged_os_workspace &&
            e.tag_entity_id === os_tag.entity_id,
        );
        const info: TagInfo = {
          tag,
          os_workspace: tagged_os_workspace,
          count: parseInt(`${cardinality}`, 10) || 0,
          sample: sample_entities.map(({ tag_entity_id, tag, ...e }) => ({
            entity_label: '',
            ...e,
          })),
          entity: os_tag,
        };
        return info;
      },
    );
    return tags;
  }

  async fetchWorkspaceRecords(
    os_workspaces: string[],
    latest: { [os_workspace: string]: WorkspaceRecords },
    limit: number,
  ): Promise<{ [os_workspace: string]: WorkspaceRecords }> {
    if (this.ontology === undefined) throw new Error('Ontology not defined.');
    if (os_workspaces.length === 0) {
      return latest;
    }
    return this.ontology
      .sendQueryT<{
        os_workspace: string;
        concept: string;
        count: number;
        max_last_updated: string;
      }>(
        `select os_workspace, entity_type as concept, count(1) as count,
        max(os_last_updated_at) as max_last_updated 
        from timbr.${WORKSPACE_RECORDS_CONCEPT}
        where ${BYPASS} os_workspace is not null AND
        os_workspace in (${os_workspaces
          .map(x => SqlString.escape(x))
          .join(', ')}) 
        group by os_workspace, entity_type
        `,
      ) // Returns counts per concept, os_workspace
      .then(async rows => {
        const result: { [os_workspace: string]: WorkspaceRecords } = JSON.parse(
          JSON.stringify(latest),
        );
        const queries: string[] = [];
        const updating: { os_workspace: string; concept: string }[] = [];
        os_workspaces.forEach(os_workspace => {
          const counts = rows
            .filter(row => row.os_workspace === os_workspace)
            .map(row => ({ ...row, count: parseInt(`${row.count}`, 10) }));
          const previous = latest[os_workspace] || {};

          const entities: WorkspaceRecords = {};
          counts.forEach(count => {
            entities[count.concept] = { ...count, entities: [] };
          });
          result[os_workspace] = entities;
          Object.entries(entities).forEach(
            ([concept, { count, max_last_updated }]) => {
              if (
                previous[concept]?.count === count &&
                previous[concept]?.max_last_updated === max_last_updated
              ) {
                entities[concept] = previous[concept];
              } else if (count) {
                // VSFQuery: If suddenly the count becomes zero (from >0), then the records may not get updated.
                const fields =
                  'os_workspace, entity_id, entity_type, entity_label, os_last_updated_at';
                queries.push(`select ${fields} from (SELECT ${fields} 
                FROM timbr.${concept} WHERE entity_type=${SqlString.escape(
                  concept,
                )} AND
                os_workspace=${SqlString.escape(os_workspace)}
                ORDER BY entity_label, entity_id LIMIT ${limit}) ${concept}`);
                updating.push({ os_workspace, concept });
              }
            },
          );
        });
        if (queries.length) {
          const workspace_records = await Promise.all(
            queries.map(query =>
              this.ontology!.sendQueryT<
                Entity & { os_workspace: string; os_last_updated_at: string }
              >(query).catch(e => {
                console.error(`Error executing query: ${query}`, e);
                return [];
              }),
            ),
          ).then(results =>
            // Flatten the array of object into a single array
            results.flat(),
          );
          // refresh cache for these items.
          workspace_records.forEach(entity =>
            this.ontology
              ?.getEntity<
                Entity & { os_workspace: string; os_last_updated_at: string }
              >(entity)
              .then(e => {
                if (e.os_last_updated_at !== entity.os_last_updated_at) {
                  this.ontology?.getEntity(e, true, true);
                }
              }),
          );
          updating.forEach(({ os_workspace, concept }) => {
            result[os_workspace][concept].entities = workspace_records.filter(
              e => e.os_workspace === os_workspace && e.entity_type === concept,
            );
          });
        }
        return result;
      });
  }

  async applyTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    const entities = Array.isArray(entity) ? entity : [entity];
    const queryParams = new URLSearchParams();
    if (tag.color) queryParams.append('color', tag.color);
    if (tag.group) queryParams.append('group', tag.group);
    if (tag.order !== undefined && tag.order !== null)
      queryParams.append('order', `${tag.order}`);

    const apiClient = await this.getApiClient();
    return apiClient
      .fetch(
        `/api/v1/octostar/workspace-tags/${os_workspace}/${
          (tag as Entity).entity_id || tag.os_item_name
        }?${queryParams}`,
        {
          method: 'POST',
          body: JSON.stringify(
            entities.map(({ entity_type, entity_id }) => ({
              entity_type,
              entity_id,
            })),
          ),
        },
      )
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        if (json.status !== 'success') {
          throw new Error(`Status ${json.status}`);
        }
      })
      .finally(async () => {
        const promises = entities.map(entity =>
          this.ontology?.getConnectedEntities(
            entity,
            TAGGED_RELATIONSHIP,
            true,
          ),
        );
        await Promise.all(promises);
      });
  }

  async removeTag(
    os_workspace: string,
    tag: OsTag | TagAttributes,
    entity: Entity | Entity[],
  ): Promise<void> {
    const entities = Array.isArray(entity) ? entity : [entity];
    const apiClient = await this.getApiClient();
    return apiClient
      .fetch(
        `/api/v1/octostar/workspace-tags/${os_workspace}/${
          (tag as Entity).entity_id || tag.os_item_name
        }`,
        {
          method: 'DELETE',
          body: JSON.stringify(
            entities.map(({ entity_type, entity_id }) => ({
              entity_type,
              entity_id,
            })),
          ),
        },
      )
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        if (json.status !== 'success') {
          throw new Error(`Status ${json.status}`);
        }
      })
      .finally(async () => {
        const promises = entities.map(entity =>
          this.ontology?.getConnectedEntities(
            entity,
            TAGGED_RELATIONSHIP,
            true,
          ),
        );
        await Promise.all(promises);
      });
  }

  async updateTag(tag: OsTag): Promise<OsTag> {
    return this.save(tag);
  }

  async getFilesTree(
    folder: WorkspaceItem,
    options?: FileTreeOptions,
  ): Promise<WorkspaceItem[]> {
    const apiClient = await this.getApiClient();
    // create the url using the set options
    let url = `/api/octostar/workspace_data_api/get_files_tree?os_workspace=${folder.os_workspace}&root=${folder.os_entity_uid}`;
    // append the options as  Record<string, boolean> to the url
    if (options) {
      url += `&${new URLSearchParams(
        options as Record<string, string>,
      ).toString()}`;
    }
    return apiClient.fetchData<WorkspaceItem[]>(url);
  }
}
async function is_wsfs_object(
  item: WorkspaceRecordIdentifier & Entity,
): Promise<boolean> {
  if (item.entity_type === FILESYSTEM_ENTITY_CONCEPT) {
    return true;
  }
  if ((item as WorkspaceItem).os_has_attachment) {
    return true;
  }
  return OntologyAPI.getConceptByName(item.entity_type).then(concept => {
    if (!concept) {
      return false;
    }
    return concept.parents.indexOf(FILESYSTEM_ENTITY_CONCEPT) >= 0;
  });
}
