import * as React from 'react';
import { Empty } from 'antd5';
import {
  Concept,
  Entity,
  OsWorkspaceEntity,
  WorkspaceItem,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { isWorkspaceBusinessRecord } from 'src/octostar/lib/ConceptUtils';
import { canEntityBeSaved } from 'src/octostar/lib/EntityUtils';
import { FileMetadata } from './FileMetadata';
import { RecordViewerGraph } from './RecordViewerGraph';
import { EntitiyWithConcept } from './types';
import RenderIfVisible from '../RenderIfVisible';
import MediaAndFileEntity from './MediaAndFileEntity';

export const CurrentEntitiesInfo = ({
  entities,
  os_workspace,
}: {
  entities: Entity[];
  os_workspace?: string;
}) => {
  const [entitiesWithConcept, setEntitiesWithConcept] = React.useState<
    EntitiyWithConcept[]
  >([]);
  const mounted = React.useRef(1);

  React.useEffect(() => {
    const { current } = mounted;
    const loadEntitiesWithConcept = async () => {
      const conceptsMap = await OntologyAPI.getConcepts();
      if (!entities || entities.length === 0) {
        return;
      }
      const entitiesWithConcept = await Promise.all(
        entities.map(async entity => {
          const concept = conceptsMap.get(entity.entity_type) as
            | Concept
            | undefined;
          let isBusinessRecord = false;
          if (concept) {
            isBusinessRecord = await isWorkspaceBusinessRecord(
              concept as Concept,
            );
          }
          const canEdit = await canEntityBeSaved(
            entity as OsWorkspaceEntity,
            true,
          );
          return {
            entity,
            concept,
            isBusinessRecord,
            isEditable: canEdit.value,
          };
        }),
      );
      if (mounted.current === current) {
        const uniqueEntitiesWithConcept = entitiesWithConcept.filter(
          (v, i, a) =>
            a.findIndex(t => t.entity.entity_id === v.entity.entity_id) === i,
        );
        if (uniqueEntitiesWithConcept.length < entitiesWithConcept.length) {
          // eslint-disable-next-line no-console
          console.error(
            'entitiesWithConcept was NOT unique for entity_id! Something went wrong.',
          );
        }
        setEntitiesWithConcept(uniqueEntitiesWithConcept);
      }
    };

    loadEntitiesWithConcept();
    return () => {
      // prevent updating state of unmounted component
      mounted.current += 1;
    };
  }, [entities]);

  return entities?.length ? (
    <div>
      {entitiesWithConcept.map(entityWithConcept => (
        <RenderIfVisible stayRendered key={entityWithConcept.entity.entity_id}>
          <div className="table-container">
            <MediaAndFileEntity entity={entityWithConcept.entity} />
            {entityWithConcept.isBusinessRecord &&
              entityWithConcept.concept !== undefined && (
                <RecordViewerGraph
                  entity={
                    entityWithConcept.entity as Entity &
                      WorkspaceRecordIdentifier
                  }
                  os_workspace={os_workspace}
                  concept={entityWithConcept.concept as Concept}
                  disableEdit={!entityWithConcept.isEditable}
                />
              )}
            {(entityWithConcept?.entity as WorkspaceItem)?.os_item_content && (
              <FileMetadata
                entity={entityWithConcept.entity as WorkspaceItem}
                disableEdit={!entityWithConcept.isEditable}
              />
            )}
          </div>
        </RenderIfVisible>
      ))}
    </div>
  ) : (
    <div className="entity-placeholder">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Select any nodes to see all their details"
      />
    </div>
  );
};
