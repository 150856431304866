import { NumberFormats, getNumberFormatter } from '@superset-ui/core';

export const bigNumberFormat = getNumberFormatter(NumberFormats.SMART_NUMBER);

export const safeParseJSON = (value: string) => {
  try {
    if (typeof value !== 'string' || value === '' || value === null) {
      return null;
    }
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const hexToRgba = (hex: string, alpha: number = 1): string => {
  const result = hex.match(/\w\w/g);
  if (!result) {
    return hex;
  }
  const [r, g, b] = result.map(pair => parseInt(pair, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
