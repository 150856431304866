import _ from 'lodash';
import { Entity, Ontology } from '@octostar/platform-types';

const keysByEntityType: { [entity_type: string]: string[] } = {};

const getLabelKeys = async (ontology: Ontology, entity: Entity) => {
  if (!entity?.entity_type) {
    return undefined;
  }
  let keys = keysByEntityType[entity.entity_type];
  if (!keys) {
    const concept = await ontology.getConceptForEntity(entity);
    if (!concept) {
      return undefined;
    }
    const conceptsMap = await ontology.getConcepts();
    const parents = _.uniq(concept.parents);
    const allLabelKeys = [concept, ...parents.map(x => conceptsMap[x])].map(
      x => x?.labelKeys || [],
    );
    keys = _.uniq(_.flatMap(allLabelKeys));
    keysByEntityType[entity.entity_type] = keys;
  }
  return keys;
};
export const tryToFixLabel = async <T extends Entity>(
  ontology: Ontology,
  entity: T,
) => {
  // workaround for timbr-clickhouse bug on labels.
  if (!_.isEmpty(entity.entity_label) || !entity.entity_type) {
    return entity;
  }
  const labelKeys = await getLabelKeys(ontology, entity);
  if (!labelKeys) {
    return entity;
  }

  const copy = { ...entity };
  copy.entity_label = labelKeys
    .map(x => copy[x])
    .filter(v => !_.isEmpty(v))
    .map(v => `${v}`)
    .join(' ');
  if (_.isEmpty(copy.entity_label)) {
    ['os_item_name', 'name', 'label', copy.entity_type].forEach(field => {
      if (_.isEmpty(copy.entity_label) && !_.isEmpty((copy as any)[field])) {
        copy.entity_label = (copy as any)[field];
      }
    });
  }
  if (_.isEmpty(copy.entity_label)) {
    copy.entity_label = `Unknown ${copy.entity_type}`;
  }
  return copy;
};
