import * as React from 'react';
import { Empty, Spin } from 'antd5';
import {
  Concept,
  Entity,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { RELATIONSHIPS_CONCEPT } from 'src/octostar/interface';
import { RecordViewerGraph } from './RecordViewerGraph';
import RenderIfVisible from '../RenderIfVisible';
import { RelationshipData } from '../flexlayout/wrappers/types';

export const CurrentRelationshipsInfo = ({
  relationships,
}: {
  relationships: RelationshipData[];
}) => {
  const [concept, setConcept] = React.useState<Concept | undefined>(undefined);

  React.useEffect(() => {
    let mounted = true;
    OntologyAPI.getConceptByName(RELATIONSHIPS_CONCEPT).then(async concept => {
      if (mounted) {
        setConcept(concept);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  if (!concept) {
    return <Spin />;
  }

  if (relationships.length === 0) {
    return (
      <div className="entity-placeholder">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Select any edges to see all their details"
        />
      </div>
    );
  }

  return (
    <div>
      {relationships.map(relationship => (
        <RenderIfVisible stayRendered key={relationship.entity_id}>
          <div className="table-container">
            <RecordViewerGraph
              entity={relationship as Entity & WorkspaceRecordIdentifier}
              concept={concept as Concept}
            />
          </div>
        </RenderIfVisible>
      ))}
    </div>
  );
};
