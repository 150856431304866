import * as React from 'react';
import { isEqual } from 'lodash';
import { WorkspaceItem } from '@octostar/platform-types';

const ON_CHANGE_NOOP = (item: WorkspaceItem) => undefined;
export const WorkspaceEntityContext = React.createContext<{
  item: WorkspaceItem | undefined;
  onChange: (item: WorkspaceItem) => void;
}>({
  item: undefined,
  onChange: ON_CHANGE_NOOP,
});

export const WorkspaceItemContextProvider = ({
  children,
  item,
  onChange,
}: {
  children: any;
  item: WorkspaceItem | undefined;
  onChange?: (item: WorkspaceItem) => void;
}) => {
  const [value, setValue] = React.useState<{
    item: WorkspaceItem | undefined;
    onChange: (item: WorkspaceItem) => void;
  }>({
    item: undefined,
    onChange: ON_CHANGE_NOOP,
  });
  React.useEffect(() => {
    const newValue = {
      item,
      onChange: onChange || ON_CHANGE_NOOP,
    };
    setValue(curr => (isEqual(curr, newValue) ? curr : newValue));
  }, [item, onChange]);
  return (
    <WorkspaceEntityContext.Provider value={value}>
      {children}
    </WorkspaceEntityContext.Provider>
  );
};
