import * as React from 'react';
import {
  Concept,
  Entity,
  OsWorkspaceEntity,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import { t } from '@superset-ui/core';
import Form from '@rjsf/antd';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Button } from 'antd5';
import { SaveOutlined } from '@ant-design/icons';
import { getSchema } from 'src/octostar/lib/SchemaAPI';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import Loading from 'src/components/Loading';
import _ from 'lodash';
import { IChangeEvent } from '@rjsf/core';
import { getLabelKeysByConcept } from 'src/octostar/lib/ConceptUtils';
import { hasFilledLabelKeys } from 'src/octostar/lib/EntityUtils';
import { parseFormData } from './lib/entity-data';
import { StandardFieldTemplate } from './StandardTemplate/FieldTemplate';
import { ObjectFieldTemplate } from './StandardTemplate/ObjectFieldTemplate';
import { customWidgets } from './StandardTemplate/CustomWidgets';
import './MultiRecordViewer.css';

type FormContextProps = {
  entity: Entity & WorkspaceRecordIdentifier;
  submitFormRef?: React.MutableRefObject<any>;
  concept: Concept;
  entityData: any;
  os_workspace?: string;
  isNewEntity?: boolean;
  requiredFieldsOnly?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  hideButtons?: boolean;
  singleColumn?: boolean;
  onSave: (entity: any) => void;
  setReadOnly?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function FormContext({
  entity,
  submitFormRef,
  concept,
  entityData,
  os_workspace,
  isNewEntity,
  requiredFieldsOnly,
  readonly,
  disabled,
  hideButtons,
  singleColumn,
  onSave,
  setReadOnly,
  setDisabled,
}: FormContextProps) {
  const [schema, setSchema] = React.useState<RJSFSchema>();
  const [schemaUI, setSchemaUI] = React.useState<UiSchema>();
  const formdata = React.useRef<any>({});
  const dataChanged = React.useRef<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [labelKeys, setLabelKeys] = React.useState<string[]>([]);
  const mounted = React.useRef(1);

  React.useEffect(() => {
    async function loadSchemaAndLabelKeys() {
      if (!concept) {
        return;
      }
      const { current } = mounted;
      const labelKeysConcept = await getLabelKeysByConcept(concept);
      if (labelKeysConcept?.length > 0 && mounted.current === current) {
        setLabelKeys(curr =>
          _.isEqual(curr, labelKeysConcept) ? curr : labelKeysConcept,
        );
      }
      const { schema, schemaUI } = await getSchema(
        concept.concept_name,
        requiredFieldsOnly,
      );
      if (mounted.current === current && schema && schemaUI) {
        setSchema(curr =>
          _.isEqual(curr, JSON.parse(schema)) ? curr : JSON.parse(schema),
        );
        setSchemaUI(curr =>
          _.isEqual(curr, JSON.parse(schemaUI)) ? curr : JSON.parse(schemaUI),
        );
        setLoading(false);
      }
    }
    loadSchemaAndLabelKeys();
  }, [concept, entity, requiredFieldsOnly]);

  React.useEffect(() => {
    formdata.current = entityData;
  }, [entityData]);

  React.useEffect(
    () => () => {
      // prevent updating state of unmounted component
      mounted.current += 1;
    },
    [],
  );

  const onSubmit = React.useCallback(
    (form: IChangeEvent<any, RJSFSchema, any>, event: React.FormEvent<any>) => {
      if (!event.isTrusted) {
        return;
      }
      const { formData } = form;
      formdata.current = formData;
      const OneOflabelsRequired = hasFilledLabelKeys(formData, labelKeys);
      if (!OneOflabelsRequired) {
        DesktopAPI.showToast({
          level: 'error',
          message: t('Record not saved'),
          description: `${t(
            'Fill one or more of these label keys to get started',
          )}: ${labelKeys?.map((key: string) => key)}`,
        });
        return;
      }
      onSave(
        parseFormData(
          formData,
          entity as OsWorkspaceEntity,
          labelKeys,
          os_workspace,
        ),
      );
    },
    [entity, labelKeys, onSave, os_workspace],
  );

  const onChange = React.useCallback((form: any) => {
    dataChanged.current = true;
    formdata.current = form.formData;
  }, []);

  const onCancel = React.useCallback(() => {
    formdata.current = entityData;
    dataChanged.current = false;
    setReadOnly?.(true);
    setDisabled?.(false);
  }, [entityData, setDisabled, setReadOnly]);

  if (loading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div key={entity.os_entity_uid}>
      {requiredFieldsOnly && !readonly && labelKeys?.length > 0 && (
        <div className="error-label-keys">
          {labelKeys?.length > 1
            ? t('Fill one or more of these label keys to get started')
            : t('Fill this label key to get started')}
        </div>
      )}
      {schema && schemaUI && (
        <Form
          className="record-editor-form"
          formData={dataChanged.current ? formdata.current : entityData}
          schema={schema}
          uiSchema={schemaUI}
          widgets={customWidgets}
          templates={{
            FieldTemplate: props => <StandardFieldTemplate {...props} />,
            ObjectFieldTemplate: props => <ObjectFieldTemplate {...props} />,
          }}
          formContext={{
            concept,
            requiredFieldsOnly,
            singleColumn,
            labelKeys,
          }}
          ref={submitFormRef}
          onSubmit={onSubmit}
          onChange={onChange}
          showErrorList="bottom"
          validator={validator}
          readonly={readonly}
          disabled={readonly || disabled}
        >
          {hideButtons ? (
            <></>
          ) : (
            <div
              className="record-editor-form-buttons"
              style={{ display: readonly ? 'none' : 'flex' }}
            >
              <>
                <Button
                  type="text"
                  onClick={onCancel}
                  className="record-editor-form-cancel-button"
                >
                  {t('Cancel')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => submitFormRef?.current.submit()}
                  className="record-editor-form-save-button"
                  disabled={readonly}
                  loading={disabled}
                >
                  {isNewEntity ? t('Create') : t('Save')}
                  <SaveOutlined />
                </Button>
              </>
            </div>
          )}
        </Form>
      )}
    </div>
  );
}
