import { QueryObjectFilterClause } from '@superset-ui/core';
import SqlString from 'sqlstring';
import { ConceptFilter, Entity } from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';
import { getSharedConcept } from './ConceptUtils';

function escape(value: number | string | boolean): number | string {
  if (typeof value === 'number') {
    return value; // Return numbers as-is
  }
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  return SqlString.escape(value);
}

export function parseQueryObjectFilterClause(
  filter: QueryObjectFilterClause,
): string {
  switch (filter.op) {
    case 'IN':
    case 'NOT IN':
      return `"${filter.col}" ${filter.op} (${filter.val
        .map(escape)
        .join(', ')})`;
    case 'IS NULL':
    case 'IS NOT NULL':
      return `"${filter.col}" ${filter.op}`;
    case '==':
      return `"${filter.col}"=${escape(filter.val)}`;
    case '!=':
    case '>':
    case '<':
    case '>=':
    case '<=':
    case 'LIKE':
      return `"${filter.col}"${filter.op}${escape(filter.val)}`;
    case 'ILIKE':
      return `lower("${filter.col}") LIKE lower(${escape(filter.val)})`;
    case 'IN_POLYGON':
      return (filter.val as any as number[][][])
        .map(polygon => {
          const arrayString = polygon.map(point => `(${point})`).join(',');
          return `pointInPolygon((${filter.col}),\`array\`(${arrayString}))`;
        })
        .join(' OR ');
    case 'IS_TAGGED': // TODO: Add support
    case 'REGEX':
    case 'TEMPORAL_RANGE':
    default:
      throw new Error(`Unsupported filter operation: ${filter.op}`);
  }
}

export function parseQueryObjectFilterClauses(
  filters: QueryObjectFilterClause[],
): string {
  return filters.map(parseQueryObjectFilterClause).join(' AND ');
}

export const toEntityQuery = (
  query: ConceptFilter,
  options?: {
    order?: boolean;
    limit?: number;
  },
): string => {
  const { concept, filters } = query;
  const whereClause = parseQueryObjectFilterClauses(filters);
  return `select entity_type, entity_id, entity_label from timbr."${concept}" where ${whereClause}${
    options?.order ? ' order by entity_label, entity_type, entity_id' : ''
  }${options?.limit ? ` limit ${options.limit}` : ''}`;
};
export const toCountQuery = (query: ConceptFilter): string => {
  const { concept, filters } = query;
  const whereClause = parseQueryObjectFilterClauses(filters);
  return `select count(1) as count from timbr."${concept}" where ${whereClause}`;
};
export const toEntityTypesQuery = (query: ConceptFilter): string => {
  const { concept, filters } = query;
  const whereClause = parseQueryObjectFilterClauses(filters);
  return `select distinct entity_type from timbr."${concept}" where ${whereClause}`;
};

export const getEntitiesCount =
  (query: ConceptFilter): (() => Promise<number>) =>
  async () =>
    OntologyAPI.sendQueryT<{ count: number }>(toCountQuery(query)).then(x =>
      parseInt(`${x?.[0]?.count || 0}`, 10),
    );

export const getEntities =
  (query: ConceptFilter): (() => Promise<Entity[]>) =>
  (limit?: number) =>
    OntologyAPI.sendQueryT<Entity>(
      toEntityQuery(query, { order: true, limit }),
    );

export const getEntityTypes =
  (query: ConceptFilter): (() => Promise<string[]>) =>
  async () =>
    OntologyAPI.sendQueryT<{ entity_type: string }>(
      toEntityTypesQuery(query),
    ).then(x => x.map(y => y.entity_type));

export const getCommonConcept = (query: ConceptFilter): Promise<string> =>
  getEntityTypes(query)().then(entity_types =>
    getSharedConcept({ entity_types }),
  );

const cachedConceptAllFields: { [concept: string]: string } = {};
export const getConceptSelectAllFields = async (
  concept: string,
): Promise<string> => {
  if (cachedConceptAllFields[concept]) {
    return cachedConceptAllFields[concept];
  }
  const defaultValue = '*';
  const xoncept = await OntologyAPI.getConceptByName(concept);
  if (!xoncept) {
    console.error(`Concept ${concept} not found`);
    return defaultValue;
  }
  const fields = xoncept.columns?.map(p => `\`${p}\``).join(', ');
  cachedConceptAllFields[concept] = fields || defaultValue;
  return cachedConceptAllFields[concept];
};
