import { EntityEdge } from '@octostar/platform-types';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { RELATIONSHIPS_CONCEPT } from 'src/octostar/interface';
import { IJsonModel } from 'flexlayout-react';
import { BorderTab, RelationshipData } from './types';

export const SIDEBAR_ID = 'secondary-row';

export const getJsonModel = (borderTabs: BorderTab[]): IJsonModel => {
  const model = {
    global: { splitterSize: 1, splitterExtra: 12 },
    borders: [
      {
        type: 'border',
        selected: -1,
        barSize: 40,
        size: 600,
        location: 'right',
        children: [],
      },
    ],
    layout: {
      type: 'row',
      id: 'main',
      children: [
        {
          type: 'tabset',
          enableTabStrip: false,
          headerHeight: 0.000001,
          id: 'flexwrapper-tabset',
          weight: 65,
          classNameTabStrip: 'flexwrapper-tabset-tabstrip',
          enableDrop: false,
          children: [
            {
              type: 'tab',
              id: '5',
              component: 'content',
              enableClose: false,
            },
          ],
        },
        {
          type: 'row',
          id: SIDEBAR_ID,
          weight: 35,
          headerHeight: 48,
          children: [],
        },
      ],
    },
  } as IJsonModel;

  if (borderTabs.find(tab => tab?.position === 'bottom')) {
    model.borders?.push({
      type: 'border',
      selected: -1,
      barSize: 40,
      size: 600,
      location: 'bottom',
      enableDrop: true,
      children: [],
    });
  }

  return model;
};

export const nameToIndex = (name: string | -1, model: any) => {
  const borders = [
    ...(model.borders?.[0]?.children || []),
    ...(model?.borders?.[1]?.children || []),
  ];
  if (name === -1 || !borders?.length) {
    return -1;
  }
  return borders.findIndex((child: any) => child.name === name);
};

export const addTabToModel = (tab: BorderTab, model: any) => {
  const borderEntry = {
    type: 'tab',
    name: tab.name,
    borderHeight: 400,
    component: tab.name,
    enableClose: false,
    enableRenderOnDemand: false,
    maximized: false,
    config: {
      componentUnwrapped: tab.componentUnwrapped,
      customIcon: tab.icon,
    },
  };
  const rowEntry = {
    type: 'tabset',
    id: tab.name,
    children: [
      {
        type: 'tab',
        name: tab.name,
        borderHeight: 48,
        component: tab.name,
        enableClose: false,
        enableRenderOnDemand: false,
        maximized: false,
        config: {
          componentUnwrapped: tab.componentUnwrapped,
          customIcon: tab.icon,
        },
      },
    ],
  };
  if (tab.isForRow) {
    const row = model.layout.children.find(
      (child: { id: string }) => child.id === SIDEBAR_ID,
    );
    if (!row) {
      model.layout.children.push({
        type: 'row',
        id: SIDEBAR_ID,
        headerHeight: 48,
        children: [],
      });
    }
    row?.children.push(rowEntry);
  } else {
    const borderRight = model.borders?.at(0);
    const borderBottom = model.borders?.at(1);
    if (tab?.position === 'bottom') {
      borderBottom?.children.push({ ...borderEntry, enableDrag: false });
    } else {
      borderRight?.children.push(borderEntry);
    }
  }
  return model;
};

export const addIfNotFound = (
  name: string,
  newItem: BorderTab,
  borderTabs: BorderTab[],
) => {
  // Add only if not found
  if (!borderTabs.find(x => x.name === name)) {
    borderTabs.push(newItem);
  }
};

export const converTabToBorderTab = (tab: any): any => ({
  type: 'tab',
  name: tab.name,
  borderHeight: 48,
  component: tab.name,
  enableClose: false,
  enableRenderOnDemand: false,
  maximized: false,
  icon: tab.config?.customIcon,
  componentUnwrapped: tab.config?.componentUnwrapped,
  isForRow: tab.config?.isForRow,
});

export const convertBorderTabToTab = (borderTab: BorderTab): any => ({
  type: 'tab',
  name: borderTab.name,
  borderHeight: 48,
  component: borderTab.name,
  enableClose: false,
  enableRenderOnDemand: false,
  maximized: false,
  config: {
    componentUnwrapped: borderTab.componentUnwrapped,
    customIcon: borderTab.icon,
  },
});

export const clearUserSelection = (): void => {
  // https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      // Firefox
      window.getSelection()?.removeAllRanges();
    }
  } else if (document.getSelection) {
    // IE?
    document.getSelection()?.empty();
  }
};

export const clickedDirectlyOnText = (element: HTMLElement): boolean =>
  // code/p etc
  element.nodeType === Node.TEXT_NODE ||
  // eg div with no children (text is not a child!)
  (element.children && element.children.length === 0);

export const getColorBadgeBorder = (label: string): string => {
  switch (label) {
    case 'Tags':
      return 'blue';
    case 'Relationships':
      return 'blue';
    default:
      return 'red';
  }
};

export const getRelationshipsDataFromEdges = async (
  edges: EntityEdge[],
): Promise<RelationshipData[]> => {
  if (!edges?.length) {
    return [];
  }
  const relationships = await Promise.all(
    edges.map(edge => {
      const entity = edge?.relationship?.is_inverse ? edge.to : edge.from;
      return OntologyAPI.getWorkspaceRelationshipRecords(
        entity,
        edge.relationship,
      );
    }),
  );
  return relationships.flat().map(relationship => ({
    ...relationship,
    entity_id: relationship.os_entity_uid,
    entity_type: RELATIONSHIPS_CONCEPT,
    entity_label: relationship.os_relationship_name,
  }));
};
