import * as React from 'react';
import { Button, Modal, Space } from 'antd5';
import ee from 'src/octostar/interface';

import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import shortid from 'shortid';
import { SearchXperienceModalProps } from '@octostar/platform-types/search';
import Table, { DataRequestParams, PageData } from '../Table/Table';
import { SearchCondition } from './SearchCondition';
import SearchForm from './SearchForm';
import {
  ENTITY_LABEL,
  ENTITY_TYPE,
  getQueryConcepts,
  params2sql,
} from './SearchQuery';

interface MyPageData<T> extends PageData<any> {
  id: number;
  sql?: string | undefined;
}
const DEFAULT_COLUMNS = [ENTITY_TYPE.field, ENTITY_LABEL.field];

const INITIAL_PAGE_DATA = { id: 0, data: [], sql: undefined };
const context = 'searchXperience';

type SearchComponentProps = {
  autosearch: boolean;
  onSelectionChanged: (selection: any[] | undefined) => void;
  eeResetTopic?: string;
  defaultConcept?: string[] | undefined;
  disableConceptSelector?: boolean;
  onClearAll: () => void;
  setAutosearch: (on: boolean) => void;
  defaultSearchFields?:
    | { entity_label?: string; os_searchable?: string }
    | undefined;
};
const SearchComponent = (props: SearchComponentProps) => {
  const {
    autosearch,
    setAutosearch,
    onSelectionChanged,
    eeResetTopic,
    defaultConcept,
    disableConceptSelector,
    onClearAll,
    defaultSearchFields,
  } = props;
  const [key, setKey] = React.useState<number>(Math.random());
  const [pageData, setPageData] =
    React.useState<MyPageData<any>>(INITIAL_PAGE_DATA);
  const pageDataRef = React.useRef<MyPageData<any>>(INITIAL_PAGE_DATA);
  const [formSearchConditions, setFormSearchConditions] = React.useState<
    SearchCondition[] | undefined
  >();
  const [tableDataRequestParams, setTableDataRequestParams] = React.useState<
    DataRequestParams | undefined
  >();
  // const [dataRequestParams, setDataRequestParams] = React.useState<DataRequestParams | undefined>()
  const [columns, setColumns] = React.useState<string[]>(DEFAULT_COLUMNS);
  const [allColumns, setAllColumns] = React.useState<string[] | undefined>();

  const reset = React.useCallback(
    (clearForm: boolean) => {
      setPageData({ ...INITIAL_PAGE_DATA });
      pageDataRef.current = { ...INITIAL_PAGE_DATA };
      if (clearForm) {
        setKey(key + 1);
        setFormSearchConditions(undefined);
        setTableDataRequestParams(undefined);
      }
    },
    [key],
  );
  React.useEffect(() => {
    if (eeResetTopic) {
      ee.on(eeResetTopic, reset);
      return () => {
        ee.off(eeResetTopic, reset);
      };
    }
    return undefined;
  }, [eeResetTopic]);

  React.useEffect(() => {
    if (!formSearchConditions) {
      if (pageData?.id !== INITIAL_PAGE_DATA.id) {
        // TODO: clear results?

        return;
      }
    }
    const params: DataRequestParams = {
      ...(tableDataRequestParams || {
        page: 1,
        pageSize: 5,
      }),
    };
    params.conditions = [
      ...(formSearchConditions || []),
      ...(tableDataRequestParams?.conditions || []),
    ];
    if (tableDataRequestParams?.columns) {
      const proposedColumns = [
        ...DEFAULT_COLUMNS,
        ...tableDataRequestParams.columns,
      ]
        .filter((s, i, a) => a.indexOf(s) === i)
        .sort();
      if (proposedColumns.join('|') !== columns.join('|')) {
        setColumns(proposedColumns);
      }
      params.columns = proposedColumns;
    }
    makeDataRequest(params);
  }, [formSearchConditions, tableDataRequestParams]);

  React.useEffect(() => {
    // update list of available fields for the table
    OntologyAPI.getConcepts().then(concepts => {
      let fields = ['entity_id', 'entity_type', 'entity_label'];
      const { queryConcepts } = getQueryConcepts(
        concepts,
        formSearchConditions,
      );
      Object.entries(concepts).forEach(([key, concept]: [string, any]) => {
        // TODO type this
        if (queryConcepts.indexOf(key) >= 0 || queryConcepts.length === 0) {
          fields = [
            ...fields,
            ...(concept.properties || []).map((x: any) => x.property_name),
          ];
        }
      });
      fields = fields.filter((s, i, a) => a.indexOf(s) === i).sort();
      if (fields.join('|') !== (allColumns || []).join('|')) {
        console.log('allColumns', fields);
        setAllColumns(fields);
      }
    });
  }, [formSearchConditions]);

  const makeDataRequest = (params: DataRequestParams) => {
    if (!params?.conditions?.length) {
      OntologyAPI.cancelQueries(context);
      setPageData({ ...INITIAL_PAGE_DATA });
      pageDataRef.current = { ...INITIAL_PAGE_DATA };
      return;
    }
    OntologyAPI.getConcepts().then(concepts => {
      const sql = params2sql(params, concepts);
      const countsSql = params2sql(params, concepts, { count: true });
      if (sql === pageDataRef.current?.sql) {
        // no change.
        // however it the original query may have been cancelled...
      }

      const myPageData: MyPageData<any> = {
        id: Math.random(),
        data: [],
        currentPage: params?.page || 1,
        loading: true,
        sql,
      };
      pageDataRef.current = myPageData;
      setPageData(myPageData);
      console.log('sending search experience query', sql);
      OntologyAPI.cancelQueries(context);
      Promise.all([
        OntologyAPI.sendQuery(sql, { context }).then(data => {
          if (pageDataRef.current?.id === myPageData.id) {
            const copy = { ...pageDataRef.current };
            if (data?.length && Array.isArray(data)) {
              copy.data = data.map(({ os_rank, ...rest }) => rest);
            } else {
              copy.data = [];
            }
            pageDataRef.current = copy;
          }
        }),
        OntologyAPI.sendQueryT<{ count: string }>(countsSql, { context }).then(
          results => {
            if (results?.length && Array.isArray(results)) {
              const count = parseInt(results[0].count, 10);
              if (pageDataRef.current?.id === myPageData.id) {
                const copy = { ...pageDataRef.current };
                copy.totalResults = count;
                pageDataRef.current = copy;
              }
            }
          },
        ),
      ])
        .then(() => {
          if (pageDataRef.current?.id === myPageData.id) {
            const { loading, ...rest } = pageDataRef.current;
            setPageData(rest);
          }
        })
        .catch(err => {
          console.log('error during query', err);
          if (pageDataRef.current?.id === myPageData.id) {
            const { loading, ...rest } = pageDataRef.current;
            rest.data = [];
            setPageData(rest);
          }
        });
    });
  };
  return (
    <Space style={{ width: '100%' }} direction="vertical" size="middle">
      <SearchForm
        key={`form-${key}`}
        onSearch={setFormSearchConditions}
        autosearch={autosearch}
        defaultConcept={defaultConcept}
        disableConceptSelector={disableConceptSelector}
        onClearAll={onClearAll}
        setAutosearch={setAutosearch}
        defaultSearchFields={defaultSearchFields}
      />
      <Table
        key={`table-${key}`}
        pageData={pageData}
        onDataRequest={setTableDataRequestParams}
        onSelectionChanged={onSelectionChanged}
        columns={columns}
        allColumns={allColumns}
      />
    </Space>
  );
};

const SearchXperience = (props: SearchXperienceModalProps) => {
  const {
    open,
    onClose,
    defaultConcept,
    disableConceptSelector = false,
    defaultSearchFields,
    title,
  } = props;
  const [autosearch, setAutosearch] = React.useState<boolean>(true);
  const [selection, setSelection] = React.useState<any[] | undefined>();
  const [randomTopic, setRandomTopic] = React.useState<string>();

  React.useEffect(() => {
    setRandomTopic(`searchxperiencereset-${shortid()}`);
  }, []);
  const reset = React.useCallback(
    (clearForm: boolean) => {
      ee.emit(`${randomTopic}`, clearForm);
    },
    [randomTopic],
  );
  const onSelectionConfirmed = () => {
    const result = [...(selection || [])];
    reset(false);
    onClose(result);
  };
  const clearAll = () => {
    reset(true);
    setSelection(undefined);
    setRandomTopic(`searchxperiencereset-${shortid()}`);
  };
  React.useEffect(() => {
    // freshen the cache
    (selection || []).forEach(entity => OntologyAPI.getEntity(entity));
  }, [selection]);

  const doClose = () => {
    onClose([]);
  };

  return (
    <Modal
      title={title || 'Global Search'}
      open={open}
      onCancel={doClose}
      onOk={onSelectionConfirmed}
      footer={[
        <Button key="back" onClick={doClose}>
          Cancel
        </Button>,
        selection?.length ? (
          <Button key="submit" type="primary" onClick={onSelectionConfirmed}>
            Add({selection.length})
          </Button>
        ) : (
          <Button key="submit" type="primary" disabled>
            Add
          </Button>
        ),
      ]}
      okText={selection?.length ? `Add(${selection.length})` : 'test˚'}
      closable={false}
    >
      {open && (
        <SearchComponent
          onClearAll={clearAll}
          eeResetTopic={randomTopic}
          autosearch={autosearch}
          setAutosearch={setAutosearch}
          onSelectionChanged={setSelection}
          defaultConcept={defaultConcept}
          disableConceptSelector={disableConceptSelector}
          defaultSearchFields={defaultSearchFields}
        />
      )}
    </Modal>
  );
};

export default SearchXperience;
