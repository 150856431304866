import React from 'react';
import { Spin } from 'antd5';
import { WorkspaceItem } from '@octostar/platform-types';
import Loading from 'src/components/Loading';
import ee, { clientCore } from 'src/octostar/interface';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks';
import { TabNode } from 'flexlayout-react';
import { CodeOutlined } from '@ant-design/icons';
import { BUILTIN_APPS } from './layout/builtins';
import { useTabNode } from '../hooks/useTabNode';
import { useAttachment } from '../hooks/useAttachment';
import { WrapperRightSidebar } from './flexlayout/wrappers/WrapperRightSidebar';
import { useRightSidebarControllerContext } from './flexlayout/wrappers/WrapperRightSidebarContext';
import useHandleErrorEffect from '../hooks/useHandleErrorEffect';
import { Metadata } from './Entity/Metadata';
import './ContentContainer.css';

const borderTabs = [
  {
    name: 'Metadata',
    component: () => <Metadata />,
    icon: <CodeOutlined />,
  },
];

export const RichtextViewer = ({
  item,
  node,
}: {
  item: WorkspaceItem;
  node?: TabNode;
}) => {
  const [html, setHtml] = React.useState('<i>Loading...</i>');
  const { loading, error, text, state } = useAttachment(item, 'text');
  const { height } = useTabNode({ node });
  const { setEntities } = useRightSidebarControllerContext();

  useHandleErrorEffect(
    item,
    state,
    loading,
    error,
    BUILTIN_APPS.richtextViewer,
  );

  React.useEffect(() => {
    setEntities(curr => (curr.includes(item) ? curr : curr.concat(item)));
  }, [item, setEntities]);

  React.useEffect(() => {
    if (text !== undefined) {
      setHtml(text);
    }
  }, [text]);

  if (loading) {
    return (
      <div className="content-container">
        <Spin />
      </div>
    );
  }
  if (error) {
    return <div className="content-container">{error}</div>;
  }
  return (
    <div
      className="content-container"
      style={{ minHeight: height - 40 }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export const RichtextViewerLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return (
    <WrapperRightSidebar withEntities borderTabs={borderTabs}>
      <RichtextViewer item={workspaceItem} node={node} />
    </WrapperRightSidebar>
  );
};
export const registerRichtextViewer = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.richtextViewer.os_entity_uid,
    factory: (node: TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <RichtextViewerLoader item={item} node={node} />;
    },
  });
};
