import _ from 'lodash';
import { Concept, ConceptMap, Entity } from '@octostar/platform-types';
import OntologyAPI from '../api/event-driven/ontology';

export const getLabelKeys = (
  concept: Concept,
  conceptsMap: ConceptMap,
): string[] => {
  const labelKeys: Set<string> = new Set();
  const addKeys = (keys: string[]) => {
    keys.forEach(key => {
      if (key && key !== '*' && !labelKeys.has(key)) {
        labelKeys.add(key);
      }
    });
  };
  addKeys(concept.labelKeys);
  _.uniq(concept.parents).forEach(parent => {
    const parentConcept = conceptsMap.get(parent);
    if (parentConcept) {
      addKeys(parentConcept.labelKeys || []);
    }
  });

  return Array.from(labelKeys);
};

export const getLabelKeysByConcept = async (
  concept: Concept,
): Promise<string[]> => {
  const conceptsMap = await OntologyAPI.getConcepts();

  const uniqueLabelKeys = new Set<string>();

  const filterLabelKeys = (keys: string[]) =>
    keys.filter(key => key !== '' && key !== '*' && !uniqueLabelKeys.has(key));

  filterLabelKeys(concept.labelKeys).forEach(key => uniqueLabelKeys.add(key));

  _.uniq(concept.parents).reduce((acc, parent) => {
    const parentConcept = conceptsMap.get(parent) as Concept;
    filterLabelKeys(parentConcept.labelKeys).forEach(key => acc.add(key));
    return acc;
  }, uniqueLabelKeys);

  return Array.from(uniqueLabelKeys);
};

export const isWorkspaceBusinessRecord = async (
  concept: string | Concept,
): Promise<boolean> => {
  let conceptSelected: Concept | undefined;
  if (typeof concept === 'string') {
    conceptSelected = await OntologyAPI.getConceptByName(concept);
  } else {
    conceptSelected = concept;
  }
  if (!conceptSelected?.parents?.includes('os_business_workspace_record'))
    return false;
  const labelKeys = await getLabelKeysByConcept(conceptSelected);
  if (!labelKeys?.length) return false;
  return true;
};

function closestCommonAncestor(ancestors: string[][]): string | undefined {
  // remove non-common ancestors
  const commonAncestors = ancestors.reduce(
    (prev, curr) => prev.filter(a => curr.includes(a)),
    ancestors[0],
  );
  // return closest common ancestor
  return commonAncestors[0];
}

export const getSharedConcept = async (config: {
  concept?: string;
  entities?: Entity[];
  entity_types?: string[];
}) => {
  if (config.concept) {
    return config.concept;
  }
  if (config.entity_types || config.entities) {
    const concepts =
      config.entity_types ||
      (config.entities as Entity[])
        .map((e: any) => e.entity_type as string)
        .filter((s: string, i: number, a: string[]) => a.indexOf(s) === i);
    if (concepts.length === 1) {
      return concepts[0];
    }
    if (concepts.length > 1) {
      const inheritanceHierarchy = await Promise.all(
        concepts.map((concept: string) =>
          OntologyAPI.getConceptByName(concept)
            .then(c => [concept, ...(c?.parents || [])])
            .catch(e => {
              console.log(`could not get concept ${concept}`, e);
              return [];
            }),
        ),
      );
      const commonAncestor = closestCommonAncestor(inheritanceHierarchy);
      return commonAncestor || 'os_thing';
    }
  }
  return 'os_thing';
};

export const getClosestAncestor = async (
  conceptName: string,
  concepts: string[],
): Promise<string | null> => {
  const concept = await OntologyAPI.getConceptByName(conceptName);
  if (!concept) {
    return null;
  }
  return (
    [...concept.parents, concept.concept_name]
      .reverse()
      .find(parent => concepts.includes(parent)) || null
  );
};
