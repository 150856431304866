import * as XLSX from 'xlsx';

export type ParsedData = Array<Record<string, any>>;

export const parseFileFromUrl = (
  fileUrl: string,
  fileType: string,
): Promise<ParsedData> =>
  fetch(fileUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok for URL: ${fileUrl}`);
      }
      return response.blob();
    })
    .then(
      blob =>
        new Promise<ArrayBuffer>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve(reader.result as ArrayBuffer);
            } else {
              reject(new Error('Failed to convert blob to arrayBuffer'));
            }
          };
          reader.onerror = () => reject(new Error('Error reading blob'));
          reader.readAsArrayBuffer(blob);
        }),
    )
    .then(arrayBuffer => {
      let workbook: XLSX.WorkBook;
      if (fileType === 'csv') {
        const binaryStr = new TextDecoder().decode(new Uint8Array(arrayBuffer));
        workbook = XLSX.read(binaryStr, { type: 'string' });
      } else {
        // Handles both 'xlsx' and 'xls' similarly as binary string
        const binaryStr = new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        );
        workbook = XLSX.read(binaryStr, { type: 'binary' });
      }
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      return XLSX.utils.sheet_to_json(worksheet);
    });

export const getColumnDefs = (data: ParsedData) => {
  if (data.length === 0) {
    return [];
  }
  return Object.keys(data[0]).map(key => ({
    headerName: !key.startsWith('__EMPTY') ? key : data?.[0]?.[key] || '',
    field: key || '',
    filter: true,
    flex: 1,
  }));
};
