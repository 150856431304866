import * as React from 'react';
import Loading from '../Loading';

export default function PDFViewer({
  pdfURL,
  defaultWidth,
  defaultHeight,
}: {
  pdfURL: string;
  defaultWidth: number;
  defaultHeight: number;
}) {
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);

  const handleLoad = () => {
    setLoaded(true); // Mark as loaded, show the iframe
    setError(false); // Reset any error states
  };

  const handleError = () => {
    setLoaded(false); // Ensure the iframe is hidden
    if (retryCount < 5) {
      setTimeout(() => {
        setRetryCount(retryCount + 1); // Increment retry count, retry logic
        setError(false); // Reset error for retry
      }, 400 * Math.pow(2, retryCount)); // Exponential backoff
    } else {
      setError(true); // Set error state if retries exceed
    }
  };

  return (
    <div>
      {error ? (
        <div>Error loading the PDF.</div>
      ) : (
        <iframe
          key={retryCount} // Force re-render of iframe on each retry
          title="PDF Viewer"
          src={pdfURL}
          width={defaultWidth || '100%'}
          height={defaultHeight || '100%'}
          style={{ display: loaded ? 'block' : 'none' }} // Hide iframe until loaded
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
      {!loaded && !error && <Loading />}
    </div>
  );
}

export const isPDFFile = (s: string) => s.toLowerCase().endsWith('pdf');
