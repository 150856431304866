import * as React from 'react';
import { Concept, Entity, WorkspaceItem } from '@octostar/platform-types';
import { Icon } from './layout/Icon';
import OntologyAPI from '../api/event-driven/ontology';
import {
  MAGIC_FOLDER_OS_CONTENT_TYPE,
  WORKSPACE_RECORDS_MAGIC_FOLDER,
  VIRTUAL_ITEM_TYPE,
} from '../interface';

export const getIconFromHierarchy = async (
  concept: Concept,
  defaultIcon: string,
  seen: string[] = [],
): Promise<string> => {
  const icon = concept?.tags?.find(t => t.tag_name === 'icon')?.tag_value;
  if (icon) {
    return icon;
  }
  if (concept.parents?.length) {
    const parentConcept = concept.parents.find(p => !seen.includes(p));
    if (parentConcept) {
      seen.push(parentConcept);
      const parent = await OntologyAPI.getConceptByName(concept.parents[0]);
      if (parent) {
        return getIconFromHierarchy(parent, defaultIcon, seen);
      }
    }
  }
  return defaultIcon;
};

export async function getConceptIcon(
  conceptName: string | undefined,
  defaultIcon = 'file',
): Promise<string> {
  if (conceptName) {
    const concept = await OntologyAPI.getConceptByName(conceptName);
    return concept ? getIconFromHierarchy(concept, defaultIcon) : defaultIcon;
  }
  return defaultIcon;
}

const mapping = [
  // Derived from https://www.npmjs.com/package/mimetype-to-fontawesome?activeTab=code
  // ISC license.

  // Images
  ['fa-file-image', /^image\//],
  // Audio
  ['fa-file-audio', /^audio\//],
  // Video
  ['fa-file-video', /^video\//],
  // Documents
  ['fa-file-pdf', 'application/pdf'],
  ['fa-file-alt', 'text/plain'],
  [
    'fa-file-code',
    [
      'text/html',
      'text/javascript',
      'application/x-python-code',
      'text/x-python',
      'text/css',
    ],
  ],
  ['fa-file-csv', 'text/csv'],
  // Archives
  [
    'fa-file-archive',
    [
      /^application\/x-(g?tar|xz|compress|bzip2|g?zip)$/,
      /^application\/x-(7z|rar|zip)-compressed$/,
      /^application\/(zip|gzip|tar)$/,
    ],
  ],
  // Word
  [
    'fa-file-word',
    [
      /ms-?word/,
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  ],
  // Powerpoint
  [
    'fa-file-powerpoint',
    [
      /ms-?powerpoint/,
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  ],
  // Excel
  [
    'fa-file-excel',
    [
      /ms-?excel/,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  ],
  // Default, misc
  ['file', []],
];
const MIMETYPE_CACHE: { [key: string]: any } = {
  [MAGIC_FOLDER_OS_CONTENT_TYPE]: 'fa-star',
  [WORKSPACE_RECORDS_MAGIC_FOLDER]: 'fa-star',
};

function matchMimeType(
  mimetype: string,
  cond?: RegExp | string | (RegExp | string)[],
): boolean {
  if (Array.isArray(cond)) {
    return cond.reduce(function (v, c) {
      return v || matchMimeType(mimetype, c);
    }, false);
  }
  if (cond instanceof RegExp) {
    return cond.test(mimetype);
  }
  if (cond === undefined) {
    return true;
  }
  return mimetype === cond;
}

const getIconByMimeType = (mimetype: string): string | undefined => {
  if (MIMETYPE_CACHE[mimetype]) {
    return MIMETYPE_CACHE[mimetype];
  }

  for (let i = 0; i < mapping.length; i += 1) {
    if (matchMimeType(mimetype, mapping[i][1])) {
      MIMETYPE_CACHE[mimetype] = mapping[i][0];
      return MIMETYPE_CACHE[mimetype];
    }
  }
  return undefined;
};
export const getIcon = async (
  x: Entity | Concept | string,
  defaultIcon: string,
): Promise<string> => {
  if ((x as WorkspaceItem).os_icon) {
    return Promise.resolve((x as WorkspaceItem).os_icon!);
  }
  if ((x as WorkspaceItem).os_item_content?.icon) {
    return Promise.resolve((x as any).os_item_content?.icon);
  }
  if ((x as Entity).entity_type === VIRTUAL_ITEM_TYPE) {
    const concept_name = (x as WorkspaceItem).os_item_content?.entity
      ?.entity_type;
    if (concept_name) {
      const concept = await OntologyAPI.getConceptByName(concept_name);
      if (concept) {
        return getIconFromHierarchy(concept, defaultIcon);
      }
    }
  }

  const { os_item_content_type } = x as WorkspaceItem;
  if (`${os_item_content_type}`.indexOf('os_concept') >= 0) {
    const concept = await OntologyAPI.getConceptByName(
      (x as WorkspaceItem).os_item_name,
    ).catch(() => {});
    if (concept) {
      return getIconFromHierarchy(concept, defaultIcon);
    }
  }
  if (os_item_content_type) {
    const icon = getIconByMimeType(os_item_content_type);
    if (icon) {
      return Promise.resolve(icon);
    }
  }
  let concept = x as Concept | undefined;
  let conceptName: string | undefined;
  if (typeof x === 'string') {
    conceptName = x;
  } else if ((x as Entity).entity_type) {
    conceptName = (x as Entity).entity_type;
  }
  if (conceptName) {
    concept = await OntologyAPI.getConceptByName(conceptName).catch(() => {});
  }
  if (concept) {
    return getIconFromHierarchy(concept, defaultIcon);
  }
  return defaultIcon;
};

export const ConfiguredIcon = ({
  hint,
  defaultIcon = 'file',
  iconRef,
  className,
}: {
  hint: Entity | Concept | string;
  defaultIcon?: string;
  iconRef?: React.Ref<HTMLSpanElement>;
  className?: string;
}) => {
  const [icon, setIcon] = React.useState<string>();
  const x = React.useRef(0);
  React.useEffect(() => {
    const { current } = x;
    getIcon(hint, defaultIcon).then(ic => {
      if (current === x.current) {
        setIcon(ic);
      }
    });
    return () => {
      x.current = current + 1;
    };
  }, [hint, defaultIcon]);
  if (!icon) {
    return <Icon icon={defaultIcon} iconRef={iconRef} className={className} />;
  }
  return <Icon icon={icon} iconRef={iconRef} className={className} />;
};
export default ConfiguredIcon;
