import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import {
  Concept,
  ConceptFilter,
  Entity,
  FocusListItem,
  Relationship,
} from '@octostar/platform-types';
import { getSharedConcept } from 'src/octostar/lib/ConceptUtils';
import { uniq } from 'lodash';

export const getAvailableRelationships = async (
  source: Entity,
  target: FocusListItem,
): Promise<Relationship[]> =>
  OntologyAPI.getRelationshipsForEntity(source).then(
    (relationships: Relationship[]) => {
      const targetConcept =
        target.type === 'single'
          ? target.entity.entity_type
          : target.type === 'group'
          ? target.query.concept
          : undefined;
      if (!targetConcept) {
        return [];
      }
      return OntologyAPI.getConceptByName(targetConcept).then(
        (concept: Concept) => {
          const concepts = [concept?.concept_name, ...(concept?.parents || [])];
          return relationships.filter(
            relationship =>
              relationship.is_mtm &&
              concepts.indexOf(relationship.target_concept) >= 0,
          );
        },
      );
    },
  );

export const getQueryFocusList = async (
  entities: Entity[],
): Promise<ConceptFilter> => {
  const entity_ids = entities.map((x: Entity) => x.entity_id);
  const entity_types = uniq(entities.map((x: Entity) => x.entity_type));
  const concept = await getSharedConcept({ entity_types });
  return {
    concept,
    filters: [
      {
        col: 'entity_id',
        op: 'IN',
        val: entity_ids,
      },
    ],
  } as ConceptFilter;
};
