import * as React from 'react';
import Editor from '@monaco-editor/react';
import ee, { clientCore } from 'src/octostar/interface';
import { TabNode } from 'flexlayout-react';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks/useCurrentWorkspaceItem';
import { DraftWorkspaceItem, WorkspaceItem } from '@octostar/platform-types';
import mime from 'mime';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import shortid from 'shortid';
import { useTabNode } from 'src/octostar/hooks/useTabNode';
import { useAttachment } from 'src/octostar/hooks/useAttachment';
import onKeyPress from 'src/octostar/lib/onKeyPress';
import { isEqual } from 'lodash';
import useHandleErrorEffect from 'src/octostar/hooks/useHandleErrorEffect';
import { BUILTIN_APPS } from '../layout/builtins';
import Loading from '../Loading';
import { Controls } from './Controls';

const languageMap = {
  as: 'actionscript',
  asp: 'asp',
  aspx: 'asp',
  bat: 'bat',
  c: 'cpp',
  cc: 'cpp',
  cfg: 'ini',
  coffee: 'coffeescript',
  conf: 'apacheconf',
  cpp: 'cpp',
  cs: 'csharp',
  css: 'css',
  csv: 'csv',
  cxx: 'cpp',
  diff: 'diff',
  dockerfile: 'dockerfile',
  ejs: 'ejs',
  f: 'fortran',
  go: 'go',
  graphql: 'graphql',
  groovy: 'groovy',
  h: 'cpp',
  handlebars: 'handlebars',
  hh: 'cpp',
  hpp: 'cpp',
  htm: 'html',
  html: 'html',
  htaccess: 'apacheconf',
  hxx: 'cpp',
  ini: 'ini',
  java: 'java',
  jar: 'java',
  jenkinsfile: 'groovy',
  js: 'javascript',
  json: 'json',
  jsp: 'jsp',
  jsx: 'javascript',
  less: 'less',
  log: 'log',
  lua: 'lua',
  m: 'objective-c',
  makefile: 'makefile',
  markdown: 'markdown',
  md: 'markdown',
  mdx: 'markdown',
  mm: 'objective-c',
  njs: 'javascript',
  npmignore: 'plaintext',
  php: 'php',
  pl: 'perl',
  properties: 'properties',
  proto: 'protobuf',
  py: 'python',
  rb: 'ruby',
  rc: 'csharp',
  rs: 'rust',
  rss: 'xml',
  rst: 'restructuredtext',
  sass: 'sass',
  scala: 'scala',
  scss: 'scss',
  sh: 'shell',
  sln: 'csharp',
  sql: 'sql',
  styl: 'stylus',
  svg: 'xml',
  swift: 'swift',
  tcl: 'tcl',
  tex: 'tex',
  tgz: 'tar',
  toml: 'toml',
  ts: 'typescript',
  tsx: 'typescript',
  txt: 'plaintext',
  vb: 'vb',
  vbs: 'vbscript',
  vm: 'velocity',
  vue: 'vue',
  webmanifest: 'json',
  xml: 'xml',
  xsl: 'xml',
  yaml: 'yaml',
  yml: 'yaml',
};

type EditFile = {
  item: WorkspaceItem;
  initialValue: string;
  name: string;
  language: string;
  value?: string;
};
export type MonacoEditorParams = {
  items: WorkspaceItem[];
  node: TabNode;
  onChange?: (value: string) => void;
  hideControls?: boolean;
  defaultValue?: string;
  language?: string;
};
export const MonacoEditor = ({
  items,
  node,
  onChange,
  hideControls,
  defaultValue,
  language,
}: MonacoEditorParams) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [file, setFile] = React.useState<EditFile>();
  const { text, error, loading, item, setItem, state } = useAttachment(
    [...items].shift(),
    'text',
  );
  const [saving, setSaving] = React.useState(false);
  const keyRef = React.useRef(shortid());
  const { width, height } = useTabNode({ node });

  useHandleErrorEffect(item, state, loading, error, BUILTIN_APPS.monacoEditor);

  const cancelChanges = React.useCallback(() => {
    DesktopAPI.showConfirm({ title: 'All changes will be lost' }).then(
      confirmed => {
        if (confirmed) {
          keyRef.current = shortid();
          setFile(v => ({
            ...v!,
            value: v!.initialValue,
          }));
        }
      },
    );
  }, [setFile]);

  const saveChanges = React.useCallback(() => {
    if (!(file && item)) {
      return;
    }
    const { value, initialValue } = file;
    const blob = new Blob([value || initialValue || ''], {
      type: item?.os_item_content_type || 'text/plain',
    });
    setSaving(true);
    DesktopAPI.saveFile(item, new File([blob], item?.os_item_name))
      .then(() => {
        setFile(v => ({ ...v!, initialValue: value || initialValue || '' }));
        DesktopAPI.showToast(`Saved ${item.os_item_name}`);
      })
      .catch(e => {
        console.error(`problem saving ${item.os_item_name}`, e);
        DesktopAPI.showToast({
          message: `save ${item.os_item_name} failed`,
          level: 'error',
          description: `Problem saving ${item.os_item_name}`,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }, [file, item]);

  React.useEffect(
    () =>
      onKeyPress('s', divRef, () => {
        if (!(saving || file?.initialValue === file?.value)) {
          saveChanges();
        }
      }),
    [file, saveChanges, saving],
  );

  React.useEffect(() => {
    if (loading || error) {
      return;
    }
    if (!item) {
      return;
    }
    if (item.os_entity_uid === file?.item.os_entity_uid) {
      return;
    }

    const extension =
      mime.getExtension(item.os_item_content_type || 'unknown') || 'text/plain';
    const filelang =
      languageMap[
        `${item.os_item_name}`.toLocaleLowerCase().split('.').pop() || 'unknown'
      ];
    const lang = language || filelang || languageMap[extension] || 'plaintext';
    if ((item as DraftWorkspaceItem).os_draft_item) {
      const newValue = {
        item,
        initialValue: '',
        name: item.os_item_name,
        language: lang,
        value: defaultValue || '',
      };
      setFile(curr => (isEqual(curr, newValue) ? curr : newValue));

      return;
    }
    if (text === undefined) {
      return;
    }

    const newValue = {
      item,
      initialValue: text || defaultValue || '',
      name: item.os_item_name,
      language: lang,
      value: text || '',
    };
    setFile(curr => {
      const unchanged = isEqual(curr, newValue);
      if (unchanged) {
        return curr;
      }
      keyRef.current = shortid();
      return newValue;
    });
  }, [item, text, loading, error, file, defaultValue, language]);

  React.useEffect(() => {
    const newItem = items.length ? items[0] : undefined;
    setItem((curr: WorkspaceItem) => (isEqual(curr, newItem) ? curr : newItem));
  }, [items, setItem]);

  React.useEffect(() => {
    onChange?.(file?.value || '');
  }, [onChange, file?.value]);

  if (error) {
    return <div>{error}</div>;
  }
  if (loading || !file) {
    return <Loading />;
  }

  return (
    <div ref={divRef}>
      {!hideControls && (
        <Controls
          disabled={file.initialValue === file.value}
          saving={saving}
          onSave={saveChanges}
          onCancel={cancelChanges}
        />
      )}
      {items.length > 1 && // TODO: add proper support for multiple files
        items.map(it => (
          <button
            type="button"
            key={it.os_entity_uid}
            disabled={it.os_entity_uid === item?.os_entity_uid}
            onClick={() => {
              setItem(it);
            }}
          >
            {it.os_item_name}
          </button>
        ))}
      {state === 'ready' && (
        <Editor
          key={keyRef.current}
          path={item?.entity_id || file.name}
          language={file.language}
          defaultValue={file.value}
          width={width}
          height={hideControls ? height : height - 100}
          saveViewState
          onChange={next => {
            setFile(v => ({
              ...v!,
              value: next,
            }));
          }}
        />
      )}
    </div>
  );
};

export const MonacoEditorLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return <MonacoEditor items={[workspaceItem]} node={node} />;
};
export const registerMonacoEditor = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.monacoEditor.os_entity_uid,
    factory: (node: TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <MonacoEditorLoader item={item} node={node} />;
    },
  });
};
