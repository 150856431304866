import * as React from 'react';
import { WorkspaceItem } from '@octostar/platform-types';
import DesktopAPI from '../api/event-driven/desktop';
import { AttachmentState } from './useAttachment';

export const useHandleErrorEffect = (
  item?: WorkspaceItem,
  state?: AttachmentState,
  loading?: boolean,
  error?: string,
  app?: WorkspaceItem,
) => {
  React.useEffect(() => {
    if (!loading && state === 'error') {
      // eslint-disable-next-line no-console
      console.log('Error loading file', error);
      DesktopAPI.showToast({
        level: 'error',
        message: 'Error loading file',
        description: error || 'Check the logs for more information.',
      });
      if (item && app) {
        DesktopAPI.closeTab({
          app,
          item,
        });
      }
    }
  }, [state, error, item, loading, app]);
};

export default useHandleErrorEffect;
