import { WorkspaceItem } from '@octostar/platform-types';

export interface S3Headers {
  key: string;
  policy: string;
  'x-amz-algorithm': string;
  'x-amz-credential': string;
  'x-amz-date': string;
  'x-amz-signature': string;
}

export type UploadRequestMethod =
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'post'
  | 'put'
  | 'patch';

export interface UploadRequestError extends Error {
  status?: number;
  method?: UploadRequestMethod;
  url?: string;
}

export interface UploadRequestOption<T = any> {
  secret: S3Headers;
  file: File;
  action: string;
  method: UploadRequestMethod;
}

function getError(option: UploadRequestOption, xhr: XMLHttpRequest) {
  const msg = `cannot ${option.method} ${option.action} ${xhr.status}'`;
  const err = new Error(msg) as UploadRequestError;
  err.status = xhr.status;
  err.method = option.method;
  err.url = option.action;
  return err;
}

function getBody(xhr: XMLHttpRequest) {
  const text = xhr.responseText || xhr.response;
  if (!text) {
    return text;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return text;
  }
}

function upload(option: UploadRequestOption): Promise<[any, any]> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    const formData = new FormData();
    if (option.secret) {
      Object.keys(option.secret).forEach(key => {
        const value = option.secret[key];
        formData.append(key, value as string | Blob);
      });
    }
    formData.append('file', option.file, (option.file as any).name);

    xhr.onerror = function error(e) {
      reject(e);
    };
    xhr.onload = function onload() {
      // allow success when 2xx status
      if (xhr.status < 200 || xhr.status >= 300) {
        return reject([getError(option, xhr), getBody(xhr)]);
      }

      return resolve([getBody(xhr), xhr]);
    };

    xhr.open(option.method, option.action, true);
    xhr.send(formData);
  });
}

export async function touchFile(wi: WorkspaceItem): Promise<void> {
  const file = new File([], wi.os_item_name, { type: wi.os_item_type });
  const { fields, url } = wi['#uploadSecret'];
  await upload({
    secret: fields,
    action: url,
    file,
    method: 'post',
  });
}
