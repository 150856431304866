import LRU from 'lru-cache';
import SqlString from 'sqlstring';
import _ from 'lodash';
import {
  Concept,
  Entity,
  Ontology,
  Relationship,
  RelationshipCountResult,
  WorkspaceRelationship,
} from '@octostar/platform-types';
import { CachinQueryExecutor, KeysToQuery } from './CachingQueryExecutor';
import { RelationshipCountCache } from './RelationshipCountCache';
import DesktopAPI from '../api/event-driven/desktop';
import { EntityTypeCache } from './EntityTypeCache';
import { preprocess, toRelationshipSql } from './RelationshipQueries';
import { getConceptSelectAllFields } from './query';

export const CACHE_TTL = 1000 * 60 * 60; // 30 minutes

type EntityFromOsWorkspaceRelationship = Entity & {
  os_workspace_relationship: WorkspaceRelationship;
};
interface SingleEntity {
  key: string;
  entity: EntityFromOsWorkspaceRelationship;
}

interface GroupedEntity {
  key: string;
  entity: EntityFromOsWorkspaceRelationship[];
}

const NO_DATA_ENTITY = {
  entity_id: 'RELATIONSHIP_CACHE_NO_DATA_ENTITY',
  entity_label: 'THIS SHOULD NEVER BE SEEN',
  entity_type: 'RELATIONSHIP_CACHE_NO_DATA_ENTITY',
};

const toBigDataRelationshipSql =
  (ontology: Ontology) =>
  async (
    batchKey: string,
    keys: string[],
    input: { entity: Entity; relationship: Relationship },
    entities: Entity[],
  ) => {
    const { relationship: rel } = input;
    const concept: Concept | undefined = await ontology.getConceptByName(
      rel.target_concept,
    );
    if (!concept) {
      throw new Error(`Concept not found: ${rel?.target_concept}`);
    }

    return toRelationshipSql(rel, entities);
  };

const toLocalDataRelationshipSql = async (
  batchKey: string,
  keys: string[],
  input: { entity: Entity; relationship: Relationship },
) => {
  const entity_ids: string[] = keys.map(s => s.split('|')[0]); // see the key function below
  const entityIds = entity_ids.map(x => SqlString.escape(x)).join(', ');
  const fields = await getConceptSelectAllFields('os_workspace_relationship');
  return `SELECT ${fields}
  FROM timbr.os_workspace_relationship
  WHERE os_entity_uid_from in (${entityIds})
  or os_entity_uid_to in (${entityIds})`;
};

const createLocalRelationshipCache = (
  ontology: Ontology,
): CachinQueryExecutor<
  { entity: Entity; relationship: Relationship },
  EntityFromOsWorkspaceRelationship
> =>
  new CachinQueryExecutor<
    { entity: Entity; relationship: Relationship; forceRefresh?: boolean },
    EntityFromOsWorkspaceRelationship
  >({
    cache: new LRU<string, EntityFromOsWorkspaceRelationship[]>({
      max: 100000,
      ttl: CACHE_TTL,
    }),
    queryExecutor: ontology,
    getEntity: ({ entity }) => entity,
    maxBatchSize: 500,
    asArray: true,
    toSql: toLocalDataRelationshipSql,
    preprocess: async (rows: WorkspaceRelationship[]) => {
      const results: SingleEntity[] = [];
      const inverse: { [name: string]: string } = {};
      await Promise.all(
        rows.map(async row => {
          let key = `${row.os_entity_uid_from}|${row.os_relationship_name}`;
          results.push({
            key,
            entity: {
              entity_type: row.os_entity_type_to,
              entity_id: row.os_entity_uid_to,
              entity_label: '',
              os_workspace_relationship: row,
            },
          });
          let inverseName = inverse[row.os_relationship_name];
          if (!inverseName) {
            const concept = await ontology.getConceptByName(
              row.os_entity_type_from,
            );
            if (concept) {
              const rel = concept.relationships.find(
                r => r.relationship_name === row.os_relationship_name,
              );
              if (rel) {
                inverseName = rel.inverse_name;
                inverse[row.os_relationship_name] = inverseName;
              }
            }
          }
          if (inverseName) {
            key = `${row.os_entity_uid_to}|${inverseName}`;
            results.push({
              key,
              entity: {
                entity_type: row.os_entity_type_from,
                entity_id: row.os_entity_uid_from,
                entity_label: '',
                os_workspace_relationship: row,
              },
            });
          }
        }),
      );
      const grouped: GroupedEntity[] = results.reduce(
        (acc: GroupedEntity[], { key, entity }) => {
          const existingGroup = acc.find(group => group.key === key);
          if (existingGroup) {
            existingGroup.entity.push(entity);
          } else {
            acc.push({ key, entity: [entity] });
          }
          return acc;
        },
        [],
      );
      return grouped;
    },
    transform: (row: any) => row.entity,
    key: ({ entity, relationship }) =>
      `${entity.entity_id}|${relationship.relationship_name}`,
    rowToKey: (row: any) => row.key,
    queryBatchKey: () => 'x',
  });
export class RelationshipCache {
  ontology: Ontology;

  bigDataExecutor: CachinQueryExecutor<
    { entity: Entity; relationship: Relationship },
    Entity
  >;

  localDataExecutor: CachinQueryExecutor<
    { entity: Entity; relationship: Relationship },
    EntityFromOsWorkspaceRelationship
  >;

  private counts: RelationshipCountCache;

  private entityTypeCache: EntityTypeCache;

  constructor(ontology: Ontology, counts: RelationshipCountCache) {
    this.ontology = ontology;
    this.counts = counts;
    this.entityTypeCache = new EntityTypeCache(ontology);

    this.bigDataExecutor = this.createRelationshipsCache(
      toBigDataRelationshipSql(ontology),
      () => NO_DATA_ENTITY,
    );
    this.localDataExecutor = createLocalRelationshipCache(this.ontology);
  }

  createRelationshipsCache(
    toSql: KeysToQuery<{
      entity: Entity;
      relationship: Relationship;
    }>,
    getDefaultValue?: () => Entity,
  ) {
    return new CachinQueryExecutor<
      { entity: Entity; relationship: Relationship },
      Entity
    >({
      cache: new LRU({ max: 10000, ttl: CACHE_TTL }),
      queryExecutor: this.ontology,
      // sendQueryOptions: { lowPriority: false, batchTimeout: 50 },
      asArray: true,
      toSql,
      getEntity: ({ entity }) => entity,
      preprocess,
      rowToKey: (row: any) => row.mapping_entity_id,
      transform: (row: any) => {
        // eslint-disable-next-line no-param-reassign
        delete row.mapping_entity_id;
        return row;
      },
      getDefaultValue,

      key: ({ entity }) => entity.entity_id,
      queryBatchKey: ({ entity, relationship }) =>
        `${this.getRelationshipCacheKey(relationship, entity.entity_type)}`,
    });
  }

  async clear({
    entity,
    relationship,
  }: {
    entity: Entity;
    relationship: Relationship;
  }) {
    this.localDataExecutor.clear({ entity, relationship });
    this.bigDataExecutor.clear({ entity, relationship });
  }

  async getLocalWorkspaceRelationships({
    entity,
    relationship,
  }: {
    entity: Entity;
    relationship: Relationship;
  }): Promise<WorkspaceRelationship[]> {
    const results = await this.localDataExecutor.getArray({
      entity,
      relationship,
    });
    return results.map(e => e.os_workspace_relationship);
  }

  async getConnected({
    entity,
    relationship,
  }: {
    entity: Entity;
    relationship: Relationship;
  }) {
    const counts = await this.counts.getRelationshipCount(
      entity,
      relationship,
      true,
    );
    if (counts.count === 0 && !counts.expired) {
      return [];
    }

    let entities = (
      await this.localDataExecutor.getArray({
        entity,
        relationship,
      })
    ).map(({ os_workspace_relationship, ...entity }) => entity);

    if (counts.count && entities.length < counts.count) {
      try {
        let bigData = await this.bigDataExecutor.getArray({
          entity,
          relationship,
        });
        if ((bigData as any) === NO_DATA_ENTITY) {
          bigData = [];
        }
        bigData = bigData.filter(x => x.entity_id !== NO_DATA_ENTITY.entity_id);

        const resolved = await Promise.all(
          bigData.map(async ({ entity_type, entity_id }) =>
            this.entityTypeCache.toEntity(entity_type, entity_id),
          ),
        );
        entities = _.uniqBy(entities.concat(resolved), 'entity_id');
      } catch (e) {
        console.log(
          `problem fetching big data relationships for ${relationship.relationship_name} for ${entity.entity_label}`,
          e,
        );
      }
    }
    warnIfWrongNumberOfResults(entities, counts, relationship, entity);
    return entities;
  }

  private getRelationshipCacheKey(rel: Relationship, entityType: string) {
    return `${rel.concept}|${rel.target_concept}|${rel.relationship_name}|${entityType}`;
  }

  async validateQueryResult(resp: Entity[]) {
    if (resp?.length === 0) {
      return resp;
    }
    if (!resp?.[0]?.entity_id) {
      console.log('Invalid entity result: ', resp);
      throw new Error(`Entity not found: ${resp?.[0]?.entity_id}`);
    }
    return resp;
  }
}
function warnIfWrongNumberOfResults(
  entities: Entity[],
  counts: RelationshipCountResult,
  relationship: Relationship,
  entity: Entity,
) {
  if (entities.length < counts.count) {
    const n = counts.count - entities.length;
    DesktopAPI.showToast({
      message: `${n} record${n > 1 ? 's' : ''} missing for relationship ${
        relationship.relationship_name
      } connected to ${entity.entity_label}`,
      level: 'warning',
    });
  }
}
