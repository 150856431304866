import EventEmitter from 'eventemitter3';
import shortid from 'shortid';
import {
  ApiResult,
  Entity,
  EventEmitterType,
  Unsubscribe,
  WorkspaceItem,
} from '@octostar/platform-types';
import { ONTOLOGY_PREFIX } from './api/messagesTypes';

export type OS = { ee: EventEmitterType };

export const OS_DRAFT_WORKSPACE = 'draft';
export const MAGIC_FOLDER_OS_CONTENT_TYPE = 'octostar.magic.folder';
export const WORKSPACE_RECORDS_MAGIC_FOLDER = `${MAGIC_FOLDER_OS_CONTENT_TYPE}.workspace_records`;
export const TAGS_CONCEPT = 'os_tag';
export const TAGGED_RELATIONSHIP = 'has_tag';
export const WORKSPACE_TAGS_MAGIC_FOLDER = `${MAGIC_FOLDER_OS_CONTENT_TYPE}.${TAGS_CONCEPT}s`;
export const WORKSPACE_TAG_MAGIC_FOLDER = `${MAGIC_FOLDER_OS_CONTENT_TYPE}.${TAGS_CONCEPT}`;
export const WORKSPACE_RECORDS_CONCEPT = 'os_business_workspace_record';
export const FILESYSTEM_ENTITY_CONCEPT = 'os_wsfs_object';
export const RELATIONSHIPS_CONCEPT = 'os_workspace_relationship';
export const FEATURE_RELATIONSHIP = 'featured_in';
export const TEMPORARY_ITEM_TYPE = 'temporary';
export const VIRTUAL_ITEM_TYPE = 'os_virtual_entity';
export const EPHEMERAL_ITEMS = [TEMPORARY_ITEM_TYPE, VIRTUAL_ITEM_TYPE];
export const IFRAME_TRANSFORM_TYPE = 'iframe_transform';
export const OCTOSTAR_INTERNAL_ENTITY_TYPES = [
  'os_wsfs_object',
  'os_workspace',
  'os_workspace_permission',
  'os_linkchart',
  'os_file',
  'os_folder',
  'os_tag',
];

export const HAS_COMMENT_REL_NAME = 'has_comment';
export const IS_COMMENT_ABOUT_REL_NAME = 'is_comment_about';
export const HAS_ATTACHMENT_ATTRIBUTE = 'os_has_attachment';

export const isFile = (x?: Entity) =>
  (x as WorkspaceItem)?.os_has_attachment || false;

export const isMedia = (x?: Entity) =>
  (x as WorkspaceItem)?.entity_type === 'image' ||
  (x as WorkspaceItem)?.entity_type === 'video' ||
  ((x as WorkspaceItem)?.entity_type === 'audio' && (x as any)?.url);

export interface VirtualOrTemporaryItem extends WorkspaceItem {
  os_item_type: typeof VIRTUAL_ITEM_TYPE | typeof TEMPORARY_ITEM_TYPE;
}

declare global {
  interface Window {
    os: OS;
  }
}

export const clientCore = (method: string) => `octostar:desktop:${method}`;
export const clientTheme = (method: string) =>
  `octostar.desktop.theme.${method}`;

/**
 * Wrapper for pub/sub event style api where the first parameter to
 * the emit is the reply topic, followed by implementation specific
 * parameters
 */
export async function apiCall<T>(target: string, ...args: any): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      const topic = `${target.replace(/^.*\./, '')}-${shortid()}-once`;
      window.os.ee.once(topic, (response: ApiResult<T>) => {
        if (!response?.status) {
          reject(
            new Error(
              `the invoked api ${target} did not return an api response`,
            ),
          );
        } else if (response.status === 'success') {
          resolve(response.data);
        } else {
          reject(new Error(`${response.data}`));
        }
      });
      window.os.ee.emit(target, topic, ...args);
    } catch (e) {
      reject(e);
    }
  });
}

export async function apiListener<T>(
  target: string,
  callback: (data: T) => void,
): Promise<Unsubscribe> {
  const unsub = async () => {
    window.os.ee.off(target, callback);
    return Promise.resolve();
  };
  window.os.ee.on(target, callback);
  return unsub;
}
// for in superset view
window.os = window.os || { ee: new EventEmitter() };
export default window.os.ee;

export const getNotificationKey = (entity: Entity) =>
  `${ONTOLOGY_PREFIX}entity:${entity.entity_type}:${entity.entity_id}`;

export const getAppTransformKey = (entity: Entity) =>
  `${getNotificationKey(entity)}:app_transform`;
