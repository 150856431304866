import { Entity, FocusListItem } from '@octostar/platform-types';
import { getQueryFocusList } from '../components/Relationships/helpers';

export class FocusList {
  private focusList: FocusListItem[];

  constructor() {
    this.focusList = [];
  }

  getList(): FocusListItem[] {
    return this.focusList;
  }

  async addEntities(
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ): Promise<void> {
    if (type === 'group') {
      const found = this.focusList.find(
        x => x.type === 'group' && x.entity_label === tabName,
      );
      if (found) {
        found.count = entities.length;
        return;
      }
      const query = await getQueryFocusList(entities);
      this.focusList.push({
        entity_label: tabName || 'Group',
        count: entities.length,
        query,
        type,
      });
      return;
    }
    entities.forEach(entity => {
      const found = this.focusList.find(
        x => x.type === 'single' && x.entity.entity_id === entity.entity_id,
      );
      if (found) {
        found.count += 1;
      } else {
        this.focusList.push({ entity, count: 1, tabName, type: 'single' });
      }
    });
  }

  removeEntities(
    entities: Entity[],
    type?: 'single' | 'group',
    tabName?: string,
  ): void {
    if (type === 'group') {
      this.focusList = this.focusList.filter(
        x => x.type !== 'group' || x.entity_label !== tabName,
      );
      return;
    }
    entities.forEach(entity => {
      const found = this.focusList.find(
        x => x.type === 'single' && x.entity.entity_id === entity.entity_id,
      );
      if (found?.type === 'single') {
        found.count -= 1;
        if (found.tabName === tabName) {
          found.tabName = undefined;
        }
        if (found.count === 0) {
          this.focusList = this.focusList.filter(
            x => x.type === 'single' && x.entity.entity_id !== entity.entity_id,
          );
        }
      }
    });
  }
}
