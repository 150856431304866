import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { ConceptMap } from '@octostar/platform-types';
import {
  resolveSchemaFromAttachment,
  resolveSchemaFromOntology,
  resolveSchemaInheritance,
  resolveSchemaUiFromOntology,
  resolveSchemaUiInheritance,
} from './SchemaInheritance';
import { SchemaJSON, SchemaType, SchemaUiJSON } from './types';
import { CUSTOM_TEMPLATE_SCHEMA, CUSTOM_TEMPLATE_SCHEMA_UI } from './util';

export const getSchema = async (
  conceptEntity: string,
  requiredFieldsOnly?: boolean,
): Promise<{ schema: string; schemaUI: string }> => {
  const conceptsMap: ConceptMap = await OntologyAPI.getConcepts();
  const schemaUI: SchemaUiJSON = await resolveSchemaUiInheritance(
    conceptEntity,
    conceptsMap,
  );
  const schema: SchemaJSON = await resolveSchemaInheritance(
    conceptEntity,
    conceptsMap,
    requiredFieldsOnly,
  );
  return { schema: JSON.stringify(schema), schemaUI: JSON.stringify(schemaUI) };
};

export const getSchemaFromConcept = async (
  conceptEntity: string,
): Promise<{ schemaConcept: string; schemaUIConcept: string }> => {
  const concept = await OntologyAPI.getConceptByName(conceptEntity);
  if (!concept || !concept.allProperties) {
    return { schemaConcept: '', schemaUIConcept: '' };
  }
  let schemaConcept = '';
  let schemaUIConcept = '';
  // Get schema from attachment or Ontology
  const schemaItems = await DesktopAPI.getSchemaItems(CUSTOM_TEMPLATE_SCHEMA);
  const schemaFromAttachment = await resolveSchemaFromAttachment(
    conceptEntity,
    SchemaType.SCHEMA,
    schemaItems,
  );
  if (!schemaFromAttachment) {
    const schemaFromOntology = await resolveSchemaFromOntology(concept);
    if (schemaFromOntology) {
      schemaConcept = JSON.stringify(schemaFromOntology);
    }
  } else {
    schemaConcept = JSON.stringify(schemaFromAttachment);
  }
  // Get schemaUI concept from attachment or Ontology
  const schemaUiItems = await DesktopAPI.getSchemaItems(
    CUSTOM_TEMPLATE_SCHEMA_UI,
  );
  const schemaUiFromAttachment = await resolveSchemaFromAttachment(
    conceptEntity,
    SchemaType.SCHEMA_UI,
    schemaUiItems,
  );
  if (!schemaUiFromAttachment) {
    const schemaUiFromOntology = resolveSchemaUiFromOntology(concept);
    if (schemaUiFromOntology) {
      schemaUIConcept = JSON.stringify(schemaUiFromOntology);
    }
  } else {
    schemaUIConcept = JSON.stringify(schemaUiFromAttachment);
  }
  return {
    schemaConcept,
    schemaUIConcept,
  };
};
