import { Ontology } from '@octostar/platform-types';
import OntologyAPIClass from 'src/octostar/lib/OntologyAPI';
import ee, { clientCore } from '../../interface';
import { createProxy } from '.';
import { ONTOLOGY_PREFIX } from '../messagesTypes';

/**
 * This is the OntologyAPI Client, for which a server is
 * registered by the OntologyContext component.
 */
export const OntologyAPI = createProxy<Ontology>(
  OntologyAPIClass,
  ONTOLOGY_PREFIX,
  ee,
);
export default OntologyAPI;

export const onOntologyChanged = (
  listener: (ontology: string | undefined) => void,
) => {
  const key = clientCore('ontologyChanged');
  ee.on(key, listener);
  return () => {
    ee.off(key, listener);
  };
};

export const BabyOntologyAPI = {};

['sendQuery', 'getConnectedEntities', 'getEntity', 'consistentUUID'].reduce(
  (acc, proxyMethod) => {
    acc[proxyMethod] = async (...args: any) =>
      OntologyAPI[proxyMethod](...args);
    return acc;
  },
  BabyOntologyAPI,
);

Object.freeze(BabyOntologyAPI);
