import shortid from 'shortid';
import {
  ApiErrorResult,
  ApiResult,
  Query,
  QueryBatchResult,
  SendQueryOptions,
} from '@octostar/platform-types';
import APIClient from './APIClient';
import { DeferredPromise } from './handy';
import { AbortContext } from './AbortContext';
import DesktopAPI from '../api/event-driven/desktop';
import ee from '../interface';
import { BUILTINS_MESSAGE_TYPES } from '../api/messagesTypes';
/**
 * BatchedQueriesManager
 *
 * A manager for batch-processing of query entries, designed to efficiently handle
 * and send multiple queries to a backend, while providing a mechanism to handle
 * concurrency and queueing.
 *
 * Features:
 * - Queries can be added to the batch with the `enqueue` method. Each query is given
 *   a unique ID and is stored in a batch associated with a specific set of options.
 *
 * - The manager handles queries that are raw SQL strings, functions returning a single
 *   SQL query, or functions returning an array of SQL queries.
 *
 * - The manager supports batching queries with the same options into single requests,
 *   optimizing for reduced HTTP overhead.
 *
 * - To prevent overwhelming the backend or the client, a maximum concurrency limit
 *   is set, ensuring that only a certain number of batch requests are active at a time.
 *
 * - Error handling is integrated, ensuring that individual queries in a batch that
 *   fail don't cause the entire batch to fail.
 *
 * Dependencies:
 * - Utilizes `shortid` for generating unique IDs for queries.
 * - Relies on `DeferredPromise` for promise management.
 * - Integrates with an external `APIClient` for sending requests to the backend.
 *
 * @exports QueryBatchEntry - Represents individual queries within a batch,
 *   containing the query itself, its unique ID, and a promise for its eventual result.
 *
 * @class BatchedQueriesManager
 * @property {AbortContext} abortContext - Context for handling abort signals.
 * @property {string} backendUrl - URL of the backend where queries are sent.
 * @property {number} maxConcurrentRuns - Maximum number of concurrent batched
 *   requests allowed.
 * @property {object} batchedQueries - Holds batches of queries grouped by options.
 * @property {number} runningCount - Count of currently running batch requests.
 * @property {array} queue - Queue of batches waiting to be sent.
 * @property {Set<string>} queuedHashes - Set of option hashes for batches currently in queue.
 *
 * @see {@link APIClient}
 * @see {@link DeferredPromise}
 * @see {@link AbortContext}
 */

export type QueryBatchEntry = {
  query: Query;
  deferred: DeferredPromise<ApiResult<any[]>>;
  query_id: string;
  force_refresh: boolean;
};

export type QueryInfo = {
  query_id: string;
  sql: string;
  force_refresh?: boolean;
};
export class BatchedQueriesManager {
  batchedQueries: {
    [optionsHash: string]: QueryBatchEntry[];
  } = {};

  private runningCount = 0;

  private queue: {
    optionsHash: string;
    ontology: string;
    options?: SendQueryOptions;
  }[] = [];

  private queuedHashes: Set<string> = new Set();

  constructor(
    private abortContext: AbortContext,
    private backendUrl: string,
    private maxConcurrentRuns: number,
  ) {}

  async enqueue(
    query: Query,
    ontology: string,
    options?: SendQueryOptions,
    force_refresh = false,
  ): Promise<ApiResult<any[]>> {
    const optionsHash = JSON.stringify(options);
    if (!this.batchedQueries[optionsHash]) {
      this.batchedQueries[optionsHash] = [];
    }
    const deferred = new DeferredPromise<ApiResult<any[]>>();
    const query_id = shortid.generate();
    this.batchedQueries[optionsHash].push({
      query,
      deferred,
      query_id,
      force_refresh,
    });
    if (!this.queuedHashes.has(optionsHash)) {
      this.queuedHashes.add(optionsHash);
      this.queue.push({ optionsHash, ontology, options });
    }
    await this._runNext();
    return deferred.promise;
  }

  private async _runNext() {
    // Only proceed if we haven't hit the concurrency limit
    while (
      this.runningCount < this.maxConcurrentRuns &&
      this.queue.length > 0
    ) {
      // Increment the running count
      this.runningCount++;

      // Take the next item from the queue
      const { optionsHash, ontology, options } = this.queue.shift()!;
      const batch = this.batchedQueries[optionsHash];

      // Process the batch asynchronously
      this._processBatch(batch, optionsHash, ontology, options)
        .then(() => {
          // After processing, decrement the running count and try to run the next batch
          this.runningCount--;
          this._runNext();
        })
        .catch(error => {
          // Handle error, decrement running count, and continue with the next batch
          console.error('Error running batch:', error);
          this.runningCount--;
          this._runNext();
        });

      // If we've reached the concurrency limit, break the loop
      if (this.runningCount >= this.maxConcurrentRuns) {
        break;
      }
    }
  }

  private async _processBatch(
    batch: QueryBatchEntry[],
    optionsHash: string,
    ontology: string,
    options: SendQueryOptions | undefined,
  ) {
    if (batch.length > 10) {
      // Determine the size of half the batch, rounded down
      const halfBatchSize = Math.floor(batch.length / 2);

      // Split the batch into two halves
      const batchToProcess = batch.slice(0, halfBatchSize);
      const nextBatch = batch.slice(halfBatchSize);

      // Update the batchedQueries with the remaining half
      this.batchedQueries[optionsHash] = nextBatch;

      // Re-insert the remaining half into the queue for future processing
      this.queue.unshift({
        optionsHash,
        ontology,
        options, // options are still valid for the next batch
      });

      // Process the first half of the batch
      await this.flushBatchedQueries(batchToProcess, ontology, options);

      // Do not delete optionsHash from queuedHashes since it's still in use
    } else {
      // If the batch is not greater than 10, process as normal and clear the hash
      this.batchedQueries[optionsHash] = [];
      this.queuedHashes.delete(optionsHash);
      await this.flushBatchedQueries(batch, ontology, options);
    }
  }

  private async flushBatchedQueries(
    batch: QueryBatchEntry[],
    ontology: string,
    options?: SendQueryOptions,
  ) {
    const batchMap = this.createBatchMap(batch);

    const queries = await this.processQueries(batchMap, batch);

    await this.sendQueriesToBackend(ontology, queries, batchMap, options);
  }

  private createBatchMap(batch: QueryBatchEntry[]): {
    [batch_id: string]: QueryBatchEntry;
  } {
    return batch.reduce((map, b) => {
      map[b.query_id] = b;
      return map;
    }, {});
  }

  /**
   * Processes a batch of query entries, preparing them for execution.
   *
   * @param batchMap - A mapping of query IDs to their corresponding `QueryBatchEntry` objects.
   * @param batch - An array of `QueryBatchEntry` objects to process.
   *
   * @returns A list of queries with their corresponding query IDs ready for execution.
   */
  private async processQueries(
    batchMap: { [batch_id: string]: QueryBatchEntry },
    batch: QueryBatchEntry[],
  ): Promise<QueryInfo[]> {
    const queriesToExecute: QueryInfo[] = [];

    await Promise.all(
      batch.map(async queryEntry => {
        const resolvedSQL =
          typeof queryEntry.query === 'function'
            ? await queryEntry.query()
            : queryEntry.query;

        if (resolvedSQL === undefined) {
          await this.resolveQueryWithEmptyResult(batchMap, queryEntry);
        } else if (Array.isArray(resolvedSQL)) {
          this.handleArrayOfSQLQueries(
            batchMap,
            queryEntry,
            resolvedSQL,
            queriesToExecute,
          );
        } else if (queryEntry.query) {
          queriesToExecute.push({
            query_id: queryEntry.query_id,
            sql: resolvedSQL,
            ...(queryEntry.force_refresh && {
              force_refresh: queryEntry.force_refresh,
            }),
          });
        }
      }),
    );

    return queriesToExecute;
  }

  /**
   * Handles a case when a query resolves to undefined. In such cases, the query is immediately resolved with an empty result.
   */
  private async resolveQueryWithEmptyResult(
    batchMap: { [batch_id: string]: QueryBatchEntry },
    queryEntry: QueryBatchEntry,
  ): Promise<void> {
    delete batchMap[queryEntry.query_id];
    try {
      await queryEntry.deferred.resolve({ status: 'success', data: [] });
    } catch (e) {
      console.log(`problem resolving query ${queryEntry.query}`, e);
    }
  }

  /**
   * Handles a case when a query resolves to an array of SQL queries. Each query in the array is processed separately.
   */
  private handleArrayOfSQLQueries(
    batchMap: { [batch_id: string]: QueryBatchEntry },
    queryEntry: QueryBatchEntry,
    sqlArray: string[],
    queriesToExecute: QueryInfo[],
  ): void {
    delete batchMap[queryEntry.query_id];
    const individualQueryPromises: Promise<QueryBatchResult<any>>[] = [];

    sqlArray.forEach(sql => {
      const individualQueryId = shortid();
      const deferred = new DeferredPromise<QueryBatchResult<any>>();
      if (sql) {
        batchMap[individualQueryId] = {
          query_id: individualQueryId,
          deferred,
          query: sql,
          force_refresh: queryEntry.force_refresh,
        };
        queriesToExecute.push({
          query_id: individualQueryId,
          sql,
          ...(queryEntry.force_refresh && {
            force_refresh: queryEntry.force_refresh,
          }),
        });
      } else {
        deferred.resolve({
          query_id: individualQueryId,
          status: 'success',
          data: [],
        });
      }
      individualQueryPromises.push(deferred.promise);
    });

    // Consolidate results of individual queries and resolve or reject the original query
    this.consolidateQueryResults(individualQueryPromises)
      .then(queryEntry.deferred.resolve)
      .catch(queryEntry.deferred.reject);
  }

  /**
   * Consolidates results from an array of individual query promises.
   */
  private async consolidateQueryResults(
    promises: Promise<QueryBatchResult<any>>[],
  ): Promise<QueryBatchResult<any>> {
    const results = await Promise.all(promises);
    const errorResult = results.find(x => x.status === 'error');
    if (errorResult) {
      return errorResult;
    }
    return {
      status: 'success',
      data: results.map(x => x.data).flat(),
    } as any;
  }

  private async sendQueriesToBackend(
    ontology: string,
    queries: QueryInfo[],
    batchMap: { [batch_id: string]: QueryBatchEntry },
    options?: SendQueryOptions,
  ) {
    const payload = { queries };
    const reqInit: RequestInit = {
      method: 'POST',
      signal: this.abortContext.get(options?.context).signal,
      body: JSON.stringify(payload, null, 2),
    };

    try {
      await new APIClient({ ontology }).streamFetch<QueryBatchResult<any>>(
        `${this.backendUrl}/api/octostar/timbr/multi_query`,
        reqInit,
        data => {
          const entry = batchMap[data.query_id];
          delete batchMap[data.query_id];
          entry.deferred.resolve(data);
          ee.emit(BUILTINS_MESSAGE_TYPES.queryExecuted, data);
          if (data.status !== 'success') {
            const query = queries.find(x => x.query_id === entry.query_id)?.sql;
            console.error(`Query Error`, { query, result: data });
            DesktopAPI.showToast({
              level: 'error',
              message: 'Query Error',
              description:
                'An exception occurred running the query and has been logged.',
            });
          }
        },
      );
      this._runNext();
    } catch (error) {
      this._runNext();
      const result: ApiErrorResult = {
        status: 'error',
        data: 'something went wrong, check logs',
      };

      if (error === 'CANCELLED' || error.name === 'AbortError') {
        result.data = 'Canceled';
      } else {
        console.error(`multi_query failed`, error);
        DesktopAPI.showToast({
          level: 'error',
          message: 'API Error',
          description:
            'An exception occurred contacting the query api and has been logged.',
        });
      }

      await Promise.all(
        Object.values(batchMap).map(b => b.deferred.resolve(result)),
      );
    }
  }
}
