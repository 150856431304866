import * as React from 'react';
import { EXCEL_EXTENSIONS } from 'src/octostar/lib/workspaceOperations';
import { AgGridReact } from 'ag-grid-react';
import { Empty } from 'antd5';
import { isEqual } from 'lodash';
import Loading from 'src/components/Loading';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { WorkspaceItem } from '@octostar/platform-types';
import { BUILTIN_APPS } from '../layout/builtins';
import { ParsedData, getColumnDefs, parseFileFromUrl } from './excelParser';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './ExcelViewer.css';

export default function ExcelViewer({
  item,
  fileType,
}: {
  item: WorkspaceItem;
  fileType: string;
}) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rowData, setRowData] = React.useState<ParsedData>([]);
  const [columnDefs, setColumnDefs] = React.useState<
    Array<{ headerName: string; field: string }>
  >([]);
  React.useEffect(() => {
    if (!item) {
      return;
    }
    parseFileFromUrl(
      `/api/octostar/workspace_data_api/attachments/${item.os_workspace}/${item.os_entity_uid}`,
      fileType,
    )
      .then(data => {
        if (data.length === 0) {
          setLoading(false);
          return;
        }
        setRowData(curr => (isEqual(curr, data) ? curr : data));
        const columns = getColumnDefs(data);
        setColumnDefs(curr => (isEqual(curr, columns) ? curr : columns));
        setLoading(false);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        DesktopAPI.showToast({
          level: 'error',
          message: 'Error loading file',
          description: 'error loading file data. Please try again.',
        });
        DesktopAPI.closeTab({
          app: BUILTIN_APPS.fileViewer,
          item,
        });
      });
  }, [fileType, item]);

  if (loading) {
    return <Loading />;
  }

  if (rowData.length === 0) {
    return (
      <Empty
        className="excel-viewer-container"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No data"
      />
    );
  }

  return (
    <div className="ag-theme-quartz excel-viewer-container">
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination
        paginationPageSize={30}
        paginationPageSizeSelector={[30, 50, 100]}
        rowSelection="multiple"
      />
    </div>
  );
}

export const isExcelFile = (s: string) =>
  EXCEL_EXTENSIONS.includes(s.toLowerCase());
