import * as React from 'react';
import { Entity } from '@octostar/platform-types';
import DesktopAPI from '../api/event-driven/desktop';

export const useFocusList = (
  entities: Entity[],
  type?: 'single' | 'group',
  tabName?: string,
) => {
  React.useEffect(() => {
    if (entities.length === 0) {
      return () => {};
    }
    const entitiesToAdd = entities.map((entity: any) => ({
      entity_id: entity.entity_id || entity.id,
      entity_type: entity?.entity_type || entity?.concept,
      entity_label: entity?.entity_label || entity?.label,
    }));
    DesktopAPI.addToFocusList(entitiesToAdd, type, tabName);
    return () => {
      DesktopAPI.removeFromFocusList(entitiesToAdd, type, tabName);
    };
  });
};
