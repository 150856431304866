import * as React from 'react';
import * as FlexLayout from 'flexlayout-react';

import { DraftWorkspaceItem, WorkspaceItem } from '@octostar/platform-types';
import { useCurrentWorkspaceItem } from 'src/octostar/hooks';
import ee, { clientCore } from 'src/octostar/interface';
import { useTabNode } from 'src/octostar/hooks/useTabNode';
import { useAttachment } from 'src/octostar/hooks/useAttachment';
import { MonacoEditor } from '../editors/MonacoEditor';
import Loading from '../Loading';
import { BUILTIN_APPS } from '../layout/builtins';
import { Mermaid } from './Mermaid';
import { ExamplePicker } from './ExamplePicker';

const json: FlexLayout.IJsonModel = {
  global: {},
  borders: [],
  layout: {
    type: 'row',
    weight: 100,
    children: [
      {
        type: 'tabset',
        weight: 50,
        children: [
          {
            type: 'tab',
            name: 'Edit',
            component: 'editor',
            enableClose: false,
          },
        ],
      },
      {
        type: 'tabset',
        weight: 50,
        children: [
          {
            type: 'tab',
            name: 'Preview',
            component: 'viewer',
            enableClose: false,
          },
        ],
      },
    ],
  },
};
export const MermaidEditor = ({
  item,
  node,
}: {
  item: WorkspaceItem;
  node: FlexLayout.TabNode;
}) => {
  const [model, setModel] = React.useState(FlexLayout.Model.fromJson(json));
  const [chart, setChart] = React.useState<string>();
  const [defaultValue, setDefaultValue] = React.useState<string>();
  const { width, height } = useTabNode({ node });
  const { loading, text } = useAttachment(item, 'text');

  const factory = (node: FlexLayout.TabNode) => {
    const component = node.getComponent();

    if (component === 'editor') {
      return (
        <MonacoEditor
          items={[item]}
          node={node}
          onChange={setChart}
          defaultValue={defaultValue}
          language="yaml"
        />
      );
    }
    if (component === 'viewer') {
      return (
        <Mermaid
          chart={chart || defaultValue || ''}
          width={width}
          height={height}
        />
      );
    }
    return null;
  };

  if (!text && (item?.os_draft_item || !chart) && !loading) {
    return <ExamplePicker onChange={setDefaultValue} />;
  }

  return <FlexLayout.Layout model={model} factory={factory} />;
};

export const MermaidEditorLoader = ({
  item,
  node,
}: {
  item: { os_entity_uid: string };
  node: FlexLayout.TabNode;
}) => {
  const workspaceItem = useCurrentWorkspaceItem(item.os_entity_uid);
  if (!workspaceItem) {
    return <Loading />;
  }
  return <MermaidEditor item={workspaceItem} node={node} />;
};

export const registerMermaidEditor = () => {
  ee.emit(clientCore('componentRegister'), {
    component: BUILTIN_APPS.mermaidEditor.os_entity_uid,
    factory: (node: FlexLayout.TabNode) => {
      const item = node.getConfig() as { os_entity_uid: string };
      return <MermaidEditorLoader item={item} node={node} />;
    },
  });
};
