import ee from '../interface';
const ALL = 'ALL';
/**
 * AbortContext
 *
 * Provides a centralized mechanism for managing and aborting pending
 * asynchronous operations using the AbortController. The `AbortContext`
 * class offers context-based abort functionality, making it easier to
 * cancel specific or all active operations.
 *
 * Features:
 * - Offers the ability to generate and retrieve AbortController instances
 *   based on a given context.
 *
 * - Supports the reset operation for either a specific context or for all
 *   active contexts. When resetting, the associated AbortController sends
 *   an abort signal, effectively cancelling any linked operations.
 *
 * - After an abort operation, the class emits a 'QUERY_ERROR_EVT' event
 *   through the octostar EventEmitter (`ee`), signaling any listeners
 *   about the aborted query.
 *
 * - Utilizes a special constant `ALL` to represent operations that don't
 *   have a specific context, ensuring that these operations can be
 *   independently managed or batch aborted.
 *
 * Dependencies:
 * - Relies on the built-in AbortController for the core abort functionality.
 * - Integrates with the octostar `EventEmitter` (`ee`) to signal other parts
 *   of the system when a query is aborted.
 *
 * @exports AbortContext - Exports the AbortContext class.
 *
 * @class AbortContext
 * @property {object} controllers - An object mapping contexts to their
 *                                  associated AbortControllers.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController}
 * @see {@link EventEmitter}
 */

export class AbortContext {
  controllers: { [key: string]: AbortController };

  constructor() {
    this.controllers = {};
  }

  private getKeyForContext = (context?: string | undefined): string =>
    context === undefined ? ALL : context;

  get(context?: string | undefined): AbortController {
    const key = this.getKeyForContext(context);
    this.controllers[key] = this.controllers[key] || new AbortController();
    return this.controllers[key];
  }

  reset(context?: string | undefined) {
    const key = this.getKeyForContext(context);
    console.log(`RESET ${key} QUERIES`);
    if (key !== ALL) {
      const controller = this.controllers[key];
      delete this.controllers[key];
      controller?.abort('CANCELLED');
    } else {
      const { controllers } = this;
      this.controllers = {};
      Object.entries(controllers).forEach(([, controller]) => {
        controller.abort('CANCELLED');
      });
    }
    ee.emit('QUERY_ERROR_EVT', { error: 'Query aborted' });
  }
}
