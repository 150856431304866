import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { JSONSchema7Definition } from 'json-schema';

export type SchemaJSON = Omit<RJSFSchema, 'properties' & 'required'> & {
  properties: {
    [key: string]: JSONSchema7Definition;
  };
  required: string[];
};
export type SchemaUiJSON = UiSchema;
export enum SchemaType {
  SCHEMA = 'schema',
  SCHEMA_UI = 'schema-ui',
}
