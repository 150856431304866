import * as React from 'react';
import { Button } from 'antd5';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { Entity } from '@octostar/platform-types';
import { ExportOutlined } from '@ant-design/icons';
import { TabContext } from './TabContext';
import './OpenTabLink.css';

export const OpenTabLink = ({
  children,
  record,
  withLabel = false,
  positionAbsolute = false,
}: {
  children: any;
  record: Entity;
  withLabel?: boolean;
  positionAbsolute?: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);
  const { tab } = React.useContext(TabContext);
  const openRecord = React.useCallback(
    event => {
      const autoSelectTab = !!(event.ctrlKey || event.metaKey);
      setLoading(true);
      OntologyAPI.getEntity(record).then(entity =>
        DesktopAPI.open(entity, {
          besideTabId: tab?.getId(),
          autoSelectTab,
        }).finally(() => setLoading(false)),
      );
    },
    [record, tab],
  );
  return (
    <>
      {positionAbsolute ? (
        <div className="os-open-tab-link-container">
          <span className="os-open-tab-label">{children}</span>
          <Button
            loading={loading}
            type="text"
            title={loading ? 'Opening...' : 'Open in new tab'}
            onClick={openRecord}
            icon={<ExportOutlined />}
            className="os-icon-button-fix os-open-tab-button"
          />
        </div>
      ) : (
        <>
          {withLabel ? (
            <div onClick={openRecord} role="button" tabIndex={0}>
              {children}
            </div>
          ) : (
            <Button
              loading={loading}
              type="text"
              title={loading ? 'Opening...' : 'Open in new tab'}
              onClick={openRecord}
              icon={<ExportOutlined />}
              className="os-icon-button-fix"
            />
          )}
        </>
      )}
    </>
  );
};
