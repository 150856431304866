import * as React from 'react';
import { t } from '@superset-ui/core';
import { Entity, FocusListItem, WorkspaceItem } from '@octostar/platform-types';
import { Menu, MenuProps, Tag } from 'antd5';
import { MenuDividerType } from 'antd5/lib/menu/hooks/useItems';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { isEqual } from 'lodash';
import {
  GroupOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { bigNumberFormat } from 'src/octostar/lib/Formatters';
import ConfiguredIcon from '../ConfiguredIcon';
import './FocusListMenu.css';

const FocusListMenuItem = ({ item }: { item: FocusListItem }) => (
  <div className="focus-list-menu-item">
    {item.type === 'group' ? (
      <>
        <span className="focus-list-menu-item-label">{item.entity_label}</span>
        <span className="focus-list-menu-item-tab">
          <Tag>{bigNumberFormat(item.count)} </Tag>
        </span>
      </>
    ) : (
      <>
        {item.entity.entity_type && <ConfiguredIcon hint={item.entity} />}
        <span className="focus-list-menu-item-label">
          {item.entity.entity_label}
        </span>
        {item.tabName && (
          <span className="focus-list-menu-item-tab">{item.tabName}</span>
        )}
      </>
    )}
  </div>
);

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
  } as MenuItem;
}

const addRelation =
  (
    entity?: Entity,
    os_workspace?: string | undefined,
    item?: FocusListItem,
    createRecord?: boolean,
  ) =>
  async () => {
    DesktopAPI.showCreateRelationsDialog({
      source: entity,
      target: item,
      os_workspace,
      createRecord,
    });
  };

const items = (
  items: FocusListItem[],
  os_workspace?: string,
  entity?: Entity,
): MenuItem[] => [
  getItem(
    t('Search for record'),
    'add-new-relation-search-record',
    <SearchOutlined />,
    undefined,
    undefined,
    addRelation(entity, os_workspace),
  ),
  getItem(
    t('Create record'),
    'add-new-relation-create-record',
    <PlusOutlined />,
    undefined,
    undefined,
    addRelation(entity, os_workspace, undefined, true),
  ),
  ...(!items?.length
    ? []
    : [
        { type: 'divider' } as MenuDividerType,
        ...(items.filter(item => item.type === 'single')?.length
          ? [
              getItem(
                t('Single Relation'),
                'add-new-relation-selected',
                <UserOutlined />,
                [
                  ...items
                    .filter(item => item.type === 'single')
                    .slice(0, 5)
                    .map(item =>
                      getItem(
                        <FocusListMenuItem item={item} />,
                        crypto.randomUUID(),
                        undefined,
                        undefined,
                        undefined,
                        addRelation(entity, os_workspace, item),
                      ),
                    ),
                ],
              ),
            ]
          : []),
        ...(items.filter(item => item.type === 'group')?.length
          ? [
              getItem(
                t('Multiple Relations'),
                'add-new-relation-selected-groups',
                <GroupOutlined />,
                [
                  ...items
                    .filter(item => item.type === 'group')
                    .reduce((accum: FocusListItem[], item: FocusListItem) => {
                      if (
                        !accum.find(
                          x =>
                            x.type === 'group' &&
                            item.type === 'group' &&
                            x.entity_label === item.entity_label,
                        )
                      ) {
                        accum.push(item);
                      }
                      return accum;
                    }, [] as FocusListItem[])
                    .slice(0, 5)
                    .map(item =>
                      getItem(
                        <FocusListMenuItem item={item} />,
                        crypto.randomUUID(),
                        undefined,
                        undefined,
                        undefined,
                        addRelation(entity, os_workspace, item),
                      ),
                    ),
                ],
              ),
            ]
          : []),
      ]),
];

export const FocusListMenu = ({
  item,
  os_workspace,
}: {
  item: WorkspaceItem;
  os_workspace?: string;
}) => {
  const [focusList, setFocusList] = React.useState<FocusListItem[]>([]);
  const [entity, setEntity] = React.useState<Entity>();

  React.useEffect(() => {
    let mounted = true;
    DesktopAPI.getPasteContext({ record: item }).then(entities => {
      if (entities?.[0] && mounted) {
        setEntity(curr =>
          isEqual(curr, entities?.[0]) ? curr : entities?.[0],
        );
      }
    });
    return () => {
      mounted = false;
    };
  }, [item]);

  React.useEffect(() => {
    let mounted = true;
    DesktopAPI.getFocusList().then(
      (items: FocusListItem[]) =>
        mounted && setFocusList(curr => (isEqual(curr, items) ? curr : items)),
    );
    return () => {
      mounted = false;
    };
  }, []);

  if (!entity) {
    return null;
  }

  return (
    <div className="focus-list-menu">
      <Menu
        items={items(focusList, os_workspace, entity)}
        getPopupContainer={function test(node) {
          return node.parentNode as HTMLElement;
        }}
      />
    </div>
  );
};
