import {
  Entity,
  EntityPasteContext,
  WorkspaceItem,
  WorkspaceItemIdentifier,
} from '@octostar/platform-types';
import { getChartDataRequest } from 'src/components/Chart/chartAction';
import { ChartDataResponseResult, t } from '@superset-ui/core';
import { waitForAsyncData } from 'src/middleware/asyncEvent';
import { ClientErrorObject } from 'src/utils/getClientErrorObject';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import BigNumber from 'bignumber.js';
import { registerServer } from 'src/octostar/api/event-driven';
import { SAVED_SEARCH_PREFIX } from 'src/octostar/api/messagesTypes';
import ee from 'src/octostar/interface';
import { SavedSearchContent } from './types';
import {
  RecordFetchOptions,
  ResultType,
  buildFormDataForEntityCount,
  buildFormDataForRecordFetch,
  extractDataMask,
  fetchCount,
  fetchRecords,
  isSavedSearch,
} from './utils';

interface RecordData extends ChartDataResponseResult {
  data: Record<string, any>[];
}

type QueryResult = { language: string; query: string };
const DEFAULT_FETCH_OPTIONS: RecordFetchOptions = {
  all_columns: ['entity_type', 'entity_id', 'entity_label'],
  order_by_cols: [],
  row_limit: 250,
  server_page_length: 10,
};
export class SavedSearchAPI {
  public static async getRecordsCount(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
  ): Promise<BigNumber | number | null> {
    const savedSearchObject = await SavedSearchAPI.#getSavedSearch(
      savedSearchIdn,
    );

    const formData = await SavedSearchAPI.#getFormDataForCount(
      savedSearchObject,
    );

    if (formData) {
      return fetchCount(formData);
    }
    return null;
  }

  public static async getRecordsCountQuery(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
  ): Promise<number | null> {
    const savedSearchObject = await SavedSearchAPI.#getSavedSearch(
      savedSearchIdn,
    );

    const formData = await SavedSearchAPI.#getFormDataForCount(
      savedSearchObject,
    );

    if (formData) {
      const { response, json } = await getChartDataRequest({
        formData,
        resultType: 'query',
      });

      if (response.status !== 200) {
        throw new Error(
          `Received unexpected response status (${response.status}) while fetching native query`,
        );
      }

      return json.result[0];
    }
    return null;
  }

  // TODO: Add scroll.
  public static async getRecords(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
    options?: Partial<RecordFetchOptions>,
  ): Promise<RecordData | null> {
    const fetch_options = { ...DEFAULT_FETCH_OPTIONS, ...options };
    return SavedSearchAPI.#fetchRecords(savedSearchIdn, 'full', fetch_options);
  }

  public static async getRecordsQuery(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
    options: RecordFetchOptions = {
      all_columns: ['entity_type', 'entity_id', 'entity_label'],
      order_by_cols: [],
      row_limit: 250,
      server_page_length: 10,
    },
  ): Promise<QueryResult | null> {
    return SavedSearchAPI.#fetchRecords(savedSearchIdn, 'query', options);
  }

  public static async getSavedSearchPasteContext(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
  ): Promise<EntityPasteContext> {
    return {
      count: () =>
        SavedSearchAPI.getRecordsCount(savedSearchIdn).then(count =>
          new BigNumber(count || 0).toNumber(),
        ),
      fetch: (limit?: number) =>
        SavedSearchAPI.getRecords(savedSearchIdn, {
          row_limit: limit || 1000,
        }).then(records => (records || { data: [] }).data as Entity[]),
    };
  }
  /**
   *  Private methods
   */

  static async #getSavedSearch(
    savedSearch: WorkspaceItemIdentifier<SavedSearchContent>,
  ): Promise<WorkspaceItem<SavedSearchContent>> {
    const savedSearchObject: WorkspaceItem<SavedSearchContent> =
      typeof savedSearch === 'string'
        ? await DesktopAPI.getItem(savedSearch)
        : savedSearch;

    if (!isSavedSearch(savedSearchObject)) {
      throw new Error(
        `Item is not a saved search ${
          (savedSearchObject as WorkspaceItem<any>).entity_label
        }`,
      );
    }
    return savedSearchObject;
  }

  static #getFormDataForCount(
    savedSearchObj: WorkspaceItem<SavedSearchContent>,
  ) {
    if (savedSearchObj.os_item_content) {
      const {
        filters,
        crossFilters,
        concept: conceptName,
        datasourcesInScope,
      } = savedSearchObj.os_item_content;
      const dataMask = extractDataMask(filters, crossFilters);

      const concept = OntologyAPI.getConceptByName(conceptName);
      return buildFormDataForEntityCount(
        conceptName,
        dataMask,
        datasourcesInScope,
        concept,
      );
    }
    return null;
  }

  static #getFormDataForRecordFetch(
    savedSearchObj: WorkspaceItem<SavedSearchContent>,
    options: RecordFetchOptions,
  ) {
    if (savedSearchObj.os_item_content) {
      const {
        filters,
        crossFilters,
        concept: conceptName,
        datasourcesInScope = {},
      } = savedSearchObj.os_item_content;
      const dataMask = extractDataMask(filters, crossFilters);

      const concept = OntologyAPI.getConceptByName(conceptName);
      return buildFormDataForRecordFetch(
        conceptName,
        dataMask,
        datasourcesInScope,
        concept,
        options,
      );
    }
    return null;
  }

  static async #fetchRecords<T>(
    savedSearchIdn: WorkspaceItemIdentifier<SavedSearchContent>,
    resultType: ResultType,
    options: RecordFetchOptions,
  ): Promise<T | null> {
    const savedSearchObject = await SavedSearchAPI.#getSavedSearch(
      savedSearchIdn,
    );

    const formData = await SavedSearchAPI.#getFormDataForRecordFetch(
      savedSearchObject,
      options,
    );

    return fetchRecords<T>(formData, resultType);
  }
}

// Register methods to be consumed by events, such as from within iFrames.
registerServer(SavedSearchAPI, SavedSearchAPI, SAVED_SEARCH_PREFIX, ee);
