import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/dashboard/types';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import { updateDataMask } from 'src/dataMask/actions';
import { Entity, WorkspaceItem } from '@octostar/platform-types';
import { TEMPORARY_ITEM_TYPE } from 'src/octostar/interface';
import { EventEmitterContext } from 'src/octostar/components/EventEmitterContext';
import { WorkspaceEntityContext } from 'src/octostar/components/WorkspaceItemContext';
import { SelectedEntitiesWsItem } from 'src/octostar/components/Entity/RecordDetails';
import { FilterChangeEvent } from '../SavedSearch/types';

interface FilterEmitterProps {
  entity_uid: string;
}

export const retrieveSelectedEntityIds = (
  workspaceItem: WorkspaceItem,
): string[] => {
  if (
    workspaceItem.entity_type === TEMPORARY_ITEM_TYPE &&
    workspaceItem.os_item_name === SelectedEntitiesWsItem &&
    workspaceItem.os_item_content?.entities.length > 0
  ) {
    return (workspaceItem.os_item_content.entities as Entity[]).reduce<
      string[]
    >((entityIds, { entity_id }) => {
      if (entity_id) {
        entityIds.push(entity_id);
      }
      return entityIds;
    }, []);
  }
  return [];
};

export const DatasetViewerFilterPrefix = 'DatasetViewer:filter';
export const DatasetViewerFilterId = `${DatasetViewerFilterPrefix}:concept`;
export const DatasetViewerEntityFilter = `${DatasetViewerFilterPrefix}:entities`;
export const DatasetViewerTagsFilter = `${DatasetViewerFilterPrefix}:tags`;
export const DatasetViewerLabelSearchFilter = `${DatasetViewerFilterPrefix}:search:label`;

export function useDatasetMask() {
  return useSelector(
    (state: RootState) => state.dataMask[DatasetViewerFilterId],
  );
}

/**
 * Emits any dataMask changes in redux store in order to update outside listeners.
 * Such as to update dataset counts.
 */
export const FilterEmitter = ({
  entity_uid,
}: FilterEmitterProps): JSX.Element => {
  const currentWorkspaceItem = React.useContext(WorkspaceEntityContext);
  const dispatch = useDispatch();
  const { ee } = React.useContext(EventEmitterContext);
  const dataMask = useSelector((state: RootState) => state.dataMask);
  const nativeFilters = useSelector((state: RootState) => state.nativeFilters);
  const datasources = useSelector((state: RootState) => state.datasources);

  React.useEffect(() => {
    async function dispatchEntityFilter(entityIds: string[]) {
      if (entityIds.length === 0) return;

      const conceptFilter = {
        id: DatasetViewerEntityFilter,
        extraFormData: {
          filters: [
            {
              col: 'entity_id',
              op: 'IN',
              val: entityIds,
            },
          ],
        },
      } as any;
      dispatch(updateDataMask(conceptFilter.id, conceptFilter));
    }

    if (currentWorkspaceItem?.item) {
      const ids = retrieveSelectedEntityIds(currentWorkspaceItem.item);
      dispatchEntityFilter(ids);
    }
  }, [currentWorkspaceItem, dispatch, dataMask]);

  React.useEffect(() => {
    if (dataMask) {
      const event: FilterChangeEvent = {
        dataMask,
        nativeFilters,
        datasources,
      };
      ee.emit(BUILTINS_MESSAGE_TYPES.filtersUpdated(entity_uid), event);
    }
  }, [dataMask, datasources, nativeFilters, ee, entity_uid]);

  return <></>;
};
