import * as React from 'react';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Concept, Entity, OsWorkspaceEntity } from '@octostar/platform-types';
import Loading from 'src/components/Loading';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import { isEqual } from 'lodash';
import { useFocusList } from 'src/octostar/hooks/useFocusList';
import { canEntityBeSaved } from 'src/octostar/lib/EntityUtils';
import FormContext from './FormContext';
import { WorkspaceContext } from '../WorkspaceContext';
import './MultiRecordViewer.css';

interface RecordEditorProps {
  entity: Entity;
  submitFormRef?: React.MutableRefObject<any>;
  readonly?: boolean;
  disabled?: boolean;
  requiredFieldsOnly?: boolean;
  hideButtons?: boolean;
  singleColumn?: boolean;
  onSave: (entity: any) => void;
  setReadOnly?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RecordEditor({
  entity,
  submitFormRef,
  readonly,
  disabled,
  requiredFieldsOnly,
  hideButtons,
  singleColumn,
  onSave,
  setReadOnly,
  setDisabled,
  setDisableMenu,
}: RecordEditorProps) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [concept, setConcept] = React.useState<Concept | undefined>(undefined);
  const { os_workspace } = React.useContext(WorkspaceContext);
  const [entityData, setEntityData] = React.useState<any>({});
  const [wi, setWi] = React.useState<any>({});
  const [isNewEntity, setIsNewEntity] = React.useState<boolean>(false);
  const mounted = React.useRef(1);

  useFocusList([entity]);

  React.useEffect(() => {
    const { current } = mounted;
    setLoading(true);

    const loadConcept = async () => {
      const concept = await OntologyAPI.getConceptByName(entity.entity_type);
      if (!concept) {
        DesktopAPI.showToast({
          level: 'error',
          message: `Concept not found for ${entity?.entity_type}`,
          description: 'An error occurred. See log for details.',
        });
        return;
      }
      if (mounted.current === current) {
        setConcept(concept);
        setLoading(false);
      }
    };

    loadConcept().finally(() => setLoading(false));
  }, [entity.entity_type, isNewEntity, requiredFieldsOnly]);

  React.useEffect(() => {
    const { current } = mounted;
    if (entity.entity_id === '') {
      setIsNewEntity(true);
      return;
    }
    const refreshEntityData = async () => {
      const entityData = await OntologyAPI.getEntity(entity);
      if (!entityData) {
        DesktopAPI.showToast({
          level: 'error',
          message: `Entity data not found for ${entity?.entity_label}`,
          description: 'An error occurred. See log for details.',
        });
        return;
      }
      if (mounted.current === current) {
        setEntityData((curr: any) =>
          isEqual(curr, entityData) ? curr : entityData,
        );
      }
    };
    refreshEntityData();
  }, [entity]);

  React.useEffect(() => {
    const item = {
      ...{ os_workspace, os_entity_uid: entity.entity_id },
      ...entity,
    };
    setWi((curr: any) => (isEqual(curr, item) ? curr : item));
  }, [entity, os_workspace]);

  React.useEffect(() => {
    const { current } = mounted;
    canEntityBeSaved(entity as OsWorkspaceEntity, true).then(
      (canBeSaved: any) => {
        if (!canBeSaved.value && current === mounted.current) {
          setDisableMenu?.(true);
          setReadOnly?.(true);
        }
      },
    );
  }, [entity, isNewEntity, setDisableMenu, setReadOnly]);

  React.useEffect(
    () => () => {
      // prevent updating state of unmounted component
      mounted.current += 1;
    },
    [entity.entity_id],
  );

  return (
    <div key={entity.entity_id}>
      {loading || !concept || !wi ? (
        <div className="loading-container">
          <Loading />
        </div>
      ) : (
        <FormContext
          entity={wi}
          submitFormRef={submitFormRef}
          concept={concept}
          entityData={entityData}
          isNewEntity={isNewEntity}
          requiredFieldsOnly={requiredFieldsOnly}
          readonly={readonly}
          disabled={disabled}
          hideButtons={hideButtons}
          singleColumn={singleColumn}
          onSave={onSave}
          setReadOnly={setReadOnly}
          setDisabled={setDisabled}
        />
      )}
    </div>
  );
}
