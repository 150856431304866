import * as React from 'react';
import { Actions, TabNode } from 'flexlayout-react';
import globalEE from 'src/octostar/interface';
import { AsyncFilter, ActionHandler } from '@octostar/platform-types';
import DesktopAPI from '../api/event-driven/desktop';
import { BUILTINS_MESSAGE_TYPES } from '../api/messagesTypes';

const NOTAB = 'notab';
type TabContextType = {
  tab: TabNode | undefined;
  tabId: string;
  setActionHandler: (handler: ActionHandler) => void;
};
const DEFAULT_CONTEXT: TabContextType = {
  tab: undefined,
  tabId: NOTAB,
  setActionHandler: (handler: ActionHandler) => {},
};
export const TabContext = React.createContext(DEFAULT_CONTEXT);

const DEFAULT_CLOSE_FILTER: AsyncFilter = async () => true;

export const TabContextProvider = React.memo(
  ({ children, tab }: { children: any; tab: TabNode }) => {
    const [actionHandler, setActionHandler] = React.useState<ActionHandler>({
      onClose: DEFAULT_CLOSE_FILTER,
    });
    React.useEffect(() => {
      const handler = (data: any) => {
        if (data.closeConfirmed) {
          return true;
        }
        if (!actionHandler.onClose) {
          return true;
        }
        actionHandler.onClose().then(shouldClose => {
          if (shouldClose) {
            const delAction = Actions.deleteTab(tab.getId());
            tab.getModel().doAction({
              ...delAction,
              data: { ...delAction.data, closeConfirmed: true },
            });
          }
        });
        return undefined;
      };
      const topic = BUILTINS_MESSAGE_TYPES.beforeCloseTab(tab.getId());
      globalEE.on(topic, handler);
      return () => {
        globalEE.off(topic, handler);
      };
    }, [tab, actionHandler]);
    return (
      <TabContext.Provider
        value={{ tab, tabId: tab?.getId() || NOTAB, setActionHandler }}
      >
        {children}
      </TabContext.Provider>
    );
  },
);

export const useFlexlayoutTab = () => {
  const context = React.useContext(TabContext);
  if (!context) {
    throw new Error(
      'useFlexlayoutTab must be used within a TabContextProvider',
    );
  }
  return context;
};

export const TabCloseMessage = ({ title }: { title: string }) => {
  const { tab } = React.useContext(TabContext);
  React.useEffect(() => {
    DesktopAPI.showConfirm({ title }).then(() => {
      if (tab) {
        const model = (tab as TabNode).getModel();
        model.doAction(Actions.deleteTab((tab as TabNode).getId()));
      }
    });
  }, [tab, title]);
  return <></>;
};
