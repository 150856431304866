import { SupersetClient } from '@superset-ui/core';
import APIClient from 'src/octostar/lib/APIClient';

export type DatasetIdentifier = {
  datasource_type: string;
  table_id: string;
  uid: string;
};

/**
 * Refresh ontology concepts and create datasets based on them
 */
export const handleOntologyRefresh = async (
  databaseId: string,
  client: APIClient,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    const resp = await client.fetch(
      `/api/v1/octostar/datasets/refresh_ontology/${databaseId}`,
      { method: 'PATCH' },
    );
    return resp;
  } catch (e) {
    console.error(e);
  }
};

export const getDatasetUID = (dataset: {
  datasource_type: string;
  table_id: string;
}) => `${dataset.table_id}__${dataset.datasource_type}`;

/**
 * @returns Dataset for {@link conceptName}
 * @throws Superset error if request fails.
 */
export const getDatasetForConcept = async (
  conceptName: string,
): Promise<DatasetIdentifier> => {
  const resp = await SupersetClient.get({
    endpoint: `api/v1/octostar/datasets/fetch_dataset_id/${conceptName}`,
  });

  if (resp.response.status !== 200) {
    throw resp.response;
  }

  resp.json.result.uid = getDatasetUID(resp.json.result);

  return resp.json.result;
};
