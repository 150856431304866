import { Desktop, StylerConfig } from '@octostar/platform-types';
import DesktopAPIClass from 'src/octostar/lib/DesktopAPI/DesktopAPI';
import ee from '../../interface';
import { createProxy } from '.';
import { DESKTOP_PREFIX } from '../messagesTypes';

/**
 * This is the DesktopAPI Client, for which a server is
 * registered by the DesktopContext component.
 */
export const DesktopAPI = createProxy<Desktop>(
  DesktopAPIClass,
  DESKTOP_PREFIX,
  ee,
);
export default DesktopAPI;
export const BabyDesktopAPI = {};

[
  'callAppService',
  'getAttachment',
  'getTemplate',
  'getUser',
  'getWorkspace',
  'import',
  'open',
  'searchXperience',
  'showToast',
  'showModalTemplate',
  'withProgressBar',
].reduce((acc, proxyMethod) => {
  acc[proxyMethod] = (...args: any) => DesktopAPI[proxyMethod](...args);
  return acc;
}, BabyDesktopAPI);

Object.freeze(BabyDesktopAPI);

const stylers: StylerConfig[] = [];
export const registerStyler = (config: StylerConfig) => {
  const prev = stylers.find(x => x.name === config.name);
  if (prev) {
    const index = stylers.indexOf(prev);
    stylers[index] = config;
    console.warn(
      `replacing styler ${config.name} with another one of the same name`,
    );
    prev.destroy?.();
  } else {
    stylers.push(config);
    stylers.sort((a, b) =>
      a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
    );
  }
  return () => {
    const index = stylers.indexOf(config);
    if (index !== -1) {
      stylers.splice(index, 1);
    }
  };
};
export const getStylerConfigs = () => [...stylers];
