import { JSONSchema7Definition } from 'json-schema';
import _ from 'lodash';
import { Concept, Property } from '@octostar/platform-types';
import { SchemaJSON, SchemaUiJSON } from './types';
import { asLabel, getParentsConcept } from './util';

const getSchemaUIPropertyByType = (property: Property): SchemaUiJSON => {
  const { property_name, property_type } = property;
  const title = asLabel(property_name);
  const uiCol = { xs: 24, sm: 12, md: 8, lg: 6, xl: 6, xxl: 6 };

  if (property_name === 'color') {
    return {
      'ui:title': title,
      'ui:col': uiCol,
      'ui:widget': 'ColorPickerWidget',
    };
  }
  if (property_type === 'integer') {
    return {
      'ui:title': title,
      'ui:col': uiCol,
      'ui:widget': 'updown',
    };
  }
  return {
    'ui:title': title,
    'ui:col': uiCol,
  };
};

/**
 * Resolve {@link schemaUI} from the Ontology with properties from the @param concept
 * @param requiredFieldsOnly Only include required fields in {@link schema}
 * Note: Properties starting with "os_" are hidden in the schemaUI
 */
export const resolveSchemaUI = (concept: Concept): SchemaUiJSON => {
  let schemaUI: SchemaUiJSON = {};
  const labelKeys: string[] =
    concept.labelKeys.length > 0 && concept.labelKeys[0] !== ''
      ? concept.labelKeys
      : [];

  concept?.properties?.forEach((property: Property) => {
    const schemaUiProperty = getSchemaUIPropertyByType(property);
    if (!_.isEmpty(schemaUiProperty)) {
      schemaUI = {
        ...schemaUI,
        [property.property_name]: schemaUiProperty,
      };
    }
  });

  schemaUI['ui:order'] = labelKeys.length > 0 ? labelKeys : [];
  const parentsConcept = getParentsConcept(
    concept.parents,
    concept.concept_name,
  );
  concept.columns?.forEach((column: string) => {
    if (
      !labelKeys.includes(column) &&
      !schemaUI['ui:order']?.includes(column)
    ) {
      const property = concept.properties?.[column];
      if (parentsConcept.includes(property?.concept)) {
        schemaUI['ui:order']?.push(column);
      }
    }
  });
  schemaUI['ui:order'].push('*');
  schemaUI['ui:order'] = _.uniq(schemaUI['ui:order']);
  return schemaUI;
};

const getSchemaPropertyByType = (property: Property): JSONSchema7Definition => {
  const { property_name, property_type } = property;
  const title = asLabel(property_name);
  switch (property_type) {
    case 'string':
    case 'timestamp':
    case 'date':
      return {
        title,
        type: ['string', 'null'],
        ...(property_type === 'timestamp' && { format: 'date-time' }),
        ...(property_type === 'date' && { format: 'date' }),
        description: '',
      };

    case 'integer':
    case 'smallint':
    case 'bigint':
      return {
        title,
        type: ['integer', 'null'],
        description: '',
      };

    case 'decimal':
    case 'float':
    case 'double':
      return {
        title,
        type: ['number', 'null'],
        description: '',
      };

    default:
      // eslint-disable-next-line no-console
      console.log('property_type', property_type);
      return {};
  }
};

/**
 * Resolve {@link schema} from the Ontology with properties from the @param concept
 */
export const resolveSchema = async (concept: Concept): Promise<SchemaJSON> => {
  const schema: SchemaJSON = {
    title: concept.concept_name.toLowerCase(),
    description: '',
    type: 'object',
    required: [],
    properties: {},
    dependencies: {},
  };
  concept?.properties?.forEach((property: Property) => {
    const schemaProperty = getSchemaPropertyByType(property);
    if (!_.isEmpty(schemaProperty)) {
      schema.properties = {
        ...schema.properties,
        [property.property_name]: schemaProperty,
      };
    }
  });
  return schema;
};
