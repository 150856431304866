import {
  DataMaskStateWithId,
  Filter,
  NativeFiltersState,
  ChartDataResponseResult,
  t,
  QueryObjectFilterClause,
  DataMask,
} from '@superset-ui/core';
import { get, isEmpty } from 'lodash';
import { DatasourcesState, RootState } from 'src/dashboard/types';
import {
  Concept,
  WorkspaceIdentifier,
  WorkspaceItem,
} from '@octostar/platform-types';
import { getChartDataRequest } from 'src/components/Chart/chartAction';
import { waitForAsyncData } from 'src/middleware/asyncEvent';

import { ClientErrorObject } from 'src/utils/getClientErrorObject';
import { filterInvalidFilterProps } from 'src/dashboard/components/nativeFilters/utils';
import shortid from 'shortid';
import {
  FILESYSTEM_ENTITY_CONCEPT,
  OCTOSTAR_INTERNAL_ENTITY_TYPES,
} from 'src/octostar/interface';
import { getSubTreeConcepts } from 'src/octostar/hooks/useConceptSubTree';
import { buildV1ChartDataPayload } from 'src/explore/exploreUtils';
import APIClient from 'src/octostar/lib/APIClient';
import { SavedDatasource, SavedSearchContent } from './types';
import { addOntologyContextToFormData } from '../Datasets/helpers';
import { DatasetViewerFilterId } from '../Filters/FilterEmitter';
import { DatasetIdentifier, getDatasetForConcept } from '../Datasets/api';
import { getDeleteRecordsPayload } from './queryBuilders';

export const SAVED_SEARCH_CONTENT_TYPE = 'os_saved_search';

export function isSavedSearch(
  item: WorkspaceIdentifier | undefined,
): item is WorkspaceItem<SavedSearchContent> {
  return (
    typeof item === 'object' &&
    item?.os_item_content_type === SAVED_SEARCH_CONTENT_TYPE
  );
}

export function extractDatasources(
  datasources: RootState['datasources'],
): SavedSearchContent['datasourcesInScope'] {
  return Object.values(datasources).reduce((datasourcesInScope, ds) => {
    const { uid, id, name, table_name, type, main_dttm_col } = ds;
    // eslint-disable-next-line no-param-reassign
    datasourcesInScope[ds.uid] = {
      uid,
      id,
      name,
      table_name,
      type,
      main_dttm_col,
    };
    return datasourcesInScope;
  }, {} as SavedSearchContent['datasourcesInScope']);
}

export function extractDataMask(
  filters: Filter[] = [],
  crossFilters: DataMask[] = [],
): DataMaskStateWithId {
  let dataMask = filters.reduce((dm, filter) => {
    // eslint-disable-next-line no-param-reassign
    dm[filter.id] = {
      id: filter.id,
      extraFormData: {
        filters: filter.defaultDataMask.extraFormData?.filters,
      },
    };
    return dm;
  }, {} as DataMaskStateWithId);

  dataMask = crossFilters.reduce((dm, filter) => {
    const id = (filter as any).id || shortid();
    // eslint-disable-next-line no-param-reassign
    dm[id] = {
      id,
      ...filter,
    };
    return dm;
  }, dataMask);

  return dataMask;
}

type ExtractedFilters = { filters: Filter[]; crossFilters: DataMask[] };
export function extractFilters(
  nativeFilters: NativeFiltersState,
  dataMask: DataMaskStateWithId,
  dataMasksToSkip = new Set([DatasetViewerFilterId]),
): ExtractedFilters {
  if (!dataMask) return { filters: [], crossFilters: [] };
  return Object.values(dataMask).reduce(
    (acc, dm) => {
      const { extraFormData, filterState, ...rest } = dm;
      if (!isEmpty(filterState) || !isEmpty(extraFormData)) {
        if (nativeFilters.filters[dm.id]) {
          acc.filters.push({
            ...(nativeFilters.filters[dm.id] as Filter),
            defaultDataMask: {
              extraFormData,
              filterState,
              ...rest,
            },
          });
        } else if (!dataMasksToSkip.has(dm.id)) {
          acc.crossFilters.push({
            extraFormData,
            filterState,
            ...rest,
          });
        }
      }
      return acc;
    },
    { filters: [], crossFilters: [] } as ExtractedFilters,
  );
}

async function selectDatasource(
  conceptName: string,
  datasources: SavedSearchContent['datasourcesInScope'],
  concept: Promise<Concept | undefined | void>,
): Promise<SavedDatasource | DatasetIdentifier | null> {
  const priorityMap: Record<string, number> =
    (await concept)?.parents.reduce((acc, parent, level) => {
      acc[parent] = level;
      return acc;
    }, {}) || {};

  let closestDs: SavedDatasource | DatasetIdentifier | null = null;
  let currentLevel = Number.MAX_SAFE_INTEGER;
  // eslint-disable-next-line no-restricted-syntax
  for (const ds of Object.values(datasources)) {
    if (ds.table_name === conceptName) {
      return ds;
    }
    if (priorityMap[ds.table_name] < currentLevel) {
      currentLevel = priorityMap[ds.table_name];
      closestDs = ds;
    }
  }

  if (!closestDs || currentLevel !== 0) {
    // Fallback to conceptName
    try {
      closestDs = await getDatasetForConcept(conceptName);
    } finally {
      // No nothing
    }
  }
  return closestDs;
}

// Used to fake payload for '/api/v1/chart/data' calls to make them fetch entity counts
const COUNT_BaseFormData = {
  viz_type: 'big_number_total',
  slice_id: 78,
  time_range: 'No filter',
  metric: 'count',
  adhoc_filters: [],
  header_font_size: 0.4,
  subheader_font_size: 0.15,
  y_axis_format: 'SMART_NUMBER',
  time_format: 'smart_date',
  dashboards: [],
  label_colors: {},
  shared_label_colors: {},
  extra_filters: [],
};

const RECORDS_BaseFormData = {
  viz_type: 'big_number_total',
  slice_id: 78,
  time_range: 'No filter',
  query_mode: 'raw',
  adhoc_filters: [],
  all_columns: ['entity_type', 'entity_id', 'entity_label'],
  order_by_cols: [],
  row_limit: 250,
  server_page_length: 10,
  header_font_size: 0.4,
  subheader_font_size: 0.15,
  result_format: 'json',
  result_type: 'full',
  time_format: 'smart_date',
  dashboards: [],
  label_colors: {},
  shared_label_colors: {},
  extra_filters: [],
};

const DELETE_BaseFormData = {
  viz_type: 'delete_ws_records',
  slice_id: 78,
  time_range: 'No filter',
  query_mode: 'raw',
  adhoc_filters: [],
  all_columns: ['entity_type', 'entity_id', 'os_workspace'],
  order_by_cols: [],
  row_limit: null,
  server_page_length: 10,
  header_font_size: 0.4,
  subheader_font_size: 0.15,
  result_format: 'json',
  result_type: 'full',
  time_format: 'smart_date',
  dashboards: [],
  label_colors: {},
  shared_label_colors: {},
  extra_filters: [],
};

const DELETE_WS_DATA_FILTERS = [
  {
    col: 'os_workspace',
    op: 'IS NOT NULL',
  },
];

async function getDatasourceAndFilters(
  conceptName: string,
  dataMask: DataMaskStateWithId,
  datasources: SavedSearchContent['datasourcesInScope'],
  concept: Promise<Concept | undefined | void>,
) {
  if (!dataMask) return null;
  const datasource = await selectDatasource(conceptName, datasources, concept);
  if (!datasource) return null;

  let time_range = 'No filter';
  const filters = filterInvalidFilterProps(
    Object.values(dataMask).reduce((filters, dm) => {
      if (dm.extraFormData?.filters) {
        filters.push(...dm.extraFormData.filters);
      }
      if (dm.extraFormData?.time_range) {
        time_range = dm.extraFormData?.time_range;
      }
      return filters;
    }, [] as QueryObjectFilterClause[]),
  );
  return { datasource, filters, time_range };
}

function buildFormData(
  mockSource: any,
  datasource: SavedDatasource | DatasetIdentifier,
  filters: any[],
  time_range = 'No filter',
) {
  return addOntologyContextToFormData(
    {
      ...mockSource,
      ...('main_dttm_col' in datasource && {
        granularity_sqla: datasource.main_dttm_col,
      }),
      datasource: datasource.uid,
      extra_form_data: {
        filters,
        time_range,
      },
    },
    { datasource: { datasource } },
  );
}

export async function buildFormDataForEntityCount(
  conceptName: string,
  dataMask: DataMaskStateWithId,
  datasources: SavedSearchContent['datasourcesInScope'],
  concept: Promise<Concept | undefined | void>,
) {
  const resp = await getDatasourceAndFilters(
    conceptName,
    dataMask,
    datasources,
    concept,
  );
  if (!resp || resp.filters.length === 0) {
    return null;
  }
  const { datasource, filters, time_range } = resp;

  return buildFormData(COUNT_BaseFormData, datasource, filters, time_range);
}

export type RecordFetchOptions = {
  all_columns: string[];
  order_by_cols: string[];
  row_limit: number;
  server_page_length: number;
};

export async function buildFormDataForRecordFetch(
  conceptName: string,
  dataMask: DataMaskStateWithId,
  datasources: SavedSearchContent['datasourcesInScope'],
  concept: Promise<Concept | undefined | void>,
  options: RecordFetchOptions,
) {
  const resp = await getDatasourceAndFilters(
    conceptName,
    dataMask,
    datasources,
    concept,
  );
  if (!resp) {
    return null;
  }
  const { datasource, filters, time_range } = resp;

  return {
    ...buildFormData(RECORDS_BaseFormData, datasource, filters, time_range),
    ...options,
  };
}

const extractCount = (resp: any): number =>
  get(resp, 'result[0].data[0].count', -1);

export type ResultType = 'full' | 'query' | 'results';

export async function handleChartDataRequest<T>(
  formData: any,
  force = false,
  resultTransformer: (result: any) => T = result => result,
) {
  const { response, json } = await getChartDataRequest({
    formData,
    force,
  });

  if (response.status === 200) {
    return resultTransformer(json);
  }

  if (response.status === 202) {
    const result = 'result' in json ? json.result[0] : json;
    return waitForAsyncData(result)
      .then((asyncResult: ChartDataResponseResult[]) =>
        resultTransformer({ result: asyncResult }),
      )
      .catch((error: ClientErrorObject) =>
        Promise.reject(
          error.message || error.error || t('Check configuration'),
        ),
      );
  }
  throw new Error(
    `Received unexpected response status (${response.status}) while fetching chart data`,
  );
}

export async function fetchCount(formData: any, force = false) {
  return handleChartDataRequest(formData, force, extractCount);
}

// TODO: Move out of saved search utils.
export async function getLocalWorkspaceRecordsCount(
  workspace: string,
  baseConcept: string,
): Promise<number> {
  const { conceptsSet } = await getSubTreeConcepts(baseConcept);
  const entity_filter = {
    col: 'entity_type',
    op: 'IN',
    val: Array.from(conceptsSet),
  };
  const workspace_filter = {
    col: 'os_workspace',
    op: '==',
    val: workspace,
  };
  const dataMask = extractDataMask(
    [],
    [
      {
        id: shortid(),
        extraFormData: {
          filters: [entity_filter, workspace_filter],
        },
        filterState: {},
        ownState: {},
      } as any,
    ],
  );
  const formData = await buildFormDataForEntityCount(
    baseConcept,
    dataMask,
    {},
    Promise.resolve(undefined),
  );
  return fetchCount(formData);
}

type LocalRecordsSignature = {
  workspace_id: string;
  workspace_label: string;
  record_count: number;
  has_write_access: boolean;
};

export type WorkspaceRecordsSignature = {
  with_write_access: LocalRecordsSignature[];
  without_write_access: LocalRecordsSignature[];
};

const API_CLIENT = new APIClient();
// TODO: Move out of saved search utils.
export async function getLocalRecordsCountInView(
  datasetViewerFormData: any,
): Promise<WorkspaceRecordsSignature> {
  const entity_filter = {
    col: 'entity_type',
    op: 'NOT IN',
    val: OCTOSTAR_INTERNAL_ENTITY_TYPES,
  };
  const local_data_filter = {
    col: 'os_workspace',
    op: 'IS NOT NULL',
  };
  const filters = datasetViewerFormData.extra_form_data.filters.concat([
    entity_filter,
    local_data_filter,
  ]);
  const formData = {
    ...datasetViewerFormData,
    extra_form_data: {
      ...datasetViewerFormData.extra_form_data,
      filters,
    },
    query_mode: 'aggregate',
    groupby: [
      'os_workspace',
      {
        label: 'workspace_label',
        sqlExpression: 'in_workspace[os_workspace].entity_label',
        expressionType: 'SQL',
      },
    ],
    ontology_context: {
      backend: 'octostar',
      schema: 'dtimbr',
      table_name: 'os_business_workspace_record',
    },
    metrics: [
      {
        expressionType: 'SIMPLE',
        column: {
          id: 475834,
          column_name: 'entity_id',
          verbose_name: null,
          description: null,
          expression: '',
          filterable: true,
          groupby: true,
          is_dttm: false,
          type: 'STRING',
          type_generic: 1,
          advanced_data_type: null,
          python_date_format: null,
          is_certified: false,
          certified_by: null,
          certification_details: null,
          warning_markdown: null,
        },
        aggregate: 'COUNT',
        sqlExpression: null,
        datasourceWarning: false,
        hasCustomLabel: false,
        label: 'count',
        optionName: 'metric_qj56y1s8d2_1uw6yp5qjfu',
      },
    ],
    viz_type: 'relational_request',
    force: true,
    result_format: 'json',
    result_type: 'full',
  };
  const payload = buildV1ChartDataPayload({ formData, force: true } as any);

  return API_CLIENT.fetchJson(`/api/v1/octostar/workspace-records/signature`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export async function deleteLocalRecordsInView(
  datasetViewerFormData: any,
  filter_workspaces: string[] = [],
): Promise<void> {
  const entity_filter = {
    col: 'entity_type',
    op: 'NOT IN',
    val: OCTOSTAR_INTERNAL_ENTITY_TYPES,
  };
  const local_data_filter = {
    col: 'os_workspace',
    op: 'IS NOT NULL',
  };
  let filters = datasetViewerFormData.extra_form_data.filters.concat([
    entity_filter,
    local_data_filter,
  ]);

  if (filter_workspaces.length) {
    filters = filters.concat({
      col: 'os_workspace',
      op: 'IN',
      val: filter_workspaces,
    });
  }
  const formData = {
    ...datasetViewerFormData,
    extra_form_data: {
      ...datasetViewerFormData.extra_form_data,
      filters,
    },
    query_mode: 'aggregate',
    groupby: [],
    ontology_context: {
      backend: 'octostar',
      schema: 'timbr',
      table_name: 'os_business_workspace_record',
    },
    metrics: null,
    viz_type: 'delete_local_records',
    force: true,
    result_format: 'json',
    result_type: 'full',
  };

  const payload = getDeleteRecordsPayload(formData);
  return API_CLIENT.fetchJson(
    `/api/v1/octostar/workspace-records/filtered_set`,
    {
      method: 'DELETE',
      body: JSON.stringify(payload),
    },
  );
}

export async function fetchRecords<T>(formData: any, resultType: ResultType) {
  const { response, json } = await getChartDataRequest({
    formData,
    resultType,
  });
  if (response.status === 200) {
    return json.result[0];
  }

  if (response.status === 202) {
    const result = 'result' in json ? json.result[0] : json;
    return waitForAsyncData(result)
      .then((asyncResult: T[]) => asyncResult[0])
      .catch((error: ClientErrorObject) =>
        Promise.reject(
          error.message || error.error || t('Check configuration'),
        ),
      );
  }
  throw new Error(
    `Received unexpected response status (${response.status}) while fetching native query`,
  );
}

export function asSavedSearch<T extends DataMask>({
  item,
  filters,
  crossFilters,
  datasources,
  concept,
  initialCount,
}: {
  item: WorkspaceItem;
  filters?: Filter[];
  crossFilters?: T[];
  datasources?: DatasourcesState;
  concept?: string;
  initialCount?: number;
}) {
  const savedSearch: WorkspaceItem<SavedSearchContent> = {
    ...item,
    entity_type: FILESYSTEM_ENTITY_CONCEPT,
    os_item_type: FILESYSTEM_ENTITY_CONCEPT,
    os_item_content_type: SAVED_SEARCH_CONTENT_TYPE,
    os_item_content: {
      ...item.os_item_content,
      ...{
        dataset: true,
        concept: concept || item.os_item_content?.concept || 'os_thing',
        initialCount,
      },
      filters: filters || [],
      crossFilters: crossFilters || [],
      datasourcesInScope: datasources ? extractDatasources(datasources) : {},
    },
  };
  return savedSearch;
}
