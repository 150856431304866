import * as React from 'react';
import { Tag } from 'antd5';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Entity, OsTag } from '@octostar/platform-types';
import { CloseOutlined } from '@ant-design/icons';
import { hexToRgba } from 'src/octostar/lib/Formatters';
import './OctostarTag.css';
import { EntityLabelTooltip } from '../EntityLabelTooltip';

export const DEFAULT_TAG_COLOR = '#2279F2';

const getTagStyles = (color: string) => {
  const backgroundColor = hexToRgba(color, 0.1);
  const borderColor = hexToRgba(color, 0.8);

  return {
    color,
    borderColor,
    backgroundColor,
  };
};

export const OctostarTag = ({
  createdIn,
  entity,
  tag,
  tagged,
  contextMenu = false,
  count,
  readonly,
  os_workspace,
  onTagAdded,
  onTagRemoved,
}: {
  createdIn: string;
  entity: Entity;
  tag: OsTag;
  os_workspace?: string;
  contextMenu?: boolean;
  count?: number;
  tagged?: boolean;
  readonly?: boolean;
  onTagAdded?: (tag: OsTag) => void;
  onTagRemoved?: (tag: OsTag) => void;
}) => {
  const [hidden, setHidden] = React.useState(false);
  const ref = React.useRef(1);

  React.useEffect(
    () => () => {
      ref.current = 1;
    },
    [],
  );

  const handleApplyTag = React.useCallback(async () => {
    if (readonly || tagged) return;
    const { current } = ref;
    const activeWorkspace =
      os_workspace || (await DesktopAPI.getActiveWorkspace(true));
    if (!activeWorkspace) {
      return;
    }
    onTagAdded?.(tag);
    DesktopAPI.applyTag(activeWorkspace, tag, entity).catch(e => {
      console.log('Failed to apply tag', e);
      onTagRemoved?.(tag);
    });
    if (current === ref.current) {
      setHidden(true);
    }
  }, [entity, onTagAdded, onTagRemoved, os_workspace, readonly, tag, tagged]);

  const handleRemoveTag = React.useCallback(() => {
    if (readonly || !tagged || !os_workspace) return;
    onTagRemoved?.(tag);
    DesktopAPI.removeTag(os_workspace, tag, entity).catch(e => {
      // eslint-disable-next-line no-console
      console.log('Failed to remove tag', e);
      onTagAdded?.(tag);
    });
    setHidden(true);
  }, [entity, onTagAdded, onTagRemoved, os_workspace, readonly, tag, tagged]);

  if (hidden) return null;

  const label = `${tag.entity_label}${count ? `\u00A0\u00A0(${count})` : ''}`;
  const styles = getTagStyles(tag.color || DEFAULT_TAG_COLOR);

  if (contextMenu && readonly) {
    return (
      <div className="os-tag-context-menu">
        <span
          className="tags-dot"
          style={{
            backgroundColor: `${tag.color || DEFAULT_TAG_COLOR}`,
          }}
        />
        <EntityLabelTooltip
          entity={{
            entity_type: 'os_workspace',
            entity_id: createdIn ?? os_workspace,
          }}
          placement="right"
        >
          {label}
        </EntityLabelTooltip>
      </div>
    );
  }

  if (contextMenu && !readonly) {
    return (
      <>
        <Tag
          className="os-tag"
          bordered={false}
          onClick={tagged ? handleRemoveTag : handleApplyTag}
        >
          <span
            className="tags-dot"
            style={{
              backgroundColor: `${tag.color || DEFAULT_TAG_COLOR}`,
            }}
          />
          {tagged ? (
            <EntityLabelTooltip
              entity={{ entity_type: 'os_workspace', entity_id: os_workspace }}
              placement="right"
            >
              {label}{' '}
              <CloseOutlined style={{ marginLeft: '4px', fontSize: '10px' }} />
            </EntityLabelTooltip>
          ) : (
            label
          )}
        </Tag>
      </>
    );
  }

  return (
    <Tag onClick={handleApplyTag} className="os-tag" style={styles}>
      <EntityLabelTooltip
        entity={{ entity_type: 'os_workspace', entity_id: os_workspace }}
        placement="top"
      >
        {label}
      </EntityLabelTooltip>{' '}
      {!readonly && tagged && (
        <span
          className="os-tag-button-close"
          style={{ color: tag.color || DEFAULT_TAG_COLOR }}
        >
          <CloseOutlined sizes="small" onClick={handleRemoveTag} />
        </span>
      )}
    </Tag>
  );
};
