import * as React from 'react';
import { debounce, isEqual } from 'lodash';
import { Unsubscribe, Workspace } from '@octostar/platform-types';
import { DesktopContext } from '../components/DesktopContext';

// VS: I see a pattern of this BS, might make sense to have a central variable to track and increase/reduce these delays.
const EVENT_DEBOUNCE_MS = 50;

/**
 * VS: To Fix!
 * VS: Hooks are by design instantiated per component so each time you call useDesktopWorkspaces in a new component, the effect (adding a listener) is run once.
 * Repeated renders of the same component will, as per React spec, not re-register the listener.
 * Also, if you manage to invoke useDesktopWorkspaces 'too late' after all workspaces are loaded and then you can pretty much pound sand.
 */
export const useDesktopWorkspaces = () => {
  const desktop = React.useContext(DesktopContext);
  const [workspaces, setWorkspaces] = React.useState<Workspace[]>([]);
  const [openWorkspaceIds, setOpenWorkspaceIds] = React.useState<string[]>([]);
  const debouncedSetOpenWorkspaces = React.useMemo(
    () =>
      debounce(
        (ws: Workspace[]) => {
          setWorkspaces(orig => {
            if (isEqual(orig, ws)) return orig;
            return ws;
          });
        },
        EVENT_DEBOUNCE_MS,
        { leading: true },
      ),
    [setWorkspaces],
  );

  React.useEffect(() => {
    const ids = workspaces.map(x => x.workspace.entity_id);
    setOpenWorkspaceIds(current => {
      if (isEqual(ids, current)) return current;
      return ids;
    });
  }, [workspaces]);

  React.useEffect(() => {
    desktop.getOpenWorkspaces().then(openWorkspaces => {
      if (openWorkspaces.length > 0) {
        setWorkspaces(curr =>
          isEqual(curr, openWorkspaces) ? curr : openWorkspaces,
        );
      }
    });
  }, [desktop]);

  React.useEffect(() => {
    // Listen to changes in open workspaces
    if (openWorkspaceIds.length === 0) {
      return () => undefined;
    }

    const cleanup: Array<Promise<Unsubscribe>> = [];

    openWorkspaceIds.forEach(wsid => {
      cleanup.push(
        desktop.onWorkspaceChanged(wsid, ws => {
          setWorkspaces(orig => {
            if (!ws) return orig;
            const index = orig.findIndex(x => x.workspace.entity_id === wsid);
            if (index < 0) return [...orig, ws];
            const newWorkspaces = [...orig];
            newWorkspaces[index] = ws;
            return isEqual(orig, newWorkspaces) ? orig : newWorkspaces;
          });
        }),
      );
    });

    return () => {
      cleanup.forEach(async unsubPromise => {
        const unsub = await unsubPromise;
        unsub();
      });
    };
  }, [desktop, openWorkspaceIds]);

  React.useEffect(() => {
    const cleanupPromise = desktop.onOpenWorkspacesChanged(
      debouncedSetOpenWorkspaces,
    );
    return () => {
      cleanupPromise.then(unsub => unsub());
    };
  }, [desktop, debouncedSetOpenWorkspaces]);

  return workspaces;
};
