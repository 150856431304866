import * as React from 'react';
import { isEqual } from 'lodash';
import { t } from '@superset-ui/core';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Empty,
  Modal,
  Tag,
  Spin,
  Dropdown,
  MenuProps,
} from 'antd5';
import OntologyAPI from 'src/octostar/api/event-driven/ontology';
import {
  Entity,
  OsWorkspaceEntity,
  Relationship,
  WorkspaceRecordIdentifier,
  WorkspaceRelationship,
} from '@octostar/platform-types';
import { RELATIONSHIPS_CONCEPT } from 'src/octostar/interface';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { bigNumberFormat } from 'src/octostar/lib/Formatters';
import {
  canEntityBeSaved,
  hasUserPermission,
} from 'src/octostar/lib/EntityUtils';
import ConfiguredIcon from 'src/octostar/components/ConfiguredIcon';
import { OpenTabLink } from 'src/octostar/components/OpenTabLink';
import RecordEditor from '../MultiRecordViewer/RecordEditor';
import { asPanelLabel } from '../MultiRecordViewer/lib/entity-data';
import './Relationships.css';

const { Panel } = Collapse;
const { confirm } = Modal;

interface EntityRelationshipProps {
  source: OsWorkspaceEntity;
  target: OsWorkspaceEntity;
  relationship_name: string;
  onDeleteRelation: (relationship_name: string) => void;
  relationshipData?: WorkspaceRelationship;
  expand?: boolean;
}

export default function EntityRelationship({
  source,
  target,
  relationship_name,
  onDeleteRelation,
  relationshipData,
  expand,
}: EntityRelationshipProps) {
  const [canDelete, setCanDelete] = React.useState<boolean>(false);
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
  const [isEditable, setIsEditable] = React.useState<boolean>(false);
  const [readonly, setReadonly] = React.useState<boolean>(true);
  const [activeKeys, setActiveKeys] = React.useState<string[]>(
    expand ? [source.entity_id] : [],
  );
  const tabSetRef = React.useRef(null);
  const [panelWidth, setPanelWidth] = React.useState<number>(0);

  React.useEffect(() => {
    const { current } = tabSetRef;
    // Check if ResizeObserver is supported
    if (ResizeObserver) {
      const resizeObserver = new ResizeObserver(entries => {
        const newPanelWidth = entries?.[0].contentRect.width;
        setPanelWidth(curr => (curr === newPanelWidth ? curr : newPanelWidth));
      });

      if (current) {
        resizeObserver.observe(current);
      }

      return () => {
        if (current) {
          resizeObserver.unobserve(current);
        }
      };
    }
    // eslint-disable-next-line no-console
    console.log('ResizeObserver is not supported in this browser.');
    return () => {};
  }, []);

  React.useEffect(() => {
    let mounted = true;
    canEntityBeSaved(target, true).then(res => {
      if (mounted && res.value) {
        setIsEditable(res.value);
      }
    });
    return () => {
      mounted = false;
    };
  }, [target, target.entity_type]);

  const onSave = React.useCallback((entity: OsWorkspaceEntity) => {
    const save = async (): Promise<void> => {
      if (entity) {
        setFormDisabled(true);
        return DesktopAPI.save(entity)
          .then(() => {
            DesktopAPI.showToast({
              message: t('Saved %s', entity.entity_label),
              level: 'success',
            });
          })
          .finally(() => setFormDisabled(false));
      }
      return Promise.resolve();
    };
    save();
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (relationshipData?.os_workspace) {
      hasUserPermission([relationshipData.os_workspace]).then(res => {
        if (mounted && res) {
          setCanDelete(true);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [relationshipData?.os_workspace]);

  const showConfirmDelete = React.useCallback(
    e => {
      e.stopPropagation();
      if (!relationshipData) {
        return;
      }
      confirm({
        title: t('Do you want to delete this relation?'),
        icon: <ExclamationCircleFilled />,
        onOk() {
          DesktopAPI.delete({
            entity_type: RELATIONSHIPS_CONCEPT,
            os_entity_uid: relationshipData.os_entity_uid,
            os_workspace: relationshipData.os_workspace,
          })
            .then(() => {
              DesktopAPI.showToast({
                level: 'success',
                message: `Relation ${relationship_name} deleted`,
              });
              onDeleteRelation(relationship_name);
            })
            .catch(() => {
              DesktopAPI.showToast({
                level: 'error',
                message: `Relation not deleted`,
                description: 'An error occurred. See log for details.',
              });
            });
        },
      });
    },
    [onDeleteRelation, relationshipData, relationship_name],
  );

  React.useEffect(() => {
    const newActiveKeys = expand ? [target.entity_id] : [];
    setActiveKeys(curr =>
      isEqual(curr, newActiveKeys) ? curr : newActiveKeys,
    );
  }, [target.entity_id, expand]);

  const onPanelChange = (keys: string[] | string) => {
    const newActiveKeys = Array.isArray(keys) ? keys : [keys];
    setActiveKeys(curr =>
      isEqual(curr, newActiveKeys) ? curr : newActiveKeys,
    );
  };

  const onDelete = React.useCallback(
    e => {
      e.stopPropagation();
      showConfirmDelete(e);
    },
    [showConfirmDelete],
  );

  const onEditChange = React.useCallback(
    e => {
      if (
        activeKeys.includes(
          `${source.entity_id}-${relationship_name}-${target.entity_id}`,
        )
      ) {
        e.stopPropagation();
      }

      setReadonly(!readonly);
    },
    [
      activeKeys,
      readonly,
      relationship_name,
      source.entity_id,
      target.entity_id,
    ],
  );

  const handleMenuClick = React.useCallback(
    e => {
      e.stopPropagation();
      switch (e.key) {
        case 'delete':
          onDelete(e);
          break;
        case 'edit':
          onEditChange(e);
          break;
        default:
          break;
      }
    },
    [onDelete, onEditChange],
  );

  const menuProps = React.useMemo(() => {
    const items: MenuProps['items'] = [
      {
        label: (
          <OpenTabLink record={target} withLabel>
            Open in a new tab
          </OpenTabLink>
        ),
        key: 'open',
      },
    ];
    if (isEditable) {
      items.push({
        label: readonly ? t('Edit') : t('Read only'),
        key: 'edit',
      });
    }
    if (canDelete) {
      items.push({
        label: t('Delete'),
        key: 'delete',
      });
    }
    return {
      items: [...(items || [])],
      onClick: handleMenuClick,
    };
  }, [target, isEditable, canDelete, handleMenuClick, readonly]);

  return (
    <Collapse
      bordered
      activeKey={activeKeys}
      expandIcon={({ isActive }) =>
        isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
      }
      onChange={onPanelChange}
      ref={tabSetRef}
    >
      <Panel
        key={`${source.entity_id}-${relationship_name}-${target.entity_id}`}
        header={
          <div className="relationships-panel-header">
            <span className="relationships-panel-label">
              {target.entity_label}
            </span>
            {panelWidth > 400 && (
              <Tag
                color="processing"
                icon={<ConfiguredIcon hint={target.entity_type} />}
              >
                {target.entity_type}
              </Tag>
            )}
          </div>
        }
        className="panel-header-relationship-entity"
        extra={
          <div className="entity-panel-extra-buttons">
            {panelWidth > 400 ? (
              <>
                <Button
                  type="text"
                  onClick={onEditChange}
                  disabled={!isEditable}
                  icon={<EditOutlined />}
                  className="os-icon-button-fix"
                />
                <Button
                  type="text"
                  onClick={onDelete}
                  disabled={!canDelete}
                  icon={<DeleteOutlined />}
                  className="os-icon-button-fix"
                />
                <OpenTabLink record={target}> </OpenTabLink>
              </>
            ) : (
              <Dropdown menu={menuProps} placement="bottomRight">
                <Button
                  className="os-icon-button-fix"
                  type="text"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            )}
          </div>
        }
      >
        <div className="panel-children-relationship">
          <RecordEditor
            entity={target}
            setReadOnly={setReadonly}
            readonly={isEditable ? readonly : true}
            onSave={onSave}
            requiredFieldsOnly={false}
            disabled={formDisabled}
            setDisabled={setFormDisabled}
          />
        </div>
      </Panel>
    </Collapse>
  );
}

export const RelatedEntities = ({
  entity,
  relationship,
  count,
  os_workspace,
  onDeleteRelation,
  expand,
}: {
  entity: OsWorkspaceEntity;
  relationship: Relationship;
  count: number;
  os_workspace: string;
  onDeleteRelation: (relationship_name: string) => void;
  expand?: boolean;
}) => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>(
    expand ? [relationship.key] : [],
  );
  const [fetching, setFetching] = React.useState(false);
  const [entities, setEntities] = React.useState<
    (Entity & WorkspaceRecordIdentifier)[]
  >([]);
  const [relationshipData, setRelationshipData] =
    React.useState<WorkspaceRelationship[]>();
  const fetch = React.useRef(0);

  React.useEffect(() => {
    if (count) {
      setFetching(true);
      const thisFetch = fetch.current;
      OntologyAPI.getConnectedEntities(entity, relationship)
        .then(entities => {
          if (fetch.current !== thisFetch) {
            return;
          }
          const newEntities = entities.map(x => ({
            ...{ os_workspace, os_entity_uid: x.entity_id },
            ...x,
          }));
          setEntities(curr =>
            isEqual(curr, newEntities) ? curr : newEntities,
          );
        })
        .finally(() => {
          if (fetch.current === thisFetch) {
            setFetching(false);
          }
        });
    }
    return () => {
      fetch.current += 1;
    };
  }, [count, entity, relationship, os_workspace]);

  React.useEffect(() => {
    let mounted = true;
    OntologyAPI.getWorkspaceRelationshipRecords(entity, relationship).then(
      rel => {
        if (mounted) {
          setRelationshipData(rel);
        }
      },
    );
    return () => {
      mounted = false;
    };
  }, [entity, relationship]);

  React.useEffect(() => {
    setActiveKeys(curr => {
      const newActiveKeys = expand ? [relationship.key] : [];
      return isEqual(curr, newActiveKeys) ? curr : newActiveKeys;
    });
  }, [expand, relationship.key]);

  const onPanelChange = (keys: string[] | string) => {
    setActiveKeys(Array.isArray(keys) ? keys : [keys]);
  };

  const showModal = React.useCallback(
    e => {
      e.stopPropagation();
      DesktopAPI.showCreateRelationsDialog({
        source: entity,
        relationName: relationship.relationship_name,
        defaultTargetConcept: relationship.target_concept,
      });
    },
    [entity, relationship.relationship_name, relationship.target_concept],
  );

  if (!count) {
    return null;
  }
  const problemClass =
    !fetching && !entities.length ? 'relationship-count-problem' : '';

  const missingRows = fetching ? false : count - entities.length;
  return (
    <Collapse
      bordered={false}
      ghost
      expandIcon={({ isActive }) =>
        isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
      }
      onChange={onPanelChange}
      activeKey={activeKeys}
      className="relationships-panel-collapse"
    >
      <Panel
        key={relationship.key}
        header={
          <div className="relationships-panel-header">
            <span className="relationships-panel-label">
              {asPanelLabel(relationship.relationship_name)}
            </span>
            {relationship.is_inverse ? (
              <ArrowLeftOutlined />
            ) : (
              <ArrowRightOutlined />
            )}
            <span className="relationships-panel-label-concept">
              {relationship.target_concept.toLowerCase()}
            </span>
            <Tag className={`relationships-header-tag ${problemClass}`}>
              {`${bigNumberFormat(count)}`}{' '}
              {fetching ? <LoadingOutlined spin /> : undefined}
            </Tag>
          </div>
        }
        className="panel-header-relationship"
        extra={
          <div>
            <Button
              onClick={showModal}
              type="default"
              icon={<PlusOutlined />}
              className="os-icon-button-fix"
            />
          </div>
        }
      >
        <div className="panel-children">
          {fetching ? (
            <Empty description={<Spin />} />
          ) : (
            entities.map((e: Entity & WorkspaceRecordIdentifier) => (
              <EntityRelationship
                key={e.entity_id}
                source={entity}
                target={e}
                onDeleteRelation={onDeleteRelation}
                relationshipData={
                  relationshipData?.filter(
                    x =>
                      x.os_entity_uid_to ||
                      x.os_entity_uid_from === e.entity_id,
                  )?.[0] || undefined
                }
                relationship_name={relationship.relationship_name}
                expand={expand}
              />
            ))
          )}
          {missingRows ? (
            <>
              {bigNumberFormat(missingRows)}&nbsp;
              {missingRows > 1
                ? t('Records not available')
                : t('Record not available')}
            </>
          ) : null}
        </div>
      </Panel>
    </Collapse>
  );
};
