import * as React from 'react';
import { isEqual } from 'lodash';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Watcher, WatchersContextValue, WatchIntent } from './types';
import { getIntents, getWatchers } from './WatchersIO';

const WatchersContext = React.createContext<WatchersContextValue | undefined>(
  undefined,
);

export const useWatchersContext = (): WatchersContextValue => {
  const context = React.useContext(WatchersContext);
  if (!context) {
    throw new Error(
      'useWatchersContext must be used within a WatchersProvider',
    );
  }
  return context;
};

export const WatchersProvider: React.FC = ({ children }) => {
  const [watchers, setWatchers] = React.useState<Watcher[]>([]);
  const [intents, setIntents] = React.useState<WatchIntent[]>([]);
  const [openWorkspaces, setOpenWorkspaces] = React.useState<string[]>([]);

  const refresh = React.useCallback(async () => {
    const wss = await DesktopAPI.getOpenWorkspaceIds();
    setOpenWorkspaces(openWorkspaces =>
      isEqual(openWorkspaces, wss) ? openWorkspaces : wss,
    );

    getIntents().then(newIntents => {
      setIntents(currentIntents =>
        isEqual(currentIntents, newIntents) ? currentIntents : newIntents,
      );
    });
    getWatchers().then(newWatchers => {
      console.log('REFRESHING WATCHERS IN REFRESH FUNC 🌟');
      setWatchers(currentWatchers =>
        isEqual(currentWatchers, newWatchers) ? currentWatchers : newWatchers,
      );
    });
  }, []);

  React.useEffect(() => {
    refresh();
    const inter = setInterval(async () => {
      await refresh();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(inter);
    };
  }, [refresh]);

  const intentsFromOpenWorkspaces = () => {
    if (!openWorkspaces || !intents) return [];
    return intents.filter(intent =>
      openWorkspaces.includes(intent.os_workspace),
    );
  };

  const watchersFromOpenWorkspaces = () => {
    if (!openWorkspaces || !watchers) return [];
    return watchers.filter(watcher =>
      openWorkspaces.includes(watcher.os_workspace),
    );
  };
  return (
    <WatchersContext.Provider
      value={{
        watchers,
        intents,
        refresh,
        intentsFromOpenWorkspaces,
        watchersFromOpenWorkspaces,
      }}
    >
      {children}
    </WatchersContext.Provider>
  );
};
