import * as React from 'react';
import { t } from '@superset-ui/core';
import { Button, Tag, Empty, Dropdown } from 'antd5';
import { bigNumberFormat } from 'src/octostar/lib/Formatters';
import {
  Entity,
  OsWorkspaceEntity,
  WorkspaceItem,
} from '@octostar/platform-types';
import {
  LoadingOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'react-use';
import _ from 'lodash';
import ee, { getNotificationKey } from 'src/octostar/interface';
import { RelatedEntities } from './EntityRelationship';
import { FocusListMenu } from './FocusListMenu';
import { withEntityRelationshipCounts } from '../MultiRecordViewer/lib/entity-data';
import { RelationshipCountStatus } from '../MultiRecordViewer/lib/types';
import './Relationships.css';

interface RelationshipsProps {
  entity: Entity;
  os_workspace: string;
  updateTabCounter?: (count: number) => void | undefined;
}

export default function Relationships({
  entity,
  os_workspace,
  updateTabCounter,
}: RelationshipsProps) {
  const [expand, setExpand] = React.useState<boolean>(false);
  const [loadingCounts, setLoadingCounts] = React.useState<boolean>(true);
  const [loadingCountsDebounce, setLoadingCountsDebounce] =
    React.useState(false);

  const [counts, setCounts] = React.useState<RelationshipCountStatus[]>([]);
  const [numRelations, setNumRelations] = React.useState<number>(0);
  const [recount, setRecount] = React.useState(0);

  React.useEffect(() => {
    const cb = _.debounce(() => {
      setRecount(x => x + 1);
    }, 200);
    const topic = getNotificationKey(entity);
    ee.on(topic, cb);
    return () => ee.off(topic, cb);
  }, [entity]);

  React.useEffect(() => {
    let mounted = true;
    setLoadingCounts(true);
    setNumRelations(0);
    setCounts([]);
    const onStatusChanged = (status: RelationshipCountStatus) => {
      if (!mounted) {
        return;
      }
      setCounts(curr => {
        const copy = [...curr];
        const x = copy.find(
          x =>
            x.relationship.relationship_name ===
            status.relationship.relationship_name,
        );
        if (x) {
          x.count = status.count;
          x.fetching = status.fetching;
        } else {
          copy.push(status);
        }
        return copy;
      });
    };
    withEntityRelationshipCounts(entity, onStatusChanged);
    return () => {
      mounted = false;
    };
  }, [entity, recount]);

  useDebounce(() => setLoadingCountsDebounce(loadingCounts), 1000, [
    loadingCounts,
  ]);

  React.useEffect(() => {
    setLoadingCounts(counts.some(x => x.fetching));
    setNumRelations(counts.reduce((a, b) => a + b.count, 0));
  }, [counts]);

  const onDeleteRelation = React.useCallback((relationship_name: string) => {
    setNumRelations(curr => curr - 1);
    setCounts(curr => {
      const copy = [...curr];
      const count = curr.find(
        c => c.relationship.relationship_name === relationship_name,
      );
      if (count) {
        copy[copy.indexOf(count)].count -= 1;
      }
      return copy;
    });
  }, []);

  const onExpand = React.useCallback(() => {
    setExpand(!expand);
  }, [expand]);

  const filteredCounts = counts.filter(x => x.count > 0);

  React.useEffect(() => {
    updateTabCounter?.(numRelations);
  }, [numRelations, updateTabCounter]);

  return (
    <div key="relationships" className="relationships-container">
      <div className="relationships-header">
        <div className="relationships-header-title">
          <span className="relationships-header-span">{t('Relations')}</span>
          <Tag>
            {bigNumberFormat(numRelations)}{' '}
            {loadingCounts && loadingCountsDebounce ? (
              <LoadingOutlined spin />
            ) : undefined}
          </Tag>
        </div>
        <div className="relationships-header-buttons">
          <Button
            type="text"
            icon={expand ? <UpCircleOutlined /> : <DownCircleOutlined />}
            onClick={onExpand}
            className="relationships-expand-button"
          />
          <Dropdown
            placement="bottomLeft"
            menu={{ items: [] }}
            dropdownRender={() => (
              <FocusListMenu
                item={entity as WorkspaceItem}
                os_workspace={os_workspace}
              />
            )}
          >
            <Button>{t(`Add relation`)}</Button>
          </Dropdown>
        </div>
      </div>
      <div className="panel-children">
        {loadingCounts && filteredCounts.length === 0 ? (
          <Empty description={<span>Loading</span>} />
        ) : filteredCounts.length > 0 ? (
          filteredCounts.map(x => (
            <RelatedEntities
              key={crypto.randomUUID()}
              entity={entity as OsWorkspaceEntity}
              relationship={x.relationship}
              count={x.count}
              os_workspace={os_workspace}
              expand={expand}
              onDeleteRelation={onDeleteRelation}
            />
          ))
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
}
