import { buildQueryContext } from '@superset-ui/core';

export const getDistinctQuery = (
  baseQueryObject: any,
  fieldName: string,
  row_limit: number,
) => ({
  ...baseQueryObject,
  columns: [
    {
      label: fieldName,
      sqlExpression: `DISTINCT ${fieldName}`,
      expressionType: 'SQL',
    },
  ],
  row_limit,
  orderby: [],
  metrics: null,
});

export const getDeleteRecordsPayload = (
  formData: Record<string, any>,
  row_limit = 10000,
) => {
  const queryContext = buildQueryContext(formData as any, baseQueryObject => {
    const distinct_queries = ['os_workspace', 'entity_type'].map(field =>
      getDistinctQuery(baseQueryObject, field, row_limit),
    );
    const entity_id_inner_query: any = {
      ...baseQueryObject,
      columns: ['entity_id'],
      orderby: [],
      metrics: null,
      result_format: 'json',
      result_type: 'query',
    };
    return distinct_queries.concat(entity_id_inner_query);
  });

  return queryContext;
};
