import * as React from 'react';
import { Button, Col, Row, Tag } from 'antd5';
import {
  Concept,
  Entity,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { t } from '@superset-ui/core';
import { Tooltip } from 'src/components/Tooltip';
import {
  checkIfEntityIsSaved,
  parseNodeDataToBeSaved,
} from 'src/octostar/lib/EntityUtils';
import { OpenTabLink } from '../OpenTabLink';
import ConfiguredIcon from '../ConfiguredIcon';
import FormContext from '../MultiRecordViewer/FormContext';
import { ItemTags } from '../tags/ItemTags';
import './RecordViewerGraph.css';
import './RecordDetails.css';

export const RecordViewerGraph = ({
  entity,
  concept,
  os_workspace,
  disableEdit,
}: {
  entity: Entity & WorkspaceRecordIdentifier;
  concept: Concept;
  os_workspace?: string;
  disableEdit?: boolean;
}) => {
  const [readonly, setReadOnly] = React.useState<boolean>(true);
  const submitFormRef = React.createRef<any>();
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
  const [isEntitySaved, setIsEntitySaved] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (disableEdit) {
      return () => {};
    }
    let mounted = true;
    checkIfEntityIsSaved(entity).then(saved => {
      if (mounted) {
        setIsEntitySaved(saved);
      }
    });
    return () => {
      mounted = false;
    };
  }, [disableEdit, entity]);

  const onSave = React.useCallback(async (formData: any) => {
    const save = async (): Promise<void> => {
      if (formData) {
        const entity = await parseNodeDataToBeSaved(
          formData,
          formData?.os_workspace,
        );
        setFormDisabled(true);
        return DesktopAPI.save(entity)
          .then(() => {
            DesktopAPI.showToast({
              message: t('Saved %s', entity.entity_label || ''),
              level: 'success',
            });
            setReadOnly(true);
          })
          .finally(() => setFormDisabled(false));
      }
      return Promise.resolve();
    };
    save();
  }, []);
  return (
    <div className="record-editor">
      <div className="record-editor-header">
        <span className="table-title">
          {!isEntitySaved && (
            <Tooltip
              className="entity-not-saved"
              title="Entity is not saved in database"
            >
              <ExclamationCircleOutlined />
            </Tooltip>
          )}
          <span className="record-label">{`${entity.entity_label}`}</span>
          <Tag color="blue" className="table-subtitle">
            <Row justify="space-between" gutter={6}>
              <Col>
                <ConfiguredIcon hint={entity.entity_type} />
              </Col>
              <Col>{`${entity.entity_type}`}</Col>
            </Row>
          </Tag>
        </span>
        {disableEdit ? (
          <></>
        ) : (
          <div className="record-editor-header-buttons">
            {readonly ? (
              <>
                <Button
                  type="default"
                  disabled={disableEdit}
                  onClick={() => setReadOnly(!readonly)}
                >
                  <FormOutlined />
                </Button>
                <div>
                  <OpenTabLink record={entity}> </OpenTabLink>
                </div>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => submitFormRef.current.submit()}
                  loading={formDisabled}
                >
                  <SaveOutlined />
                </Button>
                <Button type="text" onClick={() => setReadOnly(!readonly)}>
                  <CloseOutlined />
                </Button>
              </>
            )}
            {!isEntitySaved && (
              <Button onClick={() => onSave(entity)} title="Save entity">
                <SaveOutlined />
              </Button>
            )}
          </div>
        )}
      </div>

      {isEntitySaved && <ItemTags item={entity} />}
      <FormContext
        key={entity.entity_id}
        entity={entity}
        submitFormRef={submitFormRef}
        concept={concept}
        entityData={entity}
        os_workspace={os_workspace}
        isNewEntity={false}
        requiredFieldsOnly={false}
        readonly={readonly}
        disabled={false}
        hideButtons
        singleColumn
        onSave={onSave}
        setReadOnly={setReadOnly}
      />
    </div>
  );
};
