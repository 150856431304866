import shortid from 'shortid';
import {
  WorkspaceItem,
  WorkspaceRecordIdentifier,
} from '@octostar/platform-types';
import { isNil, set, uniq } from 'lodash';
import { WorkspaceRecordStore } from './types';

/**
 * Utility class to perform delete, revert operations on WSFS state.
 */
class WSFSOperator {
  private readonly operationStore: {
    [operationId: string]: {
      deletedItems: WorkspaceItem[];
      deletedEntityStore: WorkspaceRecordStore;
    };
  };

  private constructor() {
    this.operationStore = {};
  }

  public static instance = new WSFSOperator();

  /**
   * Also mutates {@link workspaceRecordStore} to reflect deleted entities.
   */
  public deleteItems(
    itemsToDelete: WorkspaceRecordIdentifier[],
    currentItems: WorkspaceItem[],
    workspaceRecordStore: WorkspaceRecordStore,
  ): {
    operationId: string;
    changedWorkspaces: string[];
    remainingItems: WorkspaceItem[];
  } {
    const operationId = shortid();
    const uuids = new Set(itemsToDelete.map(item => item.os_entity_uid));

    const deletedItems: WorkspaceItem[] = [];
    const remainingItems: WorkspaceItem[] = [];

    currentItems.forEach(item => {
      if (uuids.has(item.os_entity_uid)) {
        deletedItems.push(item);
      } else {
        remainingItems.push(item);
      }
    });

    const changedWorkspaces = uniq(
      itemsToDelete.map(item => item.os_workspace),
    );

    const deletedEntityStore: WorkspaceRecordStore = {};
    changedWorkspaces.forEach(wsid => {
      const workspaceRecords = workspaceRecordStore[wsid];
      deletedEntityStore[wsid] = {};
      if (workspaceRecords) {
        itemsToDelete
          .filter(item => item.os_workspace === wsid)
          .forEach(item => {
            if (
              !deletedEntityStore[wsid][item.entity_type] ||
              !deletedEntityStore[wsid][item.entity_type].entities
            ) {
              set(deletedEntityStore[wsid], `${item.entity_type}.entities`, []);
            }
            const entities = workspaceRecords[item.entity_type]?.entities || [];
            const index = entities.findIndex(
              e => e.entity_id === item.os_entity_uid,
            );
            if (index >= 0) {
              const deletedEntity = entities.splice(index, 1);
              deletedEntityStore[wsid][item.entity_type].entities.push(
                ...deletedEntity,
              );
              workspaceRecords[item.entity_type].count -= 1;
            }
          });
      }
    });

    this.operationStore[operationId] = {
      deletedItems,
      deletedEntityStore,
    };

    return { operationId, changedWorkspaces, remainingItems };
  }

  /**
   * Mutates {@link workspaceRecordStore} to restore deleted entities.
   * @returns Previously releted items.
   */
  public revertOperation(
    operationId: string,
    workspaceRecordStore: WorkspaceRecordStore,
  ): { itemsToRestore: WorkspaceItem[]; changedWorkspaces: string[] } {
    if (!this.operationStore[operationId]) {
      throw new Error(`Operation not found (${operationId})`);
    }
    const { deletedItems, deletedEntityStore } =
      this.operationStore[operationId];
    const changedWorkspaces = Object.keys(deletedEntityStore);

    changedWorkspaces.forEach(wsid => {
      const workspaceRecords = workspaceRecordStore[wsid];

      // Ignore if Workspace is no longer active
      if (workspaceRecords) {
        Object.entries(deletedEntityStore[wsid]).forEach(
          ([entity_type, { entities }]) => {
            if (
              !workspaceRecords[entity_type] ||
              !workspaceRecords[entity_type].entities
            ) {
              set(workspaceRecords, `${entity_type}.entities`, []);
            }
            if (isNil(workspaceRecords[entity_type].count)) {
              workspaceRecords[entity_type].count = 0;
            }
            workspaceRecords[entity_type].entities.push(...entities);
            workspaceRecords[entity_type].count += entities.length;
          },
        );
      }
    });

    this.cleanup(operationId);
    return { itemsToRestore: deletedItems, changedWorkspaces };
  }

  /**
   * Cleans up {@link operationId} from memory.
   */
  public cleanup(operationId: string): void {
    delete this.operationStore[operationId];
  }
}

export const wsfsOperator = WSFSOperator.instance;
