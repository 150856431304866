import * as React from 'react';
import { useRightSidebarControllerContext } from 'src/octostar/components/flexlayout/wrappers/WrapperRightSidebarContext';
import { EmptyBorder } from 'src/octostar/components/flexlayout/wrappers/EmptyBorder';
import { BorderTabHead } from 'src/octostar/components/flexlayout/wrappers/BorderTabHead';
import { EntityComments } from './EntityComments';
import { getCommentsCountForEntity } from './CommentsIO';
import './EntityCommentsCollection.css';

export const EntityCommentsCollection: React.FC<{}> = () => {
  const { entities, setTabCounter, setTabContent, detailsTabsContentMap } =
    useRightSidebarControllerContext();

  React.useEffect(() => {
    const updateCommentsTabCounter = async () => {
      const totalCommentsCount = await Promise.all(
        entities.map(x => getCommentsCountForEntity(x)),
      ).then(results => results.reduce((total, current) => total + current, 0));
      if (!detailsTabsContentMap.has('Comments')) {
        const content = <EntityCommentsCollection />;
        setTabContent('Comments', content, totalCommentsCount);
      } else if (
        detailsTabsContentMap.get('Comments')?.badgeCounter !==
        totalCommentsCount
      ) {
        setTabCounter('Comments', totalCommentsCount);
      }
    };
    updateCommentsTabCounter();
  }, [detailsTabsContentMap, entities, setTabContent, setTabCounter]);

  const title = `Comments on ${entities.length} entities`;

  return (
    <div title="Comments">
      <BorderTabHead
        title={entities.length === 0 ? 'Comments' : title}
        tabName="Comments"
        items={[]}
        showCloseBorderButton
      />
      {entities.length === 0 ? (
        <EmptyBorder description="Select any nodes to see all their comments" />
      ) : (
        <div className="comments-wrapper">
          {entities.map(entity => (
            <EntityComments key={entity.entity_id} entity={entity} />
          ))}
        </div>
      )}
    </div>
  );
};
