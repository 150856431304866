import shortid from 'shortid';
import {
  ApiResult,
  Query,
  QueryResponse,
  SendQueryOptions,
} from '@octostar/platform-types';
import { sha256hash } from './hash';
import { AbortContext } from './AbortContext';
import { BatchedQueriesManager } from './BatchedQueryManager';
/**
 * CancellableQueryClient
 *
 * A client designed to handle, execute, and manage cancellable queries to a backend.
 * The client offers mechanisms for caching in-flight queries, batching multiple queries
 * into grouped requests, and prioritizing batch requests.
 * Additionally, queries can be reset based on context, ensuring flexibility in
 * managing active queries.
 *
 * Features:
 * - Offers context-based abort functionality using the `AbortContext`, enabling
 *   the ability to cancel active queries based on the provided context.
 *
 * - Utilizes `BatchedQueriesManager` to handle batched query executions, and
 *   allows both standard priority and low-priority query batching.
 *
 * - Provides a mechanism to cache in-flight queries, avoiding repeated calls
 *   to the backend for the same query.
 *
 * - Contains error handling capabilities, logging any failed queries for debugging.
 *
 * - Can dedent provided string queries, ensuring consistent query string formatting.
 *
 * Dependencies:
 * - Leverages `shortid` for generating unique IDs when needed.
 * - Uses `sha256hash` function from the `hash` utility for creating cache keys.
 * - Relies on `AbortContext` for handling abort signals for queries.
 * - Integrates with `BatchedQueriesManager` for batching query requests.
 *
 * @exports CancellableQueryClient - Exports the CancellableQueryClient class.
 *
 * @class CancellableQueryClient
 * @property {boolean} ignore_cache - Flag to control cache ignoring. Defaults to `false`.
 * @property {AbortContext} abortContext - Context for handling abort signals.
 * @property {object} promises - Object holding promises for in-flight queries.
 * @property {BatchedQueriesManager} batchedQueryManager - Manager for standard priority batched queries.
 * @property {BatchedQueriesManager} lowPriorityBatchedQueryManager - Manager for low-priority batched queries.
 *
 * @see {@link BatchedQueriesManager}
 * @see {@link AbortContext}
 */

export class CancellableQueryClient {
  public ignore_cache = false;

  abortContext: AbortContext;

  promises: { [key: string]: Promise<ApiResult<any[]>> };

  batchedQueryManager: BatchedQueriesManager;

  lowPriorityBatchedQueryManager: BatchedQueriesManager;

  debouncedFlush: (
    optionsHash: string,
    ontology: string,
    options?: SendQueryOptions,
  ) => void;

  constructor(private readonly backendUrl: string) {
    this.abortContext = new AbortContext();
    this.promises = {};
    this.batchedQueryManager = new BatchedQueriesManager(
      this.abortContext,
      backendUrl,
      5,
    );
    this.lowPriorityBatchedQueryManager = new BatchedQueriesManager(
      this.abortContext,
      backendUrl,
      5,
    );
  }

  reset(context?: string) {
    if (!context) {
      console.log('context required for query cancel');
      return;
    }
    this.abortContext.reset(context);
  }

  private dedent(input: string) {
    if (!input) {
      return input;
    }

    return input.replace(/^[ \t]*(?=\S)/gm, '');
  }

  async sendQuery(
    query: Query,
    ontology: string,
    options?: SendQueryOptions,
  ): Promise<QueryResponse> {
    const result = await this.executeQuery(query, ontology, options);
    if (result.status === 'error') {
      console.log('💥 QUERY ERROR RESULT', query, result);
      console.trace();
    }
    return result.status === 'success' ? result.data : [];
  }

  async executeQuery<T>(
    query: Query,
    ontology: string,
    options?: SendQueryOptions,
    force_refresh = false,
  ): Promise<ApiResult<T[]>> {
    // cache in-flight queries

    if (typeof query === 'string') {
      query = this.dedent(query);
    }

    const key =
      typeof query === 'function'
        ? shortid()
        : await sha256hash(`${ontology}}:${query}`);
    const inflight = this.promises[key];
    if (inflight) {
      return inflight;
    }
    const promise = this.addQueryToBatch(
      query,
      ontology,
      options,
      force_refresh,
    ).finally(() => delete this.promises[key]);
    this.promises[key] = promise;
    return promise;
  }

  private async addQueryToBatch(
    query: Query,
    ontology: string,
    options?: SendQueryOptions,
    force_refresh?: boolean,
  ): Promise<ApiResult<any[]>> {
    if (options?.lowPriority) {
      return this.lowPriorityBatchedQueryManager.enqueue(
        query,
        ontology,
        options,
      );
    }
    return this.batchedQueryManager.enqueue(
      query,
      ontology,
      options,
      force_refresh,
    );
  }
}
