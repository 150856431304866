import * as React from 'react';
import DesktopAPI from 'src/octostar/api/event-driven/desktop';
import { Form, Modal, Select } from 'antd5';
import {
  EventEmitterType,
  WorkspaceItem,
  WorkspacePermissionValue,
} from '@octostar/platform-types';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd5/lib/checkbox';
import './WorkspacePicker.css';
import { BUILTINS_MESSAGE_TYPES } from 'src/octostar/api/messagesTypes';
import { t } from '@superset-ui/core';

type WorkspacePickerProps = {
  onChange: (value: string) => void;
  openWorkspacesOnly?: boolean;
  defaultWorkspace?: string;
  checkActiveWorkspace?: boolean;
  permissions?: WorkspacePermissionValue;
};

type Option = {
  value: string;
  label: string;
};

type Status = '' | 'validating' | 'error' | 'success' | 'warning';

export default function WorkspacePicker({
  onChange,
  openWorkspacesOnly,
  defaultWorkspace,
  checkActiveWorkspace,
  permissions = WorkspacePermissionValue.Write,
}: WorkspacePickerProps) {
  const [options, setOptions] = React.useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<
    string | undefined
  >();
  const [activeWorkspace, setActiveWorkspace] = React.useState<
    string | undefined
  >();
  const [status, setStatus] = React.useState<Status>('validating');

  React.useEffect(() => {
    let mounted = true;
    DesktopAPI.getActiveWorkspace().then((activeWorkspace: string) => {
      if (activeWorkspace && mounted) {
        setActiveWorkspace(activeWorkspace);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    DesktopAPI.listAllWorkspaces(permissions).then(
      async (workspaces: WorkspaceItem[]) => {
        let ws = workspaces;
        if (openWorkspacesOnly) {
          const ids = await DesktopAPI.getOpenWorkspaceIds();
          ws = workspaces.filter(x => ids.includes(x.entity_id));
        }
        const options: Option[] = ws.map((workspace: WorkspaceItem) => ({
          value: workspace.entity_id,
          label: workspace.entity_label,
        }));
        if (mounted) {
          if (!options?.length && mounted) {
            setStatus('error');
          } else {
            setStatus('');
            setOptions(options);
          }
        }
      },
    );
    return () => {
      mounted = false;
    };
  }, [openWorkspacesOnly, permissions]);

  React.useEffect(() => {
    if (activeWorkspace && options.find(x => x.value === activeWorkspace)) {
      setSelectedOption(activeWorkspace);
    } else if (
      defaultWorkspace &&
      options.find(x => x.value === defaultWorkspace)
    ) {
      setSelectedOption(defaultWorkspace);
    }
  }, [activeWorkspace, defaultWorkspace, options]);

  React.useEffect(() => {
    if (selectedOption) {
      setStatus('success');
    }
  }, [selectedOption]);

  const handleChangeWorkspace = React.useCallback(
    async (workspace: string) => {
      if (workspace === activeWorkspace) {
        DesktopAPI.setActiveWorkspace(workspace);
      }
      setSelectedOption(workspace);
    },
    [activeWorkspace],
  );

  React.useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption);
    }
  }, [onChange, selectedOption]);

  const handleSetActiveWorkspace = React.useCallback(
    async (e: CheckboxChangeEvent) => {
      if (!selectedOption) return;
      const isChecked = e.target.checked;
      if (
        isChecked &&
        activeWorkspace !== selectedOption &&
        (!activeWorkspace || options.find(x => x.value === activeWorkspace))
      ) {
        DesktopAPI.setActiveWorkspace(selectedOption);
        setActiveWorkspace(selectedOption);
      } else if (!isChecked && activeWorkspace === selectedOption) {
        DesktopAPI.setActiveWorkspace(undefined);
        setActiveWorkspace(undefined);
      }
    },
    [activeWorkspace, options, selectedOption],
  );

  return (
    <>
      <Form.Item
        validateStatus={status}
        hasFeedback
        {...(status === 'error'
          ? {
              help: t('No workspaces available'),
            }
          : {})}
      >
        <Select
          showSearch
          style={{ width: 250 }}
          placeholder="Choose Workspace"
          optionFilterProp="children"
          value={selectedOption}
          onChange={handleChangeWorkspace}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={options}
        />
      </Form.Item>

      {status !== 'error' && checkActiveWorkspace && (
        <div className="workspace-picker-checkbox">
          <Checkbox
            onChange={handleSetActiveWorkspace}
            checked={
              activeWorkspace ? activeWorkspace === selectedOption : false
            }
          >
            Set as Active Workspace and don't ask this again.
          </Checkbox>
        </div>
      )}
    </>
  );
}
export const EventDrivenWorkspacePicker = ({
  ee,
}: {
  ee: EventEmitterType;
}) => {
  const [open, setOpen] = React.useState(false);
  const [workspace, setWorkspace] = React.useState<string>();
  const [replyTopic, setReplyTopic] = React.useState<string>('');
  React.useEffect(() => {
    if (!ee) {
      return () => undefined;
    }
    const handler = (replyTo: string) => {
      if (replyTo) {
        setReplyTopic(replyTo);
        setOpen(true);
      }
    };

    ee.on(BUILTINS_MESSAGE_TYPES.promptForWorkspace, handler);
    return () => ee.off(BUILTINS_MESSAGE_TYPES.promptForWorkspace, handler);
  }, [ee]);
  const onCancel = React.useCallback(() => {
    setOpen(false);
    if (replyTopic) {
      ee.emit(replyTopic, { status: 'success', data: undefined });
    }
  }, [ee, replyTopic]);
  const onOk = React.useCallback(() => {
    setOpen(false);
    ee.emit(replyTopic, {
      status: 'success',
      data: workspace,
    });
  }, [ee, replyTopic, workspace]);
  if (!open) return null;
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Select a Workspace"
      onOk={onOk}
    >
      <WorkspacePicker
        onChange={setWorkspace}
        openWorkspacesOnly
        checkActiveWorkspace
        permissions={WorkspacePermissionValue.Write}
      />
    </Modal>
  );
};
