import * as React from 'react';
import { isEqual } from 'lodash';
import { useCurrentWorkspaceItem } from '../hooks';

export type WorkspaceContextType = {
  os_workspace: string | undefined;
  os_entity_uid: string | undefined;
};
export const WorkspaceContext = React.createContext<WorkspaceContextType>({
  os_workspace: undefined,
  os_entity_uid: undefined,
});

export const WorkspaceContextProvider = ({
  children,
  os_entity_uid,
  os_workspace,
}: {
  children: any;
  os_entity_uid?: string;
  os_workspace?: string;
}) => {
  const item = useCurrentWorkspaceItem(os_entity_uid || '');
  const [value, setValue] = React.useState<WorkspaceContextType>({
    os_workspace,
    os_entity_uid,
  });
  React.useEffect(() => {
    if (item) {
      const { os_workspace, os_entity_uid } = item;
      const newValue = { os_workspace, os_entity_uid };
      setValue(curr => (isEqual(curr, newValue) ? curr : newValue));
    } else {
      setValue({ os_workspace: undefined, os_entity_uid: undefined });
    }
  }, [item]);

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};
