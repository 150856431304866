import * as React from 'react';
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  FastBackwardOutlined,
  FastForwardOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignBottomOutlined,
  ForwardOutlined,
  BackwardOutlined,
  RollbackOutlined,
  EnterOutlined,
  RetweetOutlined,
  SwapOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlayCircleOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BorderBottomOutlined,
  BorderHorizontalOutlined,
  BorderInnerOutlined,
  BorderOuterOutlined,
  BorderLeftOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
  BorderVerticleOutlined,
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  QuestionOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  PauseOutlined,
  PauseCircleOutlined,
  MinusOutlined,
  MinusCircleOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  InfoOutlined,
  InfoCircleOutlined,
  ExclamationOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  CloseSquareOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  EditOutlined,
  FormOutlined,
  CopyOutlined,
  ScissorOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  DiffOutlined,
  HighlightOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BgColorsOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FontColorsOutlined,
  FontSizeOutlined,
  LineHeightOutlined,
  DashOutlined,
  SmallDashOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  DragOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  RadiusSettingOutlined,
  ColumnWidthOutlined,
  ColumnHeightOutlined,
  AreaChartOutlined,
  PieChartOutlined,
  BarChartOutlined,
  DotChartOutlined,
  LineChartOutlined,
  RadarChartOutlined,
  HeatMapOutlined,
  FallOutlined,
  RiseOutlined,
  StockOutlined,
  BoxPlotOutlined,
  FundOutlined,
  SlidersOutlined,
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
  IeOutlined,
  ChromeOutlined,
  GithubOutlined,
  AliwangwangOutlined,
  DingdingOutlined,
  WeiboSquareOutlined,
  WeiboCircleOutlined,
  TaobaoCircleOutlined,
  Html5Outlined,
  WeiboOutlined,
  TwitterOutlined,
  WechatOutlined,
  YoutubeOutlined,
  AlipayCircleOutlined,
  TaobaoOutlined,
  SkypeOutlined,
  QqOutlined,
  MediumWorkmarkOutlined,
  GitlabOutlined,
  MediumOutlined,
  LinkedinOutlined,
  GooglePlusOutlined,
  DropboxOutlined,
  FacebookOutlined,
  CodepenOutlined,
  CodeSandboxOutlined,
  AmazonOutlined,
  GoogleOutlined,
  CodepenCircleOutlined,
  AlipayOutlined,
  AntDesignOutlined,
  AntCloudOutlined,
  AliyunOutlined,
  ZhihuOutlined,
  SlackOutlined,
  SlackSquareOutlined,
  BehanceOutlined,
  BehanceSquareOutlined,
  DribbbleOutlined,
  DribbbleSquareOutlined,
  InstagramOutlined,
  YuqueOutlined,
  AlibabaOutlined,
  YahooOutlined,
  RedditOutlined,
  SketchOutlined,
  AccountBookOutlined,
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  AudioOutlined,
  AudioMutedOutlined,
  AuditOutlined,
  BankOutlined,
  BarcodeOutlined,
  BarsOutlined,
  BellOutlined,
  BlockOutlined,
  BookOutlined,
  BorderOutlined,
  BorderlessTableOutlined,
  BranchesOutlined,
  BugOutlined,
  BuildOutlined,
  BulbOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CameraOutlined,
  CarOutlined,
  CarryOutOutlined,
  CiCircleOutlined,
  CiOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
  CloudOutlined,
  CloudServerOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeOutlined,
  CoffeeOutlined,
  CommentOutlined,
  CompassOutlined,
  CompressOutlined,
  ConsoleSqlOutlined,
  ContactsOutlined,
  ContainerOutlined,
  ControlOutlined,
  CopyrightOutlined,
  CreditCardOutlined,
  CrownOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeleteColumnOutlined,
  DeleteRowOutlined,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  DingtalkOutlined,
  DisconnectOutlined,
  DislikeOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  EnvironmentOutlined,
  EuroCircleOutlined,
  EuroOutlined,
  ExceptionOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ExperimentOutlined,
  ExportOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  FileOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileMarkdownOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FilterOutlined,
  FireOutlined,
  FlagOutlined,
  FolderAddOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  FolderViewOutlined,
  ForkOutlined,
  FormatPainterOutlined,
  FrownOutlined,
  FunctionOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  FunnelPlotOutlined,
  GatewayOutlined,
  GifOutlined,
  GiftOutlined,
  GlobalOutlined,
  GoldOutlined,
  GroupOutlined,
  HddOutlined,
  HeartOutlined,
  HistoryOutlined,
  HolderOutlined,
  HomeOutlined,
  HourglassOutlined,
  IdcardOutlined,
  ImportOutlined,
  InboxOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  InsuranceOutlined,
  InteractionOutlined,
  KeyOutlined,
  LaptopOutlined,
  LayoutOutlined,
  LikeOutlined,
  LineOutlined,
  LinkOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
  LockOutlined,
  MacCommandOutlined,
  MailOutlined,
  ManOutlined,
  MedicineBoxOutlined,
  MehOutlined,
  MenuOutlined,
  MergeCellsOutlined,
  MessageOutlined,
  MobileOutlined,
  MoneyCollectOutlined,
  MonitorOutlined,
  MoreOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
  NodeIndexOutlined,
  NotificationOutlined,
  NumberOutlined,
  OneToOneOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PayCircleOutlined,
  PercentageOutlined,
  PhoneOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  PoundCircleOutlined,
  PoundOutlined,
  PoweroffOutlined,
  PrinterOutlined,
  ProfileOutlined,
  ProjectOutlined,
  PropertySafetyOutlined,
  PullRequestOutlined,
  PushpinOutlined,
  QrcodeOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RedEnvelopeOutlined,
  ReloadOutlined,
  RestOutlined,
  RobotOutlined,
  RocketOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  SaveOutlined,
  ScanOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SecurityScanOutlined,
  SelectOutlined,
  SendOutlined,
  SettingOutlined,
  ShakeOutlined,
  ShareAltOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SisternodeOutlined,
  SkinOutlined,
  SmileOutlined,
  SolutionOutlined,
  SoundOutlined,
  SplitCellsOutlined,
  StarOutlined,
  SubnodeOutlined,
  SwitcherOutlined,
  SyncOutlined,
  TableOutlined,
  TabletOutlined,
  TagOutlined,
  TagsOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToTopOutlined,
  ToolOutlined,
  TrademarkCircleOutlined,
  TrademarkOutlined,
  TransactionOutlined,
  TranslationOutlined,
  TrophyOutlined,
  UngroupOutlined,
  UnlockOutlined,
  UploadOutlined,
  UsbOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  VerifiedOutlined,
  VideoCameraAddOutlined,
  VideoCameraOutlined,
  WalletOutlined,
  WhatsAppOutlined,
  WifiOutlined,
  WomanOutlined,
} from '@ant-design/icons';

export type IconProps = {
  icon: string;
  className?: string;
  rotate?: number;
  spin?: boolean;
  style?: React.CSSProperties;
  iconRef?: React.Ref<HTMLSpanElement>;
};
/**
 * Initial implementation using ant design icons
 */
export const Icon = React.memo((props: IconProps) => {
  const { icon, className, rotate, spin, style, iconRef } = props;
  const ref = iconRef;
  if (icon?.startsWith('fa-')) {
    return (
      <i className={`fa ${icon} ${className || ''}`} style={style} ref={ref} />
    );
  }
  switch (icon) {
    case 'account-book':
      return (
        <AccountBookOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'aim':
      return (
        <AimOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'alert':
      return (
        <AlertOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'alibaba':
      return (
        <AlibabaOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'align-center':
      return (
        <AlignCenterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'align-left':
      return (
        <AlignLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'align-right':
      return (
        <AlignRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'alipay':
      return (
        <AlipayOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'alipay-circle':
      return (
        <AlipayCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'aliwangwang':
      return (
        <AliwangwangOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'aliyun':
      return (
        <AliyunOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'amazon':
      return (
        <AmazonOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'android':
      return (
        <AndroidOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ant-cloud':
      return (
        <AntCloudOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ant-design':
      return (
        <AntDesignOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'apartment':
      return (
        <ApartmentOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'api':
      return (
        <ApiOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'apple':
      return (
        <AppleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'appstore':
      return (
        <AppstoreOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'appstore-add':
      return (
        <AppstoreAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'area-chart':
      return (
        <AreaChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'arrow-down':
      return (
        <ArrowDownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'arrow-left':
      return (
        <ArrowLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'arrow-right':
      return (
        <ArrowRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'arrow-up':
      return (
        <ArrowUpOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'arrows-alt':
      return (
        <ArrowsAltOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'audio':
      return (
        <AudioOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'audio-muted':
      return (
        <AudioMutedOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'audit':
      return (
        <AuditOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'backward':
      return (
        <BackwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bank':
      return (
        <BankOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bar-chart':
      return (
        <BarChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'barcode':
      return (
        <BarcodeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bars':
      return (
        <BarsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'behance':
      return (
        <BehanceOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'behance-square':
      return (
        <BehanceSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bell':
      return (
        <BellOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bg-colors':
      return (
        <BgColorsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'block':
      return (
        <BlockOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bold':
      return (
        <BoldOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'book':
      return (
        <BookOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border':
      return (
        <BorderOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-bottom':
      return (
        <BorderBottomOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-horizontal':
      return (
        <BorderHorizontalOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-inner':
      return (
        <BorderInnerOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-left':
      return (
        <BorderLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-outer':
      return (
        <BorderOuterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-right':
      return (
        <BorderRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-top':
      return (
        <BorderTopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'border-verticle':
      return (
        <BorderVerticleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'borderless-table':
      return (
        <BorderlessTableOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'box-plot':
      return (
        <BoxPlotOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'branches':
      return (
        <BranchesOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bug':
      return (
        <BugOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'build':
      return (
        <BuildOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'bulb':
      return (
        <BulbOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'calculator':
      return (
        <CalculatorOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'calendar':
      return (
        <CalendarOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'camera':
      return (
        <CameraOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'car':
      return (
        <CarOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'caret-down':
      return (
        <CaretDownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'caret-left':
      return (
        <CaretLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'caret-right':
      return (
        <CaretRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'caret-up':
      return (
        <CaretUpOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'carry-out':
      return (
        <CarryOutOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'check':
      return (
        <CheckOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'check-circle':
      return (
        <CheckCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'check-square':
      return (
        <CheckSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'chrome':
      return (
        <ChromeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ci':
      return (
        <CiOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ci-circle':
      return (
        <CiCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'clear':
      return (
        <ClearOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'clock-circle':
      return (
        <ClockCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'close':
      return (
        <CloseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'close-circle':
      return (
        <CloseCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'close-square':
      return (
        <CloseSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cloud':
      return (
        <CloudOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cloud-download':
      return (
        <CloudDownloadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cloud-server':
      return (
        <CloudServerOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cloud-sync':
      return (
        <CloudSyncOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cloud-upload':
      return (
        <CloudUploadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'cluster':
      return (
        <ClusterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'code':
      return (
        <CodeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'code-sandbox':
      return (
        <CodeSandboxOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'codepen':
      return (
        <CodepenOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'codepen-circle':
      return (
        <CodepenCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'coffee':
      return (
        <CoffeeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'column-height':
      return (
        <ColumnHeightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'column-width':
      return (
        <ColumnWidthOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'comment':
      return (
        <CommentOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'compass':
      return (
        <CompassOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'compress':
      return (
        <CompressOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'console-sql':
      return (
        <ConsoleSqlOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'contacts':
      return (
        <ContactsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'container':
      return (
        <ContainerOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'control':
      return (
        <ControlOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'copy':
      return (
        <CopyOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'copyright':
      return (
        <CopyrightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'credit-card':
      return (
        <CreditCardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'crown':
      return (
        <CrownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'customer-service':
      return (
        <CustomerServiceOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dash':
      return (
        <DashOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dashboard':
      return (
        <DashboardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'database':
      return (
        <DatabaseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'delete':
      return (
        <DeleteOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'delete-column':
      return (
        <DeleteColumnOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'delete-row':
      return (
        <DeleteRowOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'delivered-procedure':
      return (
        <DeliveredProcedureOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'deployment-unit':
      return (
        <DeploymentUnitOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'desktop':
      return (
        <DesktopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'diff':
      return (
        <DiffOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dingding':
      return (
        <DingdingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dingtalk':
      return (
        <DingtalkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'disconnect':
      return (
        <DisconnectOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dislike':
      return (
        <DislikeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dollar':
      return (
        <DollarOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dollar-circle':
      return (
        <DollarCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dot-chart':
      return (
        <DotChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'double-left':
      return (
        <DoubleLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'double-right':
      return (
        <DoubleRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'down':
      return (
        <DownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'down-circle':
      return (
        <DownCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'down-square':
      return (
        <DownSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'download':
      return (
        <DownloadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'drag':
      return (
        <DragOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dribbble':
      return (
        <DribbbleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dribbble-square':
      return (
        <DribbbleSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'dropbox':
      return (
        <DropboxOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'edit':
      return (
        <EditOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ellipsis':
      return (
        <EllipsisOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'enter':
      return (
        <EnterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'environment':
      return (
        <EnvironmentOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'euro':
      return (
        <EuroOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'euro-circle':
      return (
        <EuroCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'exception':
      return (
        <ExceptionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'exclamation':
      return (
        <ExclamationOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'exclamation-circle':
      return (
        <ExclamationCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'expand':
      return (
        <ExpandOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'expand-alt':
      return (
        <ExpandAltOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'experiment':
      return (
        <ExperimentOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'export':
      return (
        <ExportOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'eye':
      return (
        <EyeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'eye-invisible':
      return (
        <EyeInvisibleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'facebook':
      return (
        <FacebookOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fall':
      return (
        <FallOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fast-backward':
      return (
        <FastBackwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fast-forward':
      return (
        <FastForwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'field-binary':
      return (
        <FieldBinaryOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'field-number':
      return (
        <FieldNumberOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'field-string':
      return (
        <FieldStringOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'field-time':
      return (
        <FieldTimeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file':
      return (
        <FileOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-add':
      return (
        <FileAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-done':
      return (
        <FileDoneOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-excel':
      return (
        <FileExcelOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-exclamation':
      return (
        <FileExclamationOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-gif':
      return (
        <FileGifOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-image':
      return (
        <FileImageOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-jpg':
      return (
        <FileJpgOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-markdown':
      return (
        <FileMarkdownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-pdf':
      return (
        <FilePdfOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-ppt':
      return (
        <FilePptOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-protect':
      return (
        <FileProtectOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-search':
      return (
        <FileSearchOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-sync':
      return (
        <FileSyncOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-text':
      return (
        <FileTextOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-unknown':
      return (
        <FileUnknownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-word':
      return (
        <FileWordOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'file-zip':
      return (
        <FileZipOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'filter':
      return (
        <FilterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fire':
      return (
        <FireOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'flag':
      return (
        <FlagOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'folder':
      return (
        <FolderOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'folder-add':
      return (
        <FolderAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'folder-open':
      return (
        <FolderOpenOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'folder-view':
      return (
        <FolderViewOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'font-colors':
      return (
        <FontColorsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'font-size':
      return (
        <FontSizeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fork':
      return (
        <ForkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'form':
      return (
        <FormOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'format-painter':
      return (
        <FormatPainterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'forward':
      return (
        <ForwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'frown':
      return (
        <FrownOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fullscreen':
      return (
        <FullscreenOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fullscreen-exit':
      return (
        <FullscreenExitOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'function':
      return (
        <FunctionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fund':
      return (
        <FundOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fund-projection-screen':
      return (
        <FundProjectionScreenOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'fund-view':
      return (
        <FundViewOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'funnel-plot':
      return (
        <FunnelPlotOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'gateway':
      return (
        <GatewayOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'gif':
      return (
        <GifOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'gift':
      return (
        <GiftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'github':
      return (
        <GithubOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'gitlab':
      return (
        <GitlabOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'global':
      return (
        <GlobalOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'gold':
      return (
        <GoldOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'google':
      return (
        <GoogleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'google-plus':
      return (
        <GooglePlusOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'group':
      return (
        <GroupOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'hdd':
      return (
        <HddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'heart':
      return (
        <HeartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'heat-map':
      return (
        <HeatMapOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'highlight':
      return (
        <HighlightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'history':
      return (
        <HistoryOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'holder':
      return (
        <HolderOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'home':
      return (
        <HomeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'hourglass':
      return (
        <HourglassOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'html5':
      return (
        <Html5Outlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'idcard':
      return (
        <IdcardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ie':
      return (
        <IeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'import':
      return (
        <ImportOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'inbox':
      return (
        <InboxOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'info':
      return (
        <InfoOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'info-circle':
      return (
        <InfoCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'insert-row-above':
      return (
        <InsertRowAboveOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'insert-row-below':
      return (
        <InsertRowBelowOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'insert-row-left':
      return (
        <InsertRowLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'insert-row-right':
      return (
        <InsertRowRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'instagram':
      return (
        <InstagramOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'insurance':
      return (
        <InsuranceOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'interaction':
      return (
        <InteractionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'issues-close':
      return (
        <IssuesCloseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'italic':
      return (
        <ItalicOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'key':
      return (
        <KeyOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'laptop':
      return (
        <LaptopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'layout':
      return (
        <LayoutOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'left':
      return (
        <LeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'left-circle':
      return (
        <LeftCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'left-square':
      return (
        <LeftSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'like':
      return (
        <LikeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'line':
      return (
        <LineOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'line-chart':
      return (
        <LineChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'line-height':
      return (
        <LineHeightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'link':
      return (
        <LinkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'linkedin':
      return (
        <LinkedinOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'loading':
      return (
        <LoadingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'loading3quarters':
      return (
        <Loading3QuartersOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'lock':
      return (
        <LockOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'login':
      return (
        <LoginOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'logout':
      return (
        <LogoutOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'mac-command':
      return (
        <MacCommandOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'mail':
      return (
        <MailOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'man':
      return (
        <ManOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'medicine-box':
      return (
        <MedicineBoxOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'medium':
      return (
        <MediumOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'medium-workmark':
      return (
        <MediumWorkmarkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'meh':
      return (
        <MehOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'menu':
      return (
        <MenuOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'menu-fold':
      return (
        <MenuFoldOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'menu-unfold':
      return (
        <MenuUnfoldOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'merge-cells':
      return (
        <MergeCellsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'message':
      return (
        <MessageOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'minus':
      return (
        <MinusOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'minus-circle':
      return (
        <MinusCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'minus-square':
      return (
        <MinusSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'mobile':
      return (
        <MobileOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'money-collect':
      return (
        <MoneyCollectOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'monitor':
      return (
        <MonitorOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'more':
      return (
        <MoreOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'node-collapse':
      return (
        <NodeCollapseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'node-expand':
      return (
        <NodeExpandOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'node-index':
      return (
        <NodeIndexOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'notification':
      return (
        <NotificationOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'number':
      return (
        <NumberOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'one-to-one':
      return (
        <OneToOneOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ordered-list':
      return (
        <OrderedListOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'paper-clip':
      return (
        <PaperClipOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'partition':
      return (
        <PartitionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pause':
      return (
        <PauseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pause-circle':
      return (
        <PauseCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pay-circle':
      return (
        <PayCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'percentage':
      return (
        <PercentageOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'phone':
      return (
        <PhoneOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pic-center':
      return (
        <PicCenterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pic-left':
      return (
        <PicLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pic-right':
      return (
        <PicRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'picture':
      return (
        <PictureOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pie-chart':
      return (
        <PieChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'play-circle':
      return (
        <PlayCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'play-square':
      return (
        <PlaySquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'plus':
      return (
        <PlusOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'plus-circle':
      return (
        <PlusCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'plus-square':
      return (
        <PlusSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pound':
      return (
        <PoundOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pound-circle':
      return (
        <PoundCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'poweroff':
      return (
        <PoweroffOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'printer':
      return (
        <PrinterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'profile':
      return (
        <ProfileOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'project':
      return (
        <ProjectOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'property-safety':
      return (
        <PropertySafetyOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pull-request':
      return (
        <PullRequestOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'pushpin':
      return (
        <PushpinOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'qq':
      return (
        <QqOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'qrcode':
      return (
        <QrcodeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'question':
      return (
        <QuestionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'question-circle':
      return (
        <QuestionCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radar-chart':
      return (
        <RadarChartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radius-bottomleft':
      return (
        <RadiusBottomleftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radius-bottomright':
      return (
        <RadiusBottomrightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radius-setting':
      return (
        <RadiusSettingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radius-upleft':
      return (
        <RadiusUpleftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'radius-upright':
      return (
        <RadiusUprightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'read':
      return (
        <ReadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'reconciliation':
      return (
        <ReconciliationOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'red-envelope':
      return (
        <RedEnvelopeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'reddit':
      return (
        <RedditOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'redo':
      return (
        <RedoOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'reload':
      return (
        <ReloadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rest':
      return (
        <RestOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'retweet':
      return (
        <RetweetOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'right':
      return (
        <RightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'right-circle':
      return (
        <RightCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'right-square':
      return (
        <RightSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rise':
      return (
        <RiseOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'robot':
      return (
        <RobotOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rocket':
      return (
        <RocketOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rollback':
      return (
        <RollbackOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rotate-left':
      return (
        <RotateLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'rotate-right':
      return (
        <RotateRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'safety':
      return (
        <SafetyOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'safety-certificate':
      return (
        <SafetyCertificateOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'save':
      return (
        <SaveOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'scan':
      return (
        <ScanOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'schedule':
      return (
        <ScheduleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'scissor':
      return (
        <ScissorOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'search':
      return (
        <SearchOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'security-scan':
      return (
        <SecurityScanOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'select':
      return (
        <SelectOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'send':
      return (
        <SendOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'setting':
      return (
        <SettingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'shake':
      return (
        <ShakeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'share-alt':
      return (
        <ShareAltOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'shop':
      return (
        <ShopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'shopping':
      return (
        <ShoppingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'shopping-cart':
      return (
        <ShoppingCartOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'shrink':
      return (
        <ShrinkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sisternode':
      return (
        <SisternodeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sketch':
      return (
        <SketchOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'skin':
      return (
        <SkinOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'skype':
      return (
        <SkypeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'slack':
      return (
        <SlackOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'slack-square':
      return (
        <SlackSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sliders':
      return (
        <SlidersOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'small-dash':
      return (
        <SmallDashOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'smile':
      return (
        <SmileOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'snippets':
      return (
        <SnippetsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'solution':
      return (
        <SolutionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sort-ascending':
      return (
        <SortAscendingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sort-descending':
      return (
        <SortDescendingOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sound':
      return (
        <SoundOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'split-cells':
      return (
        <SplitCellsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'star':
      return (
        <StarOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'step-backward':
      return (
        <StepBackwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'step-forward':
      return (
        <StepForwardOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'stock':
      return (
        <StockOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'stop':
      return (
        <StopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'strikethrough':
      return (
        <StrikethroughOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'subnode':
      return (
        <SubnodeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'swap':
      return (
        <SwapOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'swap-left':
      return (
        <SwapLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'swap-right':
      return (
        <SwapRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'switcher':
      return (
        <SwitcherOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'sync':
      return (
        <SyncOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'table':
      return (
        <TableOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'tablet':
      return (
        <TabletOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'tag':
      return (
        <TagOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'tags':
      return (
        <TagsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'taobao':
      return (
        <TaobaoOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'taobao-circle':
      return (
        <TaobaoCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'team':
      return (
        <TeamOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'thunderbolt':
      return (
        <ThunderboltOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'to-top':
      return (
        <ToTopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'tool':
      return (
        <ToolOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'trademark':
      return (
        <TrademarkOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'trademark-circle':
      return (
        <TrademarkCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'transaction':
      return (
        <TransactionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'translation':
      return (
        <TranslationOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'trophy':
      return (
        <TrophyOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'twitter':
      return (
        <TwitterOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'underline':
      return (
        <UnderlineOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'undo':
      return (
        <UndoOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'ungroup':
      return (
        <UngroupOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'unlock':
      return (
        <UnlockOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'unordered-list':
      return (
        <UnorderedListOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'up':
      return (
        <UpOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'up-circle':
      return (
        <UpCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'up-square':
      return (
        <UpSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'upload':
      return (
        <UploadOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'usb':
      return (
        <UsbOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'user':
      return (
        <UserOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'user-add':
      return (
        <UserAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'user-delete':
      return (
        <UserDeleteOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'user-switch':
      return (
        <UserSwitchOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'usergroup-add':
      return (
        <UsergroupAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'usergroup-delete':
      return (
        <UsergroupDeleteOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'verified':
      return (
        <VerifiedOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'vertical-align-bottom':
      return (
        <VerticalAlignBottomOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'vertical-align-middle':
      return (
        <VerticalAlignMiddleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'vertical-align-top':
      return (
        <VerticalAlignTopOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'vertical-left':
      return (
        <VerticalLeftOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'vertical-right':
      return (
        <VerticalRightOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'video-camera':
      return (
        <VideoCameraOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'video-camera-add':
      return (
        <VideoCameraAddOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'wallet':
      return (
        <WalletOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'warning':
      return (
        <WarningOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'wechat':
      return (
        <WechatOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'weibo':
      return (
        <WeiboOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'weibo-circle':
      return (
        <WeiboCircleOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'weibo-square':
      return (
        <WeiboSquareOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'whats-app':
      return (
        <WhatsAppOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'wifi':
      return (
        <WifiOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'windows':
      return (
        <WindowsOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'woman':
      return (
        <WomanOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'yahoo':
      return (
        <YahooOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'youtube':
      return (
        <YoutubeOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'yuque':
      return (
        <YuqueOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'zhihu':
      return (
        <ZhihuOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'zoom-in':
      return (
        <ZoomInOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    case 'zoom-out':
      return (
        <ZoomOutOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
    default:
      return (
        <QuestionOutlined
          className={className}
          rotate={rotate}
          spin={spin}
          style={style}
          ref={ref}
        />
      );
  }
});
