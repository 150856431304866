import _ from 'lodash';
import { Entity } from '@octostar/platform-types';

// Define the message types used for communication
export const DESKTOP_PREFIX = 'octostar:desktop:';
export const DESKTOP_MESSAGE_TYPES = Object.freeze({
  callAppService: `${DESKTOP_PREFIX}callAppService`,
  openWorkspace: `${DESKTOP_PREFIX}openWorkspace`,
  closeWorkspace: `${DESKTOP_PREFIX}closeWorkspace`,
  activeWorkspace: `${DESKTOP_PREFIX}activeWorkspace`,
  getOpenWorkspaceIds: `${DESKTOP_PREFIX}getOpenWorkspaceIds`,
  listAllWorkspaces: `${DESKTOP_PREFIX}listAllWorkspaces`,
  listOpenWorkspaces: `${DESKTOP_PREFIX}listOpenWorkspaces`,
  onWorkspaceOpened: `${DESKTOP_PREFIX}onWorkspaceOpened`,
  onWorkspaceClosed: `${DESKTOP_PREFIX}onWorkspaceClosed`,
  onWorkspaceChanged: `${DESKTOP_PREFIX}onWorkspaceChanged`,
  onWorkspaceItemChanged: `${DESKTOP_PREFIX}onWorkspaceItemChanged`,
  getWorkspace: `${DESKTOP_PREFIX}getWorkspace`,
  createWorkspace: `${DESKTOP_PREFIX}createWorkspace`,
  save: `${DESKTOP_PREFIX}save`,
  open: `${DESKTOP_PREFIX}open`,
  searchXperience: `${DESKTOP_PREFIX}searchXperience`,
  showCreateEntityForm: `${DESKTOP_PREFIX}showCreateEntityForm`,
  showTab: `${DESKTOP_PREFIX}showTab`,
  showFileUpload: `${DESKTOP_PREFIX}showFileUpload`,
  showModalTemplate: `${DESKTOP_PREFIX}showModalTemplate`,
  showContextMenu: `${DESKTOP_PREFIX}showContextMenu`,
  getAttachment: `${DESKTOP_PREFIX}getAttachment`,
  getWorkspaceItems: `${DESKTOP_PREFIX}getWorkspaceItems`,
});

export const SAVED_SEARCH_PREFIX = 'octostar:savedSearch:';
export const SAVED_SEARCH_MESSAGE_TYPES = Object.freeze({
  getRecordsCount: `${SAVED_SEARCH_PREFIX}getRecordsCount`,
  getRecordsCountQuery: `${SAVED_SEARCH_PREFIX}getRecordsCountQuery`,
  getRecords: `${SAVED_SEARCH_PREFIX}getRecords`,
  getRecordsQuery: `${SAVED_SEARCH_PREFIX}getRecordsQuery`,
  getSavedSearchPasteContext: `${SAVED_SEARCH_PREFIX}getSavedSearchPasteContext`,
});
export const ONTOLOGY_PREFIX = 'octostar:ontology:';
export const BUILTINS_PREFIX = 'octostar:desktop:builtins:';
export const BUILTINS_MESSAGE_TYPES = Object.freeze({
  callAppService: `${BUILTINS_PREFIX}callAppService`,
  showIconPicker: `${BUILTINS_PREFIX}showIconPicker`,
  showCreateTagDialog: `${BUILTINS_PREFIX}showCreateTagDialog`,
  showEditTagDialog: `${BUILTINS_PREFIX}showEditTagDialog`,
  showCreateRelationsDialog: `${BUILTINS_PREFIX}showCreateRelationsDialog`,
  showCreateAppModal: `${BUILTINS_PREFIX}showCreateAppModal`,
  showToast: `${BUILTINS_PREFIX}showToast`,
  clearToast: `${BUILTINS_PREFIX}clearToast`,
  workspaceObjectUpdate: `${BUILTINS_PREFIX}wso:update`,
  jobChanged: `${BUILTINS_PREFIX}job:changed`,
  searchXperience: `${BUILTINS_PREFIX}searchXperience`,
  contextMenuRequest: `${BUILTINS_PREFIX}contextMenuRequest`,
  dropZoneRequest: `${BUILTINS_PREFIX}dropZoneRequest`,
  contextMenuOnClose: (shortid: string) =>
    `${BUILTINS_PREFIX}contextMenuRequest:closed:${shortid}`,
  showCreateEntityForm: `${BUILTINS_PREFIX}showCreateEntityForm`,
  showFileUpload: `${BUILTINS_PREFIX}showFileUpload`,
  showProgress: (prefix?: string) =>
    `${BUILTINS_PREFIX}showProgress${_.isEmpty(prefix) ? '' : `:${prefix}`}`,
  showTab: `${BUILTINS_PREFIX}showTab`,
  closeTab: `${BUILTINS_PREFIX}closeTab`,
  beforeCloseTab: (nodeId: string) =>
    `${BUILTINS_PREFIX}beforeCloseTab:${nodeId}`,
  showConfirm: `${BUILTINS_PREFIX}showConfirm`,
  filtersUpdated: (uuid: string) => `${BUILTINS_PREFIX}filters:${uuid}`,
  showDatasetViewerDeleteModal: (uuid: string) =>
    `${BUILTINS_PREFIX}showDatasetViewerDeleteModal:${uuid}`,
  open: `${BUILTINS_PREFIX}open`,
  showDatasetModal: `${BUILTINS_PREFIX}showDatasetModal`,
  showSaveAsModal: `${BUILTINS_PREFIX}showSaveAsModal`,
  getIconConfiguration: `${BUILTINS_PREFIX}getIconConfiguration`,
  getPasteContextCount: `${BUILTINS_PREFIX}getPasteContextCount`,
  showModalTemplate: `${BUILTINS_PREFIX}showModalTemplate`,
  taskCompleted: (shortid: string) =>
    `${BUILTINS_PREFIX}taskCompleted:${shortid}`,
  consoleLogs: (entity: Entity) =>
    `${BUILTINS_PREFIX}consoleLogs:${entity.entity_id}`,
  queryExecuted: `${BUILTINS_PREFIX}queryExecuted`,
  requestNewInitialState: (nodeId: string) =>
    `${BUILTINS_PREFIX}requestNewInitialState:${nodeId}`,
  setInitialState: (nodeId: string) =>
    `${BUILTINS_PREFIX}setInitialState:${nodeId}`,
  getInitialState: (nodeId: string) =>
    `${BUILTINS_PREFIX}getInitialState:${nodeId}`,
  onInitialStateChanged: (nodeId: string) =>
    `${BUILTINS_PREFIX}onInitialStateChanged:${nodeId}`,
  onDragStart: `${BUILTINS_PREFIX}onDragStart`,
  onDragEnd: (shortid: string) => `${BUILTINS_PREFIX}onDragEnd:${shortid}`,
  promptForWorkspace: `${BUILTINS_PREFIX}promptForWorkspace`,
  promptForFolderPasteOptions: `${BUILTINS_PREFIX}promptForFolderPasteOptions`,
  showExportWorkspacesDialog: `${BUILTINS_PREFIX}showExportWorkspacesDialog`,
  showTagsContextMenu: `${BUILTINS_PREFIX}showTagsContextMenu`,
});
export const REMOTE_APP_PREFIX = 'octostar:remoteapp:';
export const REMOTE_APP_MESSAGE_TYPES = Object.freeze({
  getCurrentWorkspaceId: `${REMOTE_APP_PREFIX}getCurrentWorkspaceId`,
  context: `${REMOTE_APP_PREFIX}context`,
  transformComplete: `${REMOTE_APP_PREFIX}transformComplete`,
  contextMenuRequest: `${REMOTE_APP_PREFIX}contextMenuRequest`,
  dropZoneRequest: `${REMOTE_APP_PREFIX}dropZoneRequest`,
  onDragStart: `${REMOTE_APP_PREFIX}onDragStart`,
  whoami: `${REMOTE_APP_PREFIX}whoami`,
});
